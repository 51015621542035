import React, { createContext, useState, useCallback } from 'react';
import { Folder } from '../types';
import { FolderService } from '../services';

interface FoldersContextType {
  folders: Folder[];
  fetchFolders: () => Promise<void>;
  setFoldersForce: (folders: Folder[]) => void;
}

export const FoldersContext = createContext<FoldersContextType | undefined>(undefined);

export const FoldersProvider: React.FC<{ moduleId: string }> = ({ moduleId, children }) => {
  const [folders, setFolders] = useState<Folder[]>([]);

  const fetchFolders = useCallback(async () => {
    await FolderService.getFoldersByModule(moduleId).then((fetchedFolders: Folder[]) => {
      setFolders(fetchedFolders);
    });
  }, [moduleId]);

  const setFoldersForce = (folders: Folder[]) => {
    setFolders(folders);
  };

  return (
    <FoldersContext.Provider value={{ folders, fetchFolders, setFoldersForce }}>
      {children}
    </FoldersContext.Provider>
  );
};
