import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link, matchPath, Outlet, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AiFillHome } from 'react-icons/ai';
import { CodeDownloadDialog } from 'routes/dashboard/components/code_download_modal';
import { Authorization } from 'modules/auth/session/authorization';
import { ContextRole, RoleAuthorities } from 'modules/auth/types/auth_types';
import { default as NavbarStudio } from 'modules/designer/components/navbar';
import { ILayoutDefault, Layout as LayoutType, Modal, Page } from 'modules/designer/types';
import { LayoutService, ModalService, PageService } from 'modules/designer/services';
import { ReactComponent as BoltIcon } from '../../assets/laptop-code-solid.svg';
import Navbar from 'web_ui/navbar';
import Icon from '../../web_ui/icon';
import styles from './styles.module.css';
import { ProjectsService } from '../../modules/project/services';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import { useQuery } from 'hooks/useQuery';
import { ModuleInfo } from 'modules/dashboard/types';
import useTitle from 'hooks/useTitle';
import { AppContext } from 'modules/project/store/app_context';

export type CurrentPageLocation =
  | 'dashboard'
  | 'designer'
  | 'logic'
  | 'modeler'
  | 'logic_automation-wizard'
  | 'logic_function-editor';

export const getCurrentPageLocation = (pathName: string): CurrentPageLocation | null => {
  const matchDashboard = matchPath('/app/:app_id/dashboard/modules', pathName);
  const matchDesigner = matchPath('/app/:app_id/module/:module_id/ui/*', pathName);
  const matchLogic = matchPath('/app/:app_id/module/:module_id/logic/*', pathName);
  const matchModeler = matchPath('/app/:app_id/module/:module_id/schema/*', pathName);
  const matchAutomationWizard = matchPath(
    '/app/:app_id/module/:module_id/logic/automation-wizard',
    pathName
  );
  const matchFunctionEditor = matchPath(
    '/app/:app_id/module/:module_id/logic/service/:service_id/function-editor/:function_id',
    pathName
  );
  // This order matters. E.g.: matchAutomationWizard must be checked before matchLogic.
  if (matchFunctionEditor) return 'logic_function-editor';
  if (matchAutomationWizard) return 'logic_automation-wizard';
  if (matchDashboard) return 'dashboard';
  if (matchDesigner) return 'designer';
  if (matchLogic) return 'logic';
  if (matchModeler) return 'modeler';
  return null;
};

export const STUDIO_LOCATIONS: CurrentPageLocation[] = [
  'designer',
  'modeler',
  'logic',
  'logic_automation-wizard',
  'logic_function-editor'
];

export const checkIsStudio = (currentLocation: CurrentPageLocation | null): boolean => {
  if (!currentLocation) return false;
  if (STUDIO_LOCATIONS.includes(currentLocation)) {
    return true;
  }
  return false;
};

function Layout() {
  const queryParameters = useQuery();
  const location = useLocation();
  const { app_id, module_id } = useParams();
  const [selectedSidebarItem, setSelectedSidebarItem] = React.useState('UI');
  const [pages, setPages] = React.useState<Page[]>([]);
  const [modals, setModals] = React.useState<Modal[]>([]);
  const [layouts, setLayouts] = React.useState<LayoutType[]>([]);
  const [showDownloadDialog, setShowDownloadDialog] = React.useState<boolean>(false);
  const [layoutDefault, setLayoutDefault] = React.useState<ILayoutDefault>();
  const [moduleInfo, setModuleInfo] = React.useState<ModuleInfo>();
  const appInfo = useContext(AppContext).projectInformation;
  const { t } = useTranslation();
  const [currentLocationSubContext, setCurrentLocationSubContext] = useState('');

  const currentLocation = getCurrentPageLocation(location.pathname);

  const isStudio = checkIsStudio(currentLocation);

  useEffect(() => {
    setCurrentLocationSubContext('');
  }, [currentLocation]); // Reset every time the page changes.

  const getModuleInfo = useCallback(async () => {
    if (!module_id) return;
    const modData = await ProjectsService.getModuleById(module_id);
    setModuleInfo(modData);
  }, [module_id]);

  const fetchPages = useCallback(async () => {
    if (module_id) {
      const pages = await PageService.getPagesByModule(module_id);
      setPages(pages);
    }
  }, [module_id]);

  const fetchModals = useCallback(async () => {
    if (module_id) {
      const modals = await ModalService.getModalByModule(module_id);
      setModals(modals);
    }
  }, [module_id]);

  const fetchDefaultLayout = useCallback(async () => {
    if (app_id) {
      const layoutDef = await LayoutService.getDefaultLayout(app_id);
      setLayoutDefault(layoutDef);
    } else {
      setLayoutDefault({ defaultLayout: '' });
    }
  }, [app_id]);

  const fetchLayouts = useCallback(async () => {
    if (app_id) {
      const layouts = await LayoutService.getLayoutByApp(app_id);
      setLayouts(layouts);
    }
  }, [app_id]);

  const fetchViews = useCallback(async () => {
    if (module_id && currentLocation === 'designer') {
      await Promise.all([fetchPages(), fetchModals(), fetchLayouts(), fetchDefaultLayout()]);
    }
  }, [currentLocation, fetchDefaultLayout, fetchLayouts, fetchModals, fetchPages, module_id]);

  useEffect(() => {
    // Extract the studio mode from location.pathname
    // e.g. match[1] === "schema/829fdea7-75fd-4202-9d0c-d237567ef52f"
    const match = location.pathname.match(/\/?app\/[a-z0-9-]+\/module\/[a-z0-9-]+\/(.*)/);
    if (match) {
      // const mode = match[1].split('/')[0];
      // const secondMode = match[1].split('/')[1];
      const [mode, secondMode] = match[1].split('/');
      setSelectedSidebarItem(
        secondMode === 'automation-wizard' ? secondMode.toUpperCase() : mode.toUpperCase()
      );
    }
  }, [location.pathname]);

  const isFromVsCodeExt = (): boolean => {
    const itemFound = queryParameters.get('vscode');
    if (itemFound) {
      return Boolean(itemFound);
    } else {
      return false;
    }
  };

  useEffect(() => {
    Promise.all([fetchViews(), getModuleInfo()]);
  }, [fetchViews, getModuleInfo]);

  useTitle(
    appInfo?.name && moduleInfo?.name
      ? `${
          currentLocation === 'designer'
            ? 'Designer'
            : currentLocation === 'modeler'
            ? 'DB Modeler'
            : 'Logic Builder'
        } · ${moduleInfo.name} - ${appInfo.name}`
      : 'Exocoding',
    currentLocation,
    moduleInfo
  );

  const handleSetCurrentLocationSubContext = (subContext: string) => {
    setCurrentLocationSubContext(subContext);
  };

  return (
    <div>
      {isStudio && appInfo && (
        <NavbarStudio
          isFromVsCodeExtension={isFromVsCodeExt()}
          context={currentLocation}
          subContext={currentLocationSubContext}
        />
      )}
      {isStudio != null && !isStudio && (
        <Navbar
          context={isFromVsCodeExt() ? 'vscode' : 'default'}
          currentPageLocation={currentLocation ?? undefined}
        />
      )}
      <div className="container-fluid">
        <div className="row">
          {isStudio && !isFromVsCodeExt() && (
            <div className={styles.LeftSidebarContainer}>
              <div id="studioList" className={styles.LeftSidebarList}>
                {appInfo?.has_database && (
                  <Authorization
                    context={ContextRole.APP}
                    allowedAuthorities={[RoleAuthorities.MANAGE_DB_MODELER]}
                  >
                    <HelpPopover
                      placement="right"
                      helpBoxProps={{
                        title: t('appResume.OpenDBStudio') ?? ''
                      }}
                    >
                      <Link
                        to={`/app/${app_id}/module/${module_id}/schema/829fdea7-75fd-4202-9d0c-d237567ef52f`}
                        className={styles.LeftSidebarSpaceItems}
                      >
                        <div
                          id={'DB-Button'}
                          className={`${styles.LeftSidebarListItem} ${
                            selectedSidebarItem === 'SCHEMA' && styles.LeftSidebarSelectedItem
                          }`}
                        >
                          <Icon iconName="database" />
                        </div>
                      </Link>
                    </HelpPopover>
                  </Authorization>
                )}
                {appInfo?.has_backend && (
                  <Authorization
                    context={ContextRole.APP}
                    allowedAuthorities={[RoleAuthorities.MANAGE_LOGIC_BUILDER]}
                  >
                    <HelpPopover
                      placement="right"
                      helpBoxProps={{
                        title: t('appResume.OpenLogicBuilder') ?? ''
                      }}
                    >
                      <Link
                        to={`/app/${app_id}/module/${module_id}/logic`}
                        className={styles.LeftSidebarSpaceItems}
                      >
                        <div
                          id={'LB-Button'}
                          className={`${styles.LeftSidebarListItem} ${
                            selectedSidebarItem === 'LOGIC' && styles.LeftSidebarSelectedItem
                          }`}
                        >
                          <Icon iconName="code-branch" />
                        </div>
                      </Link>
                    </HelpPopover>
                  </Authorization>
                )}
                {appInfo?.has_frontend && (
                  <Authorization
                    context={ContextRole.APP}
                    allowedAuthorities={[RoleAuthorities.MANAGE_INTERFACE]}
                  >
                    <HelpPopover
                      helpBoxProps={{
                        title: `${t('appResume.OpenUIStudio')}`
                      }}
                      placement="right"
                    >
                      <Link
                        to={`/app/${app_id}/module/${module_id}/ui`}
                        className={styles.LeftSidebarSpaceItems}
                      >
                        <div
                          id={'UI-Button'}
                          className={`${styles.LeftSidebarListItem} ${
                            selectedSidebarItem === 'UI' && styles.LeftSidebarSelectedItem
                          }`}
                        >
                          <Icon iconName="window-maximize" />
                        </div>
                      </Link>
                    </HelpPopover>
                  </Authorization>
                )}
                <hr className={styles.SidebarSeparator} />

                <Authorization
                  context={ContextRole.APP}
                  allowedAuthorities={[RoleAuthorities.MANAGE_LOGIC_BUILDER]}
                >
                  <HelpPopover
                    placement="right"
                    helpBoxProps={{
                      title: t('appResume.OpenLab') ?? ''
                    }}
                  >
                    <div
                      id={'labButton'}
                      className={`${styles.LeftSidebarListItem} ${
                        selectedSidebarItem === 'LAB' && styles.LeftSidebarSelectedItem
                      }`}
                    >
                      <Icon iconName="flask" />
                    </div>
                  </HelpPopover>
                </Authorization>
                <hr className={styles.SidebarSeparator} />
                {appInfo?.has_backend && (
                  <Authorization
                    context={ContextRole.APP}
                    allowedAuthorities={[RoleAuthorities.GENERATE_CODE]}
                  >
                    <HelpPopover
                      placement="right"
                      helpBoxProps={{
                        title: t('appResume.CodeAssistant') ?? ''
                      }}
                    >
                      <Link
                        to={`/app/${app_id}/module/${module_id}/logic/automation-wizard`}
                        state={{ step: 0 }}
                        className={styles.LeftSidebarSpaceItems}
                      >
                        <div
                          id={'assistantButton'}
                          className={`${styles.LeftSidebarListItem} ${
                            selectedSidebarItem === 'AUTOMATION-WIZARD' &&
                            styles.LeftSidebarSelectedItem
                          }`}
                        >
                          <Icon iconName="bolt-lightning" />
                        </div>
                      </Link>
                    </HelpPopover>
                  </Authorization>
                )}
              </div>
              <div className={`pb-3 ${styles.LeftSidebarList}`}>
                <Authorization
                  context={ContextRole.APP}
                  allowedAuthorities={[RoleAuthorities.GENERATE_CODE]}
                >
                  <HelpPopover
                    placement="right"
                    helpBoxProps={{
                      title: t('appResume.Download') ?? ''
                    }}
                  >
                    <div style={{ cursor: 'pointer' }} onClick={() => setShowDownloadDialog(true)}>
                      <div id={'downloadCodeButton'} className={styles.LeftSidebarListItem}>
                        <BoltIcon color="white" className={styles.svgIcon} />
                      </div>
                    </div>
                  </HelpPopover>
                </Authorization>
                <HelpPopover
                  placement="right"
                  helpBoxProps={{
                    title: t('appResume.Homepage') ?? ''
                  }}
                >
                  <Link to={`/homepage`}>
                    <div
                      id="backToHomepage"
                      className={`text-center ${styles.LeftSidebarListItem}`}
                    >
                      <AiFillHome />
                    </div>
                  </Link>
                </HelpPopover>
                <p className="text-secondary text-center mb-0 mt-2">
                  <small>
                    {process.env.REACT_APP_VERSION}
                    {process.env.REACT_APP_BUILD && (
                      <>
                        {/* staging and dev */}
                        {window.location.href.includes('cloudfront.net') ||
                        window.location.href.includes('dev.exocoding') ? (
                          <>+{process.env.REACT_APP_BUILD}</>
                        ) : (
                          <p>Beta</p>
                        )}
                        <br />
                      </>
                    )}
                  </small>
                </p>
              </div>
            </div>
          )}
          {/* The page content. */}
          <div className="col p-0 m-0" style={{ width: 'calc(100vw - 64px)' }}>
            <Outlet
              context={{
                pages,
                modals,
                layouts,
                fetchViews,
                layoutDefault,
                setCurrentLocationSubContext: handleSetCurrentLocationSubContext
              }}
            />
          </div>
        </div>
      </div>
      {appInfo && (
        <CodeDownloadDialog
          app={appInfo}
          show={showDownloadDialog}
          withPR={appInfo.createPR}
          onClose={() => setShowDownloadDialog(false)}
        />
      )}
    </div>
  );
}

export default Layout;
