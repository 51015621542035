/**
 * API error message to i18n.
 * Returns a generic error message i18n key if the error message fails at parsing.
 */
export const apiErrorMessageToI18n = (errorMessage?: string): string => {
  //   const generic = 'inputValidationErrorMessages.GenericApiErrorMessage';
  const generic = errorMessage ?? '';

  if (!errorMessage) return generic;
  const codes = errorMessage.split(';');
  if (codes[0] === 'DUPLICATE_FIELD') {
    if (codes[1] === 'PROJECT_NAME') {
      return 'inputValidationErrorMessages.ProjectNameDuplicated';
    }
    return generic;
  }
  return generic;
};
