import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate, useParams } from 'react-router-dom';
import { FrontendFunction, FunctionTypes, ViewUUID } from '../../../types';
import { addFunction, updateFunction } from '../../store/actions/functions';
import { validateRegex } from 'utils/utils';
import { InterfaceStudioState } from '../../store';
import { useTranslation } from 'react-i18next';
import { FunctionEditorState } from 'web_ui/function_editor/store/types/function_editor_state';
import { FunctionType } from 'web_ui/function_editor/store/types/functions';
import { setErrorMessage } from '../../store/actions/studio';
import { FunctionSimple } from 'modules/logic_builder/types';
import { useQuery } from 'hooks/useQuery';

type CreateFunctionDialogProps = {
  view_id: ViewUUID;
  show: boolean;
  onClose: () => void;
  func?: FrontendFunction;
};

function CreateFunctionDialog(props: CreateFunctionDialogProps) {
  const [newFunction, setNewFunction] = useState<FunctionSimple>({
    name: '',
    description: '',
    serviceUuid: props.view_id
  });
  const [validated, setValidated] = useState(false);
  const [functions, setFunctions] = useState<FunctionType[]>([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryParameters = useQuery();
  const { t } = useTranslation();
  const functionNamePattern = /^[a-zA-Z_$][a-zA-Z_$0-9]*$/;
  const functionsKeyPair = useSelector((state: FunctionEditorState) => state.functions);
  const { app_id, module_id } = useParams();

  const isFromVsCodeExt = (): boolean => {
    const itemFound = queryParameters.get('vscode');
    if (itemFound) {
      return Boolean(itemFound);
    } else {
      return false;
    }
  };

  useEffect(() => {
    setFunctions(Object.values(functionsKeyPair));
  }, [functionsKeyPair]);

  useEffect(() => {
    if (!props.func) {
      setNewFunction({
        name: '',
        description: '',
        serviceUuid: props.view_id
      });
    } else {
      setNewFunction({
        name: props.func.name,
        description: props.func.description ?? '',
        serviceUuid: props.view_id
      });
    }
  }, [props]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }

    setValidated(false);

    const { name, description } = newFunction;

    if (!name) return;

    const functionInfo: FrontendFunction = {
      uuid: props.func?.uuid ?? uuidv4(),
      name,
      description,
      customComponent: '',
      view: props.view_id
    };

    try {
      if (functions.findIndex((item) => item.name === functionInfo.name) !== -1) {
        throw new Error('Name already exist');
      }

      if (props.func?.uuid) {
        dispatch(updateFunction(functionInfo));
      } else {
        dispatch(addFunction(functionInfo));
      }

      setTimeout(() => {
        navigate(
          isFromVsCodeExt()
            ? `/app/${app_id}/module/${module_id}/ui/${functionInfo.view}/function-editor/${functionInfo.uuid}?vscode=true`
            : `/app/${app_id}/module/${module_id}/ui/${functionInfo.view}/function-editor/${functionInfo.uuid}`,
          {
            replace: true
          }
        );
      }, 500);
    } catch (e) {
      dispatch(setErrorMessage(t('designer.functions.errorSameName')));
      return;
    }

    props.onClose();
  };

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewFunction((prev) => ({
      ...prev,
      name: e.target.value
    }));
  };

  const onChangeDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewFunction((prev) => ({
      ...prev,
      description: e.target.value
    }));
  };

  return (
    <Modal centered show={props.show} onHide={props.onClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {props.func ? `${t('appResume.module.Update')}` : `${t('appResume.module.Create')}`}{' '}
          {t('appResume.module.function')}
        </Modal.Title>
      </Modal.Header>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Body id="bodyModal">
          <Form.Group className="mb-3">
            <Form.Label>{t('appResume.module.Name')}</Form.Label>
            <Form.Control
              id="formName"
              type="text"
              value={newFunction.name}
              onChange={onChangeName}
              required
              isValid={validateRegex(functionNamePattern, newFunction.name)}
              isInvalid={!validateRegex(functionNamePattern, newFunction.name)}
            />
            <Form.Control.Feedback type="invalid">
              {`${t('appResume.module.nameMustStart')}`}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formDescription">
            <Form.Label>{t('logicBuilder.newController.description')}</Form.Label>
            <Form.Control
              as="textarea"
              placeholder={`${t('logicBuilder.newController.description')}`}
              rows={2}
              maxLength={255}
              value={newFunction.description}
              onChange={onChangeDescription}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" disabled={!newFunction.name}>
            {props.func ? `${t('appResume.module.Save')}` : `${t('appResume.module.Create')}`}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default CreateFunctionDialog;
