import React, { DragEvent, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from 'modules/logic_builder/styles.module.css';
import {
  LogicBuilderConcept,
  getLogicBuilderConceptInfo,
  SHOW_ALL_URL,
  Controller
} from 'modules/logic_builder/types';
import { ControllerCreatorDialog } from 'modules/logic_builder/components/dialogs/controller_creator_dialog';
import LogicBuilderContext from 'modules/logic_builder/store';
import { ObjectsService, ControllersService } from 'modules/logic_builder/services';
import { useNavigate, useParams } from 'react-router-dom';
import SearchBar from 'web_ui/search_bar';
import { LocalStorageManager } from 'utils/localstorage';
import { Table } from '../../../../modeler/types';
import { Folder, FolderType } from 'modules/designer/types';
import { ListGroup } from 'react-bootstrap';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import Icon from 'web_ui/icon';
import { ControllerEditorDialog } from 'modules/logic_builder/components/dialogs';
import CreateFolderDialog from 'modules/designer/studio/toolbars/views_toolbar/create_folder_modal';
import { ContextMenuItem, FolderItem, RenderFolders } from 'web_ui/folders';
import {
  checkFolderNameAlreadyExists,
  deletefolder,
  lb_dragleave,
  lb_dragover,
  lb_dragstart,
  lb_drop,
  lb_saveitem,
  savefolder,
  updateFoldersOnCreate,
  updateFoldersOnDelete,
  updateFoldersOnMoveFolder,
  updateFoldersOnRename
} from 'web_ui/folders/callbacks';
import { validateControllerName, validateFolderName } from 'utils/inputValidation';
import {
  DraggableResource,
  TargetType
} from 'web_ui/folders/draggable_resource/draggable_resource';
import { CodePreviewType } from 'web_ui/code_editor_modal/editor';
import CodeEditorModal from 'web_ui/code_editor_modal';

function ControllersToolbar() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { app_id, module_id, controller_id } = useParams();
  const [filterValue, setFilterValue] = useState('');
  const [showControllerModal, setShowControllerModal] = useState(false);
  const {
    controllers,
    deleteController,
    changeController,
    controllersFolders,
    updateFolders,
    navigateToConcept,
    setLastSelectedConcept,
    checkRepeatedController
  } = useContext(LogicBuilderContext);
  const [tables, setTables] = useState<Table[]>([]);
  const getNestedItems = (): Record<string, boolean> => {
    if (!module_id || !app_id) {
      return {};
    }
    const getItems = LocalStorageManager.getValueLocalStorageState(app_id, module_id);
    if (getItems[module_id] && getItems[module_id].logicBuilder.folderState.endpoints) {
      return getItems[module_id].logicBuilder.folderState.endpoints;
    }
    return {};
  };
  const [showNested, setShowNested] = useState<Record<string, boolean>>(() => getNestedItems());
  const [folderItems, setFolderItems] = useState<FolderItem[]>([]);
  const [showFolderDialog, setShowFolderDialog] = useState<string>();
  const [showUpdateControllerDialog, setShowUpdateControllerDialog] = useState<string>();
  const [previewControllerUuid, setPreviewControllerUuid] = useState<string>('');

  const controllerIds = Object.keys(controllers);
  const CONCEPT = LogicBuilderConcept.ENDPOINT;

  const updateShowNestedLocalStorage = useCallback(
    (next: Record<string, boolean>) => {
      if (!app_id || !module_id) {
        return;
      }
      const copying = LocalStorageManager.getValueLocalStorageState(app_id, module_id);
      copying[module_id] = {
        ...copying[module_id],
        logicBuilder: {
          ...copying[module_id].logicBuilder,
          folderState: {
            ...copying[module_id].logicBuilder.folderState,
            endpoints: next
          }
        }
      };
      LocalStorageManager.setValueLocalStorageState(app_id, copying);
    },
    [app_id, module_id]
  );

  const toggleCollapseFolder = useCallback(
    (folderId: string, collapsed: boolean): void => {
      const next: Record<string, boolean> = {
        ...showNested,
        [folderId]: collapsed
      };
      setShowNested(next);
      updateShowNestedLocalStorage(next);
    },
    [showNested, updateShowNestedLocalStorage]
  );

  const reloadControllers = React.useCallback(async () => {
    if (!module_id) return;
    const items: FolderItem[] = [];
    for (const c of Object.values(controllers)) {
      items.push({ ...c, uuid: c.uuid ?? '', type: 'CONTROLLER' });
    }
    setFolderItems(items);
  }, [module_id, controllers]);

  useEffect(() => {
    reloadControllers();
  }, [reloadControllers]);

  // ! Fix deps: navigate and navigateToConcept are probably wrong.
  const handleSelectController = useCallback(
    (controllerId: string) => {
      if (!app_id || !module_id) return;
      navigateToConcept(CONCEPT, controllerId);
      setLastSelectedConcept(CONCEPT, controllerId ?? SHOW_ALL_URL);
      const currState = LocalStorageManager.getValueLocalStorageState(app_id, module_id);
      currState[module_id] = {
        ...currState[module_id],
        logicBuilder: {
          ...currState[module_id].logicBuilder,
          lastSelectedController: controllerId
        }
      };
      LocalStorageManager.setValueLocalStorageState(app_id, currState);
    },
    [CONCEPT, app_id, module_id]
  );

  const handleDeleteController = useCallback(
    async (deleteController: any): Promise<void> => {
      if (!module_id) return;
      await ControllersService.deleteController(deleteController).then(() => {
        if (controller_id === deleteController) {
          navigate(`/app/${app_id}/module/${module_id}/logic/controller/${SHOW_ALL_URL}`, {
            replace: true
          });
          setLastSelectedConcept(CONCEPT, SHOW_ALL_URL);
        }
      });
    },
    [module_id, controller_id, app_id, CONCEPT]
  );

  const firstControllerId = controllerIds[0];
  useEffect(() => {
    if (!module_id || !app_id) {
      return;
    }
    ObjectsService.getObjectsSchema(module_id, true).then((schema) => {
      setTables(schema?.tables ? schema.tables : []);
    });
    const currState = LocalStorageManager.getValueLocalStorageState(app_id, module_id);
    if (!currState[module_id].logicBuilder.lastSelectedController) {
      navigateToConcept(CONCEPT, firstControllerId);
      setLastSelectedConcept(CONCEPT, firstControllerId);
      return;
    }
  }, [CONCEPT, app_id, module_id, firstControllerId]);

  const toSingular = (word: string) => {
    if (word.endsWith('s')) {
      return word.slice(0, -1);
    }
    return word;
  };

  const openAllFolders = useCallback(() => {
    const next: Record<string, boolean> = {};
    for (const folder of controllersFolders ?? []) {
      next[folder.uuid] = true;
    }
    setShowNested(next);
    updateShowNestedLocalStorage(next);
  }, [controllersFolders, updateShowNestedLocalStorage]);

  const closeAllFolders = useCallback(() => {
    setShowNested({});
    updateShowNestedLocalStorage({});
  }, [updateShowNestedLocalStorage]);

  const folderNameIsValid = (nextParent: string, nextName: string) => {
    if (checkFolderNameAlreadyExists(nextParent, nextName, controllersFolders ?? [])) {
      return false;
    } else if (!validateFolderName(nextName)) {
      return false;
    }
    return true;
  };

  const handleUpdateFolders = useCallback(
    (f: Folder[]) => {
      updateFolders(f, 'controllers');
    },
    [updateFolders]
  );

  const handleDrop = useCallback(
    (sourceId: string, sourceType: TargetType, newParentFolder: string) => {
      if (!controllersFolders) return;
      updateFoldersOnMoveFolder(
        controllersFolders,
        sourceId,
        newParentFolder,
        sourceType,
        handleUpdateFolders
      );
      const findObject = Object.values(controllers).find((o) => o.uuid === sourceId);
      if (findObject) {
        changeController({ ...findObject, folder_id: newParentFolder });
      }
    },
    [changeController, handleUpdateFolders, controllers, controllersFolders]
  );

  const getCallbacks = useCallback((): Record<string, Record<string, any>> | undefined => {
    if (!module_id || !controllersFolders) {
      return undefined;
    }
    const callbacks: Record<string, Record<string, any>> = {};
    // Folder items callbacks.
    for (const folderItem of folderItems) {
      callbacks[folderItem.uuid] = {};
      callbacks[folderItem.uuid].select = () => handleSelectController(folderItem.uuid);
      callbacks[folderItem.uuid].save = (newName: string) => {
        lb_saveitem(folderItem.uuid, 'CONTROLLER', newName);
        changeController({ ...folderItem, name: newName } as unknown as Controller);
      };
      callbacks[folderItem.uuid].drop = async (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
        await lb_drop(e, 'folder', el, folderItem.folder_id ?? '', module_id).then((s) => {
          if (!s) return;
          handleDrop(s.sourceId, s.sourceType, folderItem.folder_id ?? '');
        });
      callbacks[folderItem.uuid].dragstart = (e: DragEvent<HTMLDivElement>) =>
        lb_dragstart(e, folderItem.uuid, folderItem.folder_id ?? '', 'CONTROLLER', 'folderItem');
      callbacks[folderItem.uuid].dragleave = (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
        lb_dragleave(e, el);
      callbacks[folderItem.uuid].dragover = (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
        lb_dragover(e, el, 'folderItem');
      callbacks[folderItem.uuid].delete = async () =>
        await handleDeleteController(folderItem.uuid).then(() => deleteController(folderItem.uuid));
      callbacks[folderItem.uuid].select = async () => handleSelectController(folderItem.uuid);
      callbacks[folderItem.uuid].validate = (name: string): string => {
        const changedCtrl = { ...folderItem, name: name } as unknown as Controller;
        if (!validateControllerName(name, 64).valid) {
          return 'inputValidationErrorMessages.GenericErrorMessage';
        } else if (checkRepeatedController(changedCtrl)) {
          return 'inputValidationErrorMessages.GenericErrorMessage';
        }
        return '';
      };
    }
    // Folders callbacks.
    let checkFolders: Folder[] = [...controllersFolders];
    while (checkFolders.length) {
      const folder = checkFolders.pop();
      if (!folder) continue;
      checkFolders = checkFolders.concat(folder.folders ? (folder.folders as Folder[]) : []);
      callbacks[folder.uuid] = {};
      callbacks[folder.uuid].save = (newName: string) => {
        savefolder(module_id, folder.uuid, newName);
        updateFoldersOnRename(controllersFolders, folder.uuid, newName, handleUpdateFolders);
      };
      callbacks[folder.uuid].drop = async (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
        await lb_drop(e, 'folder', el, folder.uuid, module_id).then((s) => {
          if (!s) return;
          handleDrop(s.sourceId, s.sourceType, folder.uuid);
        });
      callbacks[folder.uuid].dragstart = (e: DragEvent<HTMLDivElement>) =>
        lb_dragstart(e, folder.uuid, folder.parent ?? '', '', 'folder');
      callbacks[folder.uuid].dragleave = (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
        lb_dragleave(e, el);
      callbacks[folder.uuid].dragover = (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
        lb_dragover(e, el, 'folder');
      callbacks[folder.uuid].delete = async () =>
        await deletefolder(module_id, folder.uuid).then(() =>
          updateFoldersOnDelete(controllersFolders, folder.uuid, handleUpdateFolders)
        );
    }
    return callbacks;
  }, [
    changeController,
    checkRepeatedController,
    deleteController,
    folderItems,
    handleDeleteController,
    handleDrop,
    handleSelectController,
    handleUpdateFolders,
    module_id,
    controllersFolders
  ]);

  const getMenuCallbacks = useCallback((): Record<string, ContextMenuItem[]> | undefined => {
    const callbacks: Record<string, ContextMenuItem[]> = {};
    if (!module_id || !controllersFolders) {
      return undefined;
    }
    // Folder callbacks.
    let checkFolders: Folder[] = [...controllersFolders];
    while (checkFolders.length) {
      const folder = checkFolders.pop();
      if (!folder) {
        continue;
      }
      checkFolders = checkFolders.concat(folder.folders ? (folder.folders as Folder[]) : []);
      callbacks[folder.uuid] = [];
    }
    // Folder items callbacks.
    for (const item of folderItems) {
      callbacks[item.uuid] = [];
      callbacks[item.uuid].push({
        label: 'Edit',
        icon: 'pencil',
        onClick: () => setShowUpdateControllerDialog(item.uuid)
      });
      callbacks[item.uuid].push({
        label: 'CodePreview',
        icon: 'code',
        onClick: () => setPreviewControllerUuid(item.uuid)
      });
    }
    return callbacks;
  }, [folderItems, controllersFolders, module_id]);

  const getRootCallbacks = useCallback(() => {
    const callbacks: Record<string, Record<string, any>> = {};
    if (!module_id) return callbacks;
    callbacks['root'] = {};
    callbacks['root'].drop = async (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
      await lb_drop(e, 'root', el, '', module_id).then((s) => {
        if (!s) return;
        handleDrop(s.sourceId, s.sourceType, '');
      });
    callbacks['root'].dragstart = (e: DragEvent<HTMLDivElement>) =>
      lb_dragstart(e, '', '', '', 'root');
    callbacks['root'].dragleave = (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
      lb_dragleave(e, el);
    callbacks['root'].dragover = (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
      lb_dragover(e, el, 'root');
    return callbacks;
  }, [handleDrop, module_id]);

  const rootCallbacks = getRootCallbacks()['root'];

  return (
    <div className={'d-flex flex-column h-100 flex-grow-1'} style={{ width: '100%' }}>
      <div className="p-2">
        <SearchBar
          id={'findObj'}
          placeholder={t('Search') ?? ''}
          text={filterValue}
          setText={setFilterValue}
        />
      </div>
      <div className={`${styles.layersContainer}`}>
        <div
          className="font-weight-bold ms-3 pb-2 border-bottom border-3 border-body-emphasis me-2 mb-1 d-flex justify-content-between align-items-center"
          style={{ fontWeight: 600 }}
        >
          <label id="label">{'Controllers'}</label>
          <ListGroup horizontal>
            <HelpPopover
              helpBoxProps={{
                title: `${t('designer.views.FolderCollapse')}`
              }}
              placement="top"
            >
              <ListGroup.Item
                action
                className="border-0 m-0 p-0 me-2"
                onClick={() => closeAllFolders()}
              >
                <Icon iconName="down-left-and-up-right-to-center" />
              </ListGroup.Item>
            </HelpPopover>
            <HelpPopover
              helpBoxProps={{
                title: `${t('designer.views.FolderExpand')}`
              }}
              placement="top"
            >
              <ListGroup.Item
                action
                className="border-0 m-0 p-0 me-2"
                onClick={() => openAllFolders()}
              >
                <Icon iconName="up-right-and-down-left-from-center" />
              </ListGroup.Item>
            </HelpPopover>
            <HelpPopover
              helpBoxProps={{
                title: `${t('designer.views.NewRootFolder')}`
              }}
              placement="top"
            >
              <ListGroup.Item
                id="createFolderButton"
                action
                className="border-0 m-0 p-0"
                onClick={() => {
                  setShowFolderDialog('');
                }}
              >
                <Icon iconName="folder-plus" />
              </ListGroup.Item>
            </HelpPopover>
            <HelpPopover
              placement={'top'}
              helpBoxProps={
                {
                  title: `${t('New')} ${toSingular(
                    t(getLogicBuilderConceptInfo(CONCEPT).parentTitle)
                  )}`
                } ?? ''
              }
            >
              <div
                id={`create${t(getLogicBuilderConceptInfo(CONCEPT).parentTitle)}Button`}
                className={styles.viewHeaderNewView}
                onClick={(ev) => {
                  ev.preventDefault();
                  ev.stopPropagation();
                  setShowControllerModal(true);
                }}
                style={{ cursor: 'pointer', marginLeft: 5 }}
              >
                <Icon iconName="plus" extraProps="fa-lg" />
              </div>
            </HelpPopover>
          </ListGroup>
        </div>
        <div style={{ overflowX: 'auto', width: '100%', height: '100%' }}>
          {rootCallbacks && (
            <DraggableResource
              handleDrop={getRootCallbacks()['root'].drop}
              handleDragStart={getRootCallbacks()['root'].dragstart}
              handleDragOver={getRootCallbacks()['root'].dragover}
              handleLeave={getRootCallbacks()['root'].dragleave}
              className={styles.rootDraggableArea}
            >
              <div className={`p-3 pt-0`}>
                <RenderFolders
                  folders={controllersFolders ?? []}
                  collapsedFolders={showNested}
                  toggleCollapsedFolder={toggleCollapseFolder}
                  items={folderItems}
                  selectedItem={controller_id ?? ''}
                  callbacks={getCallbacks()}
                  itemFilter={filterValue}
                  contextMenuItems={getMenuCallbacks()}
                  setFolders={handleUpdateFolders}
                  checkFolderNameExists={(parentId: string, name: string, folderId: string) =>
                    checkFolderNameAlreadyExists(parentId, name, controllersFolders ?? [], folderId)
                  }
                />
              </div>
            </DraggableResource>
          )}
        </div>
      </div>
      <ControllerCreatorDialog
        tables={tables}
        show={showControllerModal}
        onClose={() => setShowControllerModal(false)}
      />
      {showFolderDialog != null && (
        <CreateFolderDialog
          show={showFolderDialog != null}
          onClose={() => {
            setShowFolderDialog(undefined);
          }}
          parentUuid={showFolderDialog}
          onCreate={(f: Folder) =>
            updateFoldersOnCreate(controllersFolders ?? [], f, handleUpdateFolders)
          }
          type={FolderType.LG_CONTROLLER}
          validateFolderName={(parent: string, name: string) => folderNameIsValid(parent, name)}
        />
      )}
      {showUpdateControllerDialog != null && (
        <ControllerEditorDialog
          controllerUuid={showUpdateControllerDialog}
          show={showUpdateControllerDialog != null}
          onClose={() => setShowUpdateControllerDialog(undefined)}
        />
      )}
      {previewControllerUuid && (
        <CodeEditorModal
          show={previewControllerUuid !== ''}
          handleClose={() => setPreviewControllerUuid('')}
          id={previewControllerUuid}
          previewType={CodePreviewType.CONTROLLER}
        />
      )}
    </div>
  );
}

export default ControllersToolbar;
