import React, { DragEvent, MouseEvent, useCallback, useEffect, useRef } from 'react';
import styles from './style.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { MoveSource } from '../../store/actions/links';
import { setDraggedComponent, setSelectedComponent } from '../../store/actions/studio';
import { LinkInfo, deleteComponent, extractForm } from '../../store/actions/root';
import {
  getNextComponent,
  getParentIdFromComponent,
  getPreviousComponent
} from '../../designer_utils';
import { InterfaceStudioState } from '../../store';
import { v4 as uuidv4 } from 'uuid';
import { duplicateComponent } from '../../store/actions/components';
import {
  FRONTEND_EVENT_TYPES,
  LayoutComponent,
  Message,
  MessageTypes
} from 'modules/designer/types';
import { ConfirmationInfo, ConvertCustomComponentInfo } from '../../components/viewport';
import { COMPONENT_TYPES, COMPONENTS_TEMPLATE } from '../../exocode_components';
import { faker } from '@faker-js/faker';
import produce from 'immer';
import { SchemaItem } from 'modules/logic_builder/types';
import { useTranslation } from 'react-i18next';
import { FormContent, FormNestedObjectInfo } from '../../exocode_components/form';
import { useCheckShowGrid } from 'modules/designer/hooks/useCheckIsGridColumn';
import { useDeleteGridColumn } from 'modules/designer/hooks/useDeleteGridColumn';

export const TOOLBAR_ACTIONS: Record<string, any> = {
  SELECT_PARENT_COMPONENT: {
    action: 'SELECT_PARENT_COMPONENT',
    icon: 'fa-solid fa-arrow-turn-up',
    tooltip: '',
    event: 'click',
    cursor: 'pointer',
    label: 'Select parent component',
    allowedComponents: ['CUSTOM', 'DEFAULT']
  },
  SELECT_PREVIOUS_COMPONENT: {
    action: 'SELECT_PREVIOUS_COMPONENT',
    icon: 'fa-solid fa-arrow-up',
    tooltip: '',
    event: 'click',
    cursor: 'pointer',
    label: 'Select previous component',
    allowedComponents: ['CUSTOM', 'DEFAULT']
  },
  SELECT_NEXT_COMPONENT: {
    action: 'SELECT_NEXT_COMPONENT',
    icon: 'fa-solid fa-arrow-down',
    tooltip: '',
    event: 'click',
    cursor: 'pointer',
    label: 'Select next component',
    allowedComponents: ['CUSTOM', 'DEFAULT']
  },
  CONVERT_COMPONENT_TO_CUSTOM_COMPONENT: {
    action: 'CONVERT_COMPONENT_TO_CUSTOM_COMPONENT',
    icon: 'fa-regular fa-floppy-disk',
    tooltip: '',
    event: 'click',
    cursor: 'pointer',
    label: 'Convert to custom component',
    allowedComponents: ['DEFAULT'],
    allowedTypes: ['CONTAINER']
  },
  COPY_COMPONENT: {
    action: 'COPY_COMPONENT',
    icon: 'fa-solid fa-copy',
    tooltip: '',
    event: 'click',
    cursor: 'pointer',
    label: 'Duplicate',
    subMenu: {
      EDIT_TEsT_COMPONENT: {
        action: 'EDIT_TEXT_COMPONENT',
        icon: 'fa-solid fa-pencil',
        tooltip: '',
        event: 'click',
        cursor: 'pointer',
        label: 'Edit text',
        allowedComponents: ['DEFAULT', 'LABEL']
      },
      TEsT_COMPONENT: {
        action: 'EDIT_TEXT_COMPONENT',
        icon: 'fa-solid fa-pencil',
        tooltip: '',
        event: 'click',
        cursor: 'pointer',
        label: 'Edit text',
        allowedComponents: ['DEFAULT', 'LABEL']
      }
    },
    allowedComponents: ['DEFAULT']
  },
  EDIT_TEXT_COMPONENT: {
    action: 'EDIT_TEXT_COMPONENT',
    icon: 'fa-solid fa-pencil',
    tooltip: '',
    event: 'click',
    cursor: 'pointer',
    label: 'Edit text',
    allowedComponents: ['DEFAULT', 'LABEL']
  },
  DELETE_COMPONENT: {
    action: 'DELETE_COMPONENT',
    icon: 'fa-solid fa-trash',
    tooltip: '',
    event: 'click',
    cursor: 'pointer',
    label: 'Delete',
    allowedComponents: ['CUSTOM', 'DEFAULT']
  }
};

export const FORM_TOOLBAR_ACTIONS: Record<string, any> = {
  SELECT_PARENT_COMPONENT: {
    action: 'SELECT_PARENT_COMPONENT',
    icon: 'fa-solid fa-arrow-turn-up',
    tooltip: '',
    event: 'click',
    cursor: 'pointer',
    label: 'Select parent component',
    allowedComponents: ['CUSTOM', 'DEFAULT']
  },
  SELECT_PREVIOUS_COMPONENT: {
    action: 'SELECT_PREVIOUS_COMPONENT',
    icon: 'fa-solid fa-arrow-up',
    tooltip: '',
    event: 'click',
    cursor: 'pointer',
    label: 'Select previous component',
    allowedComponents: ['CUSTOM', 'DEFAULT']
  },
  SELECT_NEXT_COMPONENT: {
    action: 'SELECT_NEXT_COMPONENT',
    icon: 'fa-solid fa-arrow-down',
    tooltip: '',
    event: 'click',
    cursor: 'pointer',
    label: 'Select next component',
    allowedComponents: ['CUSTOM', 'DEFAULT']
  },
  CONVERT_COMPONENT_TO_CUSTOM_COMPONENT: {
    action: 'CONVERT_COMPONENT_TO_CUSTOM_COMPONENT',
    icon: 'fa-regular fa-floppy-disk',
    tooltip: '',
    event: 'click',
    cursor: 'pointer',
    label: 'Convert to custom component',
    allowedComponents: ['DEFAULT'],
    allowedTypes: ['CONTAINER']
  },
  COPY_COMPONENT: {
    action: 'COPY_COMPONENT',
    icon: 'fa-solid fa-copy',
    tooltip: '',
    event: 'click',
    cursor: 'pointer',
    label: 'Copy component',
    allowedComponents: ['DEFAULT']
  },
  EXTRACT_FORM_COMPONENT: {
    action: 'EXTRACT_FORM_COMPONENT',
    icon: 'fa-regular fa-keyboard',
    tooltip: '',
    event: 'click',
    cursor: 'pointer',
    label: 'Extract form component',
    allowedComponents: ['DEFAULT']
  },
  DELETE_COMPONENT: {
    action: 'DELETE_COMPONENT',
    icon: 'fa-solid fa-trash',
    tooltip: '',
    event: 'click',
    cursor: 'pointer',
    label: 'Delete',
    allowedComponents: ['CUSTOM', 'DEFAULT']
  }
};

type ComponentSelectionToolbarProps = {
  parentId: string;
  viewId: string;
  componentId: string;
  componentType: string;
  section?: string;
  width: number;
  offsetLeft: number;
  offsetTop: number;
};

export function ComponentSelectionToolbar({
  section,
  parentId,
  viewId,
  componentId,
  componentType,
  offsetLeft,
  offsetTop,
  width
}: ComponentSelectionToolbarProps) {
  const dispatch = useDispatch();
  const links = useSelector((state: InterfaceStudioState) => state.links);
  const editor = useSelector((state: InterfaceStudioState) => state.studio.editor);
  const components = useSelector((state: InterfaceStudioState) => state.components);
  const variables = useSelector((state: InterfaceStudioState) => state.variables);
  const objects = useSelector((state: InterfaceStudioState) => state.objects);
  const toolbarRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const checkShowGrid = useCheckShowGrid();
  const isGridColumn = checkShowGrid(componentId);
  const handleUpdateGridColumnsOnDelete = useDeleteGridColumn();

  const waitForResult = useCallback(
    (event: MessageEvent<Message>) => {
      if (event.origin !== window.location.origin) return;
      if (event.data.type === MessageTypes.CONFIRMATION_RESULT) {
        if (event.data.content === true) {
          dispatch(setSelectedComponent(null));
          dispatch(deleteComponent(componentId));
          handleUpdateGridColumns(componentId);
        }
        return;
      }
    },
    [componentId, dispatch]
  );

  useEffect(() => {
    window.addEventListener('message', waitForResult);
    return () => {
      window.removeEventListener('message', waitForResult);
    };
  }, [waitForResult]);

  const requestConfirmation = useCallback(() => {
    if (editor === 'CUSTOM_COMPONENT') {
      dispatch(deleteComponent(componentId));
    } else {
      const content: ConfirmationInfo = {
        message: `${t('deleteSelectedComponent')} (${isGridColumn ? 'COLUMN' : componentType})`,
        confirmationLabel: t('Confirm'),
        cancelLabel: t('Cancel')
      };
      const navigateMessage: Message = {
        type: MessageTypes.CONFIRMATION,
        content: content
      };
      window.parent.postMessage(navigateMessage);
    }
  }, [componentId, dispatch, editor]);

  const requestConvertCustomModal = React.useCallback(() => {
    const content: ConvertCustomComponentInfo = {
      componentUUID: componentId
    };
    const navigateMessage: Message = {
      type: MessageTypes.CONVERT_CUSTOM_COMPONENT,
      content: content
    };
    window.parent.postMessage(navigateMessage);
  }, [componentId]);

  function convertFormNestedObjectsToInput(formContent: FormContent, input: any) {
    const inputContent = {
      uuid: '',
      objectItem: '',
      isProp: false,
      nestedObjects: {}
    };
    let firstLevelObjectItemUuid: string | null = null;

    if (input.parentObject) {
      let currentObjectItemUuid: string = input.objectItemUuid;

      while (formContent.nestedObjectsPath[currentObjectItemUuid]) {
        const formNestedObjectInfo: FormNestedObjectInfo =
          formContent.nestedObjectsPath[currentObjectItemUuid];

        inputContent.nestedObjects = {
          ...inputContent.nestedObjects,
          [formNestedObjectInfo.parentObjectItemUuid]: {
            objectUuid: formNestedObjectInfo.parentObjectUuid,
            objectItemUuid: currentObjectItemUuid
          }
        };

        firstLevelObjectItemUuid = currentObjectItemUuid =
          formNestedObjectInfo.parentObjectItemUuid;
      }
    } else {
      firstLevelObjectItemUuid = input.objectItemUuid;
    }

    return { nestedObjects: inputContent.nestedObjects, objectItemUuid: firstLevelObjectItemUuid };
  }

  const calcToolbarOffsetTop = useCallback((): number => {
    if (!toolbarRef.current) return 0;
    const toolbarHeight = toolbarRef.current.clientHeight;
    if (offsetTop < 0) {
      return 0;
    }
    if (offsetTop < toolbarHeight) {
      return offsetTop;
    } else {
      return offsetTop - toolbarHeight;
    }
  }, [offsetTop]);

  const calcToolbarOffsetLeft = useCallback((): number => {
    if (!toolbarRef.current) return 0;
    const toolbarWidth = toolbarRef.current.clientWidth;
    if (offsetLeft < 0) {
      return 0;
    }
    if (width + offsetLeft < toolbarWidth) {
      return offsetLeft;
    } else {
      return offsetLeft + width - toolbarWidth;
    }
  }, [offsetLeft, width]);

  const position = {
    left:
      toolbarRef &&
      toolbarRef.current &&
      calcToolbarOffsetLeft() + 3 + toolbarRef.current?.clientWidth > window.innerWidth
        ? window.innerWidth - (toolbarRef.current?.clientWidth + 20)
        : calcToolbarOffsetLeft() + 3,
    top: calcToolbarOffsetTop()
  };

  function handleClickDesignerAction(e: MouseEvent<HTMLDivElement>, action: string) {
    e.stopPropagation();
    switch (action) {
      case 'SELECT_PARENT_COMPONENT': {
        const parentId = getParentIdFromComponent(componentId, links);
        if (parentId && parentId !== viewId) {
          dispatch(setSelectedComponent(parentId));
        }
        break;
      }
      case 'SELECT_PREVIOUS_COMPONENT': {
        const previousComponent = getPreviousComponent(componentId, links, viewId);
        if (previousComponent && previousComponent !== viewId) {
          dispatch(setSelectedComponent(previousComponent));
        }
        break;
      }
      case 'SELECT_NEXT_COMPONENT': {
        const nextComponent = getNextComponent(componentId, links, viewId);
        if (nextComponent && nextComponent !== viewId) {
          dispatch(setSelectedComponent(nextComponent));
        }
        break;
      }
      case 'COPY_COMPONENT': {
        // Map between old ids and new ids. We send this to the backend because the ids from
        // both the backend and the frontend has to be the same.
        const oldIdsNewIds: Record<string, string> = {};
        oldIdsNewIds[componentId] = uuidv4();
        const check = [componentId];
        while (check.length) {
          const checkThis = check.pop();
          if (!checkThis) break;
          const children = links[checkThis];
          if (!children) continue;
          for (const child of children) {
            check.push(child);
            oldIdsNewIds[child] = uuidv4();
          }
        }
        dispatch(duplicateComponent(parentId || viewId, componentId, oldIdsNewIds));
        break;
      }
      case 'EXTRACT_FORM_COMPONENT': {
        const form = components[componentId];
        if (!form.data.content.variable) return;

        const inputs: LayoutComponent[] = [];
        for (const formInput of form.data.content.inputs) {
          let newComponent: LayoutComponent | null = null;
          switch (formInput.type) {
            case 'checkbox':
              newComponent = { ...COMPONENTS_TEMPLATE[COMPONENT_TYPES.CHECK], name: '' };
              break;
            case 'number':
              newComponent = { ...COMPONENTS_TEMPLATE[COMPONENT_TYPES.INPUTNUMBER], name: '' };
              break;
            case 'date':
              newComponent = { ...COMPONENTS_TEMPLATE[COMPONENT_TYPES.INPUTDATE], name: '' };
              break;
            case 'text':
              newComponent = { ...COMPONENTS_TEMPLATE[COMPONENT_TYPES.INPUT], name: '' };
              break;
            case 'select':
              newComponent = { ...COMPONENTS_TEMPLATE[COMPONENT_TYPES.SELECT], name: '' };
              break;
            default:
              throw new Error('Missing implementation for input type.');
          }
          if (newComponent) {
            const { nestedObjects, objectItemUuid } = convertFormNestedObjectsToInput(
              form.data.content,
              formInput
            );

            newComponent.uuid = uuidv4();
            newComponent.data.name = faker.random.words(2).toLowerCase().replace(' ', '_');
            newComponent.data.label = formInput.label;
            newComponent.data.variable = {
              isProp: false,
              uuid: form.data.content.variable,
              objectItem: objectItemUuid,
              nestedObjects: nestedObjects
            };

            if (formInput.type === 'select') {
              const objectUuid = variables[form.data.content.variable]?.objectUuid?.toString();

              if (!objectUuid) return;

              const varObject = objects[objectUuid];

              if (!varObject || !varObject.objectItems) return;

              const objectItems = varObject.objectItems.filter(
                (objectItem: SchemaItem) => objectItem.uuid === formInput.objectItemUuid
              );

              if (objectItems.length === 0) return;

              newComponent.data.optionSourceType = 'ENUM';
              newComponent.data.optionRef = { uuid: (objectItems[0] as any).enumId };
            }
            newComponent.data.options = [];
            inputs.push(JSON.parse(JSON.stringify(newComponent)));
          }
        }

        if (form.data.extraText) {
          const extraButton = produce(COMPONENTS_TEMPLATE[COMPONENT_TYPES.BUTTON], (draft) => {
            draft.uuid = uuidv4();
            draft.data.name = faker.random.words(2).toLowerCase().replace(' ', '_');
            draft.data.text = form.data.extraText;
            draft.styles.themeColor = 'secondary';
            if (form.events && form.events[FRONTEND_EVENT_TYPES.CLICK]) {
              draft.events[FRONTEND_EVENT_TYPES.CLICK] = form.events[FRONTEND_EVENT_TYPES.CLICK];
            }
          });
          inputs.push(extraButton);
        }

        const submitButton = produce(COMPONENTS_TEMPLATE[COMPONENT_TYPES.BUTTON], (draft) => {
          draft.uuid = uuidv4();
          draft.data.name = faker.random.words(2).toLowerCase().replace(' ', '_');
          draft.data.text = form.data.submitText;
          draft.styles.themeColor = 'primary';
          if (form.events && form.events[FRONTEND_EVENT_TYPES.SUBMIT]) {
            draft.events[FRONTEND_EVENT_TYPES.CLICK] = form.events[FRONTEND_EVENT_TYPES.SUBMIT];
          }
        });
        inputs.push(submitButton);

        const link: LinkInfo = {
          viewUUID: viewId,
          parentUUID: parentId && parentId !== '' ? parentId : viewId
        };
        dispatch(extractForm(componentId, link, inputs));
        break;
      }
      case 'EDIT_TEXT_COMPONENT': {
        if (!components || !componentId || !components[componentId]) return;
        const inputRef = window.parent.document.getElementById(
          'text-' + components[componentId].data.name
        );
        if (inputRef) inputRef.focus();
        break;
      }
      default:
        throw new Error('Missing implementation for toolbar action handler.');
    }
  }

  const handleOnDragEnd = () => {
    dispatch(setDraggedComponent(null));
  };

  const handleOnDragStart = (event: DragEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (event.dataTransfer == null) return;
    event.dataTransfer.setData(
      'exocode/move-source',
      JSON.stringify({
        parentUUID: parentId || viewId,
        uuid: componentId,
        type: componentType,
        section: section
      } as MoveSource)
    );
    dispatch(
      setDraggedComponent({
        componentId: componentId,
        componentType: componentType
      })
    );
  };

  const isActionAllowed = (action: any) => {
    const allowedComponents: string[] = action.allowedComponents;
    if (componentType === 'CUSTOM') {
      if (allowedComponents.includes('CUSTOM')) return true;
    } else {
      if (allowedComponents.includes('DEFAULT')) return true;
    }
    return false;
  };

  // Simple filter per component type
  const isActionAllowedforComponentType = (action: any, componentType: string) => {
    const allowedComponents: string[] = action.allowedComponents;
    const filterComponentTypes = allowedComponents.filter(
      (componentName) => !['DEFAULT', 'CUSTOM'].includes(componentName)
    );

    if (filterComponentTypes.length === 0) return true;
    else if (filterComponentTypes.includes(componentType)) return true;
    else return false;
  };

  const handleUpdateGridColumns = (deletedComponentUUID: string) => {
    handleUpdateGridColumnsOnDelete(deletedComponentUUID);
  };

  return (
    <>
      <div className={`${styles.SelectionToolbarWrapper}`} style={position} ref={toolbarRef}>
        {Object.values(
          componentType === COMPONENT_TYPES.FORM ? FORM_TOOLBAR_ACTIONS : TOOLBAR_ACTIONS
        ).map((action: any, idx) => {
          if (!isActionAllowed(action)) return null;
          if (!isActionAllowedforComponentType(action, componentType)) return null;

          if (action.event === 'click' && action.action === 'DELETE_COMPONENT') {
            return (
              <div
                onClick={() => requestConfirmation()}
                id={`component-selection-toolbar-${action.action}div${idx}`}
                key={action.action}
                style={{ cursor: action.cursor }}
              >
                <i
                  className={`${action.icon}`}
                  id={`component-selection-toolbar-${action.action}${idx}`}
                />
                dkjhasdjkh
              </div>
            );
          } else if (
            action.event === 'click' &&
            action.action === 'CONVERT_COMPONENT_TO_CUSTOM_COMPONENT'
          ) {
            return (
              <div
                onClick={() => requestConvertCustomModal()}
                id={`component-selection-toolbar-${action.action}div${idx}`}
                key={action.action}
                style={{ cursor: action.cursor }}
              >
                <i
                  className={`${action.icon}`}
                  id={`component-selection-toolbar-${action.action}${idx}`}
                />
              </div>
            );
          } else if (action.event === 'drag' && action.action === 'DRAG_COMPONENT') {
            return (
              <div
                onDragStartCapture={handleOnDragStart}
                onDragEnd={handleOnDragEnd}
                id={`component-selection-toolbar-${action.action}div${idx}`}
                key={action.action}
                style={{ cursor: action.cursor }}
                draggable
              >
                <i
                  className={`fa-solid ${action.icon}`}
                  id={`component-selection-toolbar-${action.action}${idx}`}
                />
              </div>
            );
          } else if (action.event === 'click') {
            return (
              <div
                onClick={(e) => handleClickDesignerAction(e, action.action)}
                id={`component-selection-toolbar-${action.action}div${idx}`}
                key={action.action}
                style={{ cursor: action.cursor }}
              >
                <i
                  className={`${action.icon}`}
                  id={`component-selection-toolbar-${action.action}${idx}`}
                />
              </div>
            );
          } else {
            throw new Error('Event type not supported.');
          }
        })}
      </div>
    </>
  );
}
