import React, { forwardRef, memo, useMemo } from 'react';
import { ComponentUUID } from '../../../types';
import * as Icons from 'react-icons/fa';
import { parseCustomCss, removeDeclarationsWithUndefinedValue } from 'utils/cssUtils';
import { useSelector } from 'react-redux';
import { InterfaceStudioState } from '../../store';
import useGetColor from 'modules/designer/hooks/getColor';
import useCalculateComponentSize from 'modules/designer/hooks/useCalculateComponentSize';

type FaIconName = keyof typeof Icons | '';

export type ButtonData = {
  width?: number | string;
  widthUnit?: number | string;
  maxWidth?: number | string;
  maxWidthUnit?: string;
  minWidth?: number | string;
  minWidthUnit?: string;
  widthCalc?: number;
  height?: number;
  heightUnit?: number | string;
  maxHeight?: number | string;
  maxHeightUnit?: string;
  minHeight?: number | string;
  minHeightUnit?: string;
  text: string;
  leftIcon?: FaIconName | '';
  rightIcon?: FaIconName | '';
  tooltip?: string;
  disabled?: boolean;
  disabledVar?: string;
  translateKey?: string;
  hasTranslation?: string;
  visible?: string;
  optionSizeWidth?: string;
  optionSizeHeight?: string;
};

export type ButtonStyles = {
  themeColor?: string;
  staticColor?: string;
  color?: string;
  fontSize?: number;
  borderRadius?: number;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  borderTop?: number;
  borderLeft?: number;
  borderRight?: number;
  borderBottom?: number;
  paddingTop?: number;
  paddingLeft?: number;
  paddingRight?: number;
  paddingBottom?: number;
  fontWeight?: string;
  lineHeight?: number | string;
  textDecoration?: string | 'none' | 'uppercase' | 'capitalize' | 'capitalize' | 'lowercase';
  textTransform?: string;
  fontFamily?: string;
  fontStyle?: string;
  css?: string;
  iconSize?: string;
  iconMargin?: string;
  iconColor?: string;
  borderColor?: string;
  boxshadow?: string;
  classes?: string[];
};

export type ButtonComponentProps = {
  uuid: ComponentUUID;
  data: ButtonData;
  styles: ButtonStyles;
};

function Button(props: ButtonComponentProps, ref: React.Ref<any>) {
  const handleGetColor = useGetColor();

  let style = {
    ...(props.data.width &&
      props.data.widthUnit && {
        width: props.data.width + '' + props.data.widthUnit
      }),
    ...(props.data.height &&
      props.data.heightUnit && {
        height: props.data.height + '' + props.data.heightUnit
      }),
    ...(props.data.width &&
      !props.data.widthUnit && {
        width: props.data.width
      }),
    ...(props.data.height &&
      !props.data.heightUnit && {
        height: props.data.height
      }),
    ...(props.data.maxHeight &&
      props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight + '' + props.data.maxHeightUnit
      }),
    ...(props.data.maxHeight &&
      !props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight
      }),
    ...(props.data.minHeight &&
      props.data.minHeightUnit && {
        minHeight: props.data.minHeight + '' + props.data.minHeightUnit
      }),
    ...(props.data.minHeight &&
      !props.data.minHeightUnit && {
        minHeight: props.data.minHeight
      }),
    ...(props.data.maxWidth &&
      props.data.maxWidthUnit && {
        maxWidth: props.data.maxWidth + '' + props.data.maxWidthUnit
      }),
    ...(props.data.maxWidth &&
      !props.data.maxWidthUnit && {
        maxWidth: props.data.maxWidth
      }),
    ...(props.data.minWidth &&
      props.data.minWidthUnit && {
        minWidth: props.data.minWidth + '' + props.data.minWidthUnit
      }),
    ...(!props.data.minWidthUnit && {
      minWidth: props.data.minWidth
    }),
    color: props.styles.color && handleGetColor(props.styles.color),
    fontSize: `${props.styles.fontSize}px`,
    borderRadius: `${props.styles.borderRadius}px`,
    paddingTop: `${props.styles.paddingTop}px`,
    paddingRight: `${props.styles.paddingRight}px`,
    paddingBottom: `${props.styles.paddingBottom}px`,
    paddingLeft: `${props.styles.paddingLeft}px`,
    borderTop: `${props.styles.borderTop}px solid`,
    borderRight: `${props.styles.borderRight}px solid`,
    borderBottom: `${props.styles.borderBottom}px solid`,
    borderLeft: `${props.styles.borderLeft}px solid`,
    marginTop: `${props.styles.marginTop}px`,
    marginRight: `${props.styles.marginRight}px`,
    marginBottom: `${props.styles.marginBottom}px`,
    marginLeft: `${props.styles.marginLeft}px`,
    backgroundColor: `${props.styles.staticColor}`,
    fontWeight: `${props.styles.fontWeight}`,
    lineHeight: `${props.styles.lineHeight}`,
    textDecoration: `${props.styles.textDecoration}`,
    ...(props.styles.fontFamily && {
      fontFamily: `${props.styles.fontFamily}`
    }),
    fontStyle: `${props.styles.fontStyle}`,
    borderColor: props.styles.borderColor && handleGetColor(props.styles.borderColor),
    boxShadow: props.styles.boxshadow,
    overflow: props.styles.boxshadow ? 'visible' : undefined
  };

  const disabledButtonStyle = props.data.disabled
    ? {
        opacity: '0.65'
      }
    : {};

  const LeftIcon = props.data.leftIcon ? Icons[props.data.leftIcon] : null;
  const leftIconStyle = {
    marginRight: `${props.styles.iconMargin ? props.styles.iconMargin : '0'}px`,
    color: props.styles.iconColor && handleGetColor(props.styles.iconColor),
    size: '10px'
  };
  const RightIcon = props.data.rightIcon ? Icons[props.data.rightIcon] : null;
  const rightIconStyle = {
    marginLeft: `${
      props.styles.iconMargin && props.styles.iconMargin !== '0' ? props.styles.iconMargin : '0'
    }px`,
    color: props.styles.iconColor && handleGetColor(props.styles.iconColor)
  };

  if (props.data.widthCalc && props.data.widthUnit !== 'px') {
    style.width = `calc(${style.width} - ${props.data.widthCalc}px)`;
  }

  removeDeclarationsWithUndefinedValue(style);
  style = useCalculateComponentSize(props.uuid, style) ?? style;

  return (
    <>
      <button
        ref={ref}
        type="button"
        className={`btn ${props.styles.textTransform} btn-${props.styles.themeColor} `}
        title={props.data.hasTranslation ? `{${props.data.translateKey}}` : props.data.tooltip}
        style={{ ...style, ...disabledButtonStyle, ...parseCustomCss(props.styles.css) }}
        disabled={props.data.disabled}
      >
        {props.data.leftIcon && (
          <div className={`d-inline`} style={leftIconStyle}>
            {LeftIcon && <LeftIcon size={props.styles.iconSize} />}
          </div>
        )}

        {props.data.hasTranslation ? `{${props.data.translateKey}}` : props.data.text}

        {props.data.rightIcon && (
          <div className={`d-inline`} style={rightIconStyle}>
            {RightIcon && <RightIcon size={props.styles.iconSize} color="currentColor" />}
          </div>
        )}
      </button>
    </>
  );
}

export * from './manifest';
export * from './template';
export default memo(forwardRef(Button));
