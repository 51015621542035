import React, { ChangeEvent, ComponentType, memo, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InterfaceStudioState } from '../../store';
import { COMPONENTS_MANIFEST } from '../../exocode_components';
import {
  changeComponentProperty,
  changeCustomComponentViewProperty
} from '../../store/actions/components';
import { changeViewProperty, changeLayout } from '../../store/actions/views';
import {
  ComponentManifest,
  Editor,
  Layout,
  LayoutComponent,
  View,
  ViewManifest
} from '../../../types';
import { VIEWS_MANIFEST, VIEWS_TYPES } from '../../frames';
import { ViewData } from '../../frames/page_view';
import { ViewStateService } from 'modules/designer/services';
import { Form } from 'react-bootstrap';
import { ControlProps, ControlsList, ControlsTypes } from 'web_ui/workboard/sidebar/controls';
import { useTranslation } from 'react-i18next';
import ComponentHeader from '../components/component_header';
import TextControl from 'web_ui/workboard/sidebar/controls/TextControl';
import ListParams from './params';
import styles from './styles.module.css';
import { PropertyMapper } from './properties_mapper';
import RoleSelector from 'web_ui/role_selector';
import { Authorization } from 'modules/auth/session/authorization';
import { SystemRoleAuthorityName } from 'modules/auth/types/auth_types';
import { AppContext } from '../../../../project/store/app_context';

type PropertiesToolbarProps = {
  layouts: Layout[];
  name?: string;
  icon?: string;
};

export type ElementProperty = {
  value: string;
  key: string;
};

function PropertiesToolbar(props: PropertiesToolbarProps) {
  const appInfo = useContext(AppContext).projectInformation;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedComponent = useSelector(
    (state: InterfaceStudioState) => state.studio.selectedComponent.uuid
  );
  const selectedView = useSelector((state: InterfaceStudioState) => state.studio.selectedView);
  const editor = useSelector((state: InterfaceStudioState) => state.studio.editor);
  const components = useSelector((state: InterfaceStudioState) => state.components);
  const views = useSelector((state: InterfaceStudioState) => state.views);
  const roles = useSelector((state: InterfaceStudioState) => state.roles);
  const [manifest, setManifest] = React.useState<ComponentManifest | ViewManifest>();
  const [viewSource, setViewSource] = React.useState<View>();
  const [componentSource, setComponentSource] = React.useState<LayoutComponent>();
  const [selectedLayout, setSelectedLayout] = React.useState<string>('default');

  const handleChange = React.useCallback(
    (value: string, key: string) => {
      if (selectedComponent) {
        dispatch(changeComponentProperty(selectedComponent, key, value));
      } else if (selectedView && editor === Editor.CUSTOM_COMPONENT) {
        dispatch(changeCustomComponentViewProperty(selectedView, key, value));
      } else if (selectedView) {
        dispatch(changeViewProperty(selectedView, key as keyof ViewData, value));
      }
    },
    [dispatch, editor, selectedComponent, selectedView]
  );

  const handleNameChange = React.useCallback(
    (value: string, key: string) => {
      if (selectedComponent)
        dispatch(
          changeComponentProperty(selectedComponent, key, value.replace(/[^a-zA-Z0-9_]/g, ''))
        );
    },
    [dispatch, selectedComponent]
  );

  const handleLayoutSelect = async (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedLayout(e.target.value);
    if (!selectedView) return;

    if (e.target.value !== 'default') {
      const layout = await ViewStateService.getViewState(e.target.value);
      dispatch(changeLayout(selectedView, e.target.value, layout));
    } else {
      dispatch(changeLayout(selectedView, ''));
    }
  };

  const handleMainPageCheckbox = (e: ChangeEvent<HTMLInputElement>, selectedView: string) => {
    dispatch(changeViewProperty(selectedView, 'rootPage', e.target.checked));
  };

  React.useEffect(() => {
    if (selectedView && views && views[selectedView] && views[selectedView].type === 'PAGE') {
      // Set select input value.
      setSelectedLayout(views[selectedView].layout_uuid ?? 'default');
    }
    if (selectedComponent && components && components[selectedComponent]) {
      setManifest(COMPONENTS_MANIFEST[components[selectedComponent].type]);
      setComponentSource(components[selectedComponent]);
    } else if (selectedView && views && views[selectedView]) {
      setManifest(VIEWS_MANIFEST[views[selectedView].type]);
      setViewSource(views[selectedView]);
    } else if (selectedView && components && editor === Editor.CUSTOM_COMPONENT) {
      setManifest(VIEWS_MANIFEST[VIEWS_TYPES.CUSTOMCOMPONENTS]);
      setComponentSource(components[selectedView]);
    }
  }, [components, editor, selectedComponent, selectedView, views]);

  function getLabel(label: string) {
    return t('designer.right_side.' + label);
  }

  return (
    <>
      {(selectedComponent || selectedView) &&
        ((views &&
          Object.values(views).length > 0 &&
          components &&
          editor !== Editor.CUSTOM_COMPONENT) ||
          (components && editor === Editor.CUSTOM_COMPONENT)) && (
          <>
            <ComponentHeader name={props.name} icon={props.icon} />
            <div className={`${styles.controlList} p-3 pt-0`}>
              {selectedComponent && (
                <>
                  <TextControl
                    key={`${selectedComponent}-name`}
                    id="name"
                    label={getLabel('Name')}
                    value={
                      componentSource && componentSource.data && componentSource.data.name
                        ? componentSource.data.name
                        : ''
                    }
                    onChange={handleNameChange}
                  />
                </>
              )}

              {/* PAGE: Select Layouts input */}
              {editor !== Editor.CUSTOM_COMPONENT &&
                selectedView != null &&
                views[selectedView] != null &&
                views[selectedView].type === 'PAGE' && (
                  <>
                    <Form.Group className="mb-3">
                      <Form.Label>Layouts</Form.Label>
                      <Form.Select
                        id={'selectLayout'}
                        aria-label="Layout select"
                        onChange={handleLayoutSelect}
                        value={selectedLayout}
                      >
                        <option value="default">{t('designer.right_side.ChangeLayout')}</option>
                        {props.layouts.map((layout) => {
                          return (
                            <option key={layout.uuid} value={layout.uuid}>
                              {layout.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                    {viewSource && !viewSource.data['rootPage'] && <ListParams />}
                  </>
                )}

              {/* Custom component editor */}
              {editor === Editor.CUSTOM_COMPONENT &&
                manifest?.properties.map((item, index) => {
                  const Control = ControlsList[item.controlType] as ComponentType<ControlProps>;
                  return (
                    <Control
                      key={`${item.controlLabel + index}`}
                      id={item.key}
                      label={getLabel(item.controlLabel)}
                      value={
                        item.key && componentSource && componentSource.data
                          ? componentSource?.data[item.key]
                          : componentSource?.data
                      }
                      options={item.controlOptions}
                      onChange={handleChange}
                      maxLength={item.maxLength}
                      validation={item.validation}
                      tooltip={item.tooltip}
                      errorMessages={item.errorMessages}
                    />
                  );
                })}

              {/* VIEWS, excluding custom component editor */}
              {editor !== Editor.CUSTOM_COMPONENT &&
                manifest?.properties.map((item, index) => {
                  const Control = ControlsList[item.controlType] as ComponentType<ControlProps>;
                  return selectedComponent ? (
                    <Authorization
                      key={`${item.controlLabel + index}`}
                      allowedSystemAuthorities={
                        item.controlType === ControlsTypes.SELECT_TRANSLATION ||
                        item.key === 'hasTranslation'
                          ? [SystemRoleAuthorityName.MANAGE_LANGUAGES]
                          : []
                      }
                    >
                      <Control
                        id={item.key}
                        label={getLabel(item.controlLabel)}
                        value={
                          item.key && componentSource && componentSource.data
                            ? componentSource?.data[item.key]
                            : componentSource?.data
                        }
                        options={item.controlOptions}
                        onChange={handleChange}
                        tooltip={item.tooltip}
                        validation={item.validation}
                        errorMessages={item.errorMessages}
                      />
                    </Authorization>
                  ) : (
                    <Authorization
                      key={`${item.controlLabel + index}`}
                      allowedSystemAuthorities={
                        item.controlType === ControlsTypes.SELECT_TRANSLATION ||
                        item.key === 'hasTranslation'
                          ? [SystemRoleAuthorityName.MANAGE_LANGUAGES]
                          : []
                      }
                    >
                      <Control
                        id={item.key}
                        label={getLabel(item.controlLabel)}
                        value={
                          item.key
                            ? viewSource
                              ? viewSource.data[item.key as keyof ViewData]
                              : ''
                            : viewSource?.data
                        }
                        options={item.controlOptions}
                        onChange={handleChange}
                        validation={item.validation}
                        errorMessages={item.errorMessages}
                        tooltip={item.tooltip}
                      />
                    </Authorization>
                  );
                })}

              {/* PAGE: Route */}
              {editor !== Editor.CUSTOM_COMPONENT &&
                selectedView != null &&
                views[selectedView] != null &&
                views[selectedView].type === 'PAGE' &&
                viewSource && (
                  <div className="mb-3">
                    <Form.Label>Route</Form.Label>
                    <Form.Control
                      id="formRoute"
                      className={`form-control form-control-sm`}
                      // value={viewSource.data['route'] != null ? viewSource.data['route'] : ''}
                      value={
                        viewSource.data['rootPage']
                          ? '/'
                          : viewSource.data['route'] != null
                          ? viewSource.data['route']
                          : ''
                      }
                      onChange={(e) => handleChange(e.target.value, 'route')}
                      disabled={viewSource.data['rootPage']}
                    />
                  </div>
                )}

              {/* PAGE: Set as main page */}
              {editor !== Editor.CUSTOM_COMPONENT &&
                selectedView != null &&
                views[selectedView] != null &&
                views[selectedView].type === 'PAGE' &&
                viewSource && (
                  <div className="mb-3">
                    <Form.Check
                      id="setAsMainPageCheck"
                      checked={
                        viewSource.data['rootPage'] != null ? viewSource.data['rootPage'] : false
                      }
                      type="checkbox"
                      label="Set as main page"
                      onChange={(e) => handleMainPageCheckbox(e, selectedView)}
                    />
                  </div>
                )}
              {/* PAGE: Select page Roles. */}
              {editor !== Editor.CUSTOM_COMPONENT &&
                selectedView != null &&
                views[selectedView] != null &&
                views[selectedView].type === 'PAGE' &&
                viewSource &&
                appInfo?.has_authentication && (
                  <Form.Group className="mb-2">
                    <Form.Label>{t('appResume.roles.Roles')}</Form.Label>
                    <RoleSelector
                      roles={roles}
                      viewSource={viewSource}
                      setViewSource={setViewSource}
                      selectedView={selectedView}
                    />
                  </Form.Group>
                )}
              <PropertyMapper component={components[selectedComponent ?? '']} />
            </div>
          </>
        )}
    </>
  );
}

export default memo(PropertiesToolbar);
