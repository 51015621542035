import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

interface ViewportSize {
  width: number;
  height: number;
}

const ViewportContext = createContext<ViewportSize | null>(null);

interface ViewportProviderProps {
  children: ReactNode;
}

export const ViewportProvider: React.FC<ViewportProviderProps> = ({ children }) => {
  const [viewportSize, setViewportSize] = useState<ViewportSize>({ width: 0, height: 0 });

  useEffect(() => {
    const calculateSize = () => {
      const viewportContainer = document.getElementById('studioMainContainer');

      if (!viewportContainer) return;
      const iframeScrollbarSpace = 12; // pixels
      const iframeBodyPaddings = 48; // pixels

      if (!viewportContainer) return;

      const viewportGuideContainer = document.getElementById('viewport-guidelines');
      const aspectRatioString = viewportGuideContainer?.style.aspectRatio ?? '16/9';
      const [aspectRationWidth, aspectRationHeight] = aspectRatioString.split('/').map(Number);
      const aspectRatio = aspectRationWidth / aspectRationHeight;

      const newWidth = viewportContainer.clientWidth - iframeScrollbarSpace - iframeBodyPaddings;
      const newHeight = newWidth / aspectRatio;
      if (newWidth > 0 && newHeight > 0) {
        setViewportSize({ width: newWidth, height: newHeight });
      }
    };

    const waitForContainer = () => {
      const viewportContainer = document.getElementById('studioMainContainer');

      if (!viewportContainer) {
        setTimeout(waitForContainer, 100);
        return;
      }

      calculateSize();

      window.addEventListener('resize', calculateSize);
    };

    waitForContainer();

    return () => {
      window.removeEventListener('resize', calculateSize);
    };
  }, []);

  return <ViewportContext.Provider value={viewportSize}>{children}</ViewportContext.Provider>;
};

export const useViewport = (): ViewportSize => {
  const context = useContext(ViewportContext);
  if (!context) {
    throw new Error('useViewport must be used within a ViewportProvider');
  }
  return context;
};
