import React, { useCallback, useEffect, useState } from 'react';
import {
  ModuleDependencyInfo,
  ModuleInfo,
  ModuleQuery,
  ModuleSettings,
  ModuleSqlOptions
} from 'modules/dashboard/types';
import { ProjectsService } from 'modules/project/services';
import { Button, Form, Modal, Tab, Tabs } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import ModuleDependencyGroup from '../module_dendency_group';
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';
import { DashboardService } from 'modules/dashboard/services';
import TemplateModule from './components/template_module';
import InformationModule from './components/information_module';
import ModalDependedModules from './components/modal_depended_modules';
import { PopupAlert, PopupAlertVariant } from '../../../../web_ui/popup_alert';
import { validateDescriptionInputs, validateModuleName } from 'utils/inputValidation';

export type CreateModuleModalProps = {
  /* Modal visibility */
  showModal: boolean;
  /* Callback on modal closing */
  onCloseRequest: () => void;
  /* Modules List */
  modules?: ModuleInfo[];
  /* Callback on modal confirm, to update an external ModuleInfo */
  updateModulesList: (modulesList: ModuleInfo) => void;
  currentModules?: ModuleInfo[];
  loading?: boolean;
};

/**
 * Create Module Modal.
 *
 * @component
 */
export default function CreateModuleModal(props: CreateModuleModalProps) {
  const { app_id } = useParams();
  const { t } = useTranslation();
  const [module, setModule] = useState<ModuleInfo>({
    id: '',
    appId: '',
    name: '',
    description: '',
    isActive: true,
    isAssets: false,
    isAuth: false,
    dependencies: []
  });
  const [dataToAssetsModule, setDataToAssetsModule] = React.useState<ModuleSettings>({
    key: 'storage',
    value: 'file_system'
  });
  const [dataForS3, setDataForS3] = useState<ModuleSettings[]>([]);
  const [accessKey, setAccessKey] = useState<string>('');
  const [secretKey, setSecretKey] = useState<string>('');
  const [region, setRegion] = useState<string>('');
  const [bucketName, setBucketName] = useState<string>('');
  const [blockSave, setBlockSave] = useState<boolean>(false);
  const [openModalDepended, setOpenModalDepended] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertVariant, setAlertVariant] = useState<PopupAlertVariant>('success');
  const [errorMessages, setErrorMessages] = useState<Record<string, string>>({});

  const showSuccessPopup = (message: string) => {
    setAlertVariant('success');
    setAlertMessage(message);
  };

  const showErrorPopup = (message: string) => {
    setAlertVariant('danger');
    setAlertMessage(message);
  };

  const hideAlertPopup = () => {
    setAlertMessage('');
  };

  const clearS3Fields = useCallback(() => {
    setRegion('');
    setAccessKey('');
    setSecretKey('');
    setBucketName('');
    setBlockSave(false);
  }, []);

  useEffect(() => {
    if (!props.showModal) return;
    setAccessKey('');
    setSecretKey('');
    setRegion('');
    setBucketName('');
    setBlockSave(false);
    setErrorMessages({});
    setModule({
      id: '',
      appId: '',
      name: '',
      description: '',
      isActive: true,
      isAssets: false,
      isAuth: false,
      isSql: false,
      dependencies: []
    });
    setOpenModalDepended(false);
    setDataForS3([]);
  }, [props.showModal]);

  const onChangeModuleDependencies = (moduleDependencies: ModuleDependencyInfo[]) => {
    setModule({ ...module, dependencies: moduleDependencies });
  };

  const onChangeModuleStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    setModule({ ...module, isActive: e.target.checked });
  };

  const onChangeModuleSql = (value: ModuleSqlOptions) => {
    if (value === ModuleSqlOptions.JPA) {
      setModule({ ...module, isSql: false });
    } else if (value === ModuleSqlOptions.JDBC) {
      setModule({ ...module, isSql: true });
    } else {
      setModule({ ...module, isSql: undefined });
    }
  };

  const onChangeModuleDepended = (mod: ModuleInfo, checked: boolean) => {
    const next = [...(module.dependedModules ?? [])];
    if (checked) {
      next.push({ id: mod.id, name: mod.name });
    } else {
      const idx = next.findIndex((m) => m.id === mod.id);
      if (idx !== -1) {
        next.splice(idx, 1);
      }
    }
    setModule({ ...module, dependedModules: next });
  };

  const buildQuery = (appId: string, module: ModuleInfo): ModuleQuery => {
    return {
      appId: appId,
      name: module.name,
      description: module.description,
      dependencies: module.dependencies,
      isActive: module.isActive,
      isAssets: module.isAssets,
      isAuth: module.isAuth,
      isSql: module.isSql,
      dependedModules: module.dependedModules
    };
  };

  const onSubmit = async () => {
    if (isLoading) return;
    if (!validateInputs()) {
      return;
    }
    if (module.isAssets) {
      if (dataToAssetsModule.key === '' || dataToAssetsModule.value === '') {
        showErrorPopup('errorAssets');
        return;
      }
    }
    setIsLoading(true);
    if (props.currentModules) {
      const filteringByNameModule = props.currentModules.filter(
        (moduleProp) => moduleProp.name === module.name
      );
      if (filteringByNameModule.length > 0) {
        setIsLoading(false);
        showErrorPopup('appResume.new_module.SameName');
        return;
      }
    }
    try {
      props.modules &&
        props.modules.forEach((mod) => {
          if (mod.name === module.name) {
            setIsLoading(false);
            showErrorPopup('appResume.new_module.SameName');
            return;
          }
        });
      if (app_id != null) {
        const query = buildQuery(app_id, module);
        const res = await ProjectsService.createModule(query);
        const mod = res as ModuleInfo;
        if (dataToAssetsModule.key && dataToAssetsModule.value && mod) {
          const response = await DashboardService.createModuleSettings(mod.id, dataToAssetsModule);
          if (!response) {
            setIsLoading(false);
            showErrorPopup('appResume.new_module.AssetsModuleError');
            return;
          }
          if (response.value === 's3') {
            dataForS3.map(async (item) => {
              const responseS3Keys = await DashboardService.createModuleSettings(mod.id, item);
              if (!responseS3Keys) {
                setIsLoading(false);
                showErrorPopup('appResume.new_module.S3AssetsModuleError');
                return;
              }
            });
          }
        }
        props.updateModulesList(mod);
        showSuccessPopup('appResume.new_module.ModuleSuccess');
        setOpenModalDepended(false);
        props.onCloseRequest();
      }
    } catch (error) {
      showErrorPopup('appResume.new_module.ModuleError');
      props.onCloseRequest();
    } finally {
      setIsLoading(false);
    }
  };

  const choosingTheStorage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    switch (e.target.value) {
      case 'File System':
        setDataToAssetsModule({ key: 'storage', value: 'file_system' });
        break;
      case 'S3':
        setDataToAssetsModule({ key: 'storage', value: 's3' });
        break;
      case 'Blob':
        setDataToAssetsModule({ key: 'storage', value: 'blob' });
        break;
    }
  };

  const submitTheS3 = () => {
    if (dataToAssetsModule.value === 's3') {
      setDataForS3((currentVal: ModuleSettings[]) => {
        const auxVal: ModuleSettings[] = [...currentVal];
        auxVal.push({ key: 'S3_access', value: accessKey });
        auxVal.push({ key: 'S3_secret', value: secretKey });
        auxVal.push({ key: 'S3_region', value: region });
        auxVal.push({ key: 'S3_bucket', value: bucketName });
        return auxVal;
      });
    }
    setBlockSave(true);
  };

  const handleEnterKeyPress = (ev: React.KeyboardEvent<HTMLDivElement>) => {
    if (ev.key === 'Enter') {
      onSubmit();
    }
  };

  const onChangeModuleName = (value: string) => {
    handleErrorMessages('moduleName', checkNameIsValid(value));
    setModule({ ...module, name: value });
  };

  const onChangeModuleDescription = (value: string) => {
    handleErrorMessages('moduleDescription', checkDescriptionIsValid(value));
    setModule({ ...module, description: value });
  };

  const handleErrorMessages = (key: string, errorMessage: string) => {
    const nextErrorMessages = {
      ...errorMessages,
      [key]: errorMessage
    };
    setErrorMessages(nextErrorMessages);
  };

  /**
   * Validate every input that needs validation.
   * Returns false if one them isn't valid.
   */
  const validateInputs = (): boolean => {
    const nextErrorMessages = {
      ...errorMessages,
      moduleName: checkNameIsValid(module.name),
      moduleDescription: checkDescriptionIsValid(module.description)
    };
    setErrorMessages(nextErrorMessages);
    for (const errorMessage of Object.values(nextErrorMessages)) {
      if (errorMessage) {
        return false;
      }
    }
    return true;
  };

  const checkDescriptionIsValid = (input: string) => {
    const { code } = validateDescriptionInputs(input, 255, false);
    let errorMessage = '';
    if (code === 'EXCEEDS_MAX_LENGTH') {
      errorMessage = 'inputValidationErrorMessages.ModuleDescriptionExceedsMaxLength';
    } else if (code === 'EMPTY_STRING') {
      errorMessage = 'inputValidationErrorMessages.ModuleDescriptionEmptyString';
    }
    return errorMessage;
  };

  const checkNameIsValid = (input: string) => {
    const { code } = validateModuleName(input, 64);
    let errorMessage = '';
    if (code === 'EXCEEDS_MAX_LENGTH') {
      errorMessage = 'inputValidationErrorMessages.ModuleNameExceedsMaxLength';
    } else if (code) {
      errorMessage = 'inputValidationErrorMessages.GenericErrorMessage';
    }
    return errorMessage;
  };

  return (
    <>
      <div onKeyDown={(ev) => handleEnterKeyPress(ev)}>
        {alertMessage && (
          <PopupAlert
            i18nKey={alertMessage}
            onClose={hideAlertPopup}
            variant={alertVariant}
            extraStyle={{ left: 20 }}
            dismissTime={5000}
          />
        )}
        <Modal centered size="lg" show={props.showModal} onHide={props.onCloseRequest} scrollable>
          <Form onSubmit={onSubmit} className={styles.formWrapper} id="formModal">
            {alertMessage && alertVariant === 'danger' && (
              <PopupAlert
                i18nKey={alertMessage}
                onClose={hideAlertPopup}
                variant={alertVariant}
                extraStyle={{ left: 20 }}
              />
            )}
            <Modal.Header closeButton>
              <Modal.Title>{t('appResume.new_module.CreateModule')}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ overflowY: 'auto' }} id="bodyModal">
              <Tabs
                className={styles.TabContainer}
                defaultActiveKey={'informations'}
                id="tabs-create-module"
              >
                <Tab eventKey={'informations'} title={t('appResume.new_module.informations')}>
                  <InformationModule
                    module={module}
                    onChangeModuleDescription={onChangeModuleDescription}
                    onChangeModuleName={onChangeModuleName}
                    onChangeModuleStatus={onChangeModuleStatus}
                    onChangeModuleSql={onChangeModuleSql}
                    errorMessages={errorMessages}
                  />
                </Tab>
                <Tab eventKey={'template'} title={t('appResume.new_module.template')}>
                  <TemplateModule
                    keyAccess={accessKey}
                    blockSave={blockSave}
                    bucketName={bucketName}
                    choosingTheStorage={choosingTheStorage}
                    clearS3Fields={clearS3Fields}
                    dataToAssetsModule={dataToAssetsModule}
                    module={module}
                    region={region}
                    secretKey={secretKey}
                    setAccessKey={setAccessKey}
                    setBlockSave={setBlockSave}
                    setBucketName={setBucketName}
                    setModule={setModule}
                    setRegion={setRegion}
                    setSecretKey={setSecretKey}
                    submitTheS3={submitTheS3}
                  />
                </Tab>
                <Tab eventKey={'dependency'} title={t('appResume.new_module.Dependencies')}>
                  <div className={styles.appSettingsDialog}>
                    <ModuleDependencyGroup
                      onChange={onChangeModuleDependencies}
                      isCreating={true}
                    />
                  </div>
                </Tab>
              </Tabs>
            </Modal.Body>
            <Modal.Footer>
              <div className={styles.dialogSaveButton}>
                <Button
                  id={'saveButton'}
                  onClick={() => {
                    if (module.isAssets || module.isAuth) {
                      setOpenModalDepended(true);
                    } else {
                      onSubmit();
                    }
                  }}
                >
                  {t('appResume.new_module.Create')}
                </Button>
              </div>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
      {openModalDepended && (
        <ModalDependedModules
          onChange={onChangeModuleDepended}
          appModules={props.modules ?? []}
          selectedDependencyModules={module.dependedModules ?? []}
          onClose={() => setOpenModalDepended(false)}
          show={openModalDepended}
          submit={onSubmit}
        />
      )}
    </>
  );
}
