import { Page } from '../types';
import { PageSchema } from './schemas';
import { CrudPage } from '../../logic_builder/types';
import { DuplicateViewInfo } from '../studio/toolbars/views_toolbar/duplicate_page_modal';
import { ApiError } from '../../project/types';

export const API_URL = process.env.REACT_APP_API_URL;

export class PageRepo {
  // Returns the request body.
  private buildSchema(
    pageName: string,
    pageRoute: string,
    pageDescription: string,
    pageLayout: string,
    template: string,
    folderId: string
  ) {
    const pageSchema: PageSchema = {
      name: pageName,
      route: pageRoute,
      description: pageDescription,
      layout: pageLayout,
      template: template,
      folder_id: folderId,
      data: {
        route: pageRoute
      },
      editorMode: 'NORMAL'
    };
    return pageSchema;
  }

  /**
   * Create a new page for a specific module.
   */
  async createPage(
    moduleId: string,
    pageName: string,
    pageRoute: string,
    pageDescription: string,
    pageLayout: string,
    template: string,
    folderId: string
  ) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    // ADD folder_id no pedido...
    const body: PageSchema = this.buildSchema(
      pageName,
      pageRoute,
      pageDescription,
      pageLayout,
      template,
      folderId
    );

    const options: RequestInit = {
      method: 'POST',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(body)
    };

    const url = `${API_URL}/modules/${moduleId}/pages`;

    const response = await fetch(url, options);

    if (response.ok) {
      return response.json() as Promise<Page>;
    } else if (response.status === 409) {
      const apiError: ApiError = await response.json();
      throw new Error(apiError.message);
    } else {
      throw new Error('Something went wrong while trying to create a new page.');
    }
  }

  async createPageAutomationCrud(
    pages: CrudPage[],
    moduleId: string,
    entityId: string
  ): Promise<void> {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'POST',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify({ pages: pages })
    };

    const url = `${API_URL}/modules/${moduleId}/automations/crud/${entityId}/views`;
    const response = await fetch(url, options);

    if (response.ok) {
      return;
    } else if (response.status === 409) {
      const apiError: ApiError = await response.json();
      throw new Error(apiError.message);
    }
    throw new Error('Something went wrong while trying to create a new page.');
  }

  /**
   * Duplicate page.
   */
  async duplicatePage(moduleId: string, duplicatePage: DuplicateViewInfo) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'POST',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(duplicatePage)
    };

    const url = `${API_URL}/modules/${moduleId}/pages/duplicate`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<Page>;
      throw new Error('Something went wrong while trying to duplicate page.');
    });
  }

  /**
   * Fetch all pages for a specific module.
   */
  async getPagesByModule(moduleId: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const url = `${API_URL}/modules/${moduleId}/pages`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<Page[]>;
      throw new Error('Something went wrong while trying to fetch module pages.');
    });
  }

  /**
   * Get all pages that belongs a one module.
   * @param appId this params is the appId.
   */
  async getPageByApp(appId: string) {
    const headers = new Headers({ 'Content-Type': 'application/json' });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const response = await fetch(`${API_URL}/app/${appId}/pages`, options);

    if (response.ok) {
      return response.json() as Promise<Page[]>;
    } else {
      throw new Error('Something went wrong while trying to fetch module pages.');
    }
  }

  /**
   * Fetch a single page.
   */
  async getPage(pageId: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const url = `${API_URL}/pages/${pageId}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<Page>;
      throw new Error('Something went wrong while trying to fetch page.');
    });
  }

  async updatePage(pageId: string, updatedPage: Page) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'PUT',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(updatedPage)
    };

    const url = `${API_URL}/pages/${pageId}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<Page>;
      throw new Error('Something went wrong while trying to update page.');
    });
  }

  async movePage(pageId: string, moduleId: string, folderId: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const moveData = {
      folder_id: folderId
    };

    const options: RequestInit = {
      method: 'PUT',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(moveData)
    };

    const url = `${API_URL}/modules/${moduleId}/pages/${pageId}/move`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<Page>;
      throw new Error('Something went wrong while trying to move page.');
    });
  }

  async deletePage(pageId: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'DELETE',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const url = `${API_URL}/pages/${pageId}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response;
      throw new Error('Something went wrong while trying to delete page.');
    });
  }
}
