import React, { ReactNode } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import HelpBox from '../HelpBox';
import { BootstrapColors } from '../../DataTableSource/types';

interface HelpPopoverProps {
  placement: 'top' | 'top' | 'left' | 'right';
  helpBoxProps: {
    title?: string;
    icon?: string;
    iconStyles?: Record<string, any>;
    description?: string;
    note?: string[];
    variant?: BootstrapColors;
  };
  hide?: boolean;
  children: ReactNode;
  disabled?: boolean;
  className?: string;
  withoutStyles?: boolean;
}

function HelpPopover({
  placement,
  helpBoxProps,
  hide,
  children,
  disabled,
  className,
  withoutStyles
}: HelpPopoverProps) {
  const { title, description, note, icon, variant } = helpBoxProps;

  return (
    <>
      {!disabled ? (
        <OverlayTrigger
          placement={placement}
          overlay={
            <Popover>
              {hide ? (
                <></>
              ) : (
                <HelpBox
                  title={title}
                  icon={icon}
                  description={description}
                  note={note}
                  variant={variant}
                />
              )}
            </Popover>
          }
          delay={{ show: 300, hide: 0 }}
        >
          {withoutStyles ? (
            <div>{children}</div>
          ) : (
            <div className={`d-inline-block m-0 ${className}`}>{children}</div>
          )}
        </OverlayTrigger>
      ) : (
        <>{children}</>
      )}
    </>
  );
}

export default HelpPopover;
