import React, { forwardRef, memo } from 'react';
import { ComponentUUID, ViewUUID } from 'modules/designer/types';
import { Accordion } from 'react-bootstrap';
import ComponentsRenderer from '../components_renderer';
import SectionWrapper from '../section_wrapper';
import { COMPONENT_TYPES } from '../index';
import { parseCustomCss, removeDeclarationsWithUndefinedValue } from 'utils/cssUtils';
import { PositionProperty } from 'csstype';
import { convertingCSS } from 'utils/utils';
import { useSelector } from 'react-redux';
import { InterfaceStudioState } from '../../store';
import useCalculateComponentSize from 'modules/designer/hooks/useCalculateComponentSize';

export type AccordionData = {
  items: string[];
  width?: number | string;
  widthUnit?: number | string;
  maxWidth?: number | string;
  maxWidthUnit?: string;
  minWidth?: number | string;
  minWidthUnit?: string;
  widthCalc?: number;
  height?: number | string;
  heightUnit?: number | string;
  maxHeight?: number | string;
  maxHeightUnit?: string;
  minHeight?: number | string;
  minHeightUnit?: string;
  activeKey: string;
  tooltip?: string;
  visible?: string;
  optionSizeWidth?: string;
  optionSizeHeight?: string;
};

export type AccordionStyle = {
  background?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'info'
    | 'warning'
    | 'danger'
    | 'light'
    | 'dark';
  color?: string;
  fontSize?: number;
  borderRadius?: number;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  borderTop?: number;
  borderLeft?: number;
  borderRight?: number;
  borderBottom?: number;
  paddingTop?: number;
  paddingLeft?: number;
  paddingRight?: number;
  paddingBottom?: number;
  css?: string;
  borderColor?: string;
  boxshadow?: string;
  classes?: string[];
};

export type AccordionComponentProps = {
  viewUUID: ViewUUID;
  parentUUID?: ComponentUUID;
  uuid: ComponentUUID;
  data: AccordionData;
  styles: AccordionStyle;
  custom_uuid: ComponentUUID;
  new_custom_uuid: ComponentUUID;
};

function AccordionComponent(props: AccordionComponentProps, ref: React.Ref<any>) {
  const theme = useSelector((state: InterfaceStudioState) => state.theme);

  let style = {
    ...(props.data.width &&
      props.data.widthUnit && {
        width: props.data.width + '' + props.data.widthUnit
      }),
    ...(props.data.height &&
      props.data.heightUnit && {
        height: props.data.height + '' + props.data.heightUnit
      }),
    ...(props.data.width &&
      !props.data.widthUnit && {
        width: props.data.width
      }),
    ...(props.data.height &&
      !props.data.heightUnit && {
        height: props.data.height
      }),
    ...(props.data.maxHeight &&
      props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight + '' + props.data.maxHeightUnit
      }),
    ...(props.data.maxHeight &&
      !props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight
      }),
    ...(props.data.minHeight &&
      props.data.minHeightUnit && {
        minHeight: props.data.minHeight + '' + props.data.minHeightUnit
      }),
    ...(props.data.minHeight &&
      !props.data.minHeightUnit && {
        minHeight: props.data.minHeight
      }),
    ...(props.data.maxWidth &&
      props.data.maxWidthUnit && {
        maxWidth: props.data.maxWidth + '' + props.data.maxWidthUnit
      }),
    ...(props.data.maxWidth &&
      !props.data.maxWidthUnit && {
        maxWidth: props.data.maxWidth
      }),
    ...(props.data.minWidth &&
      props.data.minWidthUnit && {
        minWidth: props.data.minWidth + '' + props.data.minWidthUnit
      }),
    ...(!props.data.minWidthUnit && {
      minWidth: props.data.minWidth
    }),
    color: props.styles.color,
    fontSize: `${props.styles.fontSize}px`,
    borderRadius: `${props.styles.borderRadius}px`,
    paddingTop: `${props.styles.paddingTop}px`,
    paddingRight: `${props.styles.paddingRight}px`,
    paddingBottom: `${props.styles.paddingBottom}px`,
    paddingLeft: `${props.styles.paddingLeft}px`,
    borderTop: `${props.styles.borderTop}px solid #333`,
    borderRight: `${props.styles.borderRight}px solid #333`,
    borderBottom: `${props.styles.borderBottom}px solid #333`,
    borderLeft: `${props.styles.borderLeft}px solid #333`,
    marginTop: `${props.styles.marginTop}px`,
    marginRight: `${props.styles.marginRight}px`,
    marginBottom: `${props.styles.marginBottom}px`,
    marginLeft: `${props.styles.marginLeft}px`,
    backgroundColor: `${props.styles.background}`,
    position: 'relative' as PositionProperty, // Used on container tags to keep select tools available
    borderColor:
      props.styles.borderColor &&
      convertingCSS(props.styles.borderColor, theme.colors[props.styles.borderColor]),
    boxShadow: props.styles.boxshadow,
    overflow: props.styles.boxshadow ? 'visible' : undefined
  };

  if (props.data.widthCalc && props.data.width !== 'fit-content' && props.data.widthUnit !== 'px') {
    style.width = `calc(${style.width} - ${props.data.widthCalc}px)`;
  }
  removeDeclarationsWithUndefinedValue(style);
  style = useCalculateComponentSize(props.uuid, style) ?? style;

  const classes = props.styles.classes?.join(' ') || '';

  return (
    <div
      ref={ref}
      style={{ ...style, ...parseCustomCss(props.styles.css) }}
      title={props.data.tooltip}
      className={`${classes}`}
    >
      <Accordion activeKey={props.data.activeKey} className={`bg-${style.backgroundColor}`}>
        {props.data.items.map((key) => (
          <SectionWrapper
            key={key}
            section={key}
            uuid={props.uuid}
            type={COMPONENT_TYPES.CONTAINER}
            viewUUID={props.viewUUID}
            parentUUID={props.parentUUID}
            custom_uuid={props.custom_uuid}
          >
            <Accordion.Item eventKey={key}>
              <div>
                <Accordion.Header>
                  <h1
                    style={{
                      fontSize:
                        style.fontSize && style.fontSize != '0px' ? `${style.fontSize}` : '20px',
                      color: style.color
                    }}
                  >
                    {key}
                  </h1>
                </Accordion.Header>
                <Accordion.Body>
                  <ComponentsRenderer
                    section={key}
                    viewUUID={props.viewUUID}
                    parentUUID={props.uuid}
                    custom_uuid={props.custom_uuid}
                    new_custom_uuid={props.new_custom_uuid}
                  />
                </Accordion.Body>
              </div>
            </Accordion.Item>
          </SectionWrapper>
        ))}
      </Accordion>
    </div>
  );
}

export default memo(forwardRef(AccordionComponent));
export * from './manifest';
export * from './template';
