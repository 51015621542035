import { UserProfile } from '../../types/auth_types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthService } from '../../services';
import styles from '../password_change_form/styles.module.css';
import { Button, Container, Form } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import { disablePortalCreator } from '../../../payments/types';
import { PopupAlert, PopupAlertVariant } from '../../../../web_ui/popup_alert';

type ChangeEmailFormProps = {
  user: UserProfile;
};

function ChangeEmailForm(props: ChangeEmailFormProps) {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [currentEmail, setCurrentEmail] = useState(props.user.email);
  const [newEmail, setNewEmail] = useState('');
  const [confirmNewEmail, setConfirmNewEmail] = useState('');

  const [alertMessage, setAlertMessage] = useState('');
  const [alertVariant, setAlertVariant] = useState<PopupAlertVariant>('success');

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (newEmail !== confirmNewEmail) {
      showErrorPopup(t('account_settings.change_email.EmailMismatch'));
      return;
    }

    try {
      setIsLoading(true);
      const isOk = await AuthService.changeEmail(props.user.id, newEmail);
      if (isOk) {
        showSuccessPopup(t('account_settings.change_email.ConfirmationEmailSent'));
      }
    } catch (error) {
      if (error instanceof Error) {
        showErrorPopup(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const hideAlertPopup = () => {
    setAlertMessage('');
  };

  const showSuccessPopup = (message: string) => {
    setAlertVariant('success');
    setAlertMessage(message);
  };

  const showErrorPopup = (message: string) => {
    setAlertVariant('danger');
    setAlertMessage(message);
  };

  return (
    <>
      <Form className={styles.Form} onSubmit={handleSubmit}>
        <Form.Group className={styles.FormSection} controlId="formCurrentEmail">
          <Form.Label className={`text-body-primary ${styles.FormTitle}`}>
            {t('account_settings.change_email.CurrentEmail')}
          </Form.Label>
          <Form.Control
            className={styles.FormControl}
            type="email"
            value={currentEmail}
            readOnly
            style={{ width: '25rem' }}
          />
        </Form.Group>

        <Form.Group className={styles.FormSection} controlId="formNewEmail">
          <Form.Label className={`text-body-primary ${styles.FormTitle}`}>
            {t('account_settings.change_email.NewEmail')}
          </Form.Label>
          <InputGroup className={styles.MainInputGroup}>
            <Form.Control
              className={styles.FormControl}
              onChange={(e) => setNewEmail(e.target.value)}
              type="email"
              value={newEmail}
              maxLength={50}
              style={{ width: '25rem' }}
              disabled={isLoading || disablePortalCreator(props.user.email)}
              required
            />
          </InputGroup>
          <Form.Label className={`text-body-tertiary ${styles.FormDescription}`}>
            {t('account_settings.change_email.NewEmailSub')}
          </Form.Label>
        </Form.Group>

        <Form.Group className={styles.FormSection} controlId="formConfirmNewEmail">
          <Form.Label className={`text-body-primary ${styles.FormTitle}`}>
            {t('account_settings.change_email.ConfirmNewEmail')}
          </Form.Label>
          <InputGroup className={styles.MainInputGroup}>
            <Form.Control
              className={styles.FormControl}
              onChange={(e) => setConfirmNewEmail(e.target.value)}
              type="email"
              value={confirmNewEmail}
              maxLength={50}
              style={{ width: '25rem' }}
              disabled={isLoading || disablePortalCreator(props.user.email)}
              required
            />
          </InputGroup>
        </Form.Group>
        <Container className={styles.SubmitButton}>
          <Button
            id={'settingsSubmitEmail'}
            variant="primary"
            type="submit"
            disabled={isLoading || disablePortalCreator(props.user.email)}
          >
            {t('navigation_options.Submit')}
          </Button>
        </Container>
      </Form>

      {alertMessage && (
        <PopupAlert i18nKey={alertMessage} onClose={hideAlertPopup} variant={alertVariant} />
      )}
    </>
  );
}

export default ChangeEmailForm;
