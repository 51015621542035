import { Action } from 'redux';
import { CssClass } from 'web_ui/workboard/sidebar/controls/CssClassesControl/types';

export const SET_CSS_CLASSES = 'SET_CSS_CLASSES';
export const ADD_CSS_CLASS = 'ADD_CSS_CLASS';
export const UPDATE_CSS_CLASS = 'UPDATE_CSS_CLASS';
export const DELETE_CSS_CLASS = 'DELETE_CSS_CLASS';
export const UPDATE_CSS_CLASSES_ORDER = 'UPDATE_CSS_CLASSES_ORDER';

export interface SetCssClassesAction extends Action {
  type: 'SET_CSS_CLASSES';
  payload: {
    cssClasses: CssClass[];
  };
}

export interface AddCssClassAction extends Action {
  type: 'ADD_CSS_CLASS';
  payload: {
    cssClass: CssClass;
  };
}

export interface UpdateCssClassAction extends Action {
  type: 'UPDATE_CSS_CLASS';
  payload: {
    cssClass: CssClass;
  };
}

export interface DeleteCssClassAction extends Action {
  type: 'DELETE_CSS_CLASS';
  payload: {
    id: string;
  };
}

export interface UpdateCssClassesOrderAction extends Action {
  type: 'UPDATE_CSS_CLASSES_ORDER';
  payload: {
    appId: string;
    movedId: string;
    oldIndex: number;
    newIndex: number;
    orderedIds: string[];
  };
}

export const setCssClasses = (cssClasses: CssClass[]): SetCssClassesAction => ({
  type: SET_CSS_CLASSES,
  payload: { cssClasses }
});

export const addCssClass = (cssClass: CssClass): AddCssClassAction => ({
  type: ADD_CSS_CLASS,
  payload: { cssClass }
});

export const updateCssClass = (cssClass: CssClass): UpdateCssClassAction => ({
  type: UPDATE_CSS_CLASS,
  payload: { cssClass }
});

export const deleteCssClass = (id: string): DeleteCssClassAction => ({
  type: DELETE_CSS_CLASS,
  payload: { id }
});

export const updateCssClassesOrder = (
  appId: string,
  movedId: string,
  oldIndex: number,
  newIndex: number,
  orderedIds: string[]
): UpdateCssClassesOrderAction => ({
  type: UPDATE_CSS_CLASSES_ORDER,
  payload: { appId, movedId, oldIndex, newIndex, orderedIds }
});
