import React, { forwardRef, memo } from 'react';
import { ComponentUUID, ViewUUID } from '../../../types';
import { Button, FormControl, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { parseCustomCss, removeDeclarationsWithUndefinedValue } from 'utils/cssUtils';
import { Pagination as BSPagination } from 'react-bootstrap';
import Icon from 'web_ui/icon';
import * as Icons from 'react-icons/fa';
import FakeLine from './fakeline';
import {
  FormatterManifest,
  FormatterTypes
} from 'web_ui/workboard/sidebar/controls/DataTableSource/types';
import useCalculateComponentSize from 'modules/designer/hooks/useCalculateComponentSize';

type FaIconName = keyof typeof Icons | '';

export type DataSource = {
  variable: string;
  columns: DataTableColumn[];
  selectedItem: string;
  nestedObjectsPath?: {
    [key: string]: DataTableColumnNestedObjectInfo;
  };
};

export type DataTableColumnNestedObjectInfo = {
  parentObject: string;
  parentObjectItem: string;
};

export interface ITableAction {
  function: string;
  title?: string;
  icon?: string;
  variant?: string;
}

export interface ITableContextMenu {
  function: string;
  title?: string;
  icon?: string;
  useSelectedRows: boolean;
}

export type TableAction = {
  function: (item: any) => void;
  title?: string;
  icon?: FaIconName | '';
  variant?: string;
  iconHeight?: string;
};

export type DataTableColumn = {
  title: string;
  show: boolean;
  schemaItemUuid: string;
  objectUuid?: string;
  type?: string;
  filterable?: boolean;
  sortable?: boolean;
  hidden?: boolean;
  formatter?: FormatterTypes;
  editable?: boolean;
  formatterParams?: FormatterManifest;
  footerSum?: boolean;
  order?: number;
};

export type DataTableData = {
  width?: number | string;
  widthUnit?: number | string;
  maxWidth?: number | string;
  maxWidthUnit?: string;
  minWidth?: number | string;
  minWidthUnit?: string;
  widthCalc?: number;
  height?: number | string;
  heightUnit?: number | string;
  maxHeight?: number | string;
  maxHeightUnit?: string;
  minHeight?: number | string;
  minHeightUnit?: string;
  tooltip?: string;
  dataSource: DataSource;
  sortable?: boolean;
  pageable?: boolean;
  selectable?: boolean;
  searchable?: boolean;
  selectType?: 'SINGULAR' | 'MULTI';
  selectColumnKeys?: string[];
  selectedItems?: any[];
  currentPage?: string;
  pageSize?: string;
  pageItems?: number;
  totalPages?: string;
  visible?: string;
  formatter?: string;
  actions: TableAction[];
  editFunction?: string;
  actionBtn?: TableAction[];
  optionSizeWidth?: string;
  optionSizeHeight?: string;
};

export type DataTableStyles = {
  fontSize?: number;
  borderRadius?: number;
  borderTop?: number;
  borderBottom?: number;
  borderLeft?: number;
  borderRight?: number;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  paddingTop?: number;
  paddingLeft?: number;
  paddingRight?: number;
  paddingBottom?: number;
  variant?: string;
  css?: string;
  classes?: string[];
};

export type DataTableComponentProps = {
  viewUUID: ViewUUID;
  uuid: ComponentUUID;
  data: DataTableData;
  styles: DataTableStyles;
  custom_uuid: ComponentUUID;
  new_custom_uuid: ComponentUUID;
};

function DataTableComponent(props: DataTableComponentProps, ref: React.Ref<any>) {
  const { t } = useTranslation();
  let style = {
    ...(props.data.width &&
      props.data.widthUnit && {
        width: props.data.width + '' + props.data.widthUnit
      }),
    ...(props.data.height &&
      props.data.heightUnit && {
        height: props.data.height + '' + props.data.heightUnit
      }),
    ...(props.data.width &&
      !props.data.widthUnit && {
        width: props.data.width
      }),
    ...(props.data.height &&
      !props.data.heightUnit && {
        height: props.data.height
      }),
    ...(props.data.maxHeight &&
      props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight + '' + props.data.maxHeightUnit
      }),
    ...(props.data.maxHeight &&
      !props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight
      }),
    ...(props.data.minHeight &&
      props.data.minHeightUnit && {
        minHeight: props.data.minHeight + '' + props.data.minHeightUnit
      }),
    ...(props.data.minHeight &&
      !props.data.minHeightUnit && {
        minHeight: props.data.minHeight
      }),
    ...(props.data.maxWidth &&
      props.data.maxWidthUnit && {
        maxWidth: props.data.maxWidth + '' + props.data.maxWidthUnit
      }),
    ...(props.data.maxWidth &&
      !props.data.maxWidthUnit && {
        maxWidth: props.data.maxWidth
      }),
    ...(props.data.minWidth &&
      props.data.minWidthUnit && {
        minWidth: props.data.minWidth + '' + props.data.minWidthUnit
      }),
    ...(!props.data.minWidthUnit && {
      minWidth: props.data.minWidth
    }),
    paddingTop: `${props.styles.paddingTop}px`,
    paddingRight: `${props.styles.paddingRight}px`,
    paddingBottom: `${props.styles.paddingBottom}px`,
    paddingLeft: `${props.styles.paddingLeft}px`,
    borderTop: `${props.styles.borderTop}px solid #333`,
    borderRight: `${props.styles.borderRight}px solid #333`,
    borderLeft: `${props.styles.borderLeft}px solid #333`,
    borderBottom: `${props.styles.borderBottom}px solid #333`,
    marginTop: `${props.styles.marginTop}px`,
    marginRight: `${props.styles.marginRight}px`,
    marginBottom: `${props.styles.marginBottom}px`,
    marginLeft: `${props.styles.marginLeft}px`
  };

  if (props.data.widthCalc && props.data.widthUnit !== 'px') {
    style.width = `calc(${style.width} - ${props.data.widthCalc}px)`;
  }

  const classes = props.styles.classes?.join(' ') || '';
  removeDeclarationsWithUndefinedValue(style);
  style = useCalculateComponentSize(props.uuid, style) ?? style;

  if (!props.data.dataSource.variable) {
    return (
      <div
        ref={ref}
        className="p-5 border rounded-3 bg-body-secondary text-center"
        style={{ ...style, opacity: 0.75 }}
        title={props.data.tooltip}
      >
        <h5 className="text-body">{t('designer.right_side.NoDataSource')}</h5>
        <p className="text-body-secondary">
          {t('designer.right_side.DataTableNoSource')}
          <br />
          {t('designer.right_side.SelectDataSource')}
        </p>
      </div>
    );
  }

  return (
    <div
      ref={ref}
      className={`p-3 ${classes}`}
      style={{ ...style, ...parseCustomCss(props.styles.css) }}
    >
      <div className="d-flex justify-content-between">
        {props.data.searchable ? (
          <div className="mb-3">
            <FormControl style={{ width: '240px' }} type="text" placeholder="Search..." />
          </div>
        ) : (
          <div />
        )}
        <div className="d-flex gap-2">
          {props.data.actionBtn &&
            props.data.actionBtn.length > 0 &&
            props.data.actionBtn.map((itemAcn, index) => {
              const Icon = itemAcn.icon ? Icons[itemAcn.icon as keyof typeof Icons] : null;
              return (
                <Button style={{ height: 'fit-content' }} key={index} variant={itemAcn.variant}>
                  {Icon ? <Icon /> : itemAcn.title}
                </Button>
              );
            })}
        </div>
      </div>
      <Table striped borderless hover variant={props.styles.variant}>
        <thead>
          <tr>
            {props.data.selectable && <th></th>}
            {props.data.dataSource.columns
              .filter((column) => column.show)
              .map((column) => (
                <th key={column.title} className="text-center">
                  {column.title}
                  {column.sortable && (
                    <span className="ms-2">
                      <Icon iconName="caret-up"></Icon>
                    </span>
                  )}
                </th>
              ))}
            {props.data.actions && props.data.actions.length > 0 && <th></th>}
          </tr>
          {props.data.dataSource.columns.some((column) => column.filterable) && (
            <tr>
              {props.data.selectable && <th></th>}
              {props.data.dataSource.columns
                .filter((column) => column.show)
                .map((column) => {
                  return column.filterable ? (
                    <th className="text-center">
                      <input type="text" />
                    </th>
                  ) : (
                    <th></th>
                  );
                })}
            </tr>
          )}
        </thead>
        <tbody>
          {props.data.pageSize && props.data.pageItems && props.data.pageItems > 0 ? (
            Array.from({ length: props.data.pageItems }, (_, index) => (
              <FakeLine
                key={index}
                columns={props.data.dataSource.columns}
                opacity={100}
                selectable={props.data.selectable}
                selectType={props.data.selectType}
                index={index}
                actions={props.data.actions}
              />
            ))
          ) : (
            <>
              <FakeLine
                columns={props.data.dataSource.columns}
                opacity={100}
                selectable={props.data.selectable}
                selectType={props.data.selectType}
                index={0}
                actions={props.data.actions}
              />
              <FakeLine
                columns={props.data.dataSource.columns}
                opacity={60}
                selectable={props.data.selectable}
                selectType={props.data.selectType}
                index={1}
                actions={props.data.actions}
              />
              <FakeLine
                columns={props.data.dataSource.columns}
                opacity={40}
                selectable={props.data.selectable}
                selectType={props.data.selectType}
                index={2}
                actions={props.data.actions}
              />
            </>
          )}
        </tbody>
        <tfoot>
          {!props.data.pageable &&
            props.data.dataSource.columns.some((column: DataTableColumn) => column.footerSum) && (
              <tr className=" border-top">
                {props.data.selectable && <th></th>}
                {props.data.dataSource.columns.map((column: DataTableColumn, index: number) => (
                  <th key={column.title + index} className="text-center ">
                    {column.footerSum && 100}
                  </th>
                ))}
                {props.data.actions && <th></th>}
              </tr>
            )}
        </tfoot>
      </Table>
      {props.data.pageable && (
        <BSPagination size="sm" className="d-flex justify-content-center">
          <BSPagination.Prev />
          <BSPagination.Item active>{1}</BSPagination.Item>
          <BSPagination.Item>{2}</BSPagination.Item>
          <BSPagination.Item>{3}</BSPagination.Item>
          <BSPagination.Ellipsis />
          <BSPagination.Item>{20}</BSPagination.Item>
          <BSPagination.Next />
        </BSPagination>
      )}
    </div>
  );
}

export * from './manifest';
export * from './template';
export default memo(forwardRef(DataTableComponent));
