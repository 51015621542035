import React, { forwardRef, memo, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import styles from '../style.module.css';
import { ComponentUUID } from '../../../types';
import { TextAlignProperty } from 'csstype';
import { parseCustomCss, removeDeclarationsWithUndefinedValue } from 'utils/cssUtils';
import { convertingCSS } from 'utils/utils';
import { useSelector } from 'react-redux';
import { InterfaceStudioState } from '../../store';
import useCalculateComponentSize from 'modules/designer/hooks/useCalculateComponentSize';

export type InputData = {
  label?: string;
  placeholder?: string;
  tooltip?: string;
  defaultValue?: string;
  required?: boolean;
  readonly?: boolean;
  readonlyVar?: string;
  disabled?: boolean;
  disabledVar?: string;
  width?: number | string;
  widthUnit?: number | string;
  maxWidth?: number | string;
  maxWidthUnit?: string;
  minWidth?: number | string;
  minWidthUnit?: string;
  widthCalc?: number;
  height?: number | string;
  heightUnit?: number | string;
  maxHeight?: number | string;
  maxHeightUnit?: string;
  minHeight?: number | string;
  minHeightUnit?: string;
  translateKey?: string;
  hasTranslation?: string;
  visible?: string;
  optionSizeWidth?: string;
  optionSizeHeight?: string;
};

export type InputStyles = {
  staticColor?: string;
  themeColor?: string;
  fontSize?: number;
  textAlign?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent';
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  borderTop?: number;
  borderLeft?: number;
  borderRight?: number;
  borderBottom?: number;
  paddingTop?: number;
  paddingLeft?: number;
  paddingRight?: number;
  paddingBottom?: number;
  css?: string;
  bgColor?: string;
  borderColor?: string;
  boxshadow?: string;
  placeholderColor?: string;
  classes?: string[];
};

export type InputComponentProps = {
  uuid: ComponentUUID;
  data: InputData;
  styles: InputStyles;
};

function InputComponent(props: InputComponentProps, ref: React.Ref<any>) {
  const [value, setValue] = React.useState<string>('');
  const theme = useSelector((state: InterfaceStudioState) => state.theme);

  let style = {
    ...(props.data.width &&
      props.data.widthUnit && {
        width: props.data.width + '' + props.data.widthUnit
      }),
    ...(props.data.height &&
      props.data.heightUnit && {
        height: props.data.height + '' + props.data.heightUnit
      }),
    ...(props.data.width &&
      !props.data.widthUnit && {
        width: props.data.width
      }),
    ...(props.data.height &&
      !props.data.heightUnit && {
        height: props.data.height
      }),
    ...(props.data.maxHeight &&
      props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight + '' + props.data.maxHeightUnit
      }),
    ...(props.data.maxHeight &&
      !props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight
      }),
    ...(props.data.minHeight &&
      props.data.minHeightUnit && {
        minHeight: props.data.minHeight + '' + props.data.minHeightUnit
      }),
    ...(props.data.minHeight &&
      !props.data.minHeightUnit && {
        minHeight: props.data.minHeight
      }),
    ...(props.data.maxWidth &&
      props.data.maxWidthUnit && {
        maxWidth: props.data.maxWidth + '' + props.data.maxWidthUnit
      }),
    ...(props.data.maxWidth &&
      !props.data.maxWidthUnit && {
        maxWidth: props.data.maxWidth
      }),
    ...(props.data.minWidth &&
      props.data.minWidthUnit && {
        minWidth: props.data.minWidth + '' + props.data.minWidthUnit
      }),
    ...(!props.data.minWidthUnit && {
      minWidth: props.data.minWidth
    }),
    textAlign: props.styles.textAlign as TextAlignProperty,
    color: props.styles.staticColor
      ? props.styles.staticColor
      : props.styles.themeColor &&
        convertingCSS(
          props.styles.themeColor,
          props.styles.themeColor.includes('#') ? '' : theme.colors[props.styles.themeColor!]
        ),
    fontSize: `${props.styles.fontSize}px`,
    paddingTop: `${props.styles.paddingTop}px`,
    paddingRight: `${props.styles.paddingRight}px`,
    paddingBottom: `${props.styles.paddingBottom}px`,
    paddingLeft: `${props.styles.paddingLeft}px`,
    borderTop: `${props.styles.borderTop}px solid #333`,
    borderRight: `${props.styles.borderRight}px solid #333`,
    borderBottom: `${props.styles.borderBottom}px solid #333`,
    borderLeft: `${props.styles.borderLeft}px solid #333`,
    marginTop: `${props.styles.marginTop}px`,
    marginRight: `${props.styles.marginRight}px`,
    marginBottom: `${props.styles.marginBottom}px`,
    marginLeft: `${props.styles.marginLeft}px`
  };

  if (props.data.widthCalc && props.data.widthUnit !== 'px') {
    style.width = `calc(${style.width} - ${props.data.widthCalc}px)`;
  }

  removeDeclarationsWithUndefinedValue(style);
  style = useCalculateComponentSize(props.uuid, style) ?? style;

  const classes = props.styles.classes?.join(' ') || '';

  return (
    <div ref={ref} style={{ ...style, ...parseCustomCss(props.styles.css) }} className={classes}>
      <Form.Group>
        <Form.Label>
          {props.data.hasTranslation ? `{${props.data.translateKey}}` : props.data.label}
        </Form.Label>
        <Form.Control
          type="text"
          value={!props.data.defaultValue ? value : props.data.defaultValue}
          placeholder={props.data.placeholder}
          readOnly={props.data.readonly}
          required={props.data.required}
          disabled={props.data.disabled}
          style={{
            backgroundColor:
              props.styles.bgColor &&
              convertingCSS(
                props.styles.bgColor,
                props.styles.bgColor.includes('#') ? '' : theme.colors[props.styles.bgColor]
              ),
            borderColor:
              props.styles.borderColor &&
              convertingCSS(props.styles.borderColor, theme.colors[props.styles.borderColor]),
            boxShadow: props.styles.boxshadow,
            overflow: props.styles.boxshadow ? 'visible' : undefined,
            ...({
              '--placeholder-color':
                props.styles.placeholderColor &&
                convertingCSS(
                  props.styles.placeholderColor,
                  props.styles.placeholderColor.includes('#')
                    ? ''
                    : theme.colors[props.styles.placeholderColor]
                )
            } as React.CSSProperties)
          }}
          onChange={(evt) => {
            if (props.data.defaultValue) return;
            setValue(evt.target.value);
          }}
          className={styles.input}
        />
      </Form.Group>
    </div>
  );
}

export * from './manifest';
export * from './template';
export default memo(forwardRef(InputComponent));
