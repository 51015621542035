export interface Language {
  code: string;
  label: string;
}

const defaultLang = { code: 'en', label: 'English' };

export const languages: Language[] = [
  defaultLang,
  ...[
    { code: 'br', label: 'Português Brasileiro' },
    { code: 'pt', label: 'Português' }
  ].sort((left, right) => (left.label > right.label ? 1 : -1))
];
