import React, { useRef } from 'react';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import { Container, Spinner, Form, Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SignUpUserInformationForm from './components/signup_user_information_form';
import { AuthService } from 'modules/auth/services';
import { PopupAlert } from '../../web_ui/popup_alert';
import Navbar from 'web_ui/navbar';
import useTitle from 'hooks/useTitle';
import ReCAPTCHA from 'react-google-recaptcha';

import styles from './styles.module.css';

/**
 * Sign up process
 *
 * @component
 */
function SignUp() {
  const [email, setEmail] = React.useState('');
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [passwordConfirmation, setPasswordConfirmation] = React.useState('');
  const [promotionalCode, setPromotionalCode] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSignupForInvite, setIsSignupForInvite] = React.useState(false);
  const [disableEmailInput, setDisableEmailInput] = React.useState(false);
  const [disableCodeInput, setDisableCodeInput] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [formValidity, setFormValidity] = React.useState<boolean>(false);
  const [recaptchaToken, setRecaptchaToken] = React.useState<string | null>(null);
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useTitle(t('tab.signUp'));

  React.useEffect(() => {
    const emailInvite = searchParams.get('email');
    if (emailInvite !== null) {
      setDisableEmailInput(true);
      setEmail(emailInvite);
    }
    const codeInvite = searchParams.get('code');
    if (codeInvite !== null) {
      setDisableCodeInput(true);
      setPromotionalCode(codeInvite);
    }
  }, [searchParams]);

  const redirectToLogin = () => {
    navigate('/login', {
      state: {
        signup: {
          email: email.toLocaleLowerCase(),
          username: username
        }
      }
    });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      await AuthService.signup(
        email.toLowerCase(),
        username,
        password,
        promotionalCode,
        recaptchaToken
      );
      redirectToLogin();
    } catch (error: any) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage('sign_up.AccountCreationError');
      }
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleCheckFormValidity = (validity: boolean) => {
    setFormValidity(validity);
  };

  return (
    <>
      <div className={styles.PageWrap}>
        {errorMessage && <PopupAlert i18nKey={errorMessage} onClose={() => setErrorMessage('')} />}
        <Navbar context="signup" />
        <Container className={styles.LoginContainer}>
          <Container className={`bg-body-tertiary ${styles.LoginFormCard}`}>
            <Form id="bodyMessage" onSubmit={handleSubmit}>
              <SignUpUserInformationForm
                handleEmailOnChange={(e) => setEmail(e.target.value)}
                handleUsernameOnChange={(e) => {
                  setUsername(e.target.value);
                }}
                handlePasswordOnChange={(e) => setPassword(e.target.value)}
                handlePasswordConfirmationOnChange={(e) => setPasswordConfirmation(e.target.value)}
                handlePromotionalCodeOnChange={(e) => setPromotionalCode(e.target.value)}
                email={email}
                username={username}
                password={password}
                passwordConfirmation={passwordConfirmation}
                promotionalCode={promotionalCode}
                disabelEmail={disableEmailInput}
                disabelPromotionalCode={disableCodeInput}
                handleCheckValidity={handleCheckFormValidity}
                recaptchaToken={recaptchaToken}
                setRecaptchaToken={setRecaptchaToken}
                recaptchaRef={recaptchaRef}
              />
              {/* Sign up steps navigation buttons */}
              <Button
                id={'signUpButton'}
                variant="primary"
                type="submit"
                className={`mt-4 ${styles.SubmitButton}`}
                disabled={isLoading || !formValidity}
              >
                {isLoading ? (
                  <Spinner animation="border" variant="light" />
                ) : (
                  <span>{t('sign_up.Finish')}</span>
                )}
              </Button>
            </Form>
            {/* Show external login links only in the Step 1 of the sign up process */}
            {/* Sign in instead*/}
            <Container className={`${styles.LoginExternalLink} ${styles.LoginExternalLinkCenter}`}>
              <Link to="/login">{t('sign_up.AlreadyHaveAccount')}</Link>
            </Container>
          </Container>
        </Container>
      </div>
      {/* Information modal for redirecting invitations */}
      <Modal show={isSignupForInvite} onHide={() => setIsSignupForInvite(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('sign_up.ModalInviteTitle')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t('sign_up.ModalInviteBody')}</Modal.Body>
      </Modal>
    </>
  );
}

export default SignUp;
