import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Container, Form } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import produce from 'immer';
import { ApiAppInfo, AppFullInfo } from '../../modules/project/types';
import { ProjectsService } from '../../modules/project/services';
import WizardProgress from './components/wizard-progress';
import { initialAppValue } from './types';
import Step0 from './components/step0';
import Step1 from './components/step1';
import Step2 from './components/step2';
import Step4 from './components/step4';
import Step5 from './components/step5';
import Step6 from './components/step6';
import Step7 from './components/step7';

import styles from './styles.module.css';
import { OrganizationService } from 'modules/organization/services';
import ErrorMessageCreateApp from './components/message_error';
import { useTranslation } from 'react-i18next';
import useSession from 'hooks/useSession';
import { IntegrationsService } from '../../modules/integrations/services';
import { GitRepo } from '../../modules/integrations/types';
import Step3 from './components/step3';
import { OrganizationApiInfo } from 'modules/organization/types';
import useTitle from 'hooks/useTitle';
import { DashboardService } from 'modules/dashboard/services';
import { Template } from 'modules/project/enum';
import { apiErrorMessageToI18n } from 'utils/errorMessageUtils';
import Navbar from '../../web_ui/navbar';

type WizardState = {
  orgId?: number;
};

type Loc = {
  code: string;
  name: string;
};

/**
 * The application creation wizard.
 *
 * @component
 * @route /wizard
 * @private
 */
function Wizard() {
  const location = useLocation();
  const currentStateFromLocation = location.state;
  const formRef = useRef<HTMLFormElement>(null);
  const [currentStep, setCurrentStep] = useState(0);
  // const [formValidity, setFormValidity] = useState(false);
  const [appInfo, setAppInfo] = useState<AppFullInfo>(initialAppValue);
  const navigate = useNavigate();
  const [orgId, setOrgId] = useState<number>(-1);
  const [dispatchErrorMessage, setDispatchErrorMessage] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [session] = useSession();
  const [repos, setRepos] = useState<GitRepo[]>([]);
  const [gitHubIntegration, setGitHubIntegration] = useState<boolean>(false);
  const [errorFromApi, setErrorFromApi] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  useTitle(t('tab.wizard'));

  useEffect(() => {
    const fetchRepos = async (account: number, isOrg: boolean) => {
      const result = await IntegrationsService.getAccountRepositories(account, isOrg);
      setRepos(result);
    };
    const loadGitIntegration = async () => {
      let orgId;
      if (location.state as WizardState) orgId = (location.state as WizardState).orgId;

      if (orgId) {
        const org = await OrganizationService.getOrganizationById(orgId);
        setGitHubIntegration(org.gitHubIntegration);

        if (org.gitHubIntegration) fetchRepos(orgId, true);
      } else {
        setGitHubIntegration(!!session.user?.gitHubIntegration);
        if (session.user?.gitHubIntegration) fetchRepos(session.user?.id, false);
      }
    };

    loadGitIntegration();
  }, [location.state, session.user?.gitHubIntegration, session.user?.id]);

  useEffect(() => {
    if (location.state as WizardState) setOrgId((location.state as WizardState).orgId ?? -1);
  }, [location.state]);

  useEffect(() => {
    // if (formRef.current) {
    //   setFormValidity(formRef.current.checkValidity());
    // }
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [currentStep, appInfo, errorMessage]);

  const fetchOrganization = useCallback((organizationId: number): Promise<OrganizationApiInfo> => {
    return OrganizationService.getOrganizationById(organizationId);
  }, []);

  useEffect(() => {
    // Make a copy.
    const initialApplicationInfo: AppFullInfo = JSON.parse(JSON.stringify(initialAppValue));
    // Apply initial values.
    initialApplicationInfo.description = t('new_application.step2.ApplicationDescription');
    const defaultLocation = displayLanguage(i18n.language);
    initialApplicationInfo.mainLanguage = {
      id: '',
      name: defaultLocation.name,
      locale: defaultLocation.code
    };

    let packageName = '';
    if (currentStateFromLocation != null && currentStateFromLocation.orgId) {
      (async () => {
        const organization = await fetchOrganization(currentStateFromLocation.orgId);
        packageName = `com.${organization.name}.`;
      })();
    } else {
      if (session.user) {
        packageName = `com.${session.user.username.toLowerCase()}.`;
      }
    }
    initialApplicationInfo.domain = packageName;
    setAppInfo(initialApplicationInfo);
  }, [currentStateFromLocation, fetchOrganization, i18n.language, session.user, t]);

  function displayLanguage(val: string): Loc {
    if (val.startsWith('pt') || val.startsWith('br')) {
      return { code: 'pt', name: 'Portuguese' };
    } else if (val.startsWith('en')) {
      return { code: 'en', name: 'English' };
    } else {
      return { code: '', name: '' };
    }
  }

  function updateForm<K extends keyof AppFullInfo>(key: K, value: AppFullInfo[K]) {
    const newAppInfo = produce(appInfo, (draft) => {
      if (key === 'createRepo' && value === true) {
        draft['gitRepository'] = appInfo.name;
        draft['repoDescription'] = appInfo.description;
      }
      if (key === 'createRepo' && value === false) draft['gitRepository'] = '';
      draft[key] = value;
    });
    setAppInfo(newAppInfo);

    // if (formRef.current) {
    //   setFormValidity(formRef.current.checkValidity());
    // }
  }

  function handleStepChange(nextStep: number) {
    let next = nextStep;
    const ignoredSteps = new Set<number>();
    if (!gitHubIntegration) {
      ignoredSteps.add(3);
    }
    if (!appInfo.has_backend) {
      ignoredSteps.add(4);
    }
    if (!appInfo.has_frontend || !appInfo.has_database || !appInfo.has_backend) {
      ignoredSteps.add(1);
    }
    if (!appInfo.has_frontend) {
      ignoredSteps.add(5);
    }
    if (!appInfo.has_frontend || appInfo.template !== Template.BLANK) {
      ignoredSteps.add(6);
    }

    while (ignoredSteps.has(next)) {
      next = next > currentStep ? next + 1 : next - 1;
    }

    setCurrentStep(next);
  }

  const closingAfterCreate = async (response: ApiAppInfo) => {
    // we need to reload the user here if not, we can not enter into dashboard
    session.reloadUser();
    const modules = await DashboardService.getModulesByApp(response.id);
    if (response?.id && modules.length > 0) {
      const moduleId = modules[0].id;

      if (response.has_database) {
        navigate(
          `/app/${response.id}/module/${moduleId}/schema/829fdea7-75fd-4202-9d0c-d237567ef52f`
        );
      } else if (response.has_backend) {
        navigate(`/app/${response.id}/module/${moduleId}/logic`);
      } else if (response.has_frontend) {
        navigate(`/app/${response.id}/module/${moduleId}/ui`);
      }
    }
  };

  const creatingFavIcon = async (appInfo: AppFullInfo, currAppCreated: ApiAppInfo) => {
    // if favIcon propertie does not exist just exit
    if (!appInfo.favIcon) return;

    const formdata = new FormData();
    formdata.append('file', appInfo.favIcon);
    await ProjectsService.createFavIcon(formdata, currAppCreated.id);
    formdata.delete('file');
  };

  async function handleFinish() {
    if (isLoading) return;

    const selectedLanguage = localStorage.getItem('language') || 'en';
    const updatedAppInfo: AppFullInfo & { selectedLanguage: string } = {
      ...appInfo,
      selectedLanguage
    };

    setIsLoading(true);

    try {
      if (orgId && orgId > -1) {
        await OrganizationService.createProject(orgId, updatedAppInfo).then(async (response) => {
          await creatingFavIcon(updatedAppInfo, response).then(() => {
            closingAfterCreate(response);
          });
        });
      } else if (updatedAppInfo.mainLanguage) {
        await ProjectsService.createApp(updatedAppInfo).then(async (response) => {
          await creatingFavIcon(updatedAppInfo, response).then(() => {
            closingAfterCreate(response);
          });
        });
      }
    } catch (err) {
      console.error(err);
      if (err instanceof Error) {
        const currError = JSON.parse(err.message);
        const message = apiErrorMessageToI18n(currError.message);
        setErrorFromApi(t(message) ?? message);
      }
      setDispatchErrorMessage(true);
    } finally {
      setIsLoading(false);
    }
  }

  const handleErrorMessageUpdate = (newErrorMessage: string) => {
    setErrorMessage(newErrorMessage);
  };

  return (
    <>
      {dispatchErrorMessage && (
        <ErrorMessageCreateApp
          setgettingValueError={setDispatchErrorMessage}
          messageToRender={
            errorFromApi ? errorFromApi : 'You need to set valid domain and valid name!'
          }
        />
      )}
      <Navbar context="homepage" />
      <Container className={`bg-body p-4 pt-5 ${styles.bgWizard} ${styles.wizardContainer}`}>
        <Form ref={formRef} id="bodyForm">
          {currentStep === 0 && (
            <Step0
              updateErrorMessage={handleErrorMessageUpdate}
              appInfo={appInfo}
              onChange={updateForm}
            />
          )}
          {currentStep === 1 && (
            <Step1
              appInfo={appInfo}
              onChange={updateForm}
              updateErrorMessage={handleErrorMessageUpdate}
            />
          )}
          {currentStep === 2 && (
            <Step2
              updateErrorMessage={handleErrorMessageUpdate}
              appInfo={appInfo}
              onChange={updateForm}
            />
          )}
          {currentStep === 3 && <Step3 appInfo={appInfo} onChange={updateForm} repos={repos} />}
          {currentStep === 4 && (
            <Step4
              updateErrorMessage={handleErrorMessageUpdate}
              appInfo={appInfo}
              onChange={updateForm}
            />
          )}
          {currentStep === 5 && <Step5 appInfo={appInfo} onChange={updateForm} />}
          {currentStep === 6 && <Step6 appInfo={appInfo} onChange={updateForm} />}
          {currentStep === 7 && <Step7 appInfo={appInfo} />}
        </Form>
      </Container>
      <div className="fixed-bottom">
        <Container className="bg-body-tertiary p-4 pb-4">
          <WizardProgress
            loading={isLoading}
            totalSteps={7}
            step={currentStep}
            onStepChange={handleStepChange}
            onFinish={handleFinish}
            error={errorMessage}
          />
        </Container>
      </div>
    </>
  );
}

export default Wizard;
