import { ApiAppInfo, AppFullInfo, AppInfo, AppVersionInfo, JobInfo, JobOptions } from '../types';
import { ModuleInfo, ModuleQuery } from 'modules/dashboard/types';
import { MembersAndTeams, UpdateUserApp, UserProfile } from 'modules/auth/types/auth_types';

export const API_URL = process.env.REACT_APP_API_URL;

export default class Projects {
  async createApp(data: AppFullInfo & { selectedLanguage?: string }) {
    const requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/json');

    const options: RequestInit = {
      method: 'POST',
      headers: requestHeaders,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(data)
    };

    const request = new Request(`${API_URL}/apps`, options);
    return fetch(request).then(async (response: Response) => {
      if (response.ok) {
        return response.json() as Promise<ApiAppInfo>;
      }

      const message = await response.text();
      throw new Error(message);
    });
  }

  async createFavIconApp(data: FormData, appId: string) {
    const options: RequestInit = {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      body: data
    };

    return fetch(`${API_URL}/apps/${appId}/favicon`, options).then(async (response) => {
      if (response.ok) {
        return true;
      }

      const message = await response.text();
      throw new Error(message);
    });
  }

  async deleteFavIconApp(appId: string) {
    const options: RequestInit = {
      method: 'DELETE',
      mode: 'cors',
      credentials: 'include'
    };

    return fetch(`${API_URL}/apps/${appId}/favicon`, options).then(async (response) => {
      if (response.ok) {
        return true;
      }

      const message = await response.text();
      throw new Error(message);
    });
  }

  async createAppVersion(appId: string, appVersion: AppVersionInfo) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'POST',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(appVersion)
    };

    const req = new Request(`${API_URL}/apps/${appId}/versions`, options);
    return await fetch(req).then(async (response) => {
      if (response.ok) {
        return response.json() as Promise<ApiAppInfo>;
      }
      const errorMessage = await response.text();
      console.error(errorMessage);
      throw new Error(errorMessage);
    });
  }

  async getProjectById(appId: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const request = new Request(`${API_URL}/apps/${appId}`, options);
    return fetch(request).then(async (response: Response) => {
      if (response.ok) {
        return response.json() as Promise<ApiAppInfo>;
      }

      const message = await response.text();
      throw new Error(message);
    });
  }

  async updateProjectById(appId: string, body: AppFullInfo | AppInfo) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'PUT',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(body)
    };

    try {
      const req = new Request(`${API_URL}/apps/${appId}`, options);
      const resp = await fetch(req);
      return (await resp.json()) as Promise<ApiAppInfo>;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot resolve server');
    }
  }

  async getProjects() {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const request = new Request(`${API_URL}/apps`, options);
    return fetch(request).then(async (response: Response) => {
      if (response.ok) {
        return response.json() as Promise<ApiAppInfo[]>;
      }

      const message = await response.text();
      throw new Error(message);
    });
  }

  async deleteProject(appId: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'DELETE',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const request = new Request(`${API_URL}/apps/${appId}`, options);
    return fetch(request).then(async (response: Response) => {
      if (response.ok) {
        return response.json() as Promise<ApiAppInfo>;
      }

      const message = await response.text();
      throw new Error(message);
    });
  }

  async createModule(moduleQuery: ModuleQuery) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'POST',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(moduleQuery)
    };

    try {
      const req = new Request(`${API_URL}/modules`, options);
      const resp = await fetch(req);
      return await resp.json();
    } catch (error) {
      console.error(error);
      throw new Error('Cannot resolve server');
    }
  }

  async getModuleById(moduleId: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const request = new Request(`${API_URL}/modules/${moduleId}`, options);
    return fetch(request).then(async (response: Response) => {
      if (response.ok) {
        return response.json() as Promise<ModuleInfo>;
      }

      const message = await response.text();
      throw new Error(message);
    });
  }

  async updateModuleById(moduleId: string, body: ModuleQuery) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'PUT',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(body)
    };

    try {
      const req = new Request(`${API_URL}/modules/${moduleId}`, options);
      const res = await fetch(req);
      return res.json();
    } catch (error) {
      console.error(error);
      throw new Error('Could not resolve update module request');
    }
  }

  async deleteModuleById(moduleId: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'DELETE',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    try {
      const request = new Request(`${API_URL}/modules/${moduleId}`, options);
      const resp = await fetch(request);
      return await resp.json();
    } catch (error) {
      console.error(error);
      throw new Error('Cannot resolve server');
    }
  }

  async runJob(appId: string, jobOptions: JobOptions) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'POST',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(jobOptions)
    };

    try {
      const req = new Request(`${API_URL}/code/${appId}/run`, options);
      const resp = await fetch(req);
      return (await resp.json()) as JobInfo;
    } catch (error: any) {
      console.error(error);
      throw new Error('Cannot resolve server');
    }
  }

  async getJobStatus(appId: string, jobId: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    try {
      const req = new Request(`${API_URL}/code/${appId}/jobs/${jobId}/status`, options);
      const resp = await fetch(req);
      return (await resp.json()) as JobInfo;
    } catch (error: any) {
      console.error(error);
      throw new Error('Cannot resolve server');
    }
  }

  async getJobResult(appId: string, jobId: string) {
    window.open(`${API_URL}/code/${appId}/jobs/${jobId}/result`);
  }

  async getJobList(appId: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    try {
      const req = new Request(`${API_URL}/code/${appId}/jobs`, options);
      const resp = await fetch(req);
      return (await resp.json()) as JobInfo[];
    } catch (error: any) {
      console.error(error);
      throw new Error('Cannot resolve server');
    }
  }

  async deleteJob(appId: string, jobId: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'DELETE',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    try {
      const req = new Request(`${API_URL}/code/${appId}/jobs/${jobId}`, options);
      return await fetch(req).then((res) => {
        if (res.ok) return;
      });
    } catch (error: any) {
      console.error(error);
      throw new Error('Cannot resolve server');
    }
  }

  async getMembersOfApp(appId: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const request = new Request(`${API_URL}/apps/${appId}/collaborators`, options);
    return fetch(request).then(async (response: Response) => {
      if (response.ok) {
        return response.json() as Promise<MembersAndTeams>;
      }

      const message = await response.text();
      throw new Error(message);
    });
  }

  async updateMembersApp(appId: string, members: UpdateUserApp) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'PUT',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(members)
    };

    const request = new Request(`${API_URL}/apps/${appId}/collaborators`, options);
    return fetch(request).then(async (response: Response) => {
      if (response.ok) {
        return true;
      }

      const message = await response.text();
      throw new Error(message);
    });
  }
}
