import React, { useCallback, useContext, useEffect, useState } from 'react';
import styles from './styles.module.css';
import { Button, Form } from 'react-bootstrap';
import { ObjectSimple } from 'modules/logic_builder/types';
import { useParams } from 'react-router-dom';
import { Column, EnumFrame, Relationship, Table } from 'modules/modeler/types';
import { DashboardService, ObjectsService } from 'modules/logic_builder/services';
import { ObjectDialog } from 'modules/logic_builder/components/dialogs/object_dialog';
import { ModuleInfo } from 'modules/dashboard/types';
import Icon from '../../../../../web_ui/icon';
import { t } from 'i18next';
import { ViewStateService } from '../../../services';
import { useDispatch } from 'react-redux';
import { setObjects } from '../../store/actions/schemas';
import { ViewStateResponse } from '../../../repos/schemas/ViewState';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import Confirmation from 'web_ui/confirmation';
import LogicBuilderContext from 'modules/logic_builder/store';

type ObjectInstanceProps = {
  object: ObjectSimple;
  objectEditUuid: string;
  setObjectEditUuid: (e: string) => void;
  setShowObjectEditor: (b: boolean) => void;
  tables: Table[];
  columns: Column[];
  relationships: Relationship[];
  enums: EnumFrame[];
  fetchSchema: () => Promise<void>;
};

const ObjectInstanceItem = (props: ObjectInstanceProps) => {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const { fetchObjects } = useContext(LogicBuilderContext);
  const { module_id } = useParams();

  async function onDelete() {
    if (!module_id) return;

    await ObjectsService.deleteObject(props.object.uuid).then(() => {
      fetchObjects(module_id);
      props.fetchSchema();
      setShowConfirmationDialog(false);
    });
  }

  return (
    <>
      <div
        className={styles.containerObj}
        key={props.object.uuid}
        onClick={() => {
          props.setShowObjectEditor(true);
          props.setObjectEditUuid(props.object.uuid);
        }}
      >
        <div className={styles.headObj}>
          {props.object.name}
          <div className={styles.borderRight}></div>
        </div>
        <div id="objectBody" className={styles.bodyObj}>{`{ ${
          props.object.objectItems &&
          props.object.objectItems.length > 0 &&
          props.object.objectItems.map((item, index) => {
            return `${item.name}: ${item.dataType}`;
          })
        } }`}</div>
        {props.object.entityName != null ? (
          <div className={styles.footerObj}>
            <div className={styles.footerTag}>{props.object.entityName}</div>
          </div>
        ) : (
          <div className={styles.footerObj} />
        )}
        <div className={styles.wrapperIcons}>
          <HelpPopover
            placement={'top'}
            helpBoxProps={{
              title: t('editButton') ?? 'editButton'
            }}
          >
            <div
              id={`editButton-${props.object.name}`}
              className={`bg-body-tertiary shadow-sm ${styles.itemIcons}`}
              onClick={() => {
                props.setShowObjectEditor(true);
                props.setObjectEditUuid(props.object.uuid);
              }}
            >
              <Icon iconName="pen-to-square" extraProps={`text-secondary h7`} />
            </div>
          </HelpPopover>
          <HelpPopover
            placement={'top'}
            helpBoxProps={{
              title: t('Delete') ?? 'Delete'
            }}
          >
            <div
              id={`deleteButton-${props.object.name}`}
              className={`bg-body-tertiary shadow-sm ${styles.itemIcons}`}
              onClick={(event) => {
                event.stopPropagation();
                setShowConfirmationDialog(true);
              }}
            >
              <Icon iconName="trash" extraProps={`text-secondary h7`} />
            </div>
          </HelpPopover>
        </div>
        <Confirmation
          show={showConfirmationDialog}
          message={`${t('deleteQuotes.object')}${' '}(${props.object.name})`}
          onConfirmation={onDelete}
          onCancel={() => setShowConfirmationDialog(false)}
          onClose={() => setShowConfirmationDialog(false)}
        />
      </div>
    </>
  );
};

export function ObjectInstance() {
  const { app_id, module_id, view_id } = useParams();
  const [moduleObjs, setModuleObjs] = useState<ObjectSimple[]>();
  const [objectEditUuid, setObjectEditUuid] = useState<string>('');
  const [tables, setTables] = useState<Table[]>([]);
  const [columns, setColumns] = useState<Column[]>([]);
  const [relationships, setRelationships] = useState<Relationship[]>([]);
  const [enums, setEnums] = useState<EnumFrame[]>([]);
  const [modules, setModules] = useState<ModuleInfo[]>([]);
  const [showObjectEditor, setShowObjectEditor] = useState<boolean>(false);
  const dispatch = useDispatch();

  const fetchSchema = async () => {
    if (!module_id) return;
    const objs = await ObjectsService.getObjectsByModuleBackendFrontend(module_id, undefined, true);
    setModuleObjs(objs);
    fetchObjectsToState();
  };

  const fetchObjectsToState = async () => {
    const state: ViewStateResponse = await ViewStateService.getViewState(view_id ?? '');
    const objects = state.objects;
    dispatch(setObjects(objects));
  };

  useEffect(() => {
    fetchSchema();
  }, [module_id, objectEditUuid]);

  useEffect(() => {
    if (!app_id) return;
    DashboardService.getModulesByApp(app_id).then((modules) => setModules(modules));
  }, [app_id]);

  const [search, setSearch] = useState('');

  const applyFilterObjects = (f: ObjectSimple): boolean => {
    if (f.name.toLowerCase().includes(search.toLowerCase())) return true;
    if (f.description.toLowerCase().includes(search.toLowerCase())) return true;
    return false;
  };

  const fetchObjectsContext = useCallback(async (module_id: string) => {
    await ObjectsService.getObjectsSchema(module_id, true).then((schema) => {
      setEnums(schema?.enums ? schema.enums : []);
      setTables(schema?.tables ? schema.tables : []);
      setColumns(schema?.columns ? schema.columns : []);
      setRelationships(schema?.relationships ? schema.relationships : []);
    });
  }, []);

  useEffect(() => {
    if (!module_id) return;

    fetchObjectsContext(module_id);
  }, [fetchObjectsContext, module_id]);

  return (
    <>
      <div className={styles.FilterInput}>
        <Form className={styles.FilterInputForm}>
          <Form.Control
            id={'designer-behavior-searchinput-object'}
            type="search"
            placeholder="Search"
            aria-label="Search"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </Form>
        <div>
          <Button
            className="me-3"
            id={'createButton'}
            variant="primary"
            onClick={() => {
              setShowObjectEditor(true);
            }}
          >
            <Icon iconName="plus" extraProps="pe-1"></Icon>
            {t('organizations.new_organization.Create')}
          </Button>
        </div>
      </div>

      <div className={styles.Content}>
        {moduleObjs &&
          moduleObjs
            .filter((o) => applyFilterObjects(o))
            .sort((a, b) => moduleObjs.indexOf(b) - moduleObjs.indexOf(a))
            .map((o) => {
              return (
                <ObjectInstanceItem
                  key={o.uuid}
                  object={o}
                  objectEditUuid={objectEditUuid}
                  setObjectEditUuid={setObjectEditUuid}
                  setShowObjectEditor={setShowObjectEditor}
                  tables={tables}
                  relationships={relationships}
                  columns={columns}
                  enums={enums}
                  fetchSchema={fetchSchema}
                />
              );
            })}
      </div>
      <ObjectDialog
        show={showObjectEditor}
        onClose={() => {
          setShowObjectEditor(false);
          if (objectEditUuid === '') {
            // update objects list, when on creation
            fetchSchema();
          }
          setObjectEditUuid('');
        }}
        editMode={objectEditUuid !== ''}
        dialogTitle={objectEditUuid !== '' ? 'EditObject' : 'CreateObject'}
        objectId={objectEditUuid}
        tables={tables}
        columns={columns}
        enums={enums}
        relationships={relationships}
        entityMode={false}
        modules={modules}
      />
    </>
  );
}
