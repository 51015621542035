import React, { useCallback } from 'react';
import { ControlProps } from '..';
import styles from './styles.module.css';
import { FormLabel } from 'react-bootstrap';
import HelpPopover from '../components/Popover';
import HelpIcon from '../components/HelpIcon';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { InterfaceStudioState } from 'modules/designer/studio/store';
import { useParams } from 'react-router-dom';
import {
  changeAllComponentsProperties,
  ChangePropertyPayload
} from 'modules/designer/studio/store/actions/components';

function SpacingControl(props: ControlProps) {
  const components = useSelector((state: InterfaceStudioState) => state.components);
  const links = useSelector((state: InterfaceStudioState) => state.links);
  const selectedComponent = useSelector(
    (state: InterfaceStudioState) => state.studio.selectedComponent.uuid
  );
  const { view_id } = useParams();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  // function handleChangeVerticalBorder(ev: React.ChangeEvent<HTMLInputElement>) {
  //   if (!props.onChange) return;

  //   props.onChange(parseFloat(ev.target.value), 'borderTop');
  //   props.onChange(parseFloat(ev.target.value), 'borderBottom');
  // }

  // function handleChangeHorizontalBorder(ev: React.ChangeEvent<HTMLInputElement>) {
  //   if (!props.onChange) return;

  //   props.onChange(parseFloat(ev.target.value), 'borderLeft');
  //   props.onChange(parseFloat(ev.target.value), 'borderRight');
  // }

  function updateFullHeightSize(topMarginValue: number, bottomMarginValue: number) {
    if (
      !components ||
      !selectedComponent ||
      components[selectedComponent]?.data?.optionSizeHeight !== 'full'
    )
      return null;

    return {
      uuid: selectedComponent,
      key: 'height',
      value: `calc(${handleCheckIfIsPageChildren() ? '100vh' : '100%'} - ${
        topMarginValue ?? 0
      }px - ${bottomMarginValue ?? 0}px)`
    };
  }

  function updateFullWidthSize(leftMarginValue: number, rightMarginValue: number) {
    if (
      !components ||
      !selectedComponent ||
      components[selectedComponent]?.data?.optionSizeWidth !== 'full'
    )
      return null;

    return {
      uuid: selectedComponent,
      key: 'width',
      value: `calc(${handleCheckIfIsPageChildren() ? '100vh' : '100%'} - ${
        leftMarginValue ?? 0
      }px - ${rightMarginValue ?? 0}px)`
    };
  }

  function handleUpdateMarginValue(value: number, position: 'Top' | 'Bottom' | 'Left' | 'Right') {
    if (!props.onChange || !selectedComponent) return;

    let updatedSize;
    switch (position) {
      case 'Top':
        updatedSize = updateFullHeightSize(value, props.value.marginBottom);
        break;
      case 'Bottom':
        updatedSize = updateFullHeightSize(props.value.marginTop, value);
        break;
      case 'Left':
        updatedSize = updateFullWidthSize(value, props.value.marginRight);
        break;
      case 'Right':
        updatedSize = updateFullWidthSize(props.value.marginLeft, value);
        break;
    }

    const updatedMargin: ChangePropertyPayload = {
      uuid: selectedComponent,
      key: `margin${position}`,
      value: value
    };

    const stylesToUpdate = [updatedMargin];
    const dataToUpdate: ChangePropertyPayload[] = updatedSize ? [updatedSize] : [];

    dispatch(changeAllComponentsProperties({ data: dataToUpdate, style: stylesToUpdate }));
  }

  const handleCheckIfIsPageChildren = useCallback(() => {
    return view_id && selectedComponent && links[view_id]?.includes(selectedComponent);
  }, [view_id, links, selectedComponent]);

  return (
    <>
      {props.value && (
        <>
          <div className={` ${styles.content}`}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FormLabel className={`form-label ${styles.label}`}>
                {t('designer.right_side.BoxModel')}
              </FormLabel>
              <HelpPopover
                helpBoxProps={{
                  title: t('designer.right_side.controls.spacing.label')!,
                  description: t('designer.right_side.controls.spacing.desc')!
                }}
                placement="top"
              >
                <HelpIcon />
              </HelpPopover>
            </div>
            <div className={`${styles.spacingContainer}`}>
              <div className={styles.marginContainer}>
                <span
                  style={{
                    position: 'absolute',
                    color: '#000',
                    fontSize: '11px',
                    width: '100%',
                    height: '100%',
                    padding: '3px'
                  }}
                >
                  {t('designer.right_side.margin')}
                </span>
                {props.value.marginTop || props.value.marginTop === 0 ? (
                  <input
                    id="marginTop"
                    type="text"
                    value={props.value.marginTop}
                    className={`${styles.spacingInput} ${styles.topInput}`}
                    onChange={(ev) =>
                      props.onChange && ev.target.value.length > 0
                        ? handleUpdateMarginValue(parseFloat(ev.target.value), 'Top')
                        : null
                    }
                  />
                ) : null}

                {props.value.marginRight || props.value.marginRight === 0 ? (
                  <input
                    type="text"
                    value={props.value.marginRight}
                    className={`${styles.spacingInput} ${styles.rightInput}`}
                    onChange={(ev) =>
                      props.onChange && ev.target.value.length > 0
                        ? handleUpdateMarginValue(parseFloat(ev.target.value), 'Right')
                        : null
                    }
                  />
                ) : null}
                {props.value.marginLeft || props.value.marginLeft === 0 ? (
                  <input
                    type="text"
                    value={props.value.marginLeft}
                    className={`${styles.spacingInput} ${styles.leftInput}`}
                    onChange={(ev) =>
                      props.onChange && /^\d*\.?\d*$/.test(ev.target.value) // Check if the input matches the regex pattern
                        ? handleUpdateMarginValue(parseFloat(ev.target.value), 'Left')
                        : null
                    }
                  />
                ) : null}

                <input
                  type="text"
                  value={props.value.marginBottom}
                  className={`${styles.spacingInput} ${styles.bottomInput}`}
                  onChange={(ev) =>
                    props.onChange && ev.target.value.length > 0
                      ? handleUpdateMarginValue(parseFloat(ev.target.value), 'Bottom')
                      : null
                  }
                />
                <div className={styles.paddingContainer}>
                  <span
                    style={{
                      position: 'absolute',
                      color: '#000',
                      fontSize: '9px',
                      width: '100%',
                      height: '100%',
                      padding: '3px'
                    }}
                  >
                    {t('designer.right_side.padding')}
                  </span>
                  {props.value.paddingLeft || props.value.paddingLeft === 0 ? (
                    <input
                      type="text"
                      value={props.value.paddingLeft}
                      className={`${styles.spacingInput} ${styles.leftInput}`}
                      onChange={(ev) =>
                        props.onChange && ev.target.value.length
                          ? props.onChange(parseFloat(ev.target.value), 'paddingLeft')
                          : null
                      }
                    />
                  ) : (
                    <span className={`${styles.noProperty} ${styles.noPropLeft} mb-0`}>-</span>
                  )}
                  {props.value.paddingRight || props.value.paddingRight === 0 ? (
                    <input
                      type="text"
                      value={props.value.paddingRight}
                      className={`${styles.spacingInput} ${styles.rightInput}`}
                      onChange={(ev) =>
                        props.onChange && ev.target.value.length
                          ? props.onChange(parseFloat(ev.target.value), 'paddingRight')
                          : null
                      }
                    />
                  ) : (
                    <span className={`${styles.noProperty} ${styles.noPropRight} mb-0`}>-</span>
                  )}
                  {props.value.paddingTop || props.value.paddingTop === 0 ? (
                    <input
                      type="text"
                      value={props.value.paddingTop}
                      className={`${styles.spacingInput} ${styles.topInput}`}
                      onChange={(ev) =>
                        props.onChange && ev.target.value.length
                          ? props.onChange(parseFloat(ev.target.value), 'paddingTop')
                          : null
                      }
                    />
                  ) : (
                    <span className={`${styles.noProperty} ${styles.noPropTop} mb-0`}>-</span>
                  )}

                  {props.value.paddingBottom || props.value.paddingBottom === 0 ? (
                    <input
                      type="text"
                      value={props.value.paddingBottom}
                      className={`${styles.spacingInput} ${styles.bottomInput}`}
                      onChange={(ev) =>
                        props.onChange && ev.target.value.length
                          ? props.onChange(parseFloat(ev.target.value), 'paddingBottom')
                          : null
                      }
                    />
                  ) : (
                    <span className={`${styles.noProperty} ${styles.noPropBottom} mb-0`}>-</span>
                  )}

                  <div className={styles.elementContainer}></div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default SpacingControl;
