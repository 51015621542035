import React, { MouseEvent, useEffect, useState } from 'react';
import styles from './style.module.css';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import Icon from 'web_ui/icon';
import { Theme } from 'modules/designer/types';
import { useParams } from 'react-router-dom';
import { ThemeService } from 'modules/designer/services';
import Confirmation from 'web_ui/confirmation';
import { ProjectsService } from 'modules/project/services';
import { AppInfo } from 'modules/project/types';
import { sleep } from 'utils/utils';
import { ThemeState } from '../../store';
import { setSelectedTheme, setTheme } from '../../store/actions/theme';
import { useDispatch } from 'react-redux';
import ThemePreview from './theme_preview';
import { BOOTSTRAP_COLORS_ORDER } from './manifest';
import ThemeManagerModal from './theme_manager_modal';
import { useTranslation } from 'react-i18next';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';

type AppThemeToolbarProps = {
  themes: Theme[];
};

function AppThemeToolbar(props: AppThemeToolbarProps) {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [themes, setThemes] = useState<Theme[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [editTheme, setEditTheme] = useState<Theme>();
  const [showCopiedCode, setShowCopiedCode] = useState<boolean>(false);
  const { app_id } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    setThemes(props.themes);
  }, [props.themes]);

  async function handlePrepareToCreateTheme() {
    setEditTheme(undefined);
    setShowModal(true);
  }

  async function handleDeleteTheme() {
    try {
      if (!app_id || !editTheme?.id) return;
      const deletedTheme = await ThemeService.deleteTheme(app_id, editTheme.id);
      if (deletedTheme) {
        setThemes((themes) => themes?.filter((theme) => theme.id !== editTheme.id));
      }
    } catch (error) {
      console.log(error);
    }
    setShowDeleteModal(false);
  }

  function handleSelectToAction(ev: MouseEvent<HTMLDivElement>, theme: Theme, isUpdate = true) {
    ev.stopPropagation();
    setEditTheme(theme);
    isUpdate ? setShowModal(true) : setShowDeleteModal(true);
  }

  function handleSelectTheme(index: number) {
    if (!app_id || !themes[index]) return;
    const appInfo: AppInfo = {
      frontend: { theme: themes[index].id }
    };
    try {
      ProjectsService.updateProjectById(app_id, appInfo);

      const updatedThemes = themes.map((theme, i) => ({
        ...theme,
        selected: i === index
      }));

      setThemes(updatedThemes);

      const transformedObject: any = {};
      themes[index].values?.forEach((item: any) => {
        transformedObject[item.key] = {
          id: item.id,
          section: item.section,
          key: item.key,
          value: item.value
        };
      });

      const themeState: ThemeState = {
        colors: {
          primary: transformedObject['primary'].value,
          secondary: transformedObject['secondary'].value,
          success: transformedObject['success'].value,
          danger: transformedObject['danger'].value,
          warning: transformedObject['warning'].value,
          info: transformedObject['info'].value,
          light: transformedObject['light'].value,
          dark: transformedObject['dark'].value,
          BACKGROUND_COLOR: transformedObject['BACKGROUND_COLOR'].value
        },
        text: {
          GLOBAL_FONT: transformedObject['GLOBAL_FONT'].value,
          GLOBAL_FONT_COLOR: transformedObject['GLOBAL_FONT_COLOR'].value,
          FONT_SIZE: transformedObject['FONT_SIZE'].value
        }
      };
      dispatch(setTheme(themeState));
      dispatch(setSelectedTheme(appInfo.frontend?.theme || ''));
    } catch (error) {
      console.log(error);
    }
  }

  // This callback function is used by the manager modal to update the themes list.
  async function handleUpdateThemes(theme: Theme, isNew = false) {
    isNew
      ? setThemes([...themes, theme])
      : setThemes((prevThemes) =>
          prevThemes.map((prevTheme) => (prevTheme.id === theme.id ? theme : prevTheme))
        );
    setEditTheme(theme);
  }

  async function handleShowCopiedIcon() {
    setShowCopiedCode(true);
    await sleep(600);
    setShowCopiedCode(false);
  }

  return (
    <div className={`${styles.themeContainer}`}>
      <div className={`${styles.themeHeader} p-2 pt-0 mb-3`}>
        <p className="mb-0 text-body-emphasis">{t('designer.themes.SelectATheme')}</p>
        <Button id="createButton" className="btn-sm" onClick={handlePrepareToCreateTheme}>
          {t('designer.themes.Create')}
        </Button>
      </div>

      <div className={`${styles.paneToolbarContent}`}>
        {themes?.map((theme, index) => {
          return (
            <div onClick={(ev) => handleSelectTheme(index)} key={theme.id}>
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="right"
                rootClose
                overlay={
                  <Popover id="popover-positioned-left">
                    <Popover.Body>
                      <ThemePreview theme={theme}></ThemePreview>
                    </Popover.Body>
                  </Popover>
                }
              >
                <div
                  className={`${
                    theme.selected ? 'bg-body-tertiary text-body-emphasis' : 'text-body-tertiary'
                  } ${styles.themeCard} p-2`}
                >
                  <div className={`${styles.themeHeader} mb-3`}>
                    <label htmlFor="#">{theme.name}</label>
                    <div className="d-flex">
                      <HelpPopover
                        placement={'top'}
                        helpBoxProps={{
                          title: t('editButton')!
                        }}
                      >
                        <div
                          id={`editButton${theme.name}`}
                          onClick={(ev) => handleSelectToAction(ev, theme)}
                          className={`${styles.actionButton}`}
                        >
                          <Icon iconName="edit"></Icon>
                        </div>
                      </HelpPopover>
                      <HelpPopover
                        placement={'top'}
                        helpBoxProps={{
                          title: t('Delete')!
                        }}
                      >
                        <div
                          id={`deleteButton${theme.name}`}
                          onClick={(ev) =>
                            theme.id ? handleSelectToAction(ev, theme, false) : null
                          }
                          className={`${styles.actionButton} ms-2`}
                        >
                          <Icon iconName="trash"></Icon>
                        </div>
                      </HelpPopover>
                    </div>
                  </div>

                  <div className={`${styles.palette}`}>
                    {theme.values &&
                      theme.values
                        .sort((a, b) => {
                          const aIndex = BOOTSTRAP_COLORS_ORDER.indexOf(a.key);
                          const bIndex = BOOTSTRAP_COLORS_ORDER.indexOf(b.key);
                          return aIndex - bIndex;
                        })
                        .filter((themeValue) => themeValue.section === 'colors')
                        .map((value) => {
                          return (
                            <OverlayTrigger
                              key={value.key}
                              trigger={['hover', 'focus']}
                              placement="top"
                              rootClose
                              overlay={
                                <Popover id="popover-positioned-left">
                                  <Popover.Body>
                                    <div
                                      style={{
                                        display: 'inline',
                                        opacity: showCopiedCode ? '100%' : 0
                                      }}
                                      className={`${styles.hiddenIcon} me-1`}
                                    >
                                      {<Icon iconName="copy"></Icon>}
                                    </div>
                                    {t('designer.themes.' + value.key)}
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <div
                                title={value.key}
                                key={value.id}
                                className={`${styles.color}`}
                                style={{ backgroundColor: value.value }}
                                onClick={(ev) => {
                                  ev.stopPropagation();
                                  navigator.clipboard.writeText(value.value);
                                  handleShowCopiedIcon();
                                }}
                              >
                                <span className={`${styles.colorRef}`}>{value.value}</span>
                              </div>
                            </OverlayTrigger>
                          );
                        })}
                  </div>
                </div>
              </OverlayTrigger>
            </div>
          );
        })}
      </div>
      <Confirmation
        onClose={() => setShowDeleteModal(false)}
        show={showDeleteModal}
        onCancel={() => {
          setShowDeleteModal(false);
        }}
        onConfirmation={handleDeleteTheme}
        message={`${t('designer.Delete_Theme')} (${editTheme ? editTheme.name : ''})`}
      />
      <ThemeManagerModal
        showModal={showModal}
        setShowModal={setShowModal}
        theme={editTheme}
        setTheme={handleUpdateThemes}
        themes={themes}
      />
    </div>
  );
}

export default AppThemeToolbar;
