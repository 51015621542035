import React, { useEffect, useState } from 'react';

import styles from './styles.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedComponent, setSelectedView, setViewportMode } from '../../store/actions/studio';
import { ComponentUUID, ViewUUID } from '../../../types';
import Viewport from '../../components/viewport';
import { InterfaceStudioState, ViewportMode } from '../../store';
import DesktopMoldure from '../layout_view/deviceMoldures/desktop';
import MobileMoldure from '../layout_view/deviceMoldures/mobile';
import TabletMoldure from '../layout_view/deviceMoldures/tablet';
import { changeViewProperty } from '../../store/actions/views';

export type ViewData = {
  title: string;
  description?: string;
  route?: string;
  rootPage?: boolean;
  listParams?: string[];
  url?: string;
};

export type ViewStyles = {
  backgroundColor: string;
  globalFont: string;
  globalFontSize: number;
  globalFontColor: string;
  classes: string[];
};

export type PageViewProps = {
  uuid: ViewUUID;
  name: string;
  data: ViewData;
  layout: ComponentUUID[];
  styles?: ViewStyles;
  layout_uuid?: ViewUUID;
};

export type MoldureProps = {
  unselectComponent: () => void;
  route?: string;
  children: React.ReactNode;
  rootpage?: boolean;
  updateUrlBar: (value: string) => void;
};

function PageView(props: PageViewProps) {
  const dispatch = useDispatch();
  const selectedView = useSelector((state: InterfaceStudioState) => state.studio.selectedView);
  const selectedViewport = useSelector((state: InterfaceStudioState) => state.studio.viewportMode);
  const [elementPreview, setElementPreview] = useState<React.ReactElement | null>(null);
  const [loading, setLoading] = useState(false);
  const themes = useSelector((state: InterfaceStudioState) => state.theme);
  const modeComponents: { [key in ViewportMode]: React.ComponentType<MoldureProps> } = {
    desktop: DesktopMoldure,
    mobile: MobileMoldure,
    tablet: TabletMoldure
  };
  const API_URL = process.env.REACT_APP_API_URL;

  const style = {
    backgroundColor: themes?.colors?.['BACKGROUND_COLOR'] ?? props.styles?.backgroundColor,
    fontFamily: themes?.text?.['GLOBAL_FONT'] ?? `${props.styles?.globalFont}`,
    fontSize: themes?.text?.['FONT_SIZE']
      ? Number(themes?.text?.['FONT_SIZE']) <= 0
        ? `10px`
        : `${themes?.text?.['FONT_SIZE']}px`
      : Number(props.styles?.globalFontSize) <= 0
      ? `10px`
      : `${props.styles?.globalFontSize}px`,
    color: themes?.text?.['GLOBAL_FONT_COLOR'] ?? props.styles?.globalFontColor,
    backgroundImage:
      props.data.url && props.data.url.includes('http')
        ? `url("${props.data.url}")`
        : `url("${API_URL! + props.data.url}")`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%'
  };

  function handleSelectView() {
    dispatch(setSelectedView(props.uuid));
    dispatch(setSelectedComponent(null));
  }

  const updateUrlBar = (value: string): void => {
    if (!selectedView) return;
    dispatch(changeViewProperty(selectedView, 'route', value));
  };

  useEffect(() => {
    setLoading(true);
    if (selectedViewport in modeComponents) {
      setElementPreview(<Viewport uuid={props.uuid} styles={{ ...style }} />);
    }
    setLoading(false);
  }, [props.data.url, props.data.route, selectedViewport]);

  return (
    <>
      {loading && (
        <div className={`${styles.loading} spinner-border text-primary`} role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      )}
      <section
        className={`${styles[selectedViewport]} ${styles.browser} shadow`}
        onClick={handleSelectView}
      >
        {elementPreview && elementPreview}
      </section>
    </>
  );
}

export * from './manifest';
export * from './template';
export default PageView;
