import React from 'react';
import { ItemsWrapperProps } from 'web_ui/drag_n_drop';

export const ItemsWrapper = ({ children }: ItemsWrapperProps): JSX.Element => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(${4}, 1fr)`,
        gridGap: 10,
        maxWidth: '800px',
        margin: '16px auto'
      }}
    >
      {children}
    </div>
  );
};
