import React, { useContext } from 'react';
import { Col, Form } from 'react-bootstrap';
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';
import SessionContext from 'modules/auth/store';
import i18n from 'i18next';
import { Preferences } from 'modules/auth/enum';
import useTitle from 'hooks/useTitle';
import { languages } from 'routes/settings/constants';

function General() {
  const { t } = useTranslation();
  const session = useContext(SessionContext);

  const handleLanguageSelection = (target: string) => {
    session.setLanguage(target);
    i18n.changeLanguage(target);
    localStorage.setItem('language', target);
  };

  useTitle(t('tab.settingsAcc.general'));

  const handleChangeTheme = () => {
    session.changePreferences(Preferences.DARK_THEME, !session.preferences[Preferences.DARK_THEME]);
  };

  return (
    <>
      <Col className={styles.Section}>
        <Col className={`border-bottom ${styles.SectionTitleDiv}`}>
          <span className={`text-body-emphasis ${styles.SectionTitle}`}>
            {t('general_settings.Theme')}
          </span>
        </Col>
        <Col className={styles.FormContent}>
          <Form className={styles.Form}>
            <Form.Group className={styles.FormSection}>
              <Form.Check className={styles.FormControl} id="darkTheme">
                <Form.Check.Input
                  className={styles.FormCheckInput}
                  checked={session.preferences[Preferences.DARK_THEME]}
                  onChange={handleChangeTheme}
                />
                <Form.Check.Label className={`text-body-primary ${styles.FormTitle}`}>
                  {t('general_settings.DarkTheme')}
                </Form.Check.Label>
              </Form.Check>
              <Form.Label className={`text-body-tertiary ${styles.FormDescription}`}>
                {t('general_settings.DarkThemeDescription')}
              </Form.Label>
            </Form.Group>
          </Form>
        </Col>
      </Col>
      <Col className={styles.Section}>
        <Col className={`border-bottom ${styles.SectionTitleDiv}`}>
          <span className={`text-body-emphasis ${styles.SectionTitle}`}>
            {t('general_settings.LanguageSection')}
          </span>
        </Col>
        <Col className={styles.FormContent}>
          <Col className={styles.Section}>
            <Form>
              <Form.Group className={styles.FormSection}>
                <Form.Check.Label className={`text-body-primary ${styles.FormTitle}`}>
                  {t('general_settings.Language')}
                </Form.Check.Label>
                <Form.Select
                  id={'selectLanguage'}
                  style={{ width: '28rem' }}
                  className={styles.FormControl}
                  onChange={(e) => handleLanguageSelection(e.target.value)}
                  defaultValue={localStorage.getItem('language') || i18n.language}
                >
                  {languages.map((language) => (
                    <option key={language.code} value={language.code}>
                      {language.label}
                    </option>
                  ))}
                </Form.Select>

                <Form.Label className={`text-body-tertiary ${styles.FormDescription}`}>
                  {t('general_settings.languages.LanguageSub')}
                </Form.Label>
              </Form.Group>
            </Form>
          </Col>
        </Col>
      </Col>
    </>
  );
}

export default General;
