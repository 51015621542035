import { AppFullInfo } from 'modules/project/types';
import { OrganizationRepo } from '../repos';
import { AddOrganization, AddRole, RoleType } from '../types';

export default class Organization {
  async createOrg(data: AddOrganization) {
    return OrganizationRepo.createOrg(data);
  }

  async getOrganizationById(orgId: number) {
    return OrganizationRepo.getOrganizationById(orgId);
  }

  async updateOrganizationById(orgId: number, data: AddOrganization) {
    return OrganizationRepo.updateOrganizationById(orgId, data);
  }

  async deleteOrganizationById(orgId: number) {
    return OrganizationRepo.deleteOrganizationById(orgId);
  }

  async getOrganizations() {
    return OrganizationRepo.getOrganizations();
  }

  async getProjectsOfOrg(orgId: number) {
    return OrganizationRepo.getProjectsOfOrg(orgId);
  }

  async createProject(orgId: number, data: AppFullInfo & { selectedLanguage?: string }) {
    return OrganizationRepo.createProject(orgId, data);
  }

  async getMembersOfOrg(orgId: number) {
    return OrganizationRepo.getMembersOfOrg(orgId);
  }

  async deleteMemberOfOrg(orgId: number, memberId: number) {
    return OrganizationRepo.deleteMemberOfOrg(orgId, memberId);
  }

  async updateMemberOfOrg(orgId: number, memberId: number, newRole: string) {
    return OrganizationRepo.updateMemberOfOrg(orgId, memberId, newRole);
  }

  async getCustomRolesOfOrg(orgId: number) {
    return OrganizationRepo.getCustomRolesOfOrg(orgId);
  }

  async createCustomRoleOrg(orgId: number, newRole: AddRole) {
    return OrganizationRepo.createCustomRoleOrg(orgId, newRole);
  }

  async updateCustomRole(orgId: number, role: RoleType) {
    return OrganizationRepo.updateCustomRole(orgId, role);
  }
}
