import * as React from 'react';
import { Button, Form, FormGroup } from 'react-bootstrap';
import styles from './styles.module.css';
import { ModuleInfo, ModuleSettings } from 'modules/dashboard/types/global_types';
import { useTranslation } from 'react-i18next';
import { AVAILABLE_MODULES } from 'modules/project/types';

interface Props {
  choosingTheStorage: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  setModule: React.Dispatch<React.SetStateAction<ModuleInfo>>;
  module: ModuleInfo;
  dataToAssetsModule: ModuleSettings;
  keyAccess: string;
  setAccessKey: React.Dispatch<React.SetStateAction<string>>;
  secretKey: string;
  setSecretKey: React.Dispatch<React.SetStateAction<string>>;
  region: string;
  setRegion: React.Dispatch<React.SetStateAction<string>>;
  bucketName: string;
  setBucketName: React.Dispatch<React.SetStateAction<string>>;
  blockSave: boolean;
  setBlockSave: React.Dispatch<React.SetStateAction<boolean>>;
  clearS3Fields: () => void;
  submitTheS3: () => void;
}

function TemplateModule(props: Props) {
  const { t } = useTranslation();

  const valueToStorageType = () => {
    switch (props.dataToAssetsModule.value) {
      case 's3':
        return AVAILABLE_MODULES.S3;
      case 'Blob':
        return AVAILABLE_MODULES.BLOB;
      case 'file_system':
        return AVAILABLE_MODULES.FILE_SYSTEM;
    }
  };

  return (
    <div className={styles.appSettingsDialog}>
      <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
        <Form.Check
          label={t('appResume.new_module.customModule')}
          name="moduleType"
          type="radio"
          id="customRadio"
          onClick={() => {
            props.setModule({ ...props.module, isAuth: false, isAssets: false, name: '' });
          }}
          checked={props.module.isAuth === false && props.module.isAssets === false}
          defaultChecked={true}
        />
        <Form.Check
          label={t('appResume.new_module.authModule')}
          name="moduleType"
          type="radio"
          id="authRadio"
          checked={props.module.isAuth}
          onClick={() =>
            props.setModule({
              ...props.module,
              isAuth: true,
              isAssets: false,
              name: 'Authentication'
            })
          }
        />
        <Form.Check
          label={t('appResume.new_module.assetsModule')}
          name="moduleType"
          type="radio"
          id="assetsRadio"
          onClick={() => {
            props.setModule({ ...props.module, isAuth: false, isAssets: true, name: 'Assets' });
          }}
          checked={props.module.isAssets}
        />
      </FormGroup>
      {props.module.isAssets && (
        <div style={{ marginTop: '1rem' }}>
          <Form.Label>{t('chooseStorageType')}</Form.Label>
          <Form.Select
            value={valueToStorageType()}
            className={styles.marginBetweenContainers}
            onChange={props.choosingTheStorage}
          >
            {Object.values(AVAILABLE_MODULES).map((moduleType, index) => (
              <option key={index} value={moduleType}>
                {moduleType}
              </option>
            ))}
          </Form.Select>
          {props.dataToAssetsModule.value === 's3' && (
            <div>
              <Form.Group className={styles.marginBetweenContainers}>
                <Form.Label>Access Key</Form.Label>
                <Form.Control
                  required
                  value={props.keyAccess}
                  onChange={(ev) => props.setAccessKey(ev.target.value)}
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  disabled={props.blockSave}
                />
              </Form.Group>
              <Form.Group className={styles.marginBetweenContainers}>
                <Form.Label>Secret Key</Form.Label>
                <Form.Control
                  required
                  value={props.secretKey}
                  onChange={(ev) => props.setSecretKey(ev.target.value)}
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  disabled={props.blockSave}
                />
              </Form.Group>
              <Form.Group className={styles.marginBetweenContainers}>
                <Form.Label>Region</Form.Label>
                <Form.Control
                  required
                  value={props.region}
                  onChange={(ev) => props.setRegion(ev.target.value)}
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  disabled={props.blockSave}
                />
              </Form.Group>
              <Form.Group style={{ marginBottom: '1rem' }}>
                <Form.Label>Bucket Name</Form.Label>
                <Form.Control
                  required
                  value={props.bucketName}
                  onChange={(ev) => props.setBucketName(ev.target.value)}
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  disabled={props.blockSave}
                />
              </Form.Group>
              <div className={styles.containerButtonS3Form}>
                <Button variant="danger" onClick={props.clearS3Fields}>
                  Clear
                </Button>
                <Button onClick={props.submitTheS3} disabled={props.blockSave}>
                  Save
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default TemplateModule;
