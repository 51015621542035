import React, { useCallback } from 'react';
import { Folder as FolderType } from 'modules/designer/types';
import { Folder } from './draggable_resource/folder';
import { DraggableResource } from './draggable_resource/draggable_resource';
import { FolderItem as FolderItemComponent } from './draggable_resource/folder_item';
import { updateFoldersOnRename } from './callbacks';

export type FolderItem = {
  folder_id?: string;
  uuid: string;
  name: string;
  type: string; // Used for icons.
  [name: string]: unknown;
};

export type ContextMenuItem = {
  label: string;
  onClick: any;
  icon?: string;
};

const ICON_NAMES: Record<string, string> = {
  PAGE: 'window-maximize',
  LAYOUT: 'table-columns',
  MODAL: 'window-restore',
  ENUM: 'columns',
  TABLE: 'table',
  OBJECT: 'database',
  TAG: 'cloud',
  CLASS: 'code'
};

export interface RenderFoldersProps {
  folders: FolderType[];
  collapsedFolders: Record<string, boolean>;
  toggleCollapsedFolder: (folderId: string, collapsed: boolean) => void;
  items: FolderItem[];
  selectedItem: string;
  callbacks?: Record<string, Record<string, any>>;
  itemFilter: string;
  contextMenuItems?: Record<string, ContextMenuItem[]>;
  setFolders: (folders: FolderType[]) => void;
  checkFolderNameExists: (parentId: string, nextName: string, folderId: string) => boolean;
}

export function RenderFolders({
  folders,
  collapsedFolders,
  toggleCollapsedFolder,
  items,
  selectedItem,
  callbacks,
  itemFilter,
  contextMenuItems,
  setFolders,
  checkFolderNameExists
}: RenderFoldersProps) {
  const getItemsFromFolder = useCallback(
    (folderId: string): FolderItem[] => {
      return items.filter((item) => {
        if (folderId === '' && !item.folder_id) {
          return true;
        }
        if (item.folder_id === folderId) {
          return true;
        }
        return false;
      });
    },
    [items]
  );

  const hasItems = (folderId: string) => {
    if (items.find((i) => i.folder_id === folderId)) {
      return false;
    }
    return true;
  };

  if (!callbacks || !contextMenuItems) {
    return null;
  }

  const sortAlphabetically = (m: string, n: string): number => {
    const ml = m.toLowerCase();
    const nl = n.toLowerCase();
    return ml < nl ? -1 : ml > nl ? 1 : 0;
  };

  const renderFolder = (folder: FolderType) => {
    if (!callbacks[folder.uuid]) return null;
    const isCollapsed = !!collapsedFolders[folder.uuid];
    return (
      <React.Fragment key={folder.uuid}>
        <DraggableResource
          handleDrop={callbacks[folder.uuid].drop}
          handleDragStart={callbacks[folder.uuid].dragstart}
          handleDragOver={callbacks[folder.uuid].dragover}
          handleLeave={callbacks[folder.uuid].dragleave}
        >
          <Folder
            folderId={folder.uuid}
            folderName={folder.name}
            isSelected={false}
            isCollapsed={isCollapsed}
            toggleCollapsedFolder={(collapsed) => toggleCollapsedFolder(folder.uuid, collapsed)}
            handleSaveName={(newName: string) => callbacks[folder.uuid].save(newName)}
            contextMenus={contextMenuItems[folder.uuid]}
            canBeDeleted={hasItems(folder.uuid)}
            deleteFolder={callbacks[folder.uuid].delete}
            updateFoldersOnRename={(newName: string) =>
              updateFoldersOnRename(folders, folder.uuid, newName, setFolders)
            }
            checkFolderNameExists={(name: string, folderId: string) =>
              checkFolderNameExists(folder.parent ?? '', name, folderId)
            }
          />
        </DraggableResource>
        {isCollapsed && (
          <>
            {getItemsFromFolder(folder.uuid)
              .filter((item) => !itemFilter.trim() || item.name.toLowerCase().includes(itemFilter))
              .sort((m, n) => sortAlphabetically(m.name, n.name))
              .map((item) => {
                if (!callbacks[item.uuid]) return null;
                return (
                  <React.Fragment key={item.uuid}>
                    <div className=" border-start" style={{ marginLeft: '1.35rem' }}>
                      <DraggableResource
                        handleDrop={callbacks[item.uuid].drop}
                        handleDragStart={callbacks[item.uuid].dragstart}
                        handleDragOver={callbacks[item.uuid].dragover}
                        handleLeave={callbacks[item.uuid].dragleave}
                      >
                        <FolderItemComponent
                          itemId={item.uuid}
                          itemName={item.name}
                          isSelected={selectedItem === item.uuid}
                          handleSelectItem={(itemId) => callbacks[item.uuid].select(itemId)}
                          handleSaveName={(newName) => callbacks[item.uuid].save(newName)}
                          deleteItem={callbacks[item.uuid].delete}
                          contextMenus={contextMenuItems[item.uuid] ?? []}
                          validate={callbacks[item.uuid].validate}
                          iconName={ICON_NAMES[item.type as string]}
                          type={item.type}
                        />
                      </DraggableResource>
                    </div>
                  </React.Fragment>
                );
              })}
            {folder.folders
              ?.sort((m, n) => sortAlphabetically(m.name, n.name))
              .map((folder) => {
                return (
                  <React.Fragment key={folder.uuid}>
                    <div className="border-start" style={{ marginLeft: '1.35rem' }}>
                      {renderFolder(folder)}
                    </div>
                  </React.Fragment>
                );
              })}
          </>
        )}
      </React.Fragment>
    );
  };

  return (
    <div className="pb-5">
      {getItemsFromFolder('')
        .filter((item) => !itemFilter.trim() || item.name.toLowerCase().includes(itemFilter))
        .sort((m, n) => sortAlphabetically(m.name, n.name))
        .map((item) => {
          if (!callbacks[item.uuid]) return null;
          return (
            <React.Fragment key={item.uuid}>
              <div className="border-start">
                <DraggableResource
                  handleDrop={callbacks[item.uuid].drop}
                  handleDragStart={callbacks[item.uuid].dragstart}
                  handleDragOver={callbacks[item.uuid].dragover}
                  handleLeave={callbacks[item.uuid].dragleave}
                >
                  <FolderItemComponent
                    itemId={item.uuid}
                    itemName={item.name}
                    isSelected={selectedItem === item.uuid}
                    handleSelectItem={(itemId) => callbacks[item.uuid].select(itemId)}
                    handleSaveName={(newName) => callbacks[item.uuid].save(newName)}
                    deleteItem={callbacks[item.uuid].delete}
                    contextMenus={contextMenuItems[item.uuid] ?? []}
                    validate={callbacks[item.uuid].validate}
                    iconName={ICON_NAMES[item.type as string]}
                    type={item.type}
                  />
                </DraggableResource>
              </div>
            </React.Fragment>
          );
        })}
      {folders
        .sort((m, n) => sortAlphabetically(m.name, n.name))
        .map((folder) => {
          return renderFolder(folder);
        })}
    </div>
  );
}
