import React, { useEffect, useState } from 'react';
import { CrudData } from './index';
import styles from './styles.module.css';
import { Button, Form, Spinner } from 'react-bootstrap';
import Icon from 'web_ui/icon';
import { Trans, useTranslation } from 'react-i18next';
import { CrudAutomationService, EndpointsService } from 'modules/logic_builder/services';
import { useParams } from 'react-router-dom';
import { CrudEndpointInstance } from './Instances/crudEndpointInstance';
import { AddEndpointsDialog } from './add_endpoints_dialog';
import { ControllerCreatorDialog } from 'modules/logic_builder/components/dialogs';
import { EndpointInstance } from 'modules/logic_builder/components/endpoints_list/endpoint_instance';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import { toCamelCase } from '../../../../../utils/utils';
import { Endpoint } from '../../../../../modules/logic_builder/types';
import SessionContext from 'modules/auth/store';
import Confirmation from 'web_ui/confirmation';

type Step4Props = {
  crudData: CrudData;
  onChange: (crudData: CrudData) => void;
  setFormValidity: (validity: boolean) => void;
  loading?: (val: boolean) => void;
};

export function Step4Endpoints(props: Step4Props) {
  const { t } = useTranslation();
  const { module_id } = useParams();
  const [showEndpointCreatorDialog, setShowEndpointCreatorDialog] = useState<boolean>(false);
  const [showControllerCreatorDialog, setShowControllerCreatorDialog] = useState<boolean>(false);
  const session = React.useContext(SessionContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (props.crudData.endpoints) {
      setIsLoading(false);
    }
  }, [props.crudData.endpoints]);

  async function getEndpointsList(controllerId: string) {
    if (!module_id || !props.crudData || !props.crudData.entityUuid) return;
    props.loading && props.loading(true);

    let endpointsList: Endpoint[];
    try {
      endpointsList = await EndpointsService.getEndpointsByController(controllerId, module_id);
      endpointsList.forEach((item) => {
        item.isNew = false;
      });
    } catch {
      endpointsList = [];
    }

    const storedEndpoints = props.crudData.endpoints.filter((f) => !!f.isNew);

    const endpointsOldNew = [...endpointsList, ...storedEndpoints];

    const crud = {
      ...props.crudData,
      endpoints: endpointsOldNew
    } as CrudData;

    props.onChange(crud);
    props.loading && props.loading(false);
  }

  async function getControllersList() {
    if (!module_id || !props.crudData.entityUuid) return;
    props.loading && props.loading(true);

    const controllersList = await CrudAutomationService.getEntityControllersResume(
      module_id,
      props.crudData.entityUuid
    );
    if (controllersList) {
      const crud: CrudData = {
        ...props.crudData,
        controllers: controllersList
      } as CrudData;

      if (controllersList[0] && controllersList[0].uuid) {
        crud.selectedController = controllersList[0];
      }

      props.onChange(crud);
      props.loading && props.loading(false);
    }
  }

  useEffect(() => {
    if (!props.crudData.selectedController) {
      getControllersList();
      props.setFormValidity(false);
    } else {
      getEndpointsList(props.crudData.selectedController?.uuid ?? '');
    }
  }, [props.crudData.selectedController]);

  const getDefaultControllerName = (): string => {
    if (!props.crudData.entityUuid) return '';
    return toCamelCase(
      `${props.crudData.entities[props.crudData.entityUuid].entityName}Controller`,
      true
    );
  };

  return (
    <div className={styles.StepWrapper}>
      <div id="headerMessage" className={styles.Title}>
        {t('automation.Step4Title')}
        <HelpPopover
          helpBoxProps={{
            title: t('automation.Step4Tooltip') ?? ''
          }}
          placement="right"
        >
          <span>
            <Icon padding={'10px'} brands={'regular'} iconName={'circle-question'} />
          </span>
        </HelpPopover>
      </div>

      {/* Controller */}
      <div className={styles.DataObjectsHeader}>
        <div className={styles.SelectionWrapper}>
          <div style={{ width: 'fit-content', marginRight: '3rem' }}>
            {t('automation.step4.controller')}:
          </div>
          <div style={{ marginRight: '0.5rem', width: '20rem' }}>
            <Form.Select
              id="selectController"
              size="sm"
              value={props.crudData.selectedController?.uuid}
              onChange={(e) => {
                const newCrud: CrudData = {
                  ...props.crudData,
                  selectedController: props.crudData.controllers.find(
                    (controller) => controller.uuid === e.target.value
                  )
                };
                props.onChange(newCrud);
              }}
              style={{
                height: '2.3rem',
                fontSize: '16px',
                borderRadius: '8px',
                width: '20rem'
              }}
              className={`bg-white ${
                props.crudData.services.length === 0 ? 'text-muted' : 'text-black'
              }`}
              disabled={props.crudData.controllers.length === 0}
            >
              <option selected disabled hidden>
                {getDefaultControllerName()}
              </option>
              {props.crudData.controllers.length > 0 &&
                Object.values(props.crudData.controllers).map((controller) => {
                  return (
                    <option key={controller.name} value={controller.uuid}>
                      {controller.name}
                    </option>
                  );
                })}
              ;
            </Form.Select>
          </div>
          <HelpPopover
            placement={'top'}
            helpBoxProps={{
              title: t('createController')!
            }}
          >
            <Button
              id="createControllerButton"
              variant="primary"
              onClick={() => setShowControllerCreatorDialog(true)}
              style={{
                marginRight: '1rem',
                paddingTop: 6,
                paddingBottom: 6,
                paddingRight: 14,
                paddingLeft: 14
              }}
            >
              <Icon iconName="plus" extraProps="fa-lg" />
            </Button>
          </HelpPopover>
          <div id="existingEndpoints">
            {' '}
            {`(${
              props.crudData.endpoints
                .filter((e) => e.isNew === false)
                .filter((item) => item.controllerUuid === props.crudData.selectedController?.uuid)
                .length
            } ${t('automation.Step4Existing')})`}
          </div>
        </div>
        <div>
          <HelpPopover
            placement={'top'}
            helpBoxProps={{
              title: t('automation.step4.AddEndpoint') ?? ''
            }}
          >
            <Button
              id="createEndpointButton"
              variant="primary"
              onClick={() => setShowEndpointCreatorDialog(true)}
              style={{ width: '200px' }}
            >
              {t('automation.step4.AddEndpoint')}
            </Button>
          </HelpPopover>
        </div>
      </div>

      {isLoading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" variant="secondary" />
        </div>
      ) : Object.values(props.crudData.endpoints).length > 0 ? (
        <>
          <div id="list-group" className={styles.EndpointsListWrapper}>
            {props.crudData.endpoints
              .filter((item) => item.isNew === true)
              .map((endpoint) => {
                return (
                  <CrudEndpointInstance
                    key={endpoint.uuid}
                    // roles={endpoints.roles}
                    cursor="pointer"
                    endpoint={endpoint}
                    showEndpointEditorOnClick={true}
                    controllerPath={props.crudData.selectedController?.path}
                    crudData={props.crudData}
                    selectedControllerId={props.crudData.selectedController?.uuid}
                    onChange={props.onChange}
                  />
                );
              })}
            {props.crudData.endpoints
              .filter((e) => e.isNew === false)
              .filter((item) => item.controllerUuid === props.crudData.selectedController?.uuid)
              .map((endpoint) => {
                return (
                  <EndpointInstance
                    key={endpoint.uuid}
                    // roles={endpoints.roles}
                    cursor="pointer"
                    endpoint={endpoint}
                    showEndpointEditorOnClick={true}
                    crudData={props.crudData}
                    controllerPath={props.crudData.selectedController?.path}
                    showEditBtn={true}
                    getEndList={getEndpointsList}
                  />
                );
              })}
          </div>
        </>
      ) : (
        <div id="bodyMessage" className={styles.emptyMessage}>
          <Trans
            i18nKey="automation.Step4NoEndpoints"
            components={{
              1: <a href="#" onClick={() => setShowEndpointCreatorDialog(true)} />
            }}
          />
        </div>
      )}

      {/* New Endpoints Modal */}
      {props.crudData.entityUuid && module_id && showEndpointCreatorDialog && (
        <AddEndpointsDialog
          crudData={props.crudData}
          serviceUuid={props.crudData.entityUuid}
          show={showEndpointCreatorDialog}
          onClose={() => setShowEndpointCreatorDialog(false)}
          moduleId={module_id}
          onChange={props.onChange}
          loading={props.loading}
        />
      )}
      {/* New Controller Modal */}
      <ControllerCreatorDialog
        crudData={props.crudData}
        show={showControllerCreatorDialog}
        onClose={() => setShowControllerCreatorDialog(false)}
        preSelectedEntity={Object.values(props.crudData.entities).find(
          (e) => e.entityUuid === props.crudData.entityUuid
        )}
        onChange={props.onChange}
        loading={props.loading}
      />
    </div>
  );
}
