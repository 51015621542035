import React from 'react';
import { ComponentUUID } from 'modules/designer/types';
import { removeDeclarationsWithUndefinedValue } from 'utils/cssUtils';
import { useSelector } from 'react-redux';
import { InterfaceStudioState } from '../../store';
import { convertingCSS } from 'utils/utils';
import styles from './styles.module.css';
import { faIconName } from './fileInput';
import InputFileDropzone from './dropzoneFileInput';
import useCalculateComponentSize from 'modules/designer/hooks/useCalculateComponentSize';

export type InputFileData = {
  label?: string;
  placeholder?: string;
  tooltip?: string;
  defaultValue?: string;
  required?: boolean;
  readonly?: boolean;
  readonlyVar?: string;
  disabled?: boolean;
  disabledVar?: string;
  width?: number | string;
  widthUnit?: number | string;
  maxWidth?: number | string;
  maxWidthUnit?: string;
  minWidth?: number | string;
  minWidthUnit?: string;
  height?: number | string;
  heightUnit?: number | string;
  maxHeight?: number | string;
  maxHeightUnit?: string;
  minHeight?: number | string;
  minHeightUnit?: string;
  translateKey?: string;
  hasTranslation?: string;
  visible?: string;
  buttonText?: string;
  icon?: string;
  optionSizeWidth?: string;
  optionSizeHeight?: string;
};

export type InputFileStyles = {
  fontSize?: number;
  textAlign?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent';
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  borderTop?: number;
  borderLeft?: number;
  borderRight?: number;
  borderBottom?: number;
  paddingTop?: number;
  paddingLeft?: number;
  paddingRight?: number;
  paddingBottom?: number;
  fontWeight?: string;
  lineHeight?: number | string;
  textDecoration?: string;
  textTransform?: 'none' | 'uppercase' | 'capitalize' | 'capitalize' | 'lowercase';
  fontFamily?: string;
  fontStyle?: string;
  css?: string;
  bgColor?: string;
  color: string;
  classes?: string[];
};

export type InputFileComponentProps = {
  uuid: ComponentUUID;
  data: InputFileData;
  styles: InputFileStyles;
};

function InputFileComponent(props: InputFileComponentProps, ref: React.Ref<any>) {
  const theme = useSelector((state: InterfaceStudioState) => state.theme);
  let style = {
    ...(props.data.width &&
      props.data.widthUnit && {
        width: props.data.width + '' + props.data.widthUnit
      }),
    ...(props.data.height &&
      props.data.heightUnit && {
        height: props.data.height + '' + props.data.heightUnit
      }),
    ...(props.data.width &&
      !props.data.widthUnit && {
        width: props.data.width
      }),
    ...(props.data.height &&
      !props.data.heightUnit && {
        height: props.data.height
      }),
    // ...(props.data.maxHeight &&
    //   props.data.maxHeightUnit && {
    //     maxHeight: props.data.maxHeight + '' + props.data.maxHeightUnit
    //   }),
    // ...(props.data.maxHeight &&
    //   /!props.data.maxHeightUnit && {
    //     maxHeight: props.data.maxHeight
    //   }),
    // ...(props.data.minHeight &&
    //   props.data.minHeightUnit && {
    //     minHeight: props.data.minHeight + '' + props.data.minHeightUnit
    //   }),
    // ...(props.data.minHeight &&
    //   /!props.data.minHeightUnit && {
    //     minHeight: props.data.minHeight
    //   }),
    // ...(props.data.maxWidth &&
    //   props.data.maxWidthUnit && {
    //     maxWidth: props.data.maxWidth + '' + props.data.maxWidthUnit
    //   }),
    // ...(props.data.maxWidth &&
    //   /!props.data.maxWidthUnit && {
    //     maxWidth: props.data.maxWidth
    //   }),
    // ...(props.data.minWidth &&
    //   props.data.minWidthUnit && {
    //     minWidth: props.data.minWidth + '' + props.data.minWidthUnit
    //   }),
    // ...(!props.data.minWidthUnit && {
    //   minWidth: props.data.minWidth
    // }),
    // textAlign: props.styles.textAlign as TextAlignProperty,
    // color: props.styles.staticColor
    //   ? props.styles.staticColor
    //   : props.styles.themeColor &&
    //     convertingCSS(
    //       props.styles.themeColor,
    //       props.styles.themeColor.includes('#') ? '' : theme.colors[props.styles.themeColor!]
    //     ),
    // fontSize: `${props.styles.fontSize}px`,
    paddingTop: `${props.styles.paddingTop}px`,
    paddingRight: `${props.styles.paddingRight}px`,
    paddingBottom: `${props.styles.paddingBottom}px`,
    paddingLeft: `${props.styles.paddingLeft}px`,
    borderTop: `${props.styles.borderTop}px solid #333`,
    borderRight: `${props.styles.borderRight}px solid #333`,
    borderBottom: `${props.styles.borderBottom}px solid #333`,
    borderLeft: `${props.styles.borderLeft}px solid #333`,
    marginTop: `${props.styles.marginTop}px`,
    marginRight: `${props.styles.marginRight}px`,
    marginBottom: `${props.styles.marginBottom}px`,
    marginLeft: `${props.styles.marginLeft}px`
    // fontWeight: `${props.styles.fontWeight}`,
    // lineHeight: `${props.styles.lineHeight}`,
    // ...(props.styles.fontFamily && {
    //   fontFamily: `${props.styles.fontFamily}`
    // })
  };

  removeDeclarationsWithUndefinedValue(style);
  style = useCalculateComponentSize(props.uuid, style) ?? style;

  const classes = props.styles.classes?.join(' ') || '';

  return (
    <div ref={ref} style={{ width: 'fit-content' }} className={classes}>
      <InputFileDropzone
        width={styles.width}
        height={styles.height}
        color={convertingCSS(
          props.styles.color,
          props.styles.color.includes('#') ? '' : theme.colors[props.styles.color!]
        )}
        buttonText={props.data.buttonText}
        label={props.data.label}
        icon={props.data.icon as faIconName}
        background={
          props.styles.bgColor &&
          convertingCSS(
            props.styles.bgColor,
            props.styles.bgColor.includes('#') ? '' : theme.colors[props.styles.bgColor!]
          )
        }
      />
    </div>
  );
}

export * from './manifest';
export * from './template';
export default React.memo(React.forwardRef(InputFileComponent));
