import React, {
  memo,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import styles from './styles.module.css';
import { Frame, FrameUUID } from '.';
import { ContextMenuOption } from '../ContextMenuItem';
import FrameRenderer from './FrameRenderer';
import { useDispatch } from 'react-redux';
import {
  setSelectedComponent,
  setSelectedView
} from '../../../modules/designer/studio/store/actions/studio';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'hooks/useQuery';
import { WALKTHROUGH_STEPS_ELEMENTS } from 'web_ui/walkthrough/constants';
import { startWalkthrough } from 'web_ui/walkthrough/walkthrough';
import SessionContext from 'modules/auth/store';
import { useClickOutsideEvent } from 'hooks/useClickOutside';
import CreatePageDialog from 'modules/designer/studio/toolbars/views_toolbar/create_page_modal';
import { ILayoutDefault, Layout } from 'modules/designer/types';
import { LocalStorageManager } from 'utils/localstorage';
import { FoldersContext } from 'modules/designer/studio/FolderContext';

interface FrameWrapperProps {
  frames: { [key: FrameUUID]: Frame };
  isLeftSidebarOpen: boolean;
  isRightSidebarOpen: boolean;
  layouts: Layout[];
  layoutDef: ILayoutDefault;
  fetchViews: () => Promise<void>;
  children?: ReactNode;
  contextMenuItems?: ContextMenuOption[];
  showSettings?: boolean;
}

function FrameWrapper({
  frames,
  children,
  isRightSidebarOpen,
  isLeftSidebarOpen,
  layouts,
  layoutDef,
  fetchViews
}: FrameWrapperProps) {
  const queryParameters = useQuery();
  const { view_id, custom_component_id, module_id, app_id } = useParams();
  const mainRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const { pageVisits, updatePageVisits } = useContext(SessionContext);
  const driverInstance = useRef<any>();
  const currentContext = 'designer';
  const [showPageDialog, setShowPageDialog] = useState(false);
  const navigate = useNavigate();
  const { fetchFolders } = useContext(FoldersContext)!;

  // const getPageFolderId = async () => {
  //   if (!module_id) return null;
  //   if (folders && folders.length > 0) {
  //     const pageFolderIndex = folders.findIndex((folder) => folder.name === 'Pages');

  //     if (pageFolderIndex !== -1 && folders[pageFolderIndex]) {
  //       return folders[pageFolderIndex].uuid;
  //     }

  //     if (module_id) {
  //       const createdPagesFolder = await FolderService.createFolder(
  //         module_id,
  //         'Pages',
  //         '',
  //         FolderType.UI
  //       );
  //       return createdPagesFolder.uuid;
  //     }

  //     return folders[0].uuid;
  //   }
  //   return null;
  // };

  function handleBackgroudClick() {
    dispatch(setSelectedComponent(null));
    if (view_id) dispatch(setSelectedView(view_id));
    if (custom_component_id) dispatch(setSelectedView(custom_component_id));
  }

  const isFromVsCodeExt = (): boolean => {
    const itemFound = queryParameters.get('vscode');
    if (itemFound) {
      return Boolean(itemFound);
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (!pageVisits) {
      return;
    }
    if (!mainRef.current) return;

    const alreadyShown = pageVisits[currentContext] != null;
    if (alreadyShown) return;

    driverInstance.current = startWalkthrough({
      context: currentContext,
      prevBtnText: 'Previous',
      nextBtnText: 'Next',
      doneBtnText: 'Done',
      onClose: () => {
        updatePageVisits(currentContext);
      }
    });
  }, [pageVisits, updatePageVisits]);

  useClickOutsideEvent({
    id: 'driver-popover-content',
    action: () => {
      if (driverInstance.current) {
        if (pageVisits && !pageVisits[currentContext]) {
          updatePageVisits(currentContext);
        }
        driverInstance.current.destroy();
      }
    }
  });

  const updateLocalStorageSelectView = useCallback(
    (uuid: string) => {
      if (!app_id || !module_id) {
        return;
      }
      const copying = LocalStorageManager.getValueLocalStorageState(app_id, module_id);
      copying[module_id] = {
        ...copying[module_id],
        interface: {
          ...copying[module_id].interface,
          lastSelectedView: uuid
        }
      };
      LocalStorageManager.setValueLocalStorageState(app_id, copying);
    },
    [app_id, module_id]
  );

  function changeView(uuid: string): void {
    navigate(
      `/app/${app_id}/module/${module_id}/ui/${uuid}${isFromVsCodeExt() ? '?vscode=true' : ''}`
    );
    updateLocalStorageSelectView(uuid);
  }

  return (
    <main
      ref={mainRef}
      style={{
        marginLeft: isLeftSidebarOpen ? '240px' : '0',
        width: isRightSidebarOpen
          ? isLeftSidebarOpen
            ? `calc(100vw - ${isFromVsCodeExt() ? '480px' : '545px'})`
            : `calc(100vw - ${isFromVsCodeExt() ? '240px' : '305px'})`
          : isLeftSidebarOpen
          ? `calc(100vw - ${isFromVsCodeExt() ? '240px' : '305px'})`
          : `calc(100vw - ${isFromVsCodeExt() ? '0px' : '65px'})`
      }}
      className={styles.frameWrapperContainer}
      onClick={handleBackgroudClick}
    >
      <div
        id={WALKTHROUGH_STEPS_ELEMENTS['designer-main-workboard']}
        style={{
          overflow: frames && Object.keys(frames).length > 0 ? 'auto' : '',
          maxWidth: '100%',
          height: '100%',
          width: '100%',
          position: 'relative',
          zIndex: 1,
          backgroundColor: '#2b2f32'
        }}
      >
        <FrameRenderer
          frames={frames}
          currentContext={currentContext}
          showCreatePageDialog={() => {
            setShowPageDialog(true);
          }}
        />
        <CreatePageDialog
          showDialog={showPageDialog}
          dialogOnClose={() => setShowPageDialog(false)}
          onCreate={(uuid: string) => {
            fetchViews();
            fetchFolders();
            changeView(uuid);
          }}
          layoutsList={layouts}
          parentUuid={''}
          layoutDefault={layoutDef}
          isFirstPage
        />
        {children}
      </div>

      {/* <Background /> */}
    </main>
  );
}

export default FrameWrapper;
