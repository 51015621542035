import React, { memo, forwardRef, useMemo } from 'react';
import { ComponentUUID } from '../../../types';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { parseCustomCss, removeDeclarationsWithUndefinedValue } from 'utils/cssUtils';
import useCalculateComponentSize from 'modules/designer/hooks/useCalculateComponentSize';

export type ProgressBarData = {
  width?: number | string;
  widthUnit?: number | string;
  maxWidth?: number | string;
  maxWidthUnit?: string;
  minWidth?: number | string;
  minWidthUnit?: string;
  widthCalc?: number;
  height?: number;
  heightUnit?: number | string;
  maxHeight?: number | string;
  maxHeightUnit?: string;
  minHeight?: number | string;
  minHeightUnit?: string;
  value: number;
  isAnimated?: boolean;
  hasLabel?: boolean;
  tooltip?: string;
  optionSizeWidth?: string;
  optionSizeHeight?: string;
};

export type ProgressBarStyles = {
  background?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'info'
    | 'warning'
    | 'danger'
    | 'light'
    | 'dark';
  fontSize?: number;
  borderRadius?: number;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  borderTop?: number;
  borderLeft?: number;
  borderRight?: number;
  borderBottom?: number;
  paddingTop?: number;
  paddingLeft?: number;
  paddingRight?: number;
  paddingBottom?: number;
  css?: string;
  classes?: string[];
};

export type ProgressBarComponentProps = {
  uuid: ComponentUUID;
  data: ProgressBarData;
  styles: ProgressBarStyles;
};

function ProgressBarComponent(props: ProgressBarComponentProps, ref: React.Ref<any>) {
  let style = {
    ...(props.data.width &&
      props.data.widthUnit && {
        width: props.data.width + '' + props.data.widthUnit
      }),
    ...(props.data.height &&
      props.data.heightUnit && {
        height: props.data.height + '' + props.data.heightUnit
      }),
    ...(props.data.width &&
      !props.data.widthUnit && {
        width: props.data.width
      }),
    ...(props.data.height &&
      !props.data.heightUnit && {
        height: props.data.height
      }),
    ...(props.data.maxHeight &&
      props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight + '' + props.data.maxHeightUnit
      }),
    ...(props.data.maxHeight &&
      !props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight
      }),
    ...(props.data.minHeight &&
      props.data.minHeightUnit && {
        minHeight: props.data.minHeight + '' + props.data.minHeightUnit
      }),
    ...(props.data.minHeight &&
      !props.data.minHeightUnit && {
        minHeight: props.data.minHeight
      }),
    ...(props.data.maxWidth &&
      props.data.maxWidthUnit && {
        maxWidth: props.data.maxWidth + '' + props.data.maxWidthUnit
      }),
    ...(props.data.maxWidth &&
      !props.data.maxWidthUnit && {
        maxWidth: props.data.maxWidth
      }),
    ...(props.data.minWidth &&
      props.data.minWidthUnit && {
        minWidth: props.data.minWidth + '' + props.data.minWidthUnit
      }),
    ...(!props.data.minWidthUnit && {
      minWidth: props.data.minWidth
    }),
    fontSize: `${props.styles.fontSize}px`,
    paddingTop: `${props.styles.paddingTop}px`,
    paddingRight: `${props.styles.paddingRight}px`,
    paddingBottom: `${props.styles.paddingBottom}px`,
    paddingLeft: `${props.styles.paddingLeft}px`,
    borderTop: `${props.styles.borderTop}px solid #333`,
    borderRight: `${props.styles.borderRight}px solid #333`,
    borderBottom: `${props.styles.borderBottom}px solid #333`,
    borderLeft: `${props.styles.borderLeft}px solid #333`,
    marginTop: `${props.styles.marginTop}px`,
    marginRight: `${props.styles.marginRight}px`,
    marginBottom: `${props.styles.marginBottom}px`,
    marginLeft: `${props.styles.marginLeft}px`
  };

  if (props.data.widthCalc && props.data.widthUnit !== 'px') {
    style.width = `calc(${style.width} - ${props.data.widthCalc}px)`;
  }

  removeDeclarationsWithUndefinedValue(style);
  style = useCalculateComponentSize(props.uuid, style) ?? style;

  const classes = props.styles.classes?.join(' ') || '';

  const label = useMemo(() => {
    if (!props.data.hasLabel) {
      return '';
    }
    if (props.data.value >= 100) {
      return '100%';
    } else {
      return `${props.data.value}%`;
    }
  }, [props.data.hasLabel, props.data.value]);

  return (
    <ProgressBar
      ref={ref}
      style={{ ...style, ...parseCustomCss(props.styles.css) }}
      now={props.data.value}
      label={label}
      animated={props.data.isAnimated ? true : false}
      variant={props.styles.background}
      max={100}
      min={0}
      className={classes}
    />
  );
}

export * from './manifest';
export * from './template';
export default memo(forwardRef(ProgressBarComponent));
