import React, { ReactNode, useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { InterfaceStudioState, RESET_DESIGNER_STUDIO } from '../../store';
import { CustomComponentService } from 'modules/designer/services';
import ContextMenu from 'web_ui/workboard/ContextMenu';
import ContextMenuItem from 'web_ui/workboard/ContextMenuItem';
import { useTranslation } from 'react-i18next';
import Confirmation from 'web_ui/confirmation';
import { useQuery } from 'hooks/useQuery';

interface ComponentCustomProps {
  appId?: string;
  moduleId?: string;
  customId?: string;
  children?: ReactNode;
  isNative?: boolean;
  fetchCustomComponents?: () => void;
}

function ComponentCustom({
  appId,
  customId,
  moduleId,
  children,
  isNative,
  fetchCustomComponents
}: ComponentCustomProps) {
  const selectedView = useSelector((state: InterfaceStudioState) => state.studio.selectedView);
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);
  const customContainerRef = useRef<HTMLTableElement>(null);
  const queryParameters = useQuery();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isFromVsCodeExt = (): boolean => {
    const itemFound = queryParameters.get('vscode');
    if (itemFound) {
      return Boolean(itemFound);
    } else {
      return false;
    }
  };

  const goToCustomComponentEditor = useCallback(() => {
    dispatch({ type: RESET_DESIGNER_STUDIO });

    if (fetchCustomComponents) {
      fetchCustomComponents();
    }

    navigate(
      `/app/${appId}/module/${moduleId}/ui/custom_component/${customId}${
        isFromVsCodeExt() ? '?vscode=true' : ''
      }`
    );
  }, [appId, customId, dispatch, fetchCustomComponents, moduleId, navigate]);

  const deleteCustomComponent = async () => {
    if (!customId) return;

    await CustomComponentService.deleteCustomComponent(customId).then(() => {
      if (fetchCustomComponents) {
        fetchCustomComponents();
      }

      if (customId === selectedView) {
        dispatch({ type: RESET_DESIGNER_STUDIO });
      }
    });
  };

  return (
    <div ref={customContainerRef}>
      {children}
      {!isNative && (
        <ContextMenu elementRef={customContainerRef} offsetX={0} offsetY={-90}>
          <ContextMenuItem
            icon="pen-to-square"
            label={t('designer.componentCardDropdown.Edit')}
            onClick={goToCustomComponentEditor}
          />
          <ContextMenuItem
            style={['text-danger border-top mt-1 pt-2']}
            icon="trash"
            label={t('designer.componentCardDropdown.Delete')}
            onClick={(e) => setShowDeleteConfirmationDialog(true)}
          />
        </ContextMenu>
      )}
      <Confirmation
        show={showDeleteConfirmationDialog}
        onCancel={() => {
          setShowDeleteConfirmationDialog(false);
        }}
        onConfirmation={deleteCustomComponent}
        message={`${t('designer.componentCardDropdown.DeleteCustom')}`}
        onClose={() => {
          setShowDeleteConfirmationDialog(false);
        }}
      />
    </div>
  );
}

export default ComponentCustom;
