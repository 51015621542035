/* eslint-disable no-lone-blocks */
import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { Dropdown, Modal } from 'react-bootstrap';
import { ControlProps } from '../..';
import styles from './styles.module.css';
import Button from 'react-bootstrap/Button';
import { Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import {
  ChildrenToManifest,
  fixPostion,
  Link,
  LinksToManifest,
  LinkTarget,
  Page
} from 'modules/designer/types';
import produce from 'immer';
import { v4 as uuidv4 } from 'uuid';
import Tree from './components/Tree';
import { BsPlus } from 'react-icons/bs';
import { PageRepository } from 'modules/designer/repos';
import { TiWarning } from 'react-icons/ti';
import { IconList, IconPicker } from '../../../../../../packages/react-fa-icon-picker';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { InterfaceStudioState } from 'modules/designer/studio/store';
import { COMPONENT_TYPES } from 'modules/designer/studio/exocode_components';

type LinkUUID = string;

export type Props = {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  setting: ControlProps;
  idNavbar: string;
};

function ModalComponent({ show, setShow, setting, idNavbar }: Props) {
  const [items, setItems] = useState<LinksToManifest>(setting.value && setting.value.items);
  const [children, setChildren] = useState<ChildrenToManifest>(
    setting.value && setting.value.children
  );
  const [selectedItem, setSelectedItem] = useState<string>('');
  const [AllUrl, setAllUrl] = useState<Page[]>([]);
  const selectedComponent = useSelector(
    (state: InterfaceStudioState) => state.studio.selectedComponent.uuid
  );
  const components = useSelector((state: InterfaceStudioState) => state.components);
  const { module_id } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (
      setting.value &&
      setting.value.items &&
      setting.value.children &&
      setting.value.children[idNavbar] &&
      setting.value.children[idNavbar][0]
    ) {
      setItems(setting.value.items);
      setChildren(setting.value.children);
      setSelectedItem(setting.value.children[idNavbar][0]);
    }
    (async () => {
      const getAllPag = await PageRepository.getPagesByModule(module_id!);
      if (module_id && getAllPag.length > AllUrl.length) {
        // if it has params inside the page, just dont insert it
        setAllUrl(getAllPag.filter((p) => !(p.params.length > 0)));
      }
    })();
  }, [show]);

  function createItem(idFather?: string) {
    // setDropdownOpt(true); // come back to dropdown
    const generatedId = uuidv4();
    const title = t('designer.right_side.edit_links.New Link');

    const newItem: Link = {
      id: generatedId,
      title: title,
      target: LinkTarget.BLANK,
      url: '',
      page: '', // UUID da page
      icon: '',
      hide: false,
      isDropdown: false,
      openNewTab: false,
      readOnly: false,
      disableIcon: true
    };

    if (selectedComponent && components[selectedComponent].type === COMPONENT_TYPES.SIDEMENU) {
      newItem.fixed = fixPostion.DEFAULT;
    }

    const newItemsState = produce(items, (draft: LinksToManifest) => {
      if (generatedId) {
        draft[generatedId] = newItem;
      }
    });

    const newChildrenState = produce(children, (draft: ChildrenToManifest) => {
      const parent = idFather ? idFather : idNavbar;

      if (parent && !draft[parent]) {
        draft[parent] = [generatedId];
      } else {
        draft[parent].push(generatedId);
      }
    });
    setSelectedItem(generatedId);
    setItems(newItemsState);
    setChildren(newChildrenState);
  }

  function removeItem(selectedItem: string) {
    if (!children || !idNavbar) return;

    const newStateItems = produce(items, (draft: LinksToManifest) => {
      delete draft[selectedItem];
    });

    const newStateChildren = produce(children, (draft: ChildrenToManifest) => {
      // Find and delete selectedItem.
      let parentsToCheck = [idNavbar];
      while (parentsToCheck.length) {
        const navItem = parentsToCheck.pop();
        if (!navItem) break;

        if (!children[navItem]) continue;
        const childrenList = [...children[navItem]];

        const index = childrenList.indexOf(selectedItem);
        if (index !== -1) {
          childrenList.splice(index, 1);
          draft[navItem] = childrenList;
          break;
        }
        parentsToCheck = parentsToCheck.concat(childrenList || []);
      }

      // If selectedItem is also a parent then delete its children, and its children...
      let parentsToDelete = [selectedItem];
      while (parentsToDelete.length) {
        const parentNavItem = parentsToCheck.pop();
        if (!parentNavItem) return;

        if (children[parentNavItem]) {
          const childrenList = children[parentNavItem];
          parentsToDelete = parentsToDelete.concat(childrenList);
          delete draft[parentNavItem];
        }
      }
    });

    setItems(newStateItems);
    setChildren(newStateChildren);
  }

  function saveItems() {
    if (setting.onChange) setting.onChange({ items: items, children: children }, 'links');
    setShow(false);
  }

  function updateItem<K extends keyof Link>(key: K, value: Link[K]) {
    const newState = produce(items, (draft: LinksToManifest) => {
      if (key === 'disableIcon' && value === true) {
        draft[selectedItem].icon = '';
      }
      draft[selectedItem][key] = value;
      draft[selectedItem].target = LinkTarget.BLANK;
    });

    setItems(newState);
  }

  function handleIconPicker(e: IconList) {
    updateItem('icon', e);
  }

  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => {
        setShow(false);
      }}
      animation={false}
      centered={true}
    >
      <Modal.Body className={`bg-body ${styles.container}`}>
        <div className={`bg-body-secondary text-body ${styles.nestedItemsContainer}`}>
          <div className={styles.navHeader}>
            <p>
              <span style={{ fontWeight: 'bold' }}>{t('designer.components.Navbar')}</span>{' '}
              {t('designer.right_side.edit_links.Editor')}
            </p>
          </div>
          <div className={styles.TreeComponent}>
            {items &&
              children !== undefined &&
              children[idNavbar] &&
              children[idNavbar].length > 0 &&
              children[idNavbar].map((itemid: LinkUUID, index: number) => (
                <Tree
                  items={items}
                  // eslint-disable-next-line react/no-children-prop
                  children={children}
                  setChildrenState={setChildren}
                  onCreate={createItem}
                  onSelected={setSelectedItem}
                  onRemove={removeItem}
                  idNav={itemid}
                  key={index}
                  marginLeft={0}
                />
              ))}
          </div>
          <div className={styles.containerPlus}>
            <BsPlus
              size={28}
              cursor={'pointer'}
              className={`text-body ${styles.plusIcon}`}
              onClick={() => createItem()}
            />
          </div>
        </div>
        {children && !children[idNavbar] ? (
          <div className={styles.containerPresentation}>
            <TiWarning size={50} opacity={0.3} />
            <p
              style={{
                fontSize: 25,
                marginTop: 5,
                opacity: 0.3
              }}
            >
              {t('designer.right_side.edit_links.Create Link')}
            </p>
          </div>
        ) : (
          <div className={`d-flex flex-column justify-content-between ${styles.formEditPlace}`}>
            <Form className={styles.formContainer}>
              <Form.Group
                className="d-flex align-items-center justify-content-between mb-4"
                style={{ display: 'flex' }}
              >
                {selectedComponent &&
                  components[selectedComponent].type === COMPONENT_TYPES.SIDEMENU && (
                    <>
                      <div className="d-flex align-text-center align-items-center">
                        <Form.Check
                          inline={true}
                          label={t('designer.right_side.edit_links.AsDivider')}
                          type={'checkbox'}
                          onChange={(e) => {
                            updateItem('isDivider', e.target.checked);
                          }}
                          checked={items && items[selectedItem] && items[selectedItem].isDivider}
                        />
                      </div>

                      {items && items[selectedItem] && !items[selectedItem].isDivider && (
                        <div className="d-flex align-text-center">
                          <span
                            className="me-2 d-flex align-items-center"
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            {t('designer.right_side.edit_links.FixOn')}
                          </span>
                          <Form.Select
                            value={
                              items[selectedItem] && items[selectedItem].fixed
                                ? items[selectedItem].fixed
                                : ''
                            }
                            defaultValue={''}
                            onChange={(e) => updateItem('fixed', e.target.value as fixPostion)}
                            isValid={!!selectedComponent}
                          >
                            <option value="">---</option>
                            {Object.values(fixPostion)
                              .filter((type) => type !== '')
                              .map((fixType) => (
                                <option key={fixType} value={fixType}>
                                  {t(`designer.right_side.edit_links.${fixType}`)}
                                </option>
                              ))}
                          </Form.Select>
                        </div>
                      )}
                    </>
                  )}
              </Form.Group>
              {items && items[selectedItem] && !items[selectedItem].isDivider && (
                <>
                  <Form.Group style={{ display: 'flex', marginBottom: '1.438rem' }}>
                    <Form.Label className={styles.titleIntoEditPlace}>
                      {t('designer.right_side.edit_links.Title')}
                    </Form.Label>
                    <div style={{ flexDirection: 'column', flexGrow: 1 }}>
                      <Form.Control
                        type="text"
                        placeholder="Home"
                        maxLength={64}
                        value={
                          items &&
                          items[selectedItem] &&
                          items[selectedItem].title &&
                          items[selectedItem].title
                        }
                        onChange={(e) => updateItem('title', e.target.value)}
                      />

                      <Form.Text style={{ color: '#343A40' }}>
                        {t('designer.right_side.edit_links.Title item')}
                      </Form.Text>
                    </div>
                  </Form.Group>

                  <Form.Group style={{ display: 'flex', marginBottom: '1.438rem' }}>
                    <Form.Label className={styles.titleIntoEditPlace}>
                      {t('designer.right_side.edit_links.Description')}
                    </Form.Label>
                    <div style={{ flexDirection: 'column', flexGrow: 1 }}>
                      <Form.Control
                        type="text"
                        as="textarea"
                        placeholder="Go to home"
                        maxLength={64}
                        value={
                          items &&
                          items[selectedItem] &&
                          items[selectedItem].description &&
                          items[selectedItem].description
                        }
                        onChange={(e) => updateItem('description', e.target.value)}
                        style={{ maxHeight: '62px' }}
                      />

                      <Form.Text style={{ color: '#343A40' }}>
                        {t('designer.right_side.edit_links.Description')}
                      </Form.Text>
                    </div>
                  </Form.Group>

                  <Form.Group style={{ display: 'flex', marginBottom: '1.438rem' }}>
                    <Form.Label className={styles.titleIntoEditPlace}>
                      {t('designer.right_side.edit_links.URL')}
                    </Form.Label>
                    <Dropdown
                      style={{ flexGrow: 1, width: '100%' }}
                      onSelect={(e) => {}}
                      defaultChecked={false}
                    >
                      <Dropdown.Toggle
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          flexGrow: 1,
                          width: '100%',
                          backgroundColor: 'transparent',
                          color: '#868EBB',
                          borderColor: '#CED4DA'
                        }}
                      >
                        {(items &&
                          items[selectedItem] &&
                          items[selectedItem].url &&
                          items[selectedItem].url) ||
                          t('designer.right_side.edit_links.Pick URL')}
                      </Dropdown.Toggle>
                      <Dropdown.Menu style={{ padding: 0 }}>
                        <Form.Control
                          type="text"
                          placeholder="/Homepage"
                          value={
                            items &&
                            items[selectedItem] &&
                            items[selectedItem].url &&
                            items[selectedItem].url
                          }
                          onChange={(e) => {
                            updateItem('url', e.target.value);
                          }}
                        />
                        {AllUrl &&
                          AllUrl.map((item: Page, index: number) => (
                            <Dropdown.Item
                              eventKey={item.route}
                              className={styles.dropItem}
                              key={index}
                              onClick={() => {
                                updateItem('url', item.route);
                                const newState = produce(items, (draft: LinksToManifest) => {
                                  draft[selectedItem].url = item.data.route;
                                  draft[selectedItem].page = item.uuid;
                                });
                                setItems(newState);
                              }}
                            >
                              {item.name}
                            </Dropdown.Item>
                          ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Form.Group>

                  <Form.Group style={{ display: 'flex', marginBottom: '1.438rem' }}>
                    <Form.Label className={styles.titleIntoEditPlace}>Icon</Form.Label>
                    <div style={{ flexDirection: 'column', flexGrow: 1 }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <IconPicker
                          value={
                            (items &&
                              items[selectedItem] &&
                              items[selectedItem].icon &&
                              items[selectedItem].icon) ||
                            'FaRegCaretSquareDown'
                          }
                          onChange={(v) => handleIconPicker(v)}
                          buttonStyles={
                            items && items[selectedItem] && items[selectedItem].disableIcon === true
                              ? { visibility: 'hidden' }
                              : {}
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          id={'default-checkbox'}
                          label={t('designer.right_side.edit_links.Disable Icon')}
                          onChange={(event) => {
                            updateItem('disableIcon', event.target.checked);
                          }}
                          checked={items && items[selectedItem] && items[selectedItem].disableIcon}
                        />
                      </div>
                      <Form.Text className="text-body-tertiary">
                        {t('designer.right_side.edit_links.Select icon')}
                      </Form.Text>
                    </div>
                  </Form.Group>

                  <Form.Group
                    style={{
                      display: 'flex',
                      marginBottom: '1.438rem',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <Form.Check
                      inline={true}
                      label={t('designer.right_side.edit_links.Visible')}
                      type={'checkbox'}
                      onChange={(e) => {
                        updateItem('hide', e.target.checked);
                      }}
                      checked={items && items[selectedItem] && items[selectedItem].hide}
                    />
                    <Form.Check
                      inline={true}
                      label={t('designer.right_side.edit_links.Readonly')}
                      type={'checkbox'}
                      onChange={(e) => {
                        updateItem('readOnly', e.target.checked);
                      }}
                      checked={items && items[selectedItem] && items[selectedItem].readOnly}
                    />
                    <Form.Check
                      inline={true}
                      label={t('designer.right_side.edit_links.Open Tab')}
                      type={'checkbox'}
                      onChange={(e) => {
                        updateItem('openNewTab', e.target.checked);
                      }}
                      checked={items && items[selectedItem] && items[selectedItem].openNewTab}
                    />
                  </Form.Group>
                </>
              )}
            </Form>
            <div className={styles.containerButton}>
              <Button variant="success" onClick={(e) => saveItems()}>
                {t('Save')}
              </Button>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default ModalComponent;
