import React, { useCallback, useContext, useEffect, useState } from 'react';
import styles from './styles.module.css';
import { ObjectInstance } from './object_instance';
import EmptyMessage from 'web_ui/empty';
import { useTranslation } from 'react-i18next';
import { ObjectSimple } from 'modules/logic_builder/types';
import { useParams } from 'react-router-dom';
import { Column, EnumFrame, Relationship, Table } from 'modules/modeler/types';
import { DashboardService, ObjectsService } from 'modules/logic_builder/services';
import { ModuleInfo } from 'modules/dashboard/types';
import LogicBuilderContext from '../../store';

type ObjectsListProps = {
  search: string;
  objects: ObjectSimple[];
  onCreate: () => void;
  onEdit: (e: string) => void;
  onPreviewObject: (objectUuid: string) => void;
  loadingData?: boolean;
};

export function ObjectsList(props: ObjectsListProps) {
  const { t } = useTranslation();
  const { app_id } = useParams();
  const { module_id } = useParams();
  const { objects } = useContext(LogicBuilderContext);
  const [tables, setTables] = useState<Table[]>([]);
  const [columns, setColumns] = useState<Column[]>([]);
  const [relationships, setRelationships] = useState<Relationship[]>([]);
  const [enums, setEnums] = useState<EnumFrame[]>([]);
  const [modules, setModules] = useState<ModuleInfo[]>([]);
  const [objectsWithDeps, setObjectsWithDeps] = useState<ObjectSimple[]>();
  const { fetchObjects } = useContext(LogicBuilderContext);

  function handleCreate(event?: React.MouseEvent<HTMLButtonElement>) {
    event && event.stopPropagation();
    props.onCreate();
  }

  const applyFilter = (o: ObjectSimple): boolean => {
    return (
      !props.search ||
      (!!o.name && o.name.toLowerCase().includes(props.search)) ||
      (!!o.description && o.description.toLowerCase().includes(props.search))
    );
  };

  const fetchObjectsContext = useCallback(async (module_id: string) => {
    await ObjectsService.getObjectsSchema(module_id, true).then((schema) => {
      setEnums(schema?.enums ? schema.enums : []);
      setTables(schema?.tables ? schema.tables : []);
      setColumns(schema?.columns ? schema.columns : []);
      setRelationships(schema?.relationships ? schema.relationships : []);
    });
  }, []);

  useEffect(() => {
    if (!module_id) return;

    fetchObjectsContext(module_id);
  }, [fetchObjectsContext, module_id]);

  useEffect(() => {
    if (!app_id) return;
    DashboardService.getModulesByApp(app_id).then((modules) => setModules(modules));
  }, [app_id]);

  async function fetchObjectsWithDeps() {
    if (!module_id) return;
    await ObjectsService.getObjectsByModuleWithDependencies(module_id).then((fetchedObjects) => {
      setObjectsWithDeps(fetchedObjects);
    });
  }

  useEffect(() => {
    fetchObjectsWithDeps();
  }, [module_id, objects]);

  return (
    <>
      <div className={styles.FunctionsGroupWrapper}>
        {/* Objects list. */}
        {props.objects
          .filter((o) => applyFilter(o))
          .sort((a, b) => props.objects.indexOf(b) - props.objects.indexOf(a))
          .map((obj) => {
            return (
              <ObjectInstance
                key={obj.uuid}
                cursor="pointer"
                objectInstance={obj}
                onEdit={(e: ObjectSimple) => props.onEdit(e.uuid)}
                onPreviewObject={props.onPreviewObject}
                tables={tables}
                relationships={relationships}
                columns={columns}
                enums={enums}
                allObjects={props.objects}
                loadingData={props.loadingData}
                modules={modules}
                objectsWithDeps={objectsWithDeps}
                fetchObj={fetchObjects}
              />
            );
          })}
        {!props.objects.length && (
          <div className="w100 h100 border p-4 rounded">
            <EmptyMessage
              message={t('logicBuilder.NoObjects')}
              icon="exclamation"
              actionMessage={t('logicBuilder.CreateObject') ?? ''}
              linkAction={handleCreate}
            ></EmptyMessage>
          </div>
        )}
      </div>
    </>
  );
}
