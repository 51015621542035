import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { AppFullInfo } from 'modules/project/types';
import { Col, Row, ToggleButton } from 'react-bootstrap';
import { DesignSystemList, FrontendFrameworkList, ThemesList } from './data';
import { Dependency, FrontendFramework, Theme } from 'modules/project/enum';
import produce from 'immer';
import ThemePreview from 'web_ui/theme_preview';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';
import { TemplatesList } from 'modules/designer/studio/toolbars/views_toolbar/templates_list';
import { Template } from 'modules/designer/types';
import { TemplatesService } from 'modules/designer/services';
import { VIEWS_TYPES } from 'modules/designer/studio/frames';

export type Step5Props = {
  /** Application info to use on the form */
  appInfo: AppFullInfo;
  /** Callback on form changes */
  onChange: <K extends keyof AppFullInfo>(key: K, value: AppFullInfo[K]) => void;
};

export type FrontendTechOption<T> = {
  /** Tech stack enum */
  id: T;
  /** Name of the lib/framework to show on the UI */
  name: string;
  /** Icon of the lib/framework to show on the UI */
  icon?: string;
  /** Disables this option and shows a "Soon" badge */
  unavailable?: boolean;
};

export type ThemeOption = {
  /** Tech stack enum */
  id: Theme;
  /** Theme background color */
  backgroundColor: string;
  /** Theme primary color */
  primaryColor: string;
  /** Theme secondary color */
  secondaryColor: string;
  /** Theme text color */
  textColor: string;
  /** Disables this option and shows a "Soon" badge */
  unavailable?: boolean;
};

/**
 * Step5 of the application creation wizard.
 * In this step the user must select the frontend tech stack and the application theme.
 *
 * New technologies can be added to `FrontendFrameworkList` or `DesignSystemList`.
 * New themes can be added to `ThemesList`.
 *
 * @component
 */
function Step5(props: Step5Props) {
  const { t } = useTranslation();

  /**
   * Handles the application's frontend framework selection.
   */
  function handleFrameworkChange(e: ChangeEvent<HTMLInputElement>) {
    const frontend = produce(props.appInfo.frontend, (draft) => {
      draft.framework = e.currentTarget.value as FrontendFramework;
    });

    props.onChange('frontend', frontend);
  }

  /**
   * Handles the application's frontend dependecy selection.
   * Currently only used to select the design system.
   */
  function handleDependencyChange(e: ChangeEvent<HTMLInputElement>) {
    const dependency = e.currentTarget.value as Dependency;

    const frontend = produce(props.appInfo.frontend, (draft) => {
      // This allows the user to cherry-pick the frontend dependencies
      if (draft.dependencies.includes(dependency)) {
        const dependencyIndex = props.appInfo.frontend.dependencies.indexOf(dependency);
        draft.dependencies.splice(dependencyIndex, 1);
      } else {
        draft.dependencies.push(dependency);
      }
    });

    props.onChange('frontend', frontend);
  }

  /**
   * Handles the application's frontend theme selection.
   */
  function handleThemeChange(theme: Theme) {
    const frontend = produce(props.appInfo.frontend, (draft) => {
      draft.theme = theme;
    });

    props.onChange('frontend', frontend);
  }

  return (
    <>
      <h4 className="text-body-emphasis">{t('new_application.step4.Frontend')}</h4>

      <h5 className="text-body-primary mt-5 mb-3">{t('Framework')}</h5>
      {FrontendFrameworkList.map((framework) => (
        <ToggleButton
          key={framework.id}
          className="me-3 mb-2 position-relative"
          id={framework.id}
          type="radio"
          name="framework"
          variant={
            props.appInfo.frontend.framework === framework.id ? 'primary' : 'outline-secondary'
          }
          value={framework.id}
          disabled={framework.unavailable}
          checked={props.appInfo.frontend.framework === framework.id}
          onChange={handleFrameworkChange}
        >
          <span className={`fa fa-${framework.icon}`} /> {framework.name}
          {framework.unavailable && (
            <span
              className={`position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary ${styles.commingSoon}`}
            >
              {t('Soon')}
            </span>
          )}
        </ToggleButton>
      ))}

      <h5 className="text-body-primary mt-5 mb-3">{t('new_application.step4.DesignSystem')}</h5>
      {DesignSystemList.map((design) => (
        <ToggleButton
          key={design.id}
          className="me-3 mb-2 position-relative"
          id={design.id}
          type="radio"
          name="design"
          variant={
            props.appInfo.frontend.dependencies.some((item) => item === design.id)
              ? 'primary'
              : 'outline-secondary'
          }
          value={design.id}
          disabled={design.unavailable}
          checked={props.appInfo.frontend.dependencies.some((item) => item === design.id)}
          onChange={handleDependencyChange}
        >
          <span className={`fa fa-${design.icon}`} /> {design.name}
          {design.unavailable && (
            <span
              className={`position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary ${styles.commingSoon}`}
            >
              {t('Soon')}
            </span>
          )}
        </ToggleButton>
      ))}

      <h5 className="text-body-primary mt-5 mb-3">{t('Theme')}</h5>
      <Row>
        {ThemesList.map((theme) => (
          <Col id={`${theme.id}`} key={theme.id}>
            <ThemePreview
              id={theme.id}
              type="radio"
              group="ThemeSelector"
              backgroundColor={theme.backgroundColor}
              primaryColor={theme.primaryColor}
              secondaryColor={theme.secondaryColor}
              textColor={theme.textColor}
              checked={props.appInfo.frontend.theme === theme.id}
              disabled={theme.unavailable}
              onChange={() => handleThemeChange(theme.id)}
            />
          </Col>
        ))}
      </Row>
    </>
  );
}

export default Step5;
