import React, { ComponentType, useState } from 'react';
import { Frame, FrameTypes, FramesList } from '.';
import { Navigate, useParams } from 'react-router-dom';
import { CustomComponentViewProps } from 'modules/designer/studio/frames/custom_component_view';
import { useSelector } from 'react-redux';
import { InterfaceStudioState } from 'modules/designer/studio/store';
import { DatabaseStudioState } from 'modules/modeler/studio/store';
import WidgetsRenderer from '../widgets/widgets_renderer';
import EmptyMessage from 'web_ui/empty';
import { useTranslation } from 'react-i18next';

export type FrameRendererProps = {
  frames: { [key: string]: Frame };
  currentContext: 'designer' | 'modeler';
  showCreatePageDialog?: () => void;
};

function FrameRenderer(props: FrameRendererProps) {
  const { custom_component_id } = useParams();
  const widgets = useSelector((state: InterfaceStudioState | DatabaseStudioState) => state.widgets);
  const { t } = useTranslation();

  const renderCustomComponentView = React.useMemo(() => {
    const FrameComponent = FramesList[
      FrameTypes.CUSTOM_COMPONENT
    ] as ComponentType<CustomComponentViewProps>;

    return (
      FrameComponent &&
      custom_component_id && (
        <FrameComponent custom_uuid={custom_component_id} new_custom_uuid={custom_component_id} />
      )
    );
  }, [custom_component_id]);

  const renderWidgets = React.useMemo(() => <WidgetsRenderer widgets={widgets} />, [widgets]);

  const renderFrames = React.useMemo(() => {
    return props.frames && Object.keys(props.frames).length > 0 ? (
      Object.keys(props.frames).map((key) => {
        const frame = props.frames[key];
        if (!frame) return null;

        const FrameComponent = FramesList[frame.type] as ComponentType;
        return (
          FrameComponent &&
          !custom_component_id && <FrameComponent key={frame.uuid} posX={0} posY={0} {...frame} />
        );
      })
    ) : (
      <div className="d-flex w-100 h-100 justify-content-center align-items-center">
        {props.currentContext === 'designer' && (
          <EmptyMessage
            message={t('designer.NoPages')}
            icon="exclamation"
            actionMessage={t('designer.page.CreatePage') ?? ''}
            linkAction={props.showCreatePageDialog}
          />
        )}
        {props.currentContext === 'modeler' && (
          <EmptyMessage
            message={t('modeler.NoTables')}
            icon="exclamation"
            actionMessage={t('modeler.DragTable') ?? ''}
          />
        )}
      </div>
    );
  }, [custom_component_id, props.frames]);

  return (
    <>
      {renderCustomComponentView}
      {renderWidgets}
      {renderFrames}
    </>
  );
}

export default FrameRenderer;
