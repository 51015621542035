import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useSession from 'hooks/useSession';
import { useViews } from '../hooks/outletContext';
import { useParams } from 'react-router-dom';
import ViewsToolbar from './toolbars/views_toolbar';
import PropertiesToolbar from './toolbars/properties_toolbar';
import StylesToolbar from './toolbars/styles_toolbar';
import AppThemeToolbar from './toolbars/appTheme_toolbar';
import EventsToolbar from './toolbars/events_toolbar';
import Sidebar, { SidebarPosition, Studios, ToolbarItem } from 'web_ui/workboard/sidebar';
import { DesignerMode, InterfaceStudioState } from './store';
import { ContextRole, RoleAuthorities } from 'modules/auth/types/auth_types';
import { ComponentManifest, Editor, Theme, ViewManifest } from '../types';
import { COMPONENTS_MANIFEST } from './exocode_components';
import { VIEWS_MANIFEST, VIEWS_TYPES } from './frames';
import { PopupAlert } from 'web_ui/popup_alert';
import { setDesignerMode, setErrorMessage } from './store/actions/studio';
import { CustomComponentsToolbar } from './toolbars/custom_components_toolbar';
import { ThemeService } from '../services';
import { setSelectedTheme } from './store/actions/theme';
import { ActionBar } from './toolbars/action_toolbar/action_bar';
import { BehaviorMode } from './toolbars/action_toolbar/behavior_mode';
import FrameWrapper from 'web_ui/workboard/frame/FrameWrapper';
import { Authorization } from '../../auth/session/authorization';
import { LocalStorageManager } from 'utils/localstorage';
import { COMPONENTS_LIST } from './exocode_components';
import { CssClassToolbar } from './toolbars/css_toolbar';
import { useCheckShowGrid } from '../hooks/useCheckIsGridColumn';
import { FoldersProvider } from './FolderContext';

export type BehaviorTab = 'FUNCTIONS' | 'OBJECTS' | 'VARIABLES' | 'PROPERTIES';

export type StudioProps = {
  toggleTheme(): void;
};

function Studio(props: StudioProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [session] = useSession();
  const { pages, modals, layouts, fetchViews, layoutDefault, setCurrentLocationSubContext } =
    useViews();

  const selectedComponent = useSelector(
    (state: InterfaceStudioState) => state.studio.selectedComponent.uuid
  );
  const views = useSelector((state: InterfaceStudioState) => state.views);
  const selectedView = useSelector((state: InterfaceStudioState) => state.studio.selectedView);
  const viewId = useSelector((state: InterfaceStudioState) => state.studio.view_id);
  const editor = useSelector((state: InterfaceStudioState) => state.studio.editor);
  const globalComponents = useSelector((state: InterfaceStudioState) => state.components);
  const errorMessage = useSelector((state: InterfaceStudioState) => state.studio.errorMessage);
  const designerMode = useSelector((state: InterfaceStudioState) => state.studio.designerMode);
  const { module_id, app_id } = useParams();
  const currentValLS = LocalStorageManager.getValueLocalStorageState(app_id!, module_id!);
  const [selectedBehaviorTab, setSelectedBehaviorTab] = useState<BehaviorTab>(
    currentValLS[module_id!] &&
      (currentValLS[module_id!].interface.lastSelectedTabBehavior as BehaviorTab)
      ? (currentValLS[module_id!].interface.lastSelectedTabBehavior as BehaviorTab)
      : 'FUNCTIONS'
  );
  const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(true);
  const [isLeftSidebarOpen, setIsLeftSidebarOpen] = useState(true);
  const [manifest, setManifest] = useState<ComponentManifest | ViewManifest>();
  const [themes, setThemes] = useState<Theme[]>([]);
  const checkShowGrid = useCheckShowGrid();
  const isAGridColumn =
    selectedComponent &&
    globalComponents[selectedComponent] &&
    checkShowGrid(globalComponents[selectedComponent].uuid);

  useEffect(() => {
    setCurrentLocationSubContext(designerMode.toLowerCase());
  }, [designerMode, setCurrentLocationSubContext]);

  useEffect(() => {
    if (selectedComponent && globalComponents[selectedComponent]) {
      setManifest(COMPONENTS_MANIFEST[globalComponents[selectedComponent].type]);
    } else if (selectedView && views && views[selectedView]) {
      setManifest(VIEWS_MANIFEST[views[selectedView].type]);
    } else if (selectedView && editor === Editor.CUSTOM_COMPONENT) {
      setManifest(VIEWS_MANIFEST[VIEWS_TYPES.CUSTOMCOMPONENTS]);
    }
  }, [editor, globalComponents, selectedComponent, selectedView, views]);

  const canEditEvents = session.user?.roles.some((roleA) => {
    return (
      roleA.context === ContextRole.APP &&
      roleA.contextUuid?.toString() === app_id &&
      roleA?.roleAuthorities.includes(RoleAuthorities.MANAGE_ID_OPERATIONS)
    );
  });

  const views_tab: ToolbarItem = {
    id: 'Views',
    name: t('designer.Views'),
    icon: 'window-maximize'
  };
  const themes_tab: ToolbarItem = {
    id: 'Themes',
    name: t('designer.Themes'),
    icon: 'paintbrush'
  };
  const css_tab: ToolbarItem = {
    id: 'CSS',
    name: t('designer.cssClass.CssClasses'),
    iconBrands: 'brands',
    icon: 'css3-alt'
  };
  const custom_components_tab: ToolbarItem = {
    id: 'CustomComponents',
    name: t('designer.Blocks'),
    icon: 'cubes'
  };
  const properties: ToolbarItem = {
    id: 'Properties',
    name: t('designer.right_side.Properties'),
    icon: 'gear'
  };
  const styles: ToolbarItem = {
    id: 'Styles',
    name: t('designer.right_side.Styles'),
    icon: 'palette'
  };
  const events: ToolbarItem = {
    id: 'Events',
    name: t('designer.right_side.Events'),
    icon: 'bolt'
  };

  const toggleRightSidebar = (isOpen: boolean) => {
    setIsRightSidebarOpen(isOpen);
  };

  const toggleLeftSidebar = (isOpen: boolean) => {
    setIsLeftSidebarOpen(isOpen);
  };

  // This is used to create the first element of a custom component.
  // function handleOnDrop(ev: React.DragEvent<HTMLElement>) {
  //   if (editor !== 'CUSTOM_COMPONENT') return;

  //   // If there is already a component created then return.
  //   if (Object.keys(globalComponents).length !== 1) return;

  //   const componentType = ev.dataTransfer.getData('exocode/component-type');

  //   if (componentType === 'COLUMN') return;

  //   const parentCustomComponent = Object.values(globalComponents)[0];

  //   const template = COMPONENTS_TEMPLATE[componentType];
  //   if (componentType === 'ROW' || componentType === 'CONTAINER') {
  //     template.data.width = 700;
  //     template.data.widthUnit = 'px';
  //   }
  //   const component: LayoutComponent = JSON.parse(JSON.stringify(Object.assign({}, template)));

  //   component.uuid = uuidv4();
  //   component.data.name = faker.random.words(2).toLowerCase().replace(' ', '_');
  //   const link: LinkInfo = {
  //     viewUUID: parentCustomComponent.custom_uuid || '',
  //     parentUUID: parentCustomComponent.custom_uuid || '',
  //     section: ''
  //   };

  //   dispatch(addComponent(component, link));
  // }

  function onCloseErrorAlert() {
    dispatch(setErrorMessage(''));
  }

  // function handleWorkboardOnClick() {
  //   dispatch(setSelectedView(viewId));
  //   dispatch(setSelectedComponent(null));
  // }

  function showEventsToolbar() {
    if (!canEditEvents) return false;
    if (editor === Editor.CUSTOM_COMPONENT) {
      return (
        globalComponents && globalComponents[viewId] && globalComponents[viewId].data.isHierarchy
      );
    }
    return true;
  }

  function getRightToolbarItems() {
    const items = [properties, styles];
    if (selectedView) {
      if (showEventsToolbar()) {
        items.push(events);
      }
    } else {
      if (showEventsToolbar()) {
        items.push(events);
      }
    }
    return items;
  }

  const handleFetchThemes = useCallback(
    async (app_id: string) => {
      try {
        const themes = await ThemeService.getThemesByAppUuid(app_id);
        setThemes(themes);
        const findSelectedTheme = themes.find((t) => t.selected);
        if (findSelectedTheme) {
          dispatch(setSelectedTheme(findSelectedTheme.id || ''));
        }
      } catch (error) {
        console.log(error);
      }
    },
    [dispatch]
  );

  const handleSelectBehaviorTabs = (tab: BehaviorTab) => {
    setSelectedBehaviorTab(tab);
  };

  const handleGetComponentName = useCallback(
    (name?: string) => {
      if (isAGridColumn) return 'Column';

      return name;
    },
    [isAGridColumn]
  );

  useEffect(() => {
    if (app_id) handleFetchThemes(app_id);
  }, [app_id, handleFetchThemes]);

  useEffect(() => {
    const copying = LocalStorageManager.getValueLocalStorageState(app_id!, module_id!);

    if (selectedComponent != null) {
      copying[module_id!] = {
        ...copying[module_id!],
        interface: {
          ...copying[module_id!].interface!,
          lastSelectedComponent: selectedComponent!
        }
      };
      LocalStorageManager.setValueLocalStorageState(app_id!, copying);
    }
  }, [selectedComponent]);

  const getValueFromLSToLeft = (): number => {
    const copying = LocalStorageManager.getValueLocalStorageState(app_id!, module_id!);

    if (copying[module_id!] && copying[module_id!].interface.lastSelectedLeftSidebarTab) {
      return copying[module_id!].interface.lastSelectedLeftSidebarTab;
    } else {
      return 0;
    }
  };

  const getValueFromLSToRight = (): number => {
    const copying = LocalStorageManager.getValueLocalStorageState(app_id!, module_id!);

    if (copying[module_id!] && copying[module_id!].interface.lastSelectedRightSidebarTab) {
      return copying[module_id!].interface.lastSelectedRightSidebarTab;
    } else {
      return 0;
    }
  };

  const handleContextMenu = (event: React.MouseEvent<HTMLIFrameElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  useEffect(() => {
    if (!views || (Object.keys(views).length < 1 && designerMode !== 'DESIGN')) {
      dispatch(setDesignerMode('DESIGN' as DesignerMode));
    }
  }, [views, dispatch, designerMode]);

  return (
    <div onContextMenu={handleContextMenu}>
      <FoldersProvider moduleId={module_id!}>
        <Sidebar
          position={SidebarPosition.LEFT}
          toolbarItems={[views_tab, custom_components_tab, themes_tab, css_tab]}
          isOpen={toggleLeftSidebar}
          currentValueSaved={getValueFromLSToLeft()}
          from={Studios.DESIGNER}
        >
          {app_id && module_id && (
            <ViewsToolbar
              appId={app_id}
              moduleId={module_id}
              pages={pages}
              modals={modals}
              layouts={layouts}
              fetchViews={fetchViews}
              layoutDef={layoutDefault}
            />
          )}
          <CustomComponentsToolbar />
          <AppThemeToolbar themes={themes} />
          <CssClassToolbar />
        </Sidebar>

        <ActionBar
          handleSelectBehaviorTabs={handleSelectBehaviorTabs}
          selectedBehaviorTab={selectedBehaviorTab}
          isLeftSidebarOpen={isLeftSidebarOpen}
          isRightSidebarOpen={isRightSidebarOpen}
        />

        {designerMode === 'DESIGN' && (
          // <Workboard
          //   showZoom={true}
          //   showFocus={true}
          //   showSettings={true}
          //   toggleTheme={props.toggleTheme}
          //   frames={views}
          //   onDrop={handleOnDrop}
          //   isRightSidebarOpen={isRightSidebarOpen}
          //   handleWorkboardOnClick={handleWorkboardOnClick}
          // />
          <FrameWrapper
            frames={views}
            isRightSidebarOpen={isRightSidebarOpen}
            isLeftSidebarOpen={isLeftSidebarOpen}
            fetchViews={fetchViews}
            layouts={layouts}
            layoutDef={layoutDefault}
          />
        )}

        {designerMode === 'BEHAVIOR' && (
          <Authorization
            context={ContextRole.APP}
            allowedAuthorities={[RoleAuthorities.MANAGE_ID_OPERATIONS]}
          >
            <BehaviorMode selectedTab={selectedBehaviorTab} />
          </Authorization>
        )}

        <Sidebar
          position={SidebarPosition.RIGHT}
          toolbarItems={getRightToolbarItems()}
          isOpen={toggleRightSidebar}
          currentValueSaved={getValueFromLSToRight()}
          from={Studios.DESIGNER}
        >
          <PropertiesToolbar
            layouts={layouts}
            name={handleGetComponentName(manifest?.name)}
            icon={manifest?.icon}
          />
          <StylesToolbar name={handleGetComponentName(manifest?.name)} icon={manifest?.icon} />
          {showEventsToolbar() && (
            <EventsToolbar name={handleGetComponentName(manifest?.name)} icon={manifest?.icon} />
          )}
        </Sidebar>

        {errorMessage && <PopupAlert i18nKey={errorMessage} onClose={onCloseErrorAlert} />}
      </FoldersProvider>
    </div>
  );
}

export default Studio;
