import React, { ChangeEvent, useEffect, useState } from 'react';
import { AppFullInfo } from 'modules/project/types';
import { Badge, Card, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { TemplateList } from './data';
import { Template } from 'modules/project/enum';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';
import SessionContext from 'modules/auth/store';

export type Step1Props = {
  /** Application info to use on the form */
  appInfo: AppFullInfo;
  /** Callback on form changes */
  onChange: <K extends keyof AppFullInfo>(key: K, value: AppFullInfo[K]) => void;
  updateErrorMessage: (s: string) => void;
};

export type TemplateCategory = {
  /** Template category description */
  name: string;
  /** Font-Awesome icon */
  icon: string;
};

export type AppTemplateOption = {
  /** Application template enum */
  id: Template;
  /** Template name */
  title: string;
  /** Template description */
  description: string;
  /** URI to the template preview image */
  image: string;
  /** List of template categories */
  categories: TemplateCategory[];
  /** Disables this template and shows a "Soon" badge */
  unavailable?: boolean;
};

/**
 * Step1 of the application creation wizard.
 * In this step the user must select the application template to build.
 *
 * New templates can be added to `TemplateList`.
 *
 * @component
 */
function Step1(props: Step1Props) {
  const { t } = useTranslation();
  // used to filter the column array by titles
  const [searchText, setSearchText] = useState('');
  const session = React.useContext(SessionContext);

  const search: string = t('Search');

  useEffect(() => {
    props.updateErrorMessage('');
  }, [props.updateErrorMessage]);

  /**
   * Handles the application's template selection.
   */
  function handleChange(temp: string) {
    const template: Template = temp as Template;
    props.onChange('template', template);
  }

  function handleChangeLabelClick(template: AppTemplateOption) {
    props.onChange('template', template.id);
  }

  /**
   * Internal component to simplify the code.
   * This is a card used as a radio input.
   *
   * @component
   */
  const TemplateToggle = (template: AppTemplateOption) => (
    <Form.Check type="radio" id={template.id}>
      <Form.Check.Input
        type="radio"
        id={template.id.replaceAll(' ', '')}
        name="template"
        value={template.id}
        checked={template.id === props.appInfo.template}
        disabled={template.unavailable}
        onChange={(ev) => handleChangeLabelClick(template)}
        required
      />
      <Form.Check.Label onClick={(ev) => handleChangeLabelClick(template)}>
        <Card
          style={{ cursor: 'pointer', width: 250, height: 300 }}
          onClick={(ev) => handleChange(template.id)}
          className={session.preferences['exocode-theme'] ? styles.cardWhite : styles.cardBlack}
        >
          <Card.Img variant="top" src={template.image} height={150} />
          <Card.Body>
            <Card.Title className="text-body-primary">
              {template.title}
              {template.unavailable && <Badge bg="primary ms-2">Soon</Badge>}
            </Card.Title>
            <Card.Text className="text-body-secondary">
              <span style={{ fontSize: 14 }}>{t(template.description)}</span>
              <br />
              {template.categories.map((category) => (
                <Badge key={category.name} className="me-2 mt-2" bg="secondary">
                  <i className={`fa-solid fa-${category.icon}`}></i> {category.name}
                </Badge>
              ))}
            </Card.Text>
          </Card.Body>
        </Card>
      </Form.Check.Label>
    </Form.Check>
  );

  return (
    <>
      <h4 className="text-body-emphasis">{t('new_application.step1.ChooseTemplate')}</h4>

      <Container className="mt-5 mb-3">
        <Row>
          <Col lg={3}>
            <InputGroup className="mb-3">
              <InputGroup.Text>
                <i className="fa-solid fa-magnifying-glass"></i>
              </InputGroup.Text>
              <Form.Control
                id="searchField"
                placeholder={search}
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onKeyDown={(e) => {
                  e.key === 'Enter' && e.preventDefault();
                }}
              />
            </InputGroup>
          </Col>

          <Col lg={9}>
            <Row xs={1} sm={2} md={3} className="g-4">
              {TemplateList.filter((template) => {
                return (
                  template &&
                  (!searchText ||
                    template.title.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()))
                );
              }).map((template) => (
                <Col key={template.id} className="p-2">
                  <TemplateToggle {...template} />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Step1;
