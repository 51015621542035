import * as React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import styles from './styles.module.css';
import { ApiAppInfo } from 'modules/project/types';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ProjectsService } from 'modules/project/services';
import Icon from 'web_ui/icon';
import { useTranslation } from 'react-i18next';
import { CodeDownloadDialog } from 'routes/dashboard/components/code_download_modal';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';

type Props = {
  isFromVsCodeExt: boolean;
};

export default function HeaderJobs({ isFromVsCodeExt = false }: Props) {
  const [appInfo, setAppInfo] = React.useState<ApiAppInfo>({} as ApiAppInfo);
  const { app_id } = useParams();
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { state } = useLocation();
  const [showModalForDownload, setShowModalForDownload] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (app_id) {
      const fetchAppInfo = async () => {
        const apiAppInfo = await ProjectsService.getProjectById(app_id);
        setAppInfo(apiAppInfo);
        setLoading(false);
      };
      fetchAppInfo();
    }
  }, [app_id]);

  const navigateBackDashboard = () => {
    navigate(`/app/${app_id}/dashboard`);
  };

  const goBack = () => {
    if (state && state.location != null) {
      // inside the context of an app, just comeback
      navigate(-1);
    } else {
      navigateBackDashboard();
    }
  };

  return (
    <>
      <Row className={`${styles.firstRow} bg-body-secondary`}>
        <Col
          sm={12}
          className={`${styles.appWrapper}`}
          style={loading ? { justifyContent: 'center', alignItems: 'center' } : undefined}
        >
          {loading && (
            <div>
              <div className={`fa-3x`}>
                <i className="fas fa-spinner fa-spin"></i>
              </div>
            </div>
          )}

          {!loading && (
            <div className={styles.firstContainer}>
              <Col sm={9} className={`${styles.resumeWrapper}`}>
                <i
                  className="fa-solid fa-gears"
                  style={{ fontSize: '4.5rem', marginRight: '1rem' }}
                ></i>
                <div className={styles.appDetails}>
                  <div className={styles.appDescription}>
                    <p id="label" className={`${styles.overFlowText}`}>
                      Jobs
                    </p>
                  </div>
                  <div className={styles.appData}>
                    <div style={{ display: 'flex' }}>
                      <p className="text-body-primary" style={{ marginRight: 5 }}>
                        {appInfo.owner_name}
                      </p>
                      <p>/</p>
                    </div>
                    <h3 className="text-body-emphasis">{appInfo.name}</h3>
                  </div>
                </div>
              </Col>
              <Col sm={3} className={styles.appAction}>
                <HelpPopover
                  helpBoxProps={{
                    title: t('jobsPage.goback') ?? 'jobsPage.goback'
                  }}
                  placement="top"
                >
                  <Button
                    id="backButton"
                    style={{ marginRight: '.5rem', width: '50px', height: '50px' }}
                    className="btn-secondary"
                    onClick={() => goBack()}
                  >
                    <Icon iconName="arrow-left"></Icon>
                  </Button>
                </HelpPopover>
                <HelpPopover
                  helpBoxProps={{
                    title: t('jobsPage.overlayDashboardButton')!
                  }}
                  placement="top"
                >
                  {isFromVsCodeExt ? (
                    <></>
                  ) : (
                    <Button
                      id="dashboardButton"
                      style={{ marginRight: '.5rem', width: '50px', height: '50px' }}
                      className="btn-secondary"
                      onClick={() => navigateBackDashboard()}
                    >
                      <Icon iconName="table-columns"></Icon>
                    </Button>
                  )}
                </HelpPopover>
                <HelpPopover
                  helpBoxProps={{
                    title: t('appResume.DownloadCode')!
                  }}
                  placement="top"
                >
                  <Button
                    id={'downloadCodeButton'}
                    className={styles.downloadCodeButton}
                    onClick={() => setShowModalForDownload(true)}
                    style={{ width: '50px', height: '50px' }}
                  >
                    <Icon iconName="laptop-code"></Icon>
                  </Button>
                </HelpPopover>
              </Col>
            </div>
          )}
        </Col>
      </Row>
      <CodeDownloadDialog
        app={appInfo}
        show={showModalForDownload}
        withPR={appInfo.createPR}
        onClose={() => setShowModalForDownload(false)}
      />
    </>
  );
}
