import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import Navbar from '../../web_ui/navbar';
import { Alert, Button, Col, Container, ListGroup, Row } from 'react-bootstrap';
import { ProjectsService } from '../../modules/project/services';
import { ApiAppInfo } from '../../modules/project/types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ProjectRow from './components/project_row';
import produce from 'immer';
import useSession from 'hooks/useSession';
import CreateProjectModal from './components/create_project_modal';
import { Role } from 'modules/auth/types/auth_types';
import Organization from 'modules/organization/services/organization';
import { OrganizationApiInfo } from 'modules/organization/types';
import EmptyMessage from 'web_ui/empty';
import useCreateApp from 'hooks/useCreateApp';
import UpgradePlanModal from './components/upgrade_plan_modal';
import useTitle from 'hooks/useTitle';
import useWindowDimensions from 'modules/designer/hooks/window';

enum FiltersOptions {
  NAME = 'Name',
  DATE = 'Modification Date',
  CREATION_DATE = 'Creation date',
  PERSONAL = 'Personal',
  ORGANIZATION = 'Organization'
}

/**
 * The homepage of the platform.
 * This page shows a list with the projects of the current user.
 *
 * @component
 * @route /homepage
 * @protected
 */
function Homepage() {
  // Global user session
  // const session = useContext(SessionContext);
  const [projects, setProjects] = useState<ApiAppInfo[]>([]);
  const [isFirst, setIsFirst] = useState<boolean>(false);
  const [session] = useSession();
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [filterType, setFilterType] = useState<FiltersOptions>(FiltersOptions.CREATION_DATE);
  const [searchText, setSearchText] = useState('');
  const search = t('organizations.organization.SearchProjects');
  const [showModalCreateApp, setShowModalCreateApp] = useState<boolean>(false);
  const [allCurrentOrgsUser, setAllCurrentOrgsUser] = useState<OrganizationApiInfo[]>([]);
  const [deletedRow, setDeletedRow] = useState<boolean>(false);
  const [canCreateApp] = useCreateApp(projects);
  const [showUpgradePlanModal, setShowUpgradePlanModal] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const dimensions = useWindowDimensions();

  const isMobile = dimensions.width <= 480;
  const mobileAlertText: string[] = t('Walkthrough.Popup.MobileVersionText', {
    returnObjects: true
  });

  useTitle(t('tab.homepage'));

  /**
   * Fetch projects from the current user
   */
  const locationState = location.state;
  useEffect(() => {
    if (locationState) {
      setIsFirst(locationState.isFirst);
    }

    const fetchProjects = async () => {
      const projects = await ProjectsService.getProjects();
      // Default filter is FiltersOptions.CREATION_DATE.
      projects.sort((a, b) => (a.created_at < b.created_at ? 1 : -1));
      setProjects(projects);
      setLoading(false);
      // if projects is empty, navigate to wizard
      if (projects.length === 0) {
        navigate('/wizard');
      }
    };

    // refetching user account info to update icon
    session.reloadUser();

    fetchProjects();

    // reset DELETED
    setDeletedRow(false);
  }, [location.state, deletedRow, reload]);

  const handleDeleteApp = async () => {
    setDeletedRow(true);
    navigate('/homepage');
  };

  function changeFilterType(type: FiltersOptions) {
    setFilterType(type);
    if (type === FiltersOptions.NAME) {
      // filtertype does not exist
      setProjects((currentVal) => {
        const nextState = produce(currentVal, (draft) => {
          draft.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
        });
        return [...nextState];
      });
    } else if (type === FiltersOptions.DATE) {
      setProjects((currentVal) => {
        const nextState = produce(currentVal, (draft) => {
          draft.sort((a, b) => (a.changed_at < b.changed_at ? 1 : -1));
        });
        return [...nextState];
      });
    } else if (type === FiltersOptions.CREATION_DATE) {
      setProjects((currentVal) => {
        const nextState = produce(currentVal, (draft) => {
          draft.sort((a, b) => (a.created_at < b.created_at ? 1 : -1));
        });
        return [...nextState];
      });
    } else if (type === FiltersOptions.PERSONAL) {
      setProjects((currentVal) => {
        const nextState = produce(currentVal, (draft) => {
          draft.sort((a, b) => {
            if (!a.is_owner_org && b.is_owner_org) return -1;
            if (a.is_owner_org && !b.is_owner_org) return 1;
            return 0;
          });
        });
        return [...nextState];
      });
    } else if (type === FiltersOptions.ORGANIZATION) {
      setProjects((currentVal) => {
        const nextState = produce(currentVal, (draft) => {
          draft.sort((a, b) => {
            if (a.is_owner_org && !b.is_owner_org) return -1;
            if (!a.is_owner_org && b.is_owner_org) return 1;
            return 0;
          });
        });
        return [...nextState];
      });
    }
  }

  const verifyingTheUserRoleAuth = (): Role[] => {
    if (session.user && session.user.roles) {
      if (session.user.roles.filter((item) => item.context === 'ORGANIZATION').length === 0) {
        // navigate('/wizard');
        return [];
      } else {
        // set all organization that he user has MANAGE_APP Auth
        return session.user.roles.filter(
          (roleRow) =>
            roleRow.roleAuthorities.filter((roleAuth) => roleAuth === 'MANAGE_APP').length > 0 &&
            roleRow.context === 'ORGANIZATION'
        );
      }
    } else {
      return [];
    }
  };

  const gettingOrgById = (allOrgByUser: Role[]) => {
    const serviceOrg = new Organization();
    allOrgByUser.map(async (org) => {
      if (org.contextId) {
        const OrgById = await serviceOrg.getOrganizationById(org.contextId);
        if (allCurrentOrgsUser.filter((item) => item.id === OrgById.id).length > 0) {
          // avoid repeat the same item
          return;
        }
        setAllCurrentOrgsUser((currentVal) => [...currentVal, OrgById]);
      }
    });
  };

  React.useEffect(() => {
    const gettingAllOrgsByCurrentUser = verifyingTheUserRoleAuth();
    if (gettingAllOrgsByCurrentUser.length > 0) {
      gettingOrgById(gettingAllOrgsByCurrentUser);
    }
  }, []);

  const openApplicationWizard = () => {
    navigate('/wizard');
  };

  const verifyingCurrentPlan = () => {
    if (canCreateApp) {
      navigate('/wizard');
    } else {
      // does not have orgs
      // cant create app here
      setShowUpgradePlanModal(true);
    }
  };

  const showContent = (): boolean => {
    return !isMobile;
  };

  return (
    <>
      <Navbar context="homepage" />
      <Container className="p-4 pt-5" style={{ minHeight: 'calc(100vh - 60px)' }}>
        <Row className="pt-4 pb-4">
          <h3 className="text-body-tertiary" id="headerMessage">
            {t('dashboard.Welcome')}, {session.user?.username}
          </h3>
        </Row>
        <Alert
          id="alertMessage"
          className={`${styles.ErrorAlertWrapper}`}
          show={isFirst || !showContent()}
          variant={'secondary'}
          onClose={() => setIsFirst(false)}
          dismissible={showContent()}
        >
          <h5>{t('Walkthrough.Popup.title')}</h5>
          {!isMobile && <p>{t('Walkthrough.Popup.text')}</p>}
          {isMobile && (
            <>
              {mobileAlertText.map((t, idx) => {
                return <p key={idx}>{t}</p>;
              })}
            </>
          )}
        </Alert>
        {showContent() && (
          <>
            <Row>
              <Col sm={9}>
                <p className={`text-body-emphasis ${styles.titleContainerOne}`}>
                  {t('dashboard.projects')}
                </p>
              </Col>
              <Col sm={3}>
                {/* <Link
              to="/wizard"
              className="d-flex align-items-center justify-content-end"
              onClick={verifyingTheUserRoleAuth}
            > */}
                <div className="d-flex align-items-center justify-content-end">
                  <Button
                    id={'newProjectButton'}
                    className="d-flex align-items-center justify-content-end"
                    variant="primary"
                    onClick={() => {
                      if (allCurrentOrgsUser.length > 0) {
                        setShowModalCreateApp(true);
                      } else {
                        verifyingCurrentPlan();
                      }
                    }}
                  >
                    {t('dashboard.NewProject')}
                  </Button>
                </div>
                {/* </Link> */}
              </Col>
            </Row>
            <Row className="pt-5">
              <Col sm={4}>
                <input
                  id={'searchField'}
                  type={'search'}
                  className="form-control"
                  placeholder={search}
                  onChange={(ev) => setSearchText(ev.target.value)}
                />
              </Col>
              <Col sm={8}>
                <div className="d-flex align-items-center justify-content-end">
                  <h6 className="ps-1 pe-1" style={{ margin: 0 }}>
                    {t('OrderBy')}
                  </h6>
                  <div className="ps-1">
                    <select
                      id={'filterDropdown'}
                      className={`form-select form-select-sm`}
                      value={filterType}
                      onChange={(ev) => changeFilterType(ev.target.value as FiltersOptions)}
                    >
                      {Object.values(FiltersOptions)
                        .filter((v) => isNaN(Number(v)))
                        .map((type) => {
                          return (
                            <option id={type} key={type} value={type}>
                              {t('order_options.' + type)}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="pt-5 justify-content-center">
              <Col sm={12}>
                {loading === false ? (
                  <div className="border rounded p-4">
                    <ListGroup id={'list-group'} style={{ maxHeight: '40vh', overflowY: 'auto' }}>
                      {projects &&
                        projects
                          .filter((item) => {
                            return (
                              !searchText ||
                              item.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
                            );
                          })
                          .map((item: ApiAppInfo, index: number) => {
                            return (
                              <ProjectRow
                                key={index}
                                apiAppInfo={item}
                                setApiAppInfo={setProjects}
                                onAfterDeleteApp={handleDeleteApp}
                                isDisabled={item.disabled}
                                reload={setReload}
                              />
                            );
                          })}
                    </ListGroup>
                    {projects && !projects.length && (
                      <div className="w100 h100">
                        <EmptyMessage
                          message={t('dashboard.EmptyDashboardMessage')}
                          icon="exclamation"
                          actionMessage={t('dashboard.EmptyDashboardAction') ?? ''}
                          linkAction={openApplicationWizard}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className={`fa-3x ${styles.spinner}`}>
                    <i className={`fas fa-spinner fa-spin`}></i>
                  </div>
                )}
              </Col>
            </Row>
          </>
        )}
      </Container>
      {showModalCreateApp && (
        <CreateProjectModal
          show={showModalCreateApp}
          setShow={setShowModalCreateApp}
          orgId={allCurrentOrgsUser}
          projects={projects}
        />
      )}
      {showUpgradePlanModal && (
        <UpgradePlanModal setShow={setShowUpgradePlanModal} show={showUpgradePlanModal} />
      )}
    </>
  );
}

export default Homepage;
