import React from 'react';
import { Button, ProgressBar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';

type WizardProgressProps = {
  error: string;
  loading: boolean;
  step: number;
  totalSteps: number;
  onStepChange: (step: number) => void;
  onFinish: () => void;
};

const Loading = () => <i className="fas fa-spinner fa-spin"></i>;

function WizardProgress(props: WizardProgressProps) {
  const { t } = useTranslation();

  function handleNext() {
    if (!props.error) {
      props.onStepChange(props.step + 1);
    }
  }

  return (
    <>
      <ProgressBar min={0} max={props.totalSteps} now={props.step} />
      <div className="d-flex justify-content-between mt-3">
        <Button
          id={'backButton'}
          variant="secondary"
          disabled={props.step === 0 || props.loading}
          onClick={() => props.onStepChange(props.step - 1)}
        >
          {props.loading ? <Loading /> : t('navigation_options.Back')}
        </Button>
        {props.step !== props.totalSteps && (
          <HelpPopover
            helpBoxProps={{ title: props.error }}
            placement="top"
            disabled={!props.error}
          >
            <span>
              <Button
                id={'nextButton'}
                variant="primary"
                disabled={props.loading}
                onClick={() => handleNext()}
              >
                {props.loading ? <Loading /> : t('navigation_options.Next')}
              </Button>
            </span>
          </HelpPopover>
        )}
        {props.step === props.totalSteps && (
          <HelpPopover
            helpBoxProps={{ title: props.error }}
            placement="top"
            disabled={!props.error}
          >
            <span>
              <Button
                id={'finishButton'}
                variant="primary"
                disabled={props.loading}
                onClick={() => props.onFinish()}
              >
                {props.loading ? <Loading /> : t('navigation_options.Finish')}
              </Button>
            </span>
          </HelpPopover>
        )}
      </div>
    </>
  );
}

export default WizardProgress;
