import React, { forwardRef, memo } from 'react';
import { ComponentUUID, ViewUUID } from '../../../types';
import { Tabs, Tab } from 'react-bootstrap';
import ComponentsRenderer from '../components_renderer';
import SectionWrapper from '../section_wrapper';
import { COMPONENT_TYPES } from '../index';
import { parseCustomCss, removeDeclarationsWithUndefinedValue } from 'utils/cssUtils';
import useCalculateComponentSize from 'modules/designer/hooks/useCalculateComponentSize';

export type TabsData = {
  title: string[];
  width?: number | string;
  widthUnit?: number | string;
  maxWidth?: number | string;
  maxWidthUnit?: string;
  minWidth?: number | string;
  minWidthUnit?: string;
  height?: number | string;
  heightUnit?: number | string;
  maxHeight?: number | string;
  maxHeightUnit?: string;
  minHeight?: number | string;
  minHeightUnit?: string;
  activeKey: string;
  tooltip?: string;
  optionSizeWidth?: string;
  optionSizeHeight?: string;
};

export type TabsStyle = {
  fontSize?: number;
  borderRadius?: number;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  borderTop?: number;
  borderLeft?: number;
  borderRight?: number;
  borderBottom?: number;
  paddingTop?: number;
  paddingLeft?: number;
  paddingRight?: number;
  paddingBottom?: number;
  css?: string;
  classes?: string[];
};

export type TabsComponentProps = {
  uuid: ComponentUUID;
  viewUUID: ViewUUID;
  parentUUID?: ComponentUUID;
  data: TabsData;
  styles: TabsStyle;
  custom_uuid: ComponentUUID;
  new_custom_uuid: ComponentUUID;
};

function TabsComponent(props: TabsComponentProps, ref: React.Ref<any>) {
  let style = {
    ...(props.data.width &&
      props.data.widthUnit && {
        width: props.data.width + '' + props.data.widthUnit
      }),
    ...(props.data.height &&
      props.data.heightUnit && {
        height: props.data.height + '' + props.data.heightUnit
      }),
    ...(props.data.width &&
      !props.data.widthUnit && {
        width: props.data.width
      }),
    ...(props.data.height &&
      !props.data.heightUnit && {
        height: props.data.height
      }),
    ...(props.data.maxHeight &&
      props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight + '' + props.data.maxHeightUnit
      }),
    ...(props.data.maxHeight &&
      !props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight
      }),
    ...(props.data.minHeight &&
      props.data.minHeightUnit && {
        minHeight: props.data.minHeight + '' + props.data.minHeightUnit
      }),
    ...(props.data.minHeight &&
      !props.data.minHeightUnit && {
        minHeight: props.data.minHeight
      }),
    ...(props.data.maxWidth &&
      props.data.maxWidthUnit && {
        maxWidth: props.data.maxWidth + '' + props.data.maxWidthUnit
      }),
    ...(props.data.maxWidth &&
      !props.data.maxWidthUnit && {
        maxWidth: props.data.maxWidth
      }),
    ...(props.data.minWidth &&
      props.data.minWidthUnit && {
        minWidth: props.data.minWidth + '' + props.data.minWidthUnit
      }),
    ...(!props.data.minWidthUnit && {
      minWidth: props.data.minWidth
    }),
    fontSize: `${props.styles.fontSize}px`,
    borderRadius: `${props.styles.borderRadius}px`,
    paddingTop: `${props.styles.paddingTop}px`,
    paddingRight: `${props.styles.paddingRight}px`,
    paddingBottom: `${props.styles.paddingBottom}px`,
    paddingLeft: `${props.styles.paddingLeft}px`,
    borderTop: `${props.styles.borderTop}px solid #333`,
    borderRight: `${props.styles.borderRight}px solid #333`,
    borderBottom: `${props.styles.borderBottom}px solid #333`,
    borderLeft: `${props.styles.borderLeft}px solid #333`,
    marginTop: `${props.styles.marginTop}px`,
    marginRight: `${props.styles.marginRight}px`,
    marginBottom: `${props.styles.marginBottom}px`,
    marginLeft: `${props.styles.marginLeft}px`
  };

  removeDeclarationsWithUndefinedValue(style);
  style = useCalculateComponentSize(props.uuid, style) ?? style;

  const classes = props.styles.classes?.join(' ') || '';

  return (
    <div
      ref={ref}
      style={{ ...style, ...parseCustomCss(props.styles.css) }}
      title={props.data.tooltip}
      className={classes}
    >
      <Tabs activeKey={props.data.activeKey}>
        {props.data.title.map((key) => (
          <Tab key={key} eventKey={key} title={key}>
            <SectionWrapper
              section={key}
              uuid={props.uuid}
              type={COMPONENT_TYPES.CONTAINER}
              viewUUID={props.viewUUID}
              parentUUID={props.parentUUID}
              custom_uuid={props.custom_uuid}
            >
              <div style={{ minHeight: '100px' }}>
                <ComponentsRenderer
                  section={key}
                  viewUUID={props.viewUUID}
                  parentUUID={props.uuid}
                  custom_uuid={props.custom_uuid}
                  new_custom_uuid={props.new_custom_uuid}
                />
              </div>
            </SectionWrapper>
          </Tab>
        ))}
      </Tabs>
    </div>
  );
}

export default memo(forwardRef(TabsComponent));
export * from './manifest';
export * from './template';
