import React, {
  MouseEvent as ReactMouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useState
} from 'react';
import ReactDOM from 'react-dom';
import { useLocation } from 'react-router-dom';
import { openMenuEvent } from 'web_ui/folders/draggable_resource/folder';

export type ContextMenuProps = {
  elementRef?: React.RefObject<any>;
  offsetX?: number;
  offsetY?: number;
  children?: ReactNode;
  fromIframe?: boolean;
};

function ContextMenu(props: ContextMenuProps) {
  let appRoot = document.getElementById('contextmenu') as HTMLElement;
  if (appRoot == null) {
    appRoot = document.getElementById('root') as HTMLElement;
  }
  const [showMenu, setShowMenu] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const location = useLocation();
  const iframeHeight = parseInt(location.search.split('&')[0].split('=')[1]);
  const iframeWidth = parseInt(location.search.split('&')[1]?.split('=')[1]);

  const close = useCallback(
    (ev: CustomEvent | MouseEvent) => {
      if (props.elementRef == null || props.elementRef.current == null) return;

      if ('detail' in ev && ev.detail && ev.detail.ref === props.elementRef.current) return;

      setShowMenu(false);
      document.removeEventListener('click', close as EventListener);
      document.removeEventListener('contextmenu_close', close as EventListener);
    },
    [props.elementRef]
  );

  const closeOtherContextMenus = useCallback(() => {
    const event = new CustomEvent('contextmenu_close', {
      detail: {
        ref: props.elementRef?.current
      }
    });

    document.dispatchEvent(event);
  }, [props.elementRef]);

  const open = useCallback(
    (ev: MouseEvent) => {
      closeOtherContextMenus();
      ev.preventDefault();
      ev.stopPropagation();

      if (props.fromIframe) {
        const openIframeMenuEvent = new CustomEvent('iframeContextMenuOpen');
        window.dispatchEvent(openIframeMenuEvent);
      }

      const offsetX = ev.pageX;
      const offsetY = ev.pageY;

      setPosition({
        x: offsetX > iframeWidth - 250 ? offsetX - 240 : offsetX,
        y: offsetY > iframeHeight - 250 ? offsetY - 240 : offsetY
      });

      // overwrite for correct value
      if (location && location.pathname && location.pathname.includes('schema')) {
        setPosition({
          x: ev.clientX - 65,
          y: ev.clientY - 65
        });
      }

      setShowMenu(true);

      window.dispatchEvent(openMenuEvent);

      window.addEventListener('click', close as EventListener);
      document.addEventListener('click', close as EventListener);
      document.addEventListener('contextmenu_close', close as EventListener);
    },
    [close, closeOtherContextMenus]
  );

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (props.elementRef?.current && !props.elementRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    },
    [props.elementRef]
  );

  useEffect(() => {
    if (showMenu) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showMenu, handleClickOutside]);

  useEffect(() => {
    const handleMenuOpen = () => {
      setShowMenu(false);
    };

    window.addEventListener('menuOpen', handleMenuOpen);

    return () => {
      window.removeEventListener('menuOpen', handleMenuOpen);
    };
  }, []);

  useEffect(() => {
    if (props.elementRef == null || props.elementRef.current == null) return;

    const node = props.elementRef.current;

    node.addEventListener('contextmenu', open);

    return () => {
      node.removeEventListener('contextmenu', open);
      document.removeEventListener('click', close as EventListener);
      document.removeEventListener('contextmenu_close', close as EventListener);
    };
  }, [close, open, props.elementRef]);

  function handleOnMouseDown(event: ReactMouseEvent<HTMLUListElement>) {
    event.stopPropagation();
  }

  if (!showMenu || !props.elementRef || !props.elementRef.current) return null;

  return ReactDOM.createPortal(
    <ul
      className={`dropdown-menu ${props.children ? 'show' : 'hidden'}`}
      style={{
        position: 'absolute',
        zIndex: 4,
        left: `${position.x + (props.offsetX ? props.offsetX : 0)}px`,
        top: `${position.y + (props.offsetY ? props.offsetY : 0)}px`
      }}
      onMouseDown={handleOnMouseDown}
    >
      {props.children}
    </ul>,
    appRoot
  );
}

export default ContextMenu;
