import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { DesignerMode, InterfaceStudioState } from '.';
import { ViewUUID, Editor } from '../../types';
import { setInitialDesignerState } from './actions/designerStudio';

type StudioUpdaterProps = {
  module_id: ViewUUID;
  view_id: ViewUUID;
  editor: keyof typeof Editor | null;
  state: InterfaceStudioState;
  initialTab: DesignerMode;
};

function StoreUpdater(props: StudioUpdaterProps) {
  const dispatch = useDispatch();

  useEffect(() => {
    const state: InterfaceStudioState = {
      ...props.state,
      studio: {
        ...props.state.studio,
        designerMode: props.initialTab,
        module_id: props.module_id,
        view_id: props.view_id,
        editor: props.editor,
        selectedComponent: { uuid: null },
        selectedView: props.view_id,
        viewportMode: props.state.studio?.viewportMode ?? 'desktop',
        editMode: props.state.studio?.editMode ?? true
      }
    };

    dispatch(setInitialDesignerState(state));
  }, [dispatch, props.editor, props.initialTab, props.module_id, props.state, props.view_id]);

  return null;
}

export default StoreUpdater;
