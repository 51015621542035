import React, { ReactElement, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import 'highlight.js/styles/night-owl.css';
import { CrudPage } from '../../../../../../modules/logic_builder/types';
import Icon from '../../../../../../web_ui/icon';
import PageEditor from './components/page_edition';
import { CrudData } from '../index';
import styles from './styles.module.css';
import { t } from 'i18next';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';

type CrudPageInstanceProps = {
  page: CrudPage;
  crudData: CrudData;
  onChange: (crudData: CrudData) => void;
  onDelete: () => void;
};

const API_URL = process.env.REACT_APP_API_URL;

export function CrudPageInstance(props: CrudPageInstanceProps) {
  const [showEdit, setShowEdit] = useState(false);

  const handleSave = (newPage: CrudPage) => {
    const allPages = props.crudData.crudPages;
    const index = allPages.findIndex((item) => item.uuid === props.page.uuid);

    allPages[index] = newPage;

    const crud = {
      ...props.crudData,
      crudPages: allPages
    };

    props.onChange(crud);
  };

  const thumbnailSource = (templateName: string, isNative: boolean, thumbnail: string) => {
    if (thumbnail) {
      return `${API_URL}${thumbnail}`;
    } else if (isNative) {
      return `/assets/templates/${templateName.toLowerCase().replaceAll(' ', '_')}.png`;
    } else {
      return `/assets/default.png`;
    }
  };

  const displayingBadge = (): ReactElement<any, any>[] | undefined => {
    const arrAux = [];
    if (props.page.allowReadMulti) {
      arrAux.push(<div className={styles.footerTag}>{'Read Many'}</div>);
    }

    if (props.page.allowCreateOne) {
      arrAux.push(<div className={styles.footerTag}>{'Create One'}</div>);
    }

    if (props.page.allowReadOne) {
      arrAux.push(<div className={styles.footerTag}>{'Read One'}</div>);
    }

    if (props.page.allowDeleteOne) {
      arrAux.push(<div className={styles.footerTag}>{'Delete One'}</div>);
    }

    if (props.page.allowUpdateOne) {
      arrAux.push(<div className={styles.footerTag}>{'Update One'}</div>);
    }
    return arrAux;
  };

  return (
    <>
      <div
        id="list-group"
        key={props.page.uuid}
        className={`bg-body-tertiary border ${styles.PagesWrapper}`}
      >
        <div id={props.page.uuid} className={`${styles.PagesDescription}`}>
          <div className={styles.CodeFunctionDefinition}>{props.page.name}</div>
          <div style={{ marginLeft: '30px', fontWeight: 'normal' }}>Route: {props.page.route}</div>
        </div>
        <div>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}></div>
          <div className={styles.wrapperIcons}>
            {displayingBadge()?.map((item, index) => {
              return <React.Fragment key={index}>{item}</React.Fragment>;
            })}
            <div className={styles.BadgeNew}>{t('New')}</div>
            <HelpPopover
              placement={'top'}
              helpBoxProps={{
                title: t('editButton')!
              }}
            >
              <div
                id={`editButton-${props.page.name}`}
                className={`bg-body-tertiary shadow-sm ${styles.itemIcons}`}
                onClick={() => setShowEdit(true)}
              >
                <Icon iconName="pen-to-square" extraProps="text-secondary h5 pt-2" />
              </div>
            </HelpPopover>
            <HelpPopover
              placement={'top'}
              helpBoxProps={{
                title: t('Delete')!
              }}
            >
              <div
                id={`deleteButton-${props.page.name}`}
                className={`bg-body-tertiary shadow-sm ${styles.itemIcons}`}
                onClick={props.onDelete}
              >
                <Icon iconName="trash" extraProps="text-secondary h5 pt-2" />
              </div>
            </HelpPopover>
          </div>
        </div>
      </div>
      <PageEditor
        currentPage={props.page}
        onSave={handleSave}
        showDialog={showEdit}
        dialogOnClose={() => setShowEdit(false)}
        masterEndpoints={props.crudData.masterEndpoints}
        detailEndpoints={props.crudData.detailEndpoints}
      />
    </>
  );
}
