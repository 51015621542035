import React, { forwardRef, memo, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EnumColumnID, TableUUID } from '../../../types';
import { setSelectedConnector } from '../../store/actions/studio';
import { DatabaseStudioState } from '../../store';
import EnumEditor from '../../components/enum_editor';
import styles from './styles.module.css';
import { sleep } from 'utils/utils';
import { WorkboardContext } from 'web_ui/workboard';

/**  enum data specification, because in its
 *   definition this attribute is generic
 */
export type EnumData = {
  name: string;
  description: string;
  columns: EnumColumnID[];
};

export type EnumFrameProps = {
  /** enum identifier */
  uuid: TableUUID;
  /** enum content data: ex: (name, description, etc) */
  content: {
    data: EnumData;
  };
  /** Number to position the frame on the `x` axis of the workboard */
  posX: number;
  /** Number to position the frame on the `y` axis of the workboard */
  posY: number;
};

const BASE_ENUM_EDITOR_DIMENSIONS = {
  width: 520,
  height: 520
};

function EnumFrame(props: EnumFrameProps, ref: React.Ref<any>) {
  // context enum columns array
  const enumColumns = useSelector((state: DatabaseStudioState) => state.enum_columns);
  // Ordered list of enum columns.
  const enumIdsList: string[] = useSelector(
    (state: DatabaseStudioState) => state.enums[props.uuid].content.data.columns
  );
  // context selected frame (ex: Table, enum, etc...)
  const selectedFrame = useSelector((state: DatabaseStudioState) => state.studio.selectedFrame);
  // reference of enum wrapper component
  const enumRef = useRef<HTMLElement>(null);
  const workboard = useContext(WorkboardContext);
  // boolean used to show or hide the Table Editor component
  const [showEditor, setShowEditor] = useState<boolean>(false);
  const [overflow, setOverflow] = useState<{ overflowX: number; overflowY: number }>();
  const dispatch = useDispatch();

  // Show enum editor when enum is created
  useEffect(() => {
    // Set this event to close other editors
    const onEditorOpenEvent = new Event('openFrameEditor');
    async function showEditorOnEnumCreate() {
      if (selectedFrame === props.uuid) {
        // Do this so the editor open when creating a table with click
        await sleep(10);
        // Dispatch this event to close other editors
        document.dispatchEvent(onEditorOpenEvent);
        checkEnumEditorOverflow();
        setShowEditor(true);
      }
    }
    showEditorOnEnumCreate();
  }, []);

  // Open the enumfast edit window
  function openTableEditor() {
    if (props.uuid) {
      const frameElement = document.getElementById(props.uuid ? props.uuid : '');
      if (frameElement) frameElement.style.zIndex = '4';
      checkEnumEditorOverflow();
      setShowEditor(true);
    }
  }

  /***  Event Listener that adds a screen-wide event so that
   *  Used to show and hide the table editor and manage the z-index order.
   * */
  useEffect(() => {
    // if the Frame Editor is opened...
    if (showEditor) {
      // if is open add listener, if not, remove it
      document.addEventListener('click', handleManageEditors);
      // This listener prevents opening more than one editor
      // when the editor opens after a table is created
      document.addEventListener('openFrameEditor', handleShowEditor);
    } else {
      document.removeEventListener('click', handleManageEditors);
      document.removeEventListener('openFrameEditor', handleShowEditor);
    }

    function handleShowEditor(event: Event) {
      setShowEditor(false);
      setOverflow(undefined);
    }

    /***  Event Listener that adds a screen-wide event so that
     *    the editor closes
     *    if it is outside the editing area
     */
    function handleManageEditors(event: Event) {
      const target = event.target || event.currentTarget;
      dispatch(setSelectedConnector(null));
      // if click was in a frame editor, do nothing, if not, close editor
      if (enumRef.current?.contains(target as Node) || document.getElementById('advancedEditor')) {
        event?.stopPropagation();
      } else {
        const frameElement = document.getElementById(selectedFrame ? selectedFrame : '');
        if (frameElement) frameElement.style.zIndex = '2';
        setOverflow(undefined);
        setShowEditor(false);
      }
    }
  }, [dispatch, props.uuid, showEditor]);

  function getEditorScale() {
    if (workboard.scale >= 1) return 1 - Math.abs(workboard.scale - 1) / 2;
    else return 1 / workboard.scale;
  }

  function checkEnumEditorOverflow() {
    if (!workboard.workboardRef || !workboard.workboardRef.current || !enumRef.current) return;

    const workboardRef = workboard.workboardRef.current;
    const scale = workboard.scale;

    const modifiedScale = getEditorScale();

    // Calculate table editor height.
    //! If the dimensions of the enum editor changes then update BASE_ENUM_EDITOR_DIMENSIONS.
    const enumEditorHeight = BASE_ENUM_EDITOR_DIMENSIONS.height * scale * modifiedScale;

    // Check whether table editor overflows. Subtract 60 because navbar has height of 60px.
    if (
      workboardRef.offsetHeight - (enumRef.current.getBoundingClientRect().top - 60) <
      enumEditorHeight
    ) {
      const overflowY =
        (workboardRef.offsetHeight -
          (enumRef.current.getBoundingClientRect().top - 60) -
          enumEditorHeight) /
        scale;
      setOverflow({ overflowX: 0, overflowY: overflowY });
    } else {
      setOverflow({ overflowX: 0, overflowY: 0 });
    }
  }

  return (
    <section
      ref={enumRef}
      className={
        selectedFrame === props.uuid
          ? `${styles.tableFrameContainer} ${styles.selectedEnum}`
          : `${styles.tableFrameContainer}`
      }
      id={props.content.data.name}
      onDoubleClick={() => openTableEditor()}
    >
      <table
        className={`border border-3 border-body  ${styles.enumFrame}`}
        title={props.content.data.description}
      >
        <thead ref={ref}>
          <tr className={`text-body-emphasis bg-danger-subtle border-bottom ${styles.enumTitle}`}>
            <th colSpan={20}>{props.content.data.name}</th>
          </tr>
        </thead>
        <tbody className="bg-body">
          {enumIdsList.map((columnID: EnumColumnID) => {
            return (
              enumColumns[columnID] && (
                <tr key={enumColumns[columnID].id} className={`text-body ${styles.tableRow}`}>
                  <td key={enumColumns[columnID].id} style={{ minWidth: '150px' }}>
                    {enumColumns[columnID].literalValue}
                  </td>
                  <td key={enumColumns[columnID].literalValue} style={{ minWidth: '150px' }}>
                    {enumColumns[columnID].ordinalValue}
                  </td>
                </tr>
              )
            );
          })}

          {enumIdsList.length === 0 && (
            <tr key={'columnId'} className={`${styles.newTableRow}`}>
              <td id="bodyMessage" style={{ minWidth: '245px', userSelect: 'none' }}>
                {'Click to create new column'}
              </td>
            </tr>
          )}
        </tbody>
        <tfoot></tfoot>
      </table>
      {showEditor && overflow != null && (
        <EnumEditor
          enumUUID={props.uuid}
          keyList={Object.values(enumColumns)}
          overflow={overflow}
          editorScale={getEditorScale()}
        />
      )}
    </section>
  );
}

export default memo(forwardRef(EnumFrame));
