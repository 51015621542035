import React, { MouseEvent, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export type ConfirmationProps = {
  show: boolean;
  message: string;
  confirmationLabel?: string;
  confirmationVariant?: string;
  cancelLabel?: string;
  cancelVariant?: string;
  itemName?: string;
  showItemNameInput?: boolean;
  onConfirmation: (e: any) => void;
  onCancel: () => void;
  onClose: () => void;
  isLoading?: boolean;
};

function Confirmation(props: ConfirmationProps) {
  const { t } = useTranslation();
  const confirmationLabel = props.confirmationLabel ?? t('Confirm');
  const confirmationVariant = props.confirmationVariant ?? 'danger';
  const cancelVariant = props.cancelVariant ?? 'secondary';
  const cancelLabel = props.cancelLabel ?? t('modeler.Cancel');
  const [itemName, setItemName] = useState<string>('');

  function preventPropagation(event: MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
  }

  useEffect(() => {
    if (!props.show) return;
    setItemName('');
  }, [props.show]);

  const Loading = () => <i className="fas fa-spinner fa-spin"></i>;

  return (
    <div onMouseDown={preventPropagation} onClick={preventPropagation}>
      <Modal
        centered
        show={props.show}
        onHide={props.onClose}
        style={{ background: 'rgba(0, 0, 0, 0.35)' }}
      >
        <Modal.Header
          closeButton
          style={{
            border: '2px solid black',
            borderBottom: 'none'
          }}
        >
          <Modal.Title style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {props.message}
            {!props.showItemNameInput && props.itemName && (
              <span className="d-block fs-6 text-body-emphasis">{props.itemName}</span>
            )}
          </Modal.Title>
        </Modal.Header>
        {props.showItemNameInput && props.itemName && (
          <Modal.Body>
            <Form.Group>
              <Form.Label style={{ wordBreak: 'break-all' }}>
                {t('modeler.ConfirmInput', {
                  name: props.itemName
                })}
              </Form.Label>
              <Form.Control
                type="text"
                id="formProjectName"
                onChange={(e) => setItemName(e.target.value)}
                value={itemName}
                isInvalid={itemName !== props.itemName}
                required
              />
            </Form.Group>
          </Modal.Body>
        )}
        <Modal.Footer
          style={{
            border: '2px solid black',
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
            borderTop: 'none'
          }}
        >
          {/* delete in customComponent */}
          <Button
            id="confirmButton"
            variant={confirmationVariant}
            onClick={() => {
              props.onConfirmation(itemName);
            }}
            disabled={props.isLoading || (props.showItemNameInput && itemName !== props.itemName)}
          >
            {props.isLoading ? <Loading /> : confirmationLabel}
          </Button>
          <Button
            id={'cancelButton'}
            variant={cancelVariant}
            onClick={props.onCancel}
            disabled={props.isLoading}
          >
            {cancelLabel}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Confirmation;
