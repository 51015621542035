import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { CssStylesService } from 'modules/designer/services';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';
import { CssClass } from 'web_ui/workboard/sidebar/controls/CssClassesControl/types';
import { CssClassDialog } from './css_class_dialog';
import Confirmation from 'web_ui/confirmation';
import { useDispatch, useSelector } from 'react-redux';
import { InterfaceStudioState } from '../../store';
import { deleteCssClass, updateCssClassesOrder } from '../../store/actions/cssClasses';
import { DragNDrop, ItemsWrapperProps, RenderItemProps } from 'web_ui/drag_n_drop';
import { verticalListSortingStrategy } from '@dnd-kit/sortable';

export function CssClassToolbar() {
  const { app_id } = useParams();
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const [showCreateCssClassDialog, setShowCreateCssClassDialog] = useState(false);
  const [showEditCssClassDialog, setShowEditCssClassDialog] = useState<CssClass>();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<{
    id: string;
    name: string;
  }>();
  const globalCssClasses = useSelector((state: InterfaceStudioState) => state.globalCssClasses);
  const dispatch = useDispatch();

  function getClassName(id?: string): string {
    if (id) {
      const cssClass = globalCssClasses.find((css) => css.id === id);
      return (cssClass?.cssName ?? '') + (cssClass?.cssSelector ?? '');
    }
    return '';
  }

  function handleShowEditDialog(cssId: string) {
    if (!cssId) return;
    const cssClass = globalCssClasses.find((cssClass) => cssClass.id === cssId);
    setShowEditCssClassDialog(cssClass);
  }

  function handleCloseEditDialog() {
    setShowEditCssClassDialog(undefined);
  }

  function handleShowCreateDialog() {
    setShowCreateCssClassDialog(true);
  }

  function handleCloseCreateDialog() {
    setShowCreateCssClassDialog(false);
  }

  const handleCloseDeleteConfirmation = () => {
    setShowDeleteConfirmation({ id: '', name: showDeleteConfirmation?.name ?? '' });
  };

  const handleDelete = async () => {
    if (!app_id) return;
    if (showDeleteConfirmation == null) return;

    await CssStylesService.deleteCssClass(app_id, showDeleteConfirmation.id).then(() => {
      dispatch(deleteCssClass(showDeleteConfirmation.id));
      handleCloseDeleteConfirmation();
    });
  };

  const getFilteredCssClasses = (): CssClass[] => {
    return globalCssClasses.filter((cssClass) => {
      return (
        !searchText ||
        cssClass.cssName.toLocaleLowerCase().includes(searchText.trim().toLocaleLowerCase())
      );
    });
  };

  const ItemsWrapper = ({ children }: ItemsWrapperProps) => {
    return <div className={`${styles.paneToolbarContent}`}>{children}</div>;
  };

  const RenderItem = ({ id, isDragging }: RenderItemProps) => {
    const cssClass = globalCssClasses.find((cssClass) => cssClass.id === id);
    if (!cssClass) return <></>;
    return (
      <div key={cssClass.id} style={{ padding: '0.1rem', paddingBottom: '0.5rem' }}>
        <div className={`${styles.cssClassInstance}`}>
          <div className={`${styles.cssClassInstanceSelector}`}>
            {cssClass.cssName}
            {cssClass.cssSelector}
          </div>
          <div className={`${styles.cssClassInstanceActions}`}>
            <div
              onClick={() => handleShowEditDialog(cssClass.id ?? '')}
              onMouseDown={(e) => e.stopPropagation()}
            >
              <i className="fa-solid fa-pen-to-square"></i>
            </div>
            <div
              onClick={() =>
                setShowDeleteConfirmation({
                  id: cssClass.id ?? '',
                  name: getClassName(cssClass.id)
                })
              }
              onMouseDown={(e) => e.stopPropagation()}
            >
              <i className="fa-solid fa-trash"></i>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const updateCssClassOrder = (
    newItems: any[],
    movedId: string,
    oldIndex: number,
    newIndex: number
  ) => {
    if (!app_id) return;
    const orderedIds = newItems.map((item) => item.id);
    dispatch(updateCssClassesOrder(app_id, movedId, oldIndex, newIndex, orderedIds));
  };

  return (
    <>
      <div className="input-group input-group-sm p-2 pt-0">
        <Form.Group className="position-relative w-100">
          <Form.Control
            value={searchText}
            id="css-classes-search-field"
            className="ps-4"
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search..."
          />
          {searchText.length === 0 ? (
            <i
              className={`${styles.searchIcon} position-absolute fa-solid fa-magnifying-glass top-12 right-12`}
            ></i>
          ) : (
            <i
              role="button"
              onClick={() => setSearchText('')}
              className={`${styles.searchIcon} position-absolute fa-solid fa-xmark top-12 right-12 `}
            ></i>
          )}
        </Form.Group>
      </div>
      <div className={`mt-2 p-2 pt-0 ${styles.viewHeader}`}>
        <div className={`${styles.viewHeaderCollapse}`} aria-controls="collapse-custom-comps">
          <div className={styles.viewHeaderName}>{t('designer.cssClass.CssClasses')}</div>
        </div>
        <Button id="createButton" className="btn-sm" onClick={handleShowCreateDialog}>
          {t('designer.themes.Create')}
        </Button>
      </div>
      <DragNDrop
        items={globalCssClasses.map((cssClass) => ({ id: cssClass.id ?? '', cssClass }))}
        filteredItems={getFilteredCssClasses().map((cssClass) => ({
          id: cssClass.id ?? '',
          cssClass
        }))}
        updateOrder={updateCssClassOrder}
        RenderItem={RenderItem}
        ItemsWrapper={ItemsWrapper}
        sortingStrategy={verticalListSortingStrategy}
      />
      <CssClassDialog
        editMode={false}
        show={showCreateCssClassDialog}
        onClose={handleCloseCreateDialog}
      />
      <CssClassDialog
        editMode={true}
        show={showEditCssClassDialog != null}
        classInfo={showEditCssClassDialog}
        onClose={handleCloseEditDialog}
      />
      <Confirmation
        show={!!showDeleteConfirmation?.id}
        message={
          t('designer.cssClass.DeleteConfirmation') + ` (${showDeleteConfirmation?.name ?? ''})`
        }
        onConfirmation={handleDelete}
        onCancel={() => handleCloseDeleteConfirmation()}
        onClose={() => handleCloseDeleteConfirmation()}
        confirmationLabel={t('Confirm') ?? 'Confirm'}
        cancelLabel={t('Cancel') ?? 'Cancel'}
      />
    </>
  );
}
