import { ComponentUUID, ViewUUID, fixPostion } from 'modules/designer/types';
import React, { useState } from 'react';
import { SideMenuData, SideMenuStyle } from '../..';
import * as Icons from 'react-icons/fa';
import ListItem from './listItem';

export type CollapseMenuProps = {
  uuid: ComponentUUID;
  viewUUID: ViewUUID;
  parentUUID?: ComponentUUID;
  data: SideMenuData;
  styles: SideMenuStyle;
  middleMenu?: boolean;
  iconStyles?: { [key: string]: string };
};

function CollapseMenu(props: CollapseMenuProps) {
  const [showNested, setShowNested] = useState<any>({});

  function toggleNested(key: string) {
    setShowNested({ ...showNested, [key]: !showNested[key] });
  }

  const liStyle = {
    backgroundColor: `${props.styles.staticColor}`,
    color: `${props.styles.color}`
  };

  return (
    <div className="d-flex flex-grow-1 h-100 flex-column">
      <div className="mt-2">
        {props.data.links.children[props.uuid] && (
          <>
            <ul className={`list-group list-group-flush`}>
              {props.data.links.children[props.uuid]
                .filter((key) => props.data.links.items[key].fixed === fixPostion.TOP)
                .map((key: string) => {
                  const iconName = props.data.links.items[key].icon;
                  const IconDiv = iconName ? Icons[iconName] : null;
                  return (
                    <ListItem
                      iconStyle={props.iconStyles ?? {}}
                      liStyle={liStyle}
                      linkText={props.data.linkText}
                      linkUUID={key}
                      links={props.data.links}
                      showNested={showNested}
                      toggleNested={toggleNested}
                      stylesItem={props.styles}
                      key={key}
                      IconDiv={IconDiv}
                      btnOrientation={props.data.buttonOrientation}
                    ></ListItem>
                  );
                })}
            </ul>
            {props.data.links.children[props.uuid].filter(
              (key) => props.data.links.items[key].fixed === fixPostion.TOP
            ).length > 0 && <hr style={{ color: props.styles.color }} />}
          </>
        )}
      </div>
      <ul className={`mb-auto list-group list-group-flush`}>
        {props.data.links.children[props.uuid]
          .filter(
            (key) =>
              props.data.links.items[key].fixed !== fixPostion.TOP &&
              props.data.links.items[key].fixed !== fixPostion.BOTTOM
          )
          .map((key: string) => {
            const iconName = props.data.links.items[key].icon;
            const IconDiv = iconName ? Icons[iconName] : null;
            return (
              <ListItem
                iconStyle={props.iconStyles ?? {}}
                liStyle={liStyle}
                linkText={props.data.linkText}
                linkUUID={key}
                links={props.data.links}
                showNested={showNested}
                toggleNested={toggleNested}
                stylesItem={props.styles}
                key={key}
                IconDiv={IconDiv}
                btnOrientation={props.data.buttonOrientation}
              ></ListItem>
            );
          })}
      </ul>
      <div className="mb-2">
        {props.data.links.children[props.uuid] && (
          <>
            {props.data.links.children[props.uuid].filter(
              (key) => props.data.links.items[key].fixed === fixPostion.BOTTOM
            ).length > 0 && <hr style={{ color: props.styles.color }} />}
            <ul className={`list-group list-group-flush`}>
              {props.data.links.children[props.uuid]
                .filter((key) => props.data.links.items[key].fixed === fixPostion.BOTTOM)
                .map((key: string) => {
                  const iconName = props.data.links.items[key].icon;
                  const IconDiv = iconName ? Icons[iconName] : null;
                  return (
                    <ListItem
                      iconStyle={props.iconStyles ?? {}}
                      liStyle={liStyle}
                      linkText={props.data.linkText}
                      linkUUID={key}
                      links={props.data.links}
                      showNested={showNested}
                      toggleNested={toggleNested}
                      stylesItem={props.styles}
                      key={key}
                      IconDiv={IconDiv}
                      btnOrientation={props.data.buttonOrientation}
                    ></ListItem>
                  );
                })}
            </ul>
          </>
        )}
      </div>
    </div>
  );
}

export default CollapseMenu;
