import React, { useState } from 'react';
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { ModelGeneratorAutomationState } from '.';

type Step1Props = {
  automationState: ModelGeneratorAutomationState | undefined;
  handleUpdatedRequest: (name: string) => void;
  isLoading: boolean;
};

export function Step1({ automationState, handleUpdatedRequest, isLoading }: Step1Props) {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.StepWrapper}>
        <div className={styles.Title}>{t('automation.DBEntityGen')}</div>
        <div style={{ maxWidth: '100%' }}>
          <Form.Group className="mb-3" style={{ maxWidth: '100%', width: '100%' }}>
            <Form.Label>{t('automation.Prompt')}</Form.Label>
            <Form.Control
              id="descriptionField"
              as="textarea"
              rows={3}
              maxLength={516}
              placeholder={t('automation.DBAIPromptPlachld') ?? ''}
              className="ml-3"
              onChange={(e) => handleUpdatedRequest(e.target.value)}
              value={automationState?.request}
              disabled={isLoading}
            />
            <Form.Text id="requestHelpBlock" style={{ fontStyle: 'italic' }} muted>
              {t('automation.DBAIPromptDescr')}
            </Form.Text>
          </Form.Group>
        </div>
      </div>
    </>
  );
}
