import React, { Component, useEffect, useState } from 'react';
import { COMPONENTS_MANIFEST, COMPONENT_TYPES } from '../../exocode_components';
import { ComponentGroups, GROUPS_MANIFEST } from 'modules/designer/types';
import styles from './styles.module.css';
import { InterfaceStudioState } from '../../store';
import { useSelector } from 'react-redux';
import { Collapse, Form } from 'react-bootstrap';
import Icon from 'web_ui/icon';
import { useTranslation } from 'react-i18next';
import ComponentItem from './component_item';
import ComponentItemDragger from './componentItemDragger';
import { LocalStorageManager } from 'utils/localstorage';
import { useParams } from 'react-router-dom';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import { WALKTHROUGH_STEPS_ELEMENTS } from 'web_ui/walkthrough/constants';

function ComponentsToolbar() {
  const [searchText, setSearchText] = useState('');
  const [layoutViewType, setLayoutViewType] = useState<string>();
  const selectedView = useSelector((state: InterfaceStudioState) => state.studio.selectedView);
  const views = useSelector((state: InterfaceStudioState) => state.views);
  const [colapsedGroups, setColapsedGroups] = useState<string[]>([]);
  const [recentComponents, setRecentComponents] = useState<string[]>([]);
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const { app_id, module_id } = useParams();

  const getInitialStateOpenGroups = (): string[] => {
    const copyState = LocalStorageManager.getValueLocalStorageState(app_id!, module_id!);

    if (copyState[module_id!] && copyState[module_id!].interface.collapsedGroups) {
      return copyState[module_id!].interface.collapsedGroups;
    } else {
      return [];
    }
  };

  const [openGroups, setOpenGroups] = useState<string[]>(getInitialStateOpenGroups());

  const { t } = useTranslation();
  const MAX_ITEMS_TO_SHOW = 6;

  useEffect(() => {
    function updateRecentComponentsState() {
      const storedRecentComponts = localStorage.getItem('recentComponents');
      if (storedRecentComponts) {
        setRecentComponents(JSON.parse(storedRecentComponts));
      }
    }

    window.addEventListener('storage', updateRecentComponentsState);
    updateRecentComponentsState();

    return () => {
      // When the component unmounts remove the event listener
      window.removeEventListener('storage', updateRecentComponentsState);
    };
  }, []);

  useEffect(() => {
    if (selectedView && views && views[selectedView]) {
      const viewType = views[selectedView].type;
      setLayoutViewType(viewType);
    }
  }, [selectedView, views]);

  // Used to colapse all the groups on page start
  useEffect(() => {
    const componentGroups = Object.keys(ComponentGroups);
    componentGroups.push('RECENT');
    //setOpenGroups(componentGroups);
  }, []);

  // function to open or close the group lists
  function colapseGroup(group: string) {
    if (openGroups.includes(group)) {
      setOpenGroups(openGroups.filter((savedGroup) => savedGroup !== group));
    } else {
      setOpenGroups([...openGroups, group]);
    }
  }

  function colapseGroupComponents(group: string) {
    if (colapsedGroups.includes(group)) {
      setColapsedGroups(colapsedGroups.filter((savedGroup) => savedGroup !== group));
    } else {
      setColapsedGroups([...colapsedGroups, group]);
    }
  }

  function filterComponents(index: string, group: string) {
    // Show outlet component on Layout view only.
    if (layoutViewType !== 'LAYOUT' && index === COMPONENT_TYPES.OUTLET) return false;
    // Custom components are listed below regular components.
    if (index === COMPONENT_TYPES.CUSTOM) return false;
    // Text filter and group selection.

    return (
      (!searchText ||
        COMPONENTS_MANIFEST[index].name
          .toLocaleLowerCase()
          .includes(searchText.toLocaleLowerCase())) &&
      !['ROW', 'COLUMN'].includes(COMPONENTS_MANIFEST[index].type) &&
      COMPONENTS_MANIFEST[index].group === group
    );
  }

  // when the search text was active will open the group automatically
  useEffect(() => {
    const componentGroups = Object.keys(ComponentGroups);
    setOpenGroups(componentGroups);
  }, [searchText]);

  useEffect(() => {
    squareMinus(false);
  }, []);

  function squareMinus(value: boolean) {
    if (value) {
      setOpenGroups([]);
    } else {
      const componentGroups = Object.keys(ComponentGroups);
      componentGroups.push('RECENT');
      setOpenGroups(componentGroups);
    }
    setCollapsed(value);
  }

  useEffect(() => {
    const copyState = LocalStorageManager.getValueLocalStorageState(app_id!, module_id!);

    copyState[module_id!] = {
      ...copyState[module_id!],
      interface: {
        ...copyState[module_id!].interface,
        collapsedGroups: openGroups
      }
    };

    LocalStorageManager.setValueLocalStorageState(app_id!, copyState);
  }, [openGroups]);

  return (
    <>
      <div className="input-group input-group-sm p-2 pt-0">
        <Form.Group className="position-relative ">
          <Form.Control
            value={searchText}
            id="searchField"
            className="ps-4"
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search..."
          />
          {searchText.length === 0 ? (
            <i
              className={`${styles.searchIcon} position-absolute fa-solid fa-magnifying-glass top-12 right-12`}
            ></i>
          ) : (
            <i
              role="button"
              onClick={() => setSearchText('')}
              className={`${styles.searchIcon} position-absolute fa-solid fa-xmark top-12 right-12 `}
            ></i>
          )}
        </Form.Group>
      </div>

      <div className={`${styles.viewHeader} ms-2 me-4 d-flex justify-content-end`}>
        {/* Colapse button to open and close the basic elements section */}

        <div
          className={styles.closeColapseIcon}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            squareMinus(!collapsed);
          }}
        >
          <HelpPopover
            helpBoxProps={{
              title: !collapsed ? `${t('CloseAllGroups')}` : `${t('openAllGroups')}`
            }}
            placement="top"
          >
            {!collapsed ? (
              <Icon iconName="square-minus"></Icon>
            ) : (
              <Icon iconName="square-plus"></Icon>
            )}
          </HelpPopover>
        </div>
      </div>

      <div className={`${styles.sectionsWrapper}`}>
        <div
          className={`${styles.componentsWrapper}`}
          id={WALKTHROUGH_STEPS_ELEMENTS['designer-components-wrapper']}
        >
          <div className={`${styles.componentsContainer} p-3 pt-0`}>
            <div id="collapse-basic-comps">
              {recentComponents.length > 0 && (
                <div>
                  <div className={`p-2 ${styles.groupHeaderName}`}>
                    <div
                      className={styles.viewHeaderCollapse}
                      onClick={() => {
                        colapseGroup('RECENT');
                      }}
                      aria-controls="collapse-basic-group-comps"
                      aria-expanded={openGroups.includes('RECENT')}
                    >
                      {openGroups.includes('RECENT') ? (
                        <Icon iconName="chevron-down" extraProps={`${styles.collapseIcon}`} />
                      ) : (
                        <Icon iconName="chevron-right" extraProps={`${styles.collapseIcon}`} />
                      )}
                      <div className={styles.groupHeaderName}>Last used</div>
                    </div>
                  </div>
                  <Collapse in={openGroups.includes('RECENT') ? true : false}>
                    <div className="list-group p-2 pe-0">
                      {/* Default components list */}
                      <div className="row">
                        {recentComponents
                          .slice(0, 3)
                          .reverse()
                          .filter((componentType) =>
                            layoutViewType !== 'LAYOUT'
                              ? componentType !== COMPONENT_TYPES.OUTLET
                              : true
                          )
                          .map((componentType) => (
                            <div
                              key={componentType}
                              className="col-4"
                              style={{ padding: '0.1rem', paddingBottom: '0.5rem' }}
                            >
                              <ComponentItemDragger
                                componentType={COMPONENTS_MANIFEST[componentType]?.type}
                              >
                                <ComponentItem
                                  name={t(
                                    'designer.components.' +
                                      COMPONENTS_MANIFEST[componentType]?.name
                                  )}
                                  type={COMPONENTS_MANIFEST[componentType]?.type}
                                  description={t(
                                    'designer.components.' +
                                      COMPONENTS_MANIFEST[componentType]?.descriptionId
                                  )}
                                  icon={COMPONENTS_MANIFEST[componentType]?.icon}
                                />
                              </ComponentItemDragger>
                            </div>
                          ))}
                      </div>
                    </div>
                  </Collapse>
                </div>
              )}

              {/* Component Groups list */}
              {Object.keys(ComponentGroups).map((group) => {
                return (
                  <div key={group}>
                    <div className={`p-2 ${styles.groupHeaderName}`}>
                      <div
                        className={styles.viewHeaderCollapse}
                        onClick={() => {
                          colapseGroup(group);
                        }}
                        aria-controls="collapse-basic-group-comps"
                        aria-expanded={openGroups.includes(group)}
                      >
                        {openGroups.includes(group) ? (
                          <Icon iconName="chevron-down" extraProps={`${styles.collapseIcon}`} />
                        ) : (
                          <Icon iconName="chevron-right" extraProps={`${styles.collapseIcon}`} />
                        )}
                        <div
                          id={`groupHeader${GROUPS_MANIFEST[group].name}`}
                          className={styles.groupHeaderName}
                        >
                          {GROUPS_MANIFEST[group].name === 'Datas'
                            ? t('data')
                            : GROUPS_MANIFEST[group].name}
                        </div>
                      </div>
                    </div>
                    <Collapse in={openGroups.includes(group) ? true : false}>
                      <div className="list-group p-2 pe-0">
                        {/* Default components list */}
                        <div className="row">
                          {Object.keys(COMPONENTS_MANIFEST)
                            .filter((index) => filterComponents(index, group))
                            .slice(
                              0,
                              colapsedGroups.includes(group)
                                ? Object.keys(COMPONENTS_MANIFEST).length
                                : MAX_ITEMS_TO_SHOW
                            )
                            .map((index) => (
                              <div
                                key={index}
                                className="col-4"
                                style={{ padding: '0.1rem', paddingBottom: '0.5rem' }}
                              >
                                <ComponentItemDragger
                                  componentType={COMPONENTS_MANIFEST[index].type}
                                >
                                  <ComponentItem
                                    name={t(
                                      'designer.components.' + COMPONENTS_MANIFEST[index].name
                                    )}
                                    type={COMPONENTS_MANIFEST[index].type}
                                    description={t(
                                      'designer.components.' +
                                        COMPONENTS_MANIFEST[index].descriptionId
                                    )}
                                    icon={COMPONENTS_MANIFEST[index].icon}
                                  />
                                </ComponentItemDragger>
                              </div>
                            ))}

                          {Object.keys(COMPONENTS_MANIFEST).filter(
                            (index) => COMPONENTS_MANIFEST[index].group === group
                          ).length > 6 && (
                            // t('designer.components.ShowAll')
                            <HelpPopover
                              helpBoxProps={{
                                title: t('designer.components.ShowAll')!
                              }}
                              placement="top"
                            >
                              <div
                                onClick={(e) => {
                                  colapseGroupComponents(group);
                                }}
                                style={colapsedGroups.includes(group) ? { fontSize: '14px' } : {}}
                                className={`${styles.moreButton} w-100 d-flex justify-content-center align-items-center text-center border`}
                              >
                                <Icon
                                  iconName={
                                    !colapsedGroups.includes(group) ? 'ellipsis' : 'chevron-up'
                                  }
                                ></Icon>
                              </div>
                            </HelpPopover>
                          )}
                        </div>
                      </div>
                    </Collapse>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ComponentsToolbar;
