import React from 'react';
import { ModuleDependencyInfo, ModuleInfo } from 'modules/dashboard/types';
import { Button, Form, ListGroup, ListGroupItem, Modal } from 'react-bootstrap';
import styles from './style.module.css';
import { useTranslation } from 'react-i18next';

type Props = {
  onChange: (module: ModuleInfo, checked: boolean) => void;
  show: boolean;
  onClose: () => void;
  submit: () => Promise<void>;
  selectedDependencyModules: ModuleDependencyInfo[];
  appModules: ModuleInfo[];
};

export default function ModalDependedModules(props: Props) {
  const { onChange, selectedDependencyModules, appModules } = props;
  const { t } = useTranslation();

  return (
    <Modal
      show={props.show}
      onHide={props.onClose}
      style={{ background: 'rgba(51, 51, 51, 0.4)' }}
      centered
    >
      <Modal.Header>
        <label>{t('appResume.module.LabelDependedModules')}</label>
      </Modal.Header>
      <Modal.Body>
        {appModules.length > 0 && (
          <ListGroup>
            {appModules.map((module, index) => {
              return (
                <ListGroupItem as="li" className={styles.dependencyItem} key={module.id}>
                  <div className={styles.moduleContainer}>
                    <span title={module.name} className={styles.moduleName}>
                      {module.name}
                    </span>
                    <Form.Check
                      type="switch"
                      id={`switchDep-${index}`}
                      checked={selectedDependencyModules.find((m) => m.id === module.id) != null}
                      onChange={(e) => onChange(module, e.target.checked)}
                    />
                  </div>
                </ListGroupItem>
              );
            })}
          </ListGroup>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          id={'saveButton'}
          onClick={() => {
            props.submit();
          }}
        >
          {t('Create')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
