import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import hljs from 'highlight.js';
import 'highlight.js/styles/night-owl.css';
import java from 'highlight.js/lib/languages/java';
import { useParams } from 'react-router-dom';
import { CrudData } from '..';
import Icon from 'web_ui/icon';
import { functionEditorCrud } from 'web_ui/function_editor/store/types/functions';
import FunctionEditor from './components/function_edition';
import { t } from 'i18next';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import useSession from 'hooks/useSession';
import { ObjectTypeCrud } from 'modules/logic_builder/types';
import CodeEditorModal from 'web_ui/code_editor_modal';
import { CodePreviewType } from 'web_ui/code_editor_modal/editor';

hljs.registerLanguage('java', java);

type CrudFunctionInstanceProps = {
  serviceUuid: string;
  // Apply custom styles.
  className?: string;
  cursor?: string;
  functionInstance: functionEditorCrud; // currentFunction
  crudData: CrudData;
  onChange: (crudData: CrudData) => void;
  objects: ObjectTypeCrud[];
};

export function CrudFunctionInstance(props: CrudFunctionInstanceProps) {
  const [showFunctionEditor, setShowFunctionEditor] = useState(false);
  const [returnNameType, setReturnNameType] = useState<string>('');
  const [functionName, setFunctionName] = useState<string>('');
  const [functionParameters, setFunctionParameters] = useState<string>('');
  const [previewFunctionUuid, setPreviewFunctionUuid] = useState<string>('');
  const [session] = useSession();

  const { app_id, module_id } = useParams();

  async function handleShowFunctionEditorDialog(event: React.MouseEvent<HTMLDivElement>) {
    if (!app_id || !module_id) return;
    event.stopPropagation();
    setShowFunctionEditor(!showFunctionEditor);
  }

  const deletingFunctFromList = (func: functionEditorCrud) => {
    const indexToDelete = props.crudData.functions.indexOf(func);
    const copyData = props.crudData.functions;

    copyData.splice(indexToDelete, 1);

    const data = {
      ...props.crudData,
      functions: copyData
    };

    // overwrite with the func deleted
    props.onChange(data);
  };

  useEffect(() => {
    const copyData = props.crudData.functions;

    copyData
      .filter((f) => f.isNew) // it needs to filter before.
      .forEach((item, index) => {
        item.serviceUuid = props.serviceUuid;
      });

    const crud = {
      ...props.crudData,
      functions: copyData
    };

    props.onChange(crud);
  }, []);

  const returningFunctionName = (currName: string): string => {
    const allFunc = props.crudData.functions;
    const correctFunc = allFunc.findIndex((item) => item.uuid === props.functionInstance.uuid);

    if (allFunc[correctFunc] && allFunc[correctFunc].name) {
      return allFunc[correctFunc].name;
    } else {
      return currName.split('(')[0];
    }
  };

  useEffect(() => {
    const returned = props.functionInstance.functionDefinition;
    const returnName = returned.split(' ');
    setReturnNameType(returnName[0]);
    returnName.shift();
    const functionName = returnName.toString();
    setFunctionName(returningFunctionName(functionName));
    // ?
  }, [props.crudData.functions.find((item) => item.uuid === props.functionInstance.uuid)?.name]);

  function formatCrudType(crudType: string) {
    if (crudType) {
      const words = crudType.split('_').map((word) => word.toLowerCase());

      const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));

      const result = capitalizedWords.join(' ');

      return result;
    }
  }

  useEffect(() => {
    if (props.functionInstance.parameters.length < 0) return;
    const parameters = props.functionInstance.parameters.reduce((acc, param, index, array) => {
      let parameterType = '';
      if (param.type === 'OBJECT') {
        parameterType = props.objects.find((o) => o.uuid === param.objectUuid)?.name ?? '';
      } else {
        parameterType = param.type;
      }
      const parameterDef = `${parameterType} ${param.name}`;
      return index === array.length - 1 ? acc + parameterDef : acc + parameterDef + ', ';
    }, '');
    setFunctionParameters(`(${parameters})`);
  }, [props.functionInstance.parameters, props.objects, props.functionInstance.parameters.length]);

  return (
    <>
      <div
        className={`border ${styles.FunctionWrapper} ${props.className}`}
        style={{ backgroundColor: session.preferences['exocode-theme'] ? '#1c2025' : '#FFFFFF' }}
      >
        <div
          id={functionName}
          className={`${styles.FunctionDescription}`}
          style={{ backgroundColor: session.preferences['exocode-theme'] ? '#1c2025' : '#FFFFFF' }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className={`${styles.Badge} `}>
              <span style={{ color: '#8E619A', marginRight: 4 }}>{returnNameType}</span>
              <span className={styles.functionNameStyle}>{functionName}</span>
              <span style={{ color: '#63A189' }}>{functionParameters}</span>
            </div>
            <div className={styles.wrapperIcons}>
              <div className={styles.BadgeFunction}>
                {formatCrudType(props.functionInstance.crudType)}
              </div>
              <div className={styles.BadgeNew}>{t('New')}</div>
              <HelpPopover
                placement={'top'}
                helpBoxProps={{
                  title: t('CodePreview') ?? 'CodePreview'
                }}
              >
                <div
                  id="codePreview"
                  className={`bg-body-tertiary shadow-sm ${styles.itemIcons}`}
                  onClick={(event) => {
                    event.stopPropagation();
                    setPreviewFunctionUuid(props.functionInstance.uuid ?? '');
                  }}
                >
                  <Icon iconName="fa-solid fa-code" extraProps="text-secondary h5 pt-2" />
                </div>
              </HelpPopover>
              <HelpPopover
                placement={'top'}
                helpBoxProps={{
                  title: t('editButton') ?? 'editButton'
                }}
              >
                <div
                  id="editButton"
                  className={`bg-body-tertiary shadow-sm ${styles.itemIcons}`}
                  onClick={handleShowFunctionEditorDialog}
                >
                  <Icon iconName="pen-to-square" extraProps="text-secondary h5 pt-2" />
                </div>
              </HelpPopover>
              <HelpPopover
                placement={'top'}
                helpBoxProps={{
                  title: t('Delete') ?? 'Delete'
                }}
              >
                <div
                  id="deleteButton"
                  className={`bg-body-tertiary shadow-sm ${styles.itemIcons}`}
                  onClick={() => deletingFunctFromList(props.functionInstance)}
                >
                  <Icon iconName="trash" extraProps="text-secondary h5 pt-2" />
                </div>
              </HelpPopover>
            </div>
          </div>
        </div>
        {/* the functionInstace needs to be inside the array */}
      </div>
      <CodeEditorModal
        show={previewFunctionUuid !== ''}
        handleClose={() => setPreviewFunctionUuid('')}
        id={previewFunctionUuid}
        previewType={CodePreviewType.FUNCTION}
      />
      {showFunctionEditor &&
        props.crudData.functions.filter((f) => f.isNew)[
          props.crudData.functions.filter((f) => f.isNew).indexOf(props.functionInstance)
        ] && (
          <FunctionEditor
            crudData={props.crudData}
            onChange={props.onChange}
            currentFunction={props.functionInstance}
          />
        )}
    </>
  );
}
