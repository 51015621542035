import * as React from 'react';
import styles from './styles.module.css';
import { Container, Form } from 'react-bootstrap';
import SessionContext from 'modules/auth/store';
import { useTranslation } from 'react-i18next';
import HeaderJobs from './components/header_jobs';
import JobLine from './components/job_line';
import { useEffect } from 'react';
import { ProjectsService } from '../../modules/project/services';
import { useParams } from 'react-router-dom';
import { ApiAppInfo, JobStatus } from '../../modules/project/types';
import useTitle from 'hooks/useTitle';
import { useQuery } from 'hooks/useQuery';

function Jobs() {
  const queryParameters = useQuery();
  const session = React.useContext(SessionContext);
  const { app_id } = useParams();
  const { t } = useTranslation();
  const [search, setSearch] = React.useState<string>('');
  const [app, setApp] = React.useState<ApiAppInfo>();

  const setCorrectTabName = React.useCallback(async () => {
    if (!app_id) return;
    const projectData = await ProjectsService.getProjectById(app_id);
    setApp(projectData);
  }, [app_id]);

  useEffect(() => {
    setCorrectTabName();
  }, []);

  useTitle(app?.name ? `Jobs · ${app.name}` : 'Exocoding', app);

  useEffect(() => {
    const hasPendingJobs = Object.keys(session.downloads).find(
      (jobId) =>
        session.downloads[jobId].status !== JobStatus.SUCCESS &&
        session.downloads[jobId].status !== JobStatus.FAILURE
    );

    if (hasPendingJobs) updateJobStatus();
  }, [session.downloads]);

  const updateJobStatus = async () => {
    if (!app_id) return;
    setTimeout(async () => {
      const downloadList = await ProjectsService.getActiveJobsByApp(app_id);
      session.setDownloads(downloadList);
    }, 2000);
  };

  const isFromVsCodeExt = (): boolean => {
    const itemFound = queryParameters.get('vscode');
    if (itemFound) {
      return Boolean(itemFound);
    } else {
      return false;
    }
  };

  return (
    <>
      <div className={styles.containerMain}>
        <Container
          className={styles.containerPage}
          style={{
            width: `calc(100vh - ${document.getElementById('navbar')?.clientHeight}px)`,
            height: `calc(100vh - ${document.getElementById('navbar')?.clientHeight}px)`
          }}
        >
          <HeaderJobs isFromVsCodeExt={isFromVsCodeExt()} />
          <Form.Group className={styles.containerSearchAndButton}>
            <Form.Control
              id="searchInput"
              type="text"
              placeholder="Ex: Admin"
              width={'150px'}
              onChange={(ev) => setSearch(ev.target.value)}
              value={search}
            />
          </Form.Group>
          <div style={{ maxHeight: '30rem', overflowY: 'auto', marginTop: '1rem' }}>
            <table className={styles.table}>
              <tbody>
                <tr className="bg-body-seconday text-body">
                  <th className="bg-body-secondary" style={{ width: '4.5rem' }}>
                    #
                  </th>
                  <th className="bg-body-secondary" style={{ width: '10rem' }}>
                    {t('jobsPage.status')}
                  </th>
                  <th className="bg-body-secondary">{t('jobsPage.user')}</th>
                  <th className="bg-body-secondary" style={{ width: '10rem' }}>
                    {t('jobsPage.date')}
                  </th>
                  <th className="bg-body-secondary" style={{ width: '1rem' }}></th>
                </tr>
                {Object.keys(session.downloads).length > 0 ? ( // verifying if it has something inside
                  <>
                    {Object.keys(session.downloads)
                      .filter((itemDownloadId, index) => {
                        if (search && search.length > 0 && session.downloads[itemDownloadId].user) {
                          return session.downloads[itemDownloadId].user
                            ?.toLowerCase() // min - min
                            .includes(search.toLowerCase());
                        } else {
                          return true;
                        }
                      })
                      .sort((a, b) =>
                        session.downloads[a].number < session.downloads[b].number ? 1 : -1
                      )
                      .map((downloadId, index) => {
                        return session.downloads[downloadId] ? (
                          <JobLine key={index} job={session.downloads[downloadId]} />
                        ) : (
                          <></>
                        );
                      })}
                  </>
                ) : (
                  <tr>
                    <td id="bodyMessage" colSpan={5} className="text-center bg-body-secondary">
                      {t('jobsPage.noJobs')}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Container>
      </div>
    </>
  );
}

export default Jobs;
