import React, { forwardRef, memo } from 'react';
import { Form } from 'react-bootstrap';
import { FloatingLabel } from 'react-bootstrap';
import { ComponentUUID } from 'modules/designer/types';
import { parseCustomCss, removeDeclarationsWithUndefinedValue } from 'utils/cssUtils';
import { convertingCSS } from 'utils/utils';
import { useSelector } from 'react-redux';
import { InterfaceStudioState } from '../../store';
import styles from '../style.module.css';
import useCalculateComponentSize from 'modules/designer/hooks/useCalculateComponentSize';

export type InputTextareaData = {
  label?: string;
  placeholder?: string;
  defaultValue?: string;
  required?: boolean;
  readonly?: boolean;
  tooltip?: string;
  disabled?: boolean;
  width?: number | string;
  widthUnit?: number | string;
  maxWidth?: number | string;
  maxWidthUnit?: string;
  minWidth?: number | string;
  minWidthUnit?: string;
  widthCalc?: number;
  height?: number | string;
  heightUnit?: number | string;
  maxHeight?: number | string;
  maxHeightUnit?: string;
  minHeight?: number | string;
  minHeightUnit?: string;
  translateKey?: string;
  hasTranslation?: string;
  optionSizeWidth?: string;
  optionSizeHeight?: string;
};

export type InputTextareaStyles = {
  staticColor?: string;
  themeColor?: string;
  fontSize?: number;
  textAlign?: string;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  borderTop?: number;
  borderLeft?: number;
  borderRight?: number;
  borderBottom?: number;
  paddingTop?: number;
  paddingLeft?: number;
  paddingRight?: number;
  paddingBottom?: number;
  css?: string;
  fontWeight?: string;
  lineHeight?: number | string;
  textDecoration?: string;
  textTransform?: string;
  fontFamily?: string;
  fontStyle?: string;
  bgColor?: string;
  borderColor?: string;
  boxshadow?: string;
  placeholderColor?: string;
  classes?: string[];
};

export type InputTextareaComponentProps = {
  uuid: ComponentUUID;
  data: InputTextareaData;
  styles: InputTextareaStyles;
};

function InputTextareaComponent(props: InputTextareaComponentProps, ref: React.Ref<any>) {
  const [value, setValue] = React.useState<string>('');
  const theme = useSelector((state: InterfaceStudioState) => state.theme);

  let style = {
    ...(props.data.width &&
      props.data.widthUnit && {
        width: props.data.width + '' + props.data.widthUnit
      }),
    ...(props.data.height &&
      props.data.heightUnit && {
        height: props.data.height + '' + props.data.heightUnit
      }),
    ...(props.data.width &&
      !props.data.widthUnit && {
        width: props.data.width
      }),
    ...(props.data.height &&
      !props.data.heightUnit && {
        height: props.data.height
      }),
    ...(props.data.maxHeight &&
      props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight + '' + props.data.maxHeightUnit
      }),
    ...(props.data.maxHeight &&
      !props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight
      }),
    ...(props.data.minHeight &&
      props.data.minHeightUnit && {
        minHeight: props.data.minHeight + '' + props.data.minHeightUnit
      }),
    ...(props.data.minHeight &&
      !props.data.minHeightUnit && {
        minHeight: props.data.minHeight
      }),
    ...(props.data.maxWidth &&
      props.data.maxWidthUnit && {
        maxWidth: props.data.maxWidth + '' + props.data.maxWidthUnit
      }),
    ...(props.data.maxWidth &&
      !props.data.maxWidthUnit && {
        maxWidth: props.data.maxWidth
      }),
    ...(props.data.minWidth &&
      props.data.minWidthUnit && {
        minWidth: props.data.minWidth + '' + props.data.minWidthUnit
      }),
    ...(!props.data.minWidthUnit && {
      minWidth: props.data.minWidth
    }),
    color: props.styles.staticColor
      ? props.styles.staticColor
      : props.styles.themeColor &&
        convertingCSS(
          props.styles.themeColor,
          props.styles.themeColor.includes('#') ? '' : theme.colors[props.styles.themeColor!]
        ),
    fontSize: `${props.styles.fontSize}px`,
    paddingTop: `${props.styles.paddingTop}px`,
    paddingRight: `${props.styles.paddingRight}px`,
    paddingBottom: `${props.styles.paddingBottom}px`,
    paddingLeft: `${props.styles.paddingLeft}px`,
    borderTop: `${props.styles.borderTop}px solid #333`,
    borderRight: `${props.styles.borderRight}px solid #333`,
    borderBottom: `${props.styles.borderBottom}px solid #333`,
    borderLeft: `${props.styles.borderLeft}px solid #333`,
    marginTop: `${props.styles.marginTop}px`,
    marginRight: `${props.styles.marginRight}px`,
    marginBottom: `${props.styles.marginBottom}px`,
    marginLeft: `${props.styles.marginLeft}px`,
    fontWeight: `${props.styles.fontWeight}`,
    lineHeight: `${props.styles.lineHeight}`,
    TextTransform: `${props.styles.textTransform}`,
    ...(props.styles.fontFamily && {
      fontFamily: `${props.styles.fontFamily}`
    })
  };

  if (props.data.widthCalc && props.data.widthUnit !== 'px') {
    style.width = `calc(${style.width} - ${props.data.widthCalc}px)`;
  }

  removeDeclarationsWithUndefinedValue(style);
  style = useCalculateComponentSize(props.uuid, style) ?? style;

  const classes = props.styles.classes?.join(' ') || '';

  return (
    <div
      ref={ref}
      style={{ ...style, ...parseCustomCss(props.styles.css) }}
      title={props.data.tooltip}
      className={classes}
    >
      <Form.Group>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <FloatingLabel
            className={` mb-3 text-${props.styles.textTransform} text-${props.styles.textAlign}`}
            style={{
              fontStyle: `${props.styles.fontStyle}`,
              textDecoration: `${props.styles.textDecoration}`
            }}
            label={props.data.hasTranslation ? `{${props.data.translateKey}}` : props.data.label}
            controlId={'floatingTextarea'}
          >
            <Form.Control
              style={{
                width: style.width,
                height: style.height,
                backgroundColor:
                  props.styles.bgColor &&
                  convertingCSS(
                    props.styles.bgColor,
                    props.styles.bgColor.includes('#') ? '' : theme.colors[props.styles.bgColor]
                  ),
                borderColor:
                  props.styles.borderColor &&
                  convertingCSS(props.styles.borderColor, theme.colors[props.styles.borderColor]),
                boxShadow: props.styles.boxshadow,
                overflow: props.styles.boxshadow ? 'visible' : undefined,
                ...({
                  '--placeholder-color':
                    props.styles.placeholderColor &&
                    convertingCSS(
                      props.styles.placeholderColor,
                      props.styles.placeholderColor.includes('#')
                        ? ''
                        : theme.colors[props.styles.placeholderColor]
                    )
                } as React.CSSProperties)
              }}
              as={'textarea'}
              placeholder={props.data.placeholder}
              readOnly={props.data.readonly}
              required={props.data.required}
              disabled={props.data.disabled}
              value={!props.data.defaultValue ? value : props.data.defaultValue}
              onChange={(evt) => {
                if (props.data.defaultValue) return;
                setValue(evt.target.value);
              }}
              className={styles.input}
            />
          </FloatingLabel>
        </div>
      </Form.Group>
    </div>
  );
}

export * from './manifest';
export * from './template';
export default memo(forwardRef(InputTextareaComponent));
