import produce from 'immer';

import { initialState, StudioState } from '../index';
import {
  SET_CURRENT_VIEW,
  SET_SELECTED_COMPONENT,
  SET_HOVERED_COMPONENT,
  SetCurrentPageAction,
  SetSelectedComponentAction,
  SetHoveredComponentAction,
  SET_SELECTED_VIEW,
  SetSelectedViewAction,
  SetDraggedComponentAction,
  SET_DRAGGED_COMPONENT,
  SetCurrentEditorAction,
  SET_CURRENT_EDITOR,
  SetErrorMessageAction,
  SET_ERROR_MESSAGE,
  SetDesignerModeAction,
  SET_DESIGNER_MODE,
  SET_EDIT_MODE,
  SetEditModeAction,
  SetViewportModeAction,
  SET_VIEWPORT_MODE
} from '../actions/studio';
import { SET_SELECTED_THEME, SetSelectedThemeAction } from '../actions/theme';

type StudioActions =
  | SetCurrentPageAction
  | SetSelectedComponentAction
  | SetHoveredComponentAction
  | SetDraggedComponentAction
  | SetSelectedViewAction
  | SetCurrentEditorAction
  | SetErrorMessageAction
  | SetSelectedThemeAction
  | SetDesignerModeAction
  | SetViewportModeAction
  | SetEditModeAction;

export const studioReducer = (
  state: StudioState = initialState.studio,
  action: StudioActions
): StudioState => {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_CURRENT_VIEW:
        return doSetCurrentView(draft, action);
      case SET_SELECTED_COMPONENT:
        return doSetSelectedComponent(draft, action);
      case SET_HOVERED_COMPONENT:
        return doSetHoveredComponent(draft, action);
      case SET_SELECTED_VIEW:
        return doSetSelectedView(draft, action);
      case SET_DRAGGED_COMPONENT:
        return doSetDraggedComponent(draft, action);
      case SET_CURRENT_EDITOR:
        return doSetCurrentEditor(draft, action);
      case SET_ERROR_MESSAGE:
        return doSetErrorMessage(draft, action);
      case SET_SELECTED_THEME:
        return doSetSelectedTheme(draft, action);
      case SET_DESIGNER_MODE:
        return doSetDesignerMode(draft, action);
      case SET_VIEWPORT_MODE:
        return doSetViewportMode(draft, action);
      case SET_EDIT_MODE:
        return doEditMode(draft, action);
      default:
        return draft;
    }
  });
};

function doSetCurrentView(state: StudioState, action: SetCurrentPageAction): StudioState {
  state.module_id = action.payload.module_id;
  state.view_id = action.payload.view_id;
  return state;
}

function doSetCurrentEditor(state: StudioState, action: SetCurrentEditorAction): StudioState {
  state.editor = action.payload;
  return state;
}

function doSetSelectedComponent(
  state: StudioState,
  action: SetSelectedComponentAction
): StudioState {
  state.selectedComponent = action.payload;
  return state;
}

function doSetHoveredComponent(state: StudioState, action: SetHoveredComponentAction): StudioState {
  state.hoveredComponent = action.payload;
  return state;
}

function doSetSelectedView(state: StudioState, action: SetSelectedViewAction): StudioState {
  state.selectedView = action.payload;
  return state;
}

function doSetDraggedComponent(state: StudioState, action: SetDraggedComponentAction): StudioState {
  state.draggedComponent = action.payload;
  return state;
}

function doSetErrorMessage(state: StudioState, action: SetErrorMessageAction): StudioState {
  state.errorMessage = action.payload;
  return state;
}

function doSetSelectedTheme(state: StudioState, action: SetSelectedThemeAction): StudioState {
  state.selectedTheme = action.payload.uuid;
  return state;
}

function doSetDesignerMode(state: StudioState, action: SetDesignerModeAction): StudioState {
  state.designerMode = action.payload;
  return state;
}

function doSetViewportMode(state: StudioState, action: SetViewportModeAction): StudioState {
  state.viewportMode = action.payload;
  return state;
}

function doEditMode(state: StudioState, action: SetEditModeAction): StudioState {
  state.editMode = action.payload;
  return state;
}
