import React, { useContext } from 'react';
import Nav from 'react-bootstrap/Nav';
import { useTranslation } from 'react-i18next';
import styles from '../../../styles.module.css';
import { LogicBuilderConcept, getLogicBuilderConceptInfo } from 'modules/logic_builder/types';
import Icon from 'web_ui/icon';
import LogicBuilderContext from 'modules/logic_builder/store';
import { LocalStorageManager } from 'utils/localstorage';
import { useParams } from 'react-router-dom';
import { WALKTHROUGH_STEPS_ELEMENTS } from 'web_ui/walkthrough/constants';

function EditorNav() {
  const { t } = useTranslation();
  const { getSelectedConcept, navigateToConcept } = useContext(LogicBuilderContext);
  const { app_id, module_id } = useParams();
  const object = getLogicBuilderConceptInfo(LogicBuilderConcept.OBJECT);
  const fn = getLogicBuilderConceptInfo(LogicBuilderConcept.FUNCTION);
  const scheduler = getLogicBuilderConceptInfo(LogicBuilderConcept.SCHEDULER);
  const endpoint = getLogicBuilderConceptInfo(LogicBuilderConcept.ENDPOINT);

  const savingTheTabLogicBuilder = (val: LogicBuilderConcept) => {
    if (!app_id || !module_id) {
      return;
    }
    const currState = LocalStorageManager.getValueLocalStorageState(app_id, module_id);
    currState[module_id] = {
      ...currState[module_id],
      logicBuilder: {
        ...currState[module_id].logicBuilder,
        lastSelectedTab: val
      }
    };
    LocalStorageManager.setValueLocalStorageState(app_id, currState);
  };

  return (
    <div className={`bg-body border-bottom ${styles.LogicBuilderNavWrapper}`}>
      <Nav
        className={`justify-content-center ${styles.LogicBuilderNav}`}
        variant="underline"
        defaultActiveKey={getSelectedConcept()}
        as="ul"
      >
        <Nav.Item as="li" id={WALKTHROUGH_STEPS_ELEMENTS['logic-objects-tab']}>
          <Nav.Link
            id="objectTab"
            eventKey={LogicBuilderConcept.OBJECT}
            onClick={() => {
              navigateToConcept(LogicBuilderConcept.OBJECT);
              savingTheTabLogicBuilder(LogicBuilderConcept.OBJECT);
            }}
          >
            <Icon iconName={object.icon} extraProps="pe-1"></Icon>
            <span className={styles.HideTabName}> {t(object.title)}</span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li" id={WALKTHROUGH_STEPS_ELEMENTS['logic-functions-tab']}>
          <Nav.Link
            id="functionTab"
            eventKey={LogicBuilderConcept.FUNCTION}
            onClick={() => {
              navigateToConcept(LogicBuilderConcept.FUNCTION);
              savingTheTabLogicBuilder(LogicBuilderConcept.FUNCTION);
            }}
          >
            <Icon iconName={fn.icon} extraProps="pe-1"></Icon>
            <span className={styles.HideTabName}> {t(fn.title)}</span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li" id={WALKTHROUGH_STEPS_ELEMENTS['logic-endpoints-tab']}>
          <Nav.Link
            id="endpointTab"
            eventKey={LogicBuilderConcept.ENDPOINT}
            onClick={() => {
              navigateToConcept(LogicBuilderConcept.ENDPOINT);
              savingTheTabLogicBuilder(LogicBuilderConcept.ENDPOINT);
            }}
          >
            <Icon iconName={endpoint.icon} extraProps="pe-1"></Icon>
            <span className={styles.HideTabName}> {t(endpoint.title)}</span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li" id={WALKTHROUGH_STEPS_ELEMENTS['logic-schedulers-tab']}>
          <Nav.Link
            id="schedulerTab"
            eventKey={LogicBuilderConcept.SCHEDULER}
            onClick={() => {
              navigateToConcept(LogicBuilderConcept.SCHEDULER);
              savingTheTabLogicBuilder(LogicBuilderConcept.SCHEDULER);
            }}
          >
            <Icon iconName={scheduler.icon} extraProps="pe-1"></Icon>
            <span className={styles.HideTabName}> {t(scheduler.title)}</span>
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </div>
  );
}

export default EditorNav;
