import React from 'react';
import { IconText } from 'web_ui/workboard/sidebar/controls/IconTextControl';
import RecursiveCollapse from './recursiveCollapse';
import { IconType } from 'react-icons';
import * as FontIcon from 'react-icons/fa';
import styles from '../../styles.module.css';

interface ListItemProps {
  linkUUID: string;
  links: any;
  stylesItem: any;
  showNested: { [key: string]: boolean };
  toggleNested: (key: string) => void;
  iconStyle: { [key: string]: string };
  linkText: string;
  liStyle: { [key: string]: string };
  IconDiv: IconType | null;
  btnOrientation?: string;
}

function ListItem({
  linkUUID,
  links,
  stylesItem,
  showNested,
  toggleNested,
  iconStyle,
  linkText,
  liStyle,
  IconDiv,
  btnOrientation
}: ListItemProps) {
  return (
    <>
      <li
        key={linkUUID}
        className={`list-group-item bg-${stylesItem.themeColor} border-bottom-0 ${
          links.items[linkUUID].isDivider ? 'pt-0 pb-0' : ''
        }`}
        style={liStyle}
      >
        {!links.items[linkUUID].isDivider && (
          <>
            <div
              data-bs-toggle="collapse"
              data-bs-target={`#menuColapse${linkUUID}`}
              onClick={() => toggleNested(linkUUID)}
              className="d-flex align-items-center"
            >
              <span
                className={`${
                  !links.children[linkUUID]
                    ? linkText === IconText.ICON
                      ? styles.overlook
                      : styles.hidden
                    : ''
                } ${styles.smoothRotate} ${showNested[linkUUID] ? styles.openedItem : ''} `}
                style={{ height: 'fit-content' }}
              >
                <FontIcon.FaChevronRight></FontIcon.FaChevronRight>
              </span>
              <div
                className={`d-flex align-items-center w-100 ${
                  btnOrientation === 'Vertical' ? 'flex-column' : ''
                }`}
              >
                {(linkText === IconText.BOTH || linkText === IconText.ICON) && (
                  <div
                    className={`d-inline  ${
                      links.items[linkUUID].title &&
                      links.items[linkUUID].title!.length > 0 &&
                      links.items[linkUUID].icon
                        ? 'ms-1'
                        : ''
                    }`}
                    style={iconStyle}
                  >
                    {IconDiv && <IconDiv size={stylesItem.iconSize} />}
                  </div>
                )}

                {(linkText === IconText.BOTH || linkText === IconText.TEXT) && (
                  <button
                    className={`${styles.btnToggle} btn btn-toggle align-items-center rounded`}
                    style={liStyle}
                  >
                    {links.items[linkUUID].title}
                  </button>
                )}
              </div>
            </div>
            {links.children[linkUUID] && (
              <RecursiveCollapse
                linkUUID={linkUUID}
                links={links}
                styles={stylesItem}
                showNested={showNested}
                toggleNested={toggleNested}
                iconStyle={iconStyle}
                linkText={linkText}
              />
            )}
          </>
        )}

        {links.items[linkUUID].isDivider && <hr />}
      </li>
    </>
  );
}

export default ListItem;
