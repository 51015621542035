import React, { CSSProperties } from 'react';
import styles from './styles.module.css';
import { ItemsWrapper } from './items_wrapper';
import { inlineFixedStyles, inlineNameLabel } from './css_clases';

export interface AddItemProps {
  availableClasses: string[];
  addClass: (className: string) => void;
}

export const AvailableCssClasses = (props: AddItemProps) => {
  const inlineStyles: CSSProperties = {
    ...inlineFixedStyles,
    backgroundColor: 'var(--bs-body-color)',
    color: 'var(--bs-body-bg)',
    cursor: 'pointer'
  };

  return (
    <ItemsWrapper>
      {props.availableClasses.map((name, index) => {
        return (
          <div
            key={index}
            style={inlineStyles}
            onClick={() => props.addClass(name)}
            className={styles.item}
          >
            <div style={inlineNameLabel}>{name}</div>
          </div>
        );
      })}
    </ItemsWrapper>
  );
};
