import React, { useEffect, useState } from 'react';
import { CrudData } from './index';
import styles from './styles.module.css';
import { Button, Form, Spinner } from 'react-bootstrap';
import Icon from 'web_ui/icon';
import { Trans, useTranslation } from 'react-i18next';
import { AddFunctionsDialog } from './add_functions_dialog';
import { CrudAutomationService, FunctionService } from 'modules/logic_builder/services';
import { useParams } from 'react-router-dom';
import { CrudFunctionInstance } from './Instances/crudFunctionInstance';
import { FunctionParameterCrud, ObjectTypeCrud } from 'modules/logic_builder/types';
import { ServiceCreatorDialog } from 'modules/logic_builder/components/dialogs/service_creator_dialog';
import { FunctionInstance } from 'modules/logic_builder/components/functions_list/function_instance';
import { functionEditorCrud } from 'web_ui/function_editor/store/types/functions';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import { toCamelCase } from '../../../../../utils/utils';
import SessionContext from 'modules/auth/store';
import Confirmation from 'web_ui/confirmation';

type Step3Props = {
  crudData: CrudData;
  onChange: (crudData: CrudData) => void;
  setFormValidity: (validity: boolean) => void;
  objects: ObjectTypeCrud[];
  loading?: (val: boolean) => void;
};

export function Step3Functions(props: Step3Props) {
  const { t } = useTranslation();
  const { module_id } = useParams();
  const [showAddFunctionDialog, setShowAddFunctionDialog] = useState<boolean>(false);
  const [showServiceCreatorDialog, setShowServiceCreatorDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (props.crudData.functions) {
      setIsLoading(false);
    }
  }, [props.crudData.functions]);

  async function getFunctionsList(serviceId: string) {
    if (!module_id || !props.crudData || !props.crudData.entityUuid) return;
    props.loading && props.loading(true);

    const functionsList = await FunctionService.getFunctionsByService(serviceId, module_id);
    functionsList.forEach((func) => {
      func.isNew = false;
    });

    functionsList.forEach(async (func) => {
      const currentParameters = await FunctionService.getParameters(func.uuid);
      func.parameters = currentParameters as FunctionParameterCrud[];
    });

    const storedFunctions = props.crudData.functions.filter((f) => !!f.isNew);

    const functionsOldNew = [...functionsList, ...storedFunctions];

    const crud = {
      ...props.crudData,
      functions: functionsOldNew as functionEditorCrud[]
    } as CrudData;

    props.onChange(crud);
    props.loading && props.loading(false);
  }

  async function getServicesList() {
    if (!module_id || !props.crudData.entityUuid) return;
    props.loading && props.loading(true);

    const serviceList = await CrudAutomationService.getEntityServiceResume(
      module_id,
      props.crudData.entityUuid
    );

    const crud = {
      ...props.crudData,
      services: serviceList
    } as CrudData;

    if (serviceList[0] && serviceList[0].uuid) {
      crud.selectedService = serviceList[0].uuid;
    }

    props.onChange(crud);
    props.loading && props.loading(false);
  }

  useEffect(() => {
    if (props.crudData.selectedService === '') {
      getServicesList();
      props.setFormValidity(false);
    } else {
      props.setFormValidity(true);
      getFunctionsList(props.crudData.selectedService);
    }
  }, [props.crudData.selectedService]);

  const getDefaultServiceName = (): string => {
    if (!props.crudData.entityUuid) return '';
    return toCamelCase(
      `${props.crudData.entities[props.crudData.entityUuid]?.entityName}Service`,
      true
    );
  };

  return (
    <div className={styles.StepWrapper}>
      <div id="headerMessage" className={styles.Title}>
        {t('automation.Step3Title')}
        <HelpPopover
          helpBoxProps={{
            title: t('automation.Step3Tooltip') ?? ''
          }}
          placement="right"
        >
          <span>
            <Icon padding={'10px'} brands={'regular'} iconName={'circle-question'} />
          </span>
        </HelpPopover>
      </div>

      {/* Class */}
      <div className={styles.DataObjectHeaderClass}>
        <div className={styles.SelectionWrapper}>
          <div style={{ width: 'fit-content', marginRight: '3rem' }}>
            {t('automation.step3.class')}:
          </div>
          <div style={{ marginRight: '0.5rem', width: '20rem' }}>
            <Form.Select
              id="selectService"
              size="sm"
              value={props.crudData.selectedService}
              onChange={(e) => {
                const newCrud = {
                  ...props.crudData,
                  selectedService: e.target.value
                };
                props.onChange(newCrud);
              }}
              style={{
                height: '2.3rem',
                fontSize: '16px',
                borderRadius: '8px',
                width: '20rem'
              }}
              className={`bg-white ${
                props.crudData.services.length === 0 ? 'text-muted' : 'text-black'
              }`}
              disabled={props.crudData.services.length === 0}
            >
              <option selected disabled hidden>
                {getDefaultServiceName()}
              </option>
              {props.crudData.services &&
                Object.values(props.crudData.services).map((service) => {
                  return (
                    <option key={service.uuid} value={service.uuid}>
                      {service.name}
                    </option>
                  );
                })}
              ;
            </Form.Select>
          </div>
          <HelpPopover
            placement={'top'}
            helpBoxProps={{
              title: t('createService') ?? ''
            }}
          >
            <Button
              id="createServiceButton"
              variant="primary"
              onClick={() => setShowServiceCreatorDialog(true)}
              style={{
                marginRight: '1rem',
                paddingTop: 6,
                paddingBottom: 6,
                paddingRight: 14,
                paddingLeft: 14
              }}
            >
              <Icon iconName="plus" extraProps="fa-lg" />
            </Button>
          </HelpPopover>
          <div id="existingFunctions">{`(${
            props.crudData.functions.filter((f) => f.isNew === false).length
          } ${t('automation.Step3Existing')})`}</div>
        </div>
        <HelpPopover
          placement={'top'}
          helpBoxProps={{
            title: t('automation.step3.addFunc') ?? ''
          }}
        >
          <Button
            id="createFunctionButton"
            variant="primary"
            onClick={() => setShowAddFunctionDialog(true)}
            style={{ width: '200px' }}
          >
            {t('automation.step3.addFunc')}
          </Button>
        </HelpPopover>
      </div>

      {isLoading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" variant="secondary" />
        </div>
      ) : Object.values(props.crudData.functions).length > 0 ? (
        <>
          {/* Functions List from PATCH props.crudData.crudFunctions*/}
          <div id="list-group" className={styles.FunctionsListWrapper}>
            {props.crudData.functions
              .filter((f) => f.isNew === true)
              .map((func) => {
                return (
                  <CrudFunctionInstance
                    key={func.uuid}
                    serviceUuid={props.crudData.selectedService}
                    cursor="pointer"
                    functionInstance={func}
                    crudData={props.crudData}
                    onChange={props.onChange}
                    objects={props.objects}
                  />
                );
              })}
            {/* Previous Functions */}
            {props.crudData.functions
              .filter((f) => f.isNew === false)
              .map((func) => {
                return (
                  <FunctionInstance
                    key={func.uuid}
                    serviceUuid={func.serviceUuid}
                    cursor="pointer"
                    functionInstance={func}
                    crudData={props.crudData}
                    onlyOpenWindow={true}
                    getFuncList={getFunctionsList}
                  />
                );
              })}
          </div>
        </>
      ) : (
        <div id="bodyMessage" className={styles.emptyMessage}>
          <Trans
            i18nKey="automation.Step3NoFunctions"
            components={{
              1: <a href="#" id="addFunctionLink" onClick={() => setShowAddFunctionDialog(true)} />
            }}
          />
        </div>
      )}
      {/* New Function Modal */}
      {props.crudData.entityUuid && module_id && (
        <AddFunctionsDialog
          crudData={props.crudData}
          serviceUuid={props.crudData.entityUuid}
          show={showAddFunctionDialog}
          onClose={() => setShowAddFunctionDialog(false)}
          moduleId={module_id}
          onChange={props.onChange}
          loading={props.loading}
        />
      )}
      {/* <span>{props.crudData.entityUuid}</span> */}

      <ServiceCreatorDialog
        show={showServiceCreatorDialog}
        onClose={() => setShowServiceCreatorDialog(false)}
        preSelectedEntity={Object.values(props.crudData.entities).find(
          (e) => e.entityUuid === props.crudData.entityUuid
        )}
        onChange={props.onChange}
        crudData={props.crudData}
        loading={props.loading}
      />
    </div>
  );
}
