import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { InterfaceStudioState, RESTORE_LAST_VALID_STATE, SAVE_LAST_VALID_STATE } from '../index';
import {
  ADD_AND_MOVE_COMPONENTS_INTO,
  ADD_COMPONENT,
  ADD_COMPONENT_SET,
  ADD_CUSTOM_COMPONENT,
  ADD_DETACHED_CUSTOM_COMPONENT,
  ADD_MULTIPLES_AND_MOVE_COMPONENTS_INTO,
  ComponentPayload,
  CREATE_CUSTOM_COMPONENT,
  CREATE_DETACHED_CUSTOM_COMPONENT,
  DELETE_COMPONENT,
  EXTRACT_FORM,
  MOVE_COMPONENT
} from '../actions/root';
import {
  ADD_COMPONENT_EVENT,
  CHANGE_COMPONENT_PROPERTY,
  CHANGE_COMPONENT_STYLE,
  CHANGE_CUSTOM_COMPONENT_VIEW_PROPERTY,
  DELETE_COMPONENT_EVENT,
  COPY_COMPONENT,
  CHANGE_COMPONENT_PROPERTIES,
  CHANGE_ALL_COMPONENT_PROPERTIES
} from '../actions/components';
import {
  ADD_EVENT,
  ATTACH_MODAL,
  CHANGE_EDITOR_MODE,
  CHANGE_LAYOUT,
  CHANGE_VIEW_PROPERTY,
  CHANGE_VIEW_STYLE,
  DELETE_EVENT
} from '../actions/views';
import { ViewStateService } from 'modules/designer/services';
import {
  ADD_WIDGET,
  CHANGE_WIDGET,
  DELETE_WIDGET,
  UPDATE_WIDGET_POSITION
} from '../actions/widgets';
import { Editor, Message, MessageTypes, MoveComponentPayload } from 'modules/designer/types';
import { ADD_FUNCTION, DELETE_FUNCTION, UPDATE_FUNCTION } from '../actions/functions';
import {
  ADD_VARIABLE,
  CHANGE_VARIABLE_TYPE,
  DELETE_VARIABLE,
  UPDATE_VARIABLE
} from '../actions/variables';
import { ADD_PARAMS, DELETE_PARAMS } from '../actions/params';
import { ADD_PROPERTY, DELETE_PROPERTY, UPDATE_PROPERTY } from '../actions/properties';
import { setErrorMessage } from '../actions/studio';
import { SET_ROLE_VIEW } from '../actions/roles';
import { UPDATE_CSS_CLASSES_ORDER } from '../actions/cssClasses';

const actions = [
  ADD_COMPONENT,
  CHANGE_COMPONENT_PROPERTY,
  CHANGE_COMPONENT_STYLE,
  MOVE_COMPONENT,
  DELETE_COMPONENT,
  CHANGE_VIEW_PROPERTY,
  CHANGE_VIEW_STYLE,
  ATTACH_MODAL,
  CHANGE_VIEW_STYLE,
  ADD_CUSTOM_COMPONENT,
  ADD_DETACHED_CUSTOM_COMPONENT,
  CREATE_CUSTOM_COMPONENT,
  CHANGE_LAYOUT,
  ADD_WIDGET,
  CHANGE_WIDGET,
  DELETE_WIDGET,
  UPDATE_WIDGET_POSITION,
  ADD_EVENT,
  DELETE_EVENT,
  ADD_FUNCTION,
  UPDATE_FUNCTION,
  DELETE_FUNCTION,
  ADD_COMPONENT_EVENT,
  DELETE_COMPONENT_EVENT,
  ADD_VARIABLE,
  UPDATE_VARIABLE,
  CHANGE_VARIABLE_TYPE,
  DELETE_VARIABLE,
  ADD_PARAMS,
  DELETE_PARAMS,
  ADD_PROPERTY,
  DELETE_PROPERTY,
  UPDATE_PROPERTY,
  EXTRACT_FORM,
  CHANGE_CUSTOM_COMPONENT_VIEW_PROPERTY,
  CREATE_DETACHED_CUSTOM_COMPONENT,
  COPY_COMPONENT,
  SET_ROLE_VIEW,
  ADD_COMPONENT_SET,
  CHANGE_EDITOR_MODE,
  ADD_AND_MOVE_COMPONENTS_INTO,
  ADD_MULTIPLES_AND_MOVE_COMPONENTS_INTO,
  CHANGE_COMPONENT_PROPERTIES,
  CHANGE_ALL_COMPONENT_PROPERTIES,
  UPDATE_CSS_CLASSES_ORDER
];

let lastAction: string | null = null;
let lastActionKey: string | null = null;
let timerRef: NodeJS.Timeout | null = null;

const buildActionQuery = (action: any) => {
  const actionType = action.type;
  switch (actionType) {
    case 'ADD_COMPONENT': {
      action.payload.link['parent'] = action.payload.link.parentUUID;
      delete action.payload.link.parentUUID;

      action.payload.link['view'] = action.payload.link.viewUUID;
      delete action.payload.link.viewUUID;
      break;
    }
    case 'EXTRACT_FORM': {
      action.payload.link['parent'] = action.payload.link.parentUUID;
      delete action.payload.link.parentUUID;

      action.payload.link['view'] = action.payload.link.viewUUID;
      delete action.payload.link.viewUUID;
      break;
    }
    case 'MOVE_COMPONENT': {
      action.payload.source['parent'] = action.payload.source.parentUUID;
      delete action.payload.source.parentUUID;

      action.payload.target['parent'] = action.payload.target.parentUUID;
      delete action.payload.target.parentUUID;
      break;
    }
    case 'CREATE_CUSTOM_COMPONENT': {
      action.payload['source_uuid'] = action.payload.componentUUID;
      delete action.payload.componentUUID;

      action.payload['uuid'] = action.payload.customComponentUUID;
      delete action.payload.customComponentUUID;
      break;
    }
    case 'ADD_DETACHED_CUSTOM_COMPONENT': {
      delete action.payload.customComponentsState;
      break;
    }
    case 'ADD_CUSTOM_COMPONENT': {
      action.payload = {
        uuid: action.payload.newCustomComponentUUID,
        custom_uuid: action.payload.uuid,
        link: { view: action.payload.link.viewUUID, parent: action.payload.link.parentUUID }
      };
      break;
    }
    case 'CHANGE_LAYOUT': {
      delete action.payload.layout;
      break;
    }
    case 'ADD_COMPONENT_SET': {
      action.payload.componentSet.forEach((componentInfo: any) => {
        componentInfo.link['parent'] = componentInfo.link.parentUUID;
        delete componentInfo.link.parentUUID;

        componentInfo.link['view'] = componentInfo.link.viewUUID;
        delete componentInfo.link.viewUUID;
      });

      break;
    }
    case 'CHANGE_EDITOR_MODE': {
      action.payload['editor_mode'] = action.payload.value;
      delete action.payload.value;
      break;
    }
    case 'ADD_AND_MOVE_COMPONENTS_INTO': {
      action.payload.componentToAdd.link['parent'] = action.payload.componentToAdd.link.parentUUID;
      delete action.payload.componentToAdd.link.parentUUID;

      action.payload.componentToAdd.link['view'] = action.payload.componentToAdd.link.viewUUID;
      delete action.payload.componentToAdd.link.viewUUID;

      action.payload.componentsToMove.forEach((componentToMove: any) => {
        componentToMove.source['parent'] = componentToMove.source.parentUUID;
        // TODO:
        // delete componentToMove.source.parentUUID;

        componentToMove.target['parent'] = componentToMove.target.parentUUID;
        // TODO:
        // delete componentToMove.target.parentUUID;
      });

      break;
    }
    case 'ADD_MULTIPLES_AND_MOVE_COMPONENTS_INTO': {
      action.payload.componentsToAdd.forEach((componentInfo: any) => {
        componentInfo.link['parent'] = componentInfo.link.parentUUID;
        // delete componentInfo.link.parentUUID;

        componentInfo.link['view'] = componentInfo.link.viewUUID;
        // delete componentInfo.link.viewUUID;
      });

      action.payload.componentsToMove.forEach((componentToMove: any) => {
        componentToMove.source['parent'] = componentToMove.source.parentUUID;
        // TODO:
        // delete componentToMove.source.parentUUID;

        componentToMove.target['parent'] = componentToMove.target.parentUUID;
        // TODO:
        // delete componentToMove.target.parentUUID;
      });

      break;
    }
    default: {
      break;
    }
  }
};

const DESIGNER_STUDIO_ERROR_MESSAGES: Record<string, string> = {
  ERROR_PROCESSING_ACTION: 'designer.errors.errorProcessingAction'
};

const sendAction = (
  editor: keyof typeof Editor | null,
  view_id: string,
  action: any,
  store: MiddlewareAPI<Dispatch<AnyAction>, InterfaceStudioState>
) => {
  if (editor === Editor.PAGE || editor === Editor.MODAL || editor === Editor.LAYOUT) {
    // View editor.
    ViewStateService.updateViewStateAction(view_id, action).then(async (response) => {
      if (!response.ok) {
        store.dispatch({ type: RESTORE_LAST_VALID_STATE });
        const body: any = await response.json();

        const i18nKeyErrorMessage = DESIGNER_STUDIO_ERROR_MESSAGES.ERROR_PROCESSING_ACTION;
        store.dispatch(setErrorMessage(body?.message ?? i18nKeyErrorMessage));
      } else {
        store.dispatch({ type: SAVE_LAST_VALID_STATE });
      }
    });
  } else {
    // Custom component editor.
    ViewStateService.updateCustomComponentAction(view_id, action).then((response) => {
      if (!response.ok) {
        store.dispatch({ type: RESTORE_LAST_VALID_STATE });

        const i18nKeyErrorMessage = DESIGNER_STUDIO_ERROR_MESSAGES.ERROR_PROCESSING_ACTION;
        store.dispatch(setErrorMessage(i18nKeyErrorMessage));
      } else {
        store.dispatch({ type: SAVE_LAST_VALID_STATE });
      }
    });
  }
};

export const saveMiddleware: Middleware<Record<string, unknown>, InterfaceStudioState> = function (
  store
) {
  return (next) => (action) => {
    if (action.stopReplay) return next(action);

    const message: Message = {
      type: MessageTypes.ACTION,
      content: action
    };
    if (window.location.pathname.includes('preview')) {
      window.parent.postMessage(message);
    } else {
      // @ts-ignore
      document.getElementById('viewport')?.contentWindow.postMessage(message);
    }

    if (!actions.includes(action.type)) return next(action);

    const currentActionKey = action.payload.key;

    if (lastAction === action.type && !currentActionKey && timerRef) {
      clearTimeout(timerRef);
    } else if (lastAction === action.type && currentActionKey === lastActionKey && timerRef) {
      clearTimeout(timerRef);
    }

    lastActionKey = currentActionKey;
    lastAction = action.type;
    const nextAction = next(action);

    timerRef = setTimeout(() => {
      // We need to know the editor here because the endpoint used to send actions...
      // ...for regular views (modal/layout/page) and the custom component editor...
      // ...are different.
      // If the editor === Editor.CUSTOM_COMPONENT then view_id holds the id of...
      // ...the custom component.
      const { view_id, editor } = store.getState().studio;
      buildActionQuery(action);
      sendAction(editor, view_id, action, store);
    }, 350);

    return nextAction;
  };
};
