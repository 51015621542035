import React, { useCallback, useEffect } from 'react';
import { ComponentUUID, ViewUUID, fixPostion } from 'modules/designer/types';
import { SideMenuData, SideMenuStyle } from '../..';
import SideBySideLinks from './listItems';

export type SideMenuProps = {
  uuid: ComponentUUID;
  viewUUID: ViewUUID;
  parentUUID?: ComponentUUID;
  data: SideMenuData;
  styles: SideMenuStyle;
  iconStyles?: { [key: string]: string };
};

function SideMenu(props: SideMenuProps) {
  const handleCheckExistenceOfFixedLinks = useCallback(
    (position: fixPostion) => {
      return (
        props.data.links.children[props.uuid].filter(
          (key) => props.data.links.items[key].fixed === position
        ).length > 0
      );
    },
    [props.data.links.children, props.data.links.items, props.uuid]
  );
  return (
    <div className="mt-2 d-flex h-100 flex-grow-1 flex-column">
      {handleCheckExistenceOfFixedLinks(fixPostion.TOP) && (
        <div>
          <SideBySideLinks
            data={props.data}
            uuid={props.uuid}
            styles={props.styles}
            fixed={fixPostion.TOP}
            iconStyles={props.iconStyles ?? {}}
          />
          <hr style={{ color: props.styles.color }} />
        </div>
      )}

      <div className="mb-auto">
        <SideBySideLinks
          data={props.data}
          uuid={props.uuid}
          styles={props.styles}
          fixed={fixPostion.DEFAULT}
          iconStyles={props.iconStyles ?? {}}
        />
      </div>
      {handleCheckExistenceOfFixedLinks(fixPostion.BOTTOM) && (
        <div>
          <hr style={{ color: props.styles.color }} />
          <SideBySideLinks
            data={props.data}
            uuid={props.uuid}
            styles={props.styles}
            fixed={fixPostion.BOTTOM}
            iconStyles={props.iconStyles ?? {}}
          />
        </div>
      )}
    </div>
  );
}

export default SideMenu;
