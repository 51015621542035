import React from 'react';
import styles from './styles.module.css';
import { Alert } from 'react-bootstrap';

type ErrorMessageProps = {
  setgettingValueError: React.Dispatch<React.SetStateAction<boolean>>;
  messageToRender: string;
};

export default function ErrorMessageCreateApp({
  setgettingValueError,
  messageToRender
}: ErrorMessageProps) {
  React.useEffect(() => {
    setTimeout(() => {
      setgettingValueError(false);
    }, 5000);
  }, [setgettingValueError]);

  return (
    <Alert className={styles.container} variant={'danger'}>
      {messageToRender}
    </Alert>
  );
}
