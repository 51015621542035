import { CustomComponentService, TemplatesService } from 'modules/designer/services';
import React, { RefObject, useCallback, useEffect } from 'react';
import { COMPONENT_TYPES, COMPONENTS_MANIFEST, COMPONENTS_TEMPLATE, ComponentTypes } from '.';
import { useDispatch, useSelector } from 'react-redux';
import { InterfaceStudioState } from '../store';
import { MoveInfo, MoveSource, MoveTarget } from '../store/actions/links';
import { v4 as uuidv4 } from 'uuid';
import {
  addAndMoveComponentsInto,
  addComponent,
  addCustomComponent,
  addDetachedCustomComponent,
  addMultiplesAndMoveComponentsInto,
  ComponentPayload,
  CustomComponentsChildren,
  LinkInfo,
  LinkInfoModified,
  moveComponent
} from '../store/actions/root';
import { setDraggedComponent, setSelectedComponent } from '../store/actions/studio';
import {
  ComponentUUID,
  CustomComponent,
  Editor,
  EditorModeOptions,
  LayoutComponent,
  MoveComponentPayload,
  ViewUUID
} from '../../types';
import { faker } from '@faker-js/faker';
import { useParams } from 'react-router-dom';
import { VIEWS_TYPES } from '../frames';
import { changeComponentProperties, ChangePropertyPayload } from '../store/actions/components';
import { handleContainerFactory } from './grid/generatedChilds';
import { sleep } from 'utils/utils';

function useDropZone(
  element: RefObject<any>,
  uuid: ComponentUUID,
  type: string,
  viewUUID: ViewUUID,
  parentUUID?: ComponentUUID,
  custom_uuid?: string,
  section?: string,
  isAdvancedDrop?: () => string | null,
  setIsBeingHoveredWhenDragged?: (beingHovered: boolean) => void,
  handleGetAnchorZone?: () => string | null
) {
  const dispatch = useDispatch();
  const editor = useSelector((state: InterfaceStudioState) => state.studio.editor);
  const editMode = useSelector((state: InterfaceStudioState) => state.studio.editMode);
  const links = useSelector((state: InterfaceStudioState) => state.links);
  const components = useSelector((state: InterfaceStudioState) => state.components);
  const views = useSelector((state: InterfaceStudioState) => state.views);

  const { module_id } = useParams();

  /**
   * Target component is valid if:
   *   - Target component is not a custom component.
   *   - Target component is in the validParents list.
   *   - validParents list is not defined for source component type.
   */
  const checkComponentIsValidParent = useCallback(
    (sourceComponentType: string | null, targetComponentType: string): boolean => {
      if (sourceComponentType === 'CUSTOM' && editor === Editor.CUSTOM_COMPONENT) {
        return false;
      }
      if (sourceComponentType == null || targetComponentType === 'CUSTOM') return false;
      const validParents = COMPONENTS_MANIFEST[sourceComponentType].validParents;
      if (!validParents) return true;

      for (const target of validParents) {
        if (target === targetComponentType) {
          return true;
        }
      }

      return false;
    },
    [editor]
  );

  const checkComponentIsValidChild = (
    sourceComponentType: string,
    targetComponentType: string,
    isHierarchy = false
  ): boolean => {
    const validChildren = COMPONENTS_MANIFEST[targetComponentType].validChildren ?? [];
    if (validChildren.length <= 0) return true;
    if (
      targetComponentType === COMPONENT_TYPES.LIST &&
      sourceComponentType === COMPONENT_TYPES.CUSTOM
    ) {
      return isHierarchy;
    }
    return validChildren.includes(sourceComponentType as ComponentTypes);
  };

  const canAddMoreChildren = (parentUuid: string) => {
    const parent = components[parentUuid];
    const parentType = parent.type;
    const limit = COMPONENTS_MANIFEST[parentType].maxChildren;
    if (!limit) {
      return true;
    }
    if (links[parentUuid].length <= 0) {
      return true;
    }
    const childrenCount = links[parentUuid].length;
    return childrenCount < limit;
  };

  const getCustomComponentData = React.useCallback(async (uuid: string) => {
    const custons = await TemplatesService.getTemplates('createPage', VIEWS_TYPES.CUSTOMCOMPONENTS);
    const isTemplate = custons.some((Template) => Template.uuid === uuid);
    const customComponentData = await CustomComponentService.getCustomComponent(
      uuid,
      module_id!,
      isTemplate
    );
    return customComponentData;
  }, []);

  const handleCreateContainerToChangeOrientation = useCallback(
    async (
      parentUUID: string,
      adjacentSide: string,
      sourceComponent: MoveSource,
      targetComponent: MoveTarget,
      parentDirection: string
    ) => {
      await sleep(200);
      if (!components[parentUUID] || !targetComponent.uuid) return;

      if (
        (parentDirection === 'column' && ['top', 'bottom'].includes(adjacentSide)) ||
        (parentDirection === 'row' && ['left', 'right'].includes(adjacentSide)) ||
        !parentDirection
      )
        return;

      const { component, link }: ComponentPayload = handleContainerFactory(viewUUID, parentUUID);
      link.targetUuid = targetComponent.uuid;
      link.adjacentSide = 'left';
      const containerOrientation = parentDirection === 'row' ? 'column' : 'row';
      handleUpdateContainerStyles(component, containerOrientation);
      const newTarget: MoveTarget = {
        parentUUID: component.uuid
      };
      const movedComponent: MoveComponentPayload = {
        source: sourceComponent,
        target: newTarget
      };
      const pastTarget: MoveSource = {
        parentUUID: targetComponent.parentUUID,
        uuid: targetComponent.uuid,
        type: components[targetComponent.uuid].type,
        section: targetComponent.section
      };
      const automaticMovedComponent: MoveComponentPayload = {
        source: pastTarget,
        target: newTarget
      };

      const isNewComponentFirst = ['left', 'top'].includes(adjacentSide);
      const orderedComponentsToMove = isNewComponentFirst
        ? [movedComponent, automaticMovedComponent]
        : [automaticMovedComponent, movedComponent];

      dispatch(addAndMoveComponentsInto({ component, link }, orderedComponentsToMove));
    },
    [components, dispatch, viewUUID]
  );

  const handleCreateComponentAndContainerToChangeOrientation = useCallback(
    async (
      parentUUID: string,
      adjacentSide: string,
      componentToAdd: ComponentPayload,
      targetUUID: string,
      parentDirection: string
    ) => {
      await sleep(200);
      if (!components[parentUUID] || !targetUUID) return;

      if (
        (parentDirection === 'column' && ['top', 'bottom'].includes(adjacentSide)) ||
        (parentDirection === 'row' && ['left', 'right'].includes(adjacentSide)) ||
        !parentDirection
      )
        return;

      const { component: containerComponent, link: containerLink }: ComponentPayload =
        handleContainerFactory(viewUUID, parentUUID);
      containerLink.targetUuid = targetUUID;
      containerLink.adjacentSide = 'left';
      const containerOrientation = parentDirection === 'row' ? 'column' : 'row';
      handleUpdateContainerStyles(containerComponent, containerOrientation);

      componentToAdd.link.parentUUID = containerComponent.uuid;

      const targetComponentSourceInfo: MoveSource = {
        parentUUID: parentUUID,
        uuid: targetUUID,
        type: components[targetUUID].type
      };

      const oppositeAdjacentSides: Record<string, string> = {
        left: 'right',
        right: 'left',
        top: 'bottom',
        bottom: 'top'
      };

      const targetComponentTargetInfo: MoveTarget = {
        parentUUID: containerComponent.uuid,
        uuid: componentToAdd.component.uuid,
        adjacentSide: oppositeAdjacentSides[adjacentSide]
      };

      const moveInfo: MoveComponentPayload[] = [
        { source: targetComponentSourceInfo, target: targetComponentTargetInfo }
      ];

      const containerInfo: ComponentPayload = {
        component: containerComponent,
        link: containerLink
      };

      const newComponentInfo: ComponentPayload = {
        component: componentToAdd.component,
        link: componentToAdd.link
      };

      const componentsPayload: ComponentPayload[] = [newComponentInfo, containerInfo];
      dispatch(addMultiplesAndMoveComponentsInto(componentsPayload, moveInfo));
    },
    [components, dispatch, viewUUID]
  );

  const handleUpdateContainerStyles = (component: LayoutComponent, orientation: string) => {
    component.data.flexDirection = orientation;
    component.data.width = 'fit-content';
    component.data.widthUnit = '';
    component.data.minHeight = 'none';
    component.data.minHeightUnit = '';

    component.data.height = 'fit-content';
    component.data.heightUnit = '';
    component.data.autoGenerated = true;
    component.styles.paddingLeft = 0;
    component.styles.paddingRight = 0;
  };

  // customComponentUUID: The original custom component uuid.
  const handleDropOnAllowDropComponents = React.useCallback(
    async (
      moveInfo: string,
      addInfo: string,
      customComponentUUID: string,
      adjacentSide?: string
    ) => {
      let newParentUUID = uuid;
      // if dropped on adjacent areas, then use the parent of the target element
      if (adjacentSide) {
        newParentUUID = parentUUID ? parentUUID : viewUUID;
      }
      if (moveInfo) {
        const source: MoveSource = JSON.parse(moveInfo);
        if (source.type === 'GRID') return;
        const target: MoveTarget = {
          // ### TODO: COLOCAR ORIENTAÇÃO AQUI
          parentUUID: newParentUUID,
          section: section
        };

        if (adjacentSide) {
          const parentDirection = components[newParentUUID].data.flexDirection;
          target.uuid = uuid;
          target.adjacentSide = adjacentSide;

          if (
            views[viewUUID]?.editorMode === EditorModeOptions.NORMAL &&
            ((parentDirection === 'row' && ['top', 'bottom'].includes(adjacentSide)) ||
              (parentDirection === 'column' && ['left', 'right'].includes(adjacentSide))) &&
            parentDirection
          ) {
            handleCreateContainerToChangeOrientation(
              newParentUUID,
              adjacentSide,
              source,
              target,
              parentDirection
            );
            return;
          }
        }

        if (
          !adjacentSide &&
          handleGetAnchorZone &&
          parentUUID &&
          views[viewUUID]?.editorMode === EditorModeOptions.NORMAL
        ) {
          handleChangeParentAnchor();
        }

        dispatch(moveComponent(source, target));
      } else if (addInfo) {
        if (addInfo === 'CUSTOM') {
          const customComponent = await getCustomComponentData(customComponentUUID);
          if (!customComponent || !customComponent.components || !customComponent.links) return;
          if (customComponent.isHierarchy) {
            const link: LinkInfo = { viewUUID: viewUUID, parentUUID: uuid };
            const customComponentChildren: CustomComponentsChildren = {
              components: customComponent.components,
              links: customComponent.links
            };

            const newCustomComponentUUID = uuidv4();

            dispatch(
              addCustomComponent(
                customComponent.uuid,
                link,
                customComponentChildren,
                newCustomComponentUUID
              )
            );
          } else {
            const link: LinkInfoModified = { view: viewUUID, parent: uuid };
            const customComponentChildren: CustomComponentsChildren = {
              components: customComponent.components,
              links: customComponent.links
            };

            // Map between old ids and new ids. We send this to the backend because the ids from
            // both the backend and the frontend have to be the same.
            const oldIdsNewIds: Record<string, string> = {};
            oldIdsNewIds[customComponent.uuid] = uuidv4();
            for (const oldId of Object.keys(customComponent.links)) {
              const childrenIds = customComponent.links[oldId];
              for (const oldChildrenId of childrenIds) {
                oldIdsNewIds[oldChildrenId] = uuidv4();
              }
            }

            dispatch(
              addDetachedCustomComponent(
                customComponent.uuid,
                link,
                customComponentChildren,
                oldIdsNewIds
              )
            );
          }
        } else {
          const component: LayoutComponent = JSON.parse(
            JSON.stringify(Object.assign({}, COMPONENTS_TEMPLATE[addInfo]))
          );

          component.uuid = uuidv4();
          component.data.name = faker.random.words(2).toLowerCase().replace(' ', '_');

          let newParentUUID = uuid;
          // if dropped on adjacent areas, then use the parent of the target element
          if (adjacentSide) {
            newParentUUID = parentUUID ? parentUUID : viewUUID;
          }

          const link: LinkInfo = {
            viewUUID: viewUUID,
            parentUUID: newParentUUID,
            section: section
          };

          if (adjacentSide) {
            const parentDirection = components[newParentUUID].data.flexDirection;
            // link.adjacentSide = adjacentSide;
            // link.targetUuid = uuid;
            const newComponent: ComponentPayload = { component, link };

            if (
              views[viewUUID]?.editorMode === EditorModeOptions.NORMAL &&
              ((parentDirection === 'row' && ['top', 'bottom'].includes(adjacentSide)) ||
                (parentDirection === 'column' && ['left', 'right'].includes(adjacentSide))) &&
              parentDirection
            ) {
              handleCreateComponentAndContainerToChangeOrientation(
                newParentUUID,
                adjacentSide,
                newComponent,
                uuid,
                parentDirection
              );

              return;
            }
            link.adjacentSide = adjacentSide;
            link.targetUuid = uuid;
          }

          if (
            !adjacentSide &&
            handleGetAnchorZone &&
            parentUUID &&
            views[viewUUID]?.editorMode === EditorModeOptions.NORMAL
          ) {
            handleChangeParentAnchor();
          }

          dispatch(addComponent(component, link));
          dispatch(setSelectedComponent(component.uuid));
        }
      }
    },
    [
      dispatch,
      getCustomComponentData,
      section,
      uuid,
      viewUUID,
      views,
      handleCreateContainerToChangeOrientation,
      parentUUID
    ]
  );

  const anchorToOrientationMapper: Record<string, string> = {
    left: 'start',
    right: 'end',
    top: 'start',
    bottom: 'end',
    center: 'center',
    middle: 'center'
  };

  const handleDropOnSingularComponents = React.useCallback(
    async (moveInfo: string, addInfo: string, adjacentSide?: string) => {
      if (moveInfo) {
        let adjacentParent;
        if (adjacentSide) {
          adjacentParent = parentUUID ? searchParent(parentUUID) : viewUUID;
        }

        const moveAction: MoveSource = JSON.parse(moveInfo);
        const source: MoveSource = {
          parentUUID: moveAction.parentUUID,
          uuid: moveAction.uuid,
          type: moveAction.type,
          section: moveAction.section
        };
        const target: MoveTarget = {
          parentUUID: parentUUID ?? viewUUID,
          uuid: uuid,
          section: section
        };
        if (!canAddMoreChildren(target.parentUUID)) return;
        let customComponent = {} as CustomComponent;
        if (source.type === COMPONENT_TYPES.CUSTOM) {
          customComponent = await getCustomComponentData(source.uuid);
        }
        if (
          !checkComponentIsValidChild(
            source.type,
            components[target.parentUUID].type,
            customComponent.isHierarchy
          )
        )
          return;

        dispatch(moveComponent(source, target));
        dispatch(setSelectedComponent(null));
        // Add component from the component card.
      } else {
        const component: LayoutComponent = JSON.parse(
          JSON.stringify(Object.assign({}, COMPONENTS_TEMPLATE[addInfo]))
        );

        component.uuid = uuidv4();
        component.data.name = faker.random.words(2).toLowerCase().replace(' ', '_');

        const link: LinkInfo = {
          viewUUID: viewUUID,
          parentUUID: parentUUID ?? viewUUID,
          section: section
        };
        if (!canAddMoreChildren(link.parentUUID)) return;
        let customComponent = {} as CustomComponent;
        if (component.type === COMPONENT_TYPES.CUSTOM) {
          customComponent = await getCustomComponentData(component?.custom_uuid ?? '');
        }
        if (
          !checkComponentIsValidChild(
            component.type,
            components[link.parentUUID].type,
            customComponent.isHierarchy
          )
        )
          return;
        dispatch(addComponent(component, link));
      }
    },
    [dispatch, parentUUID, section, uuid, viewUUID, links, components]
  );

  function searchParent(uuid: string) {
    const parent = Object.keys(links).filter((componentUUID) =>
      links[componentUUID].includes(uuid)
    );
    return parent[0];
  }

  const handleDrop = React.useCallback(
    async (ev: DragEvent) => {
      ev.preventDefault();
      ev.stopPropagation();

      if (!editMode) return;

      let adjacentDrop = null;
      if (isAdvancedDrop) adjacentDrop = isAdvancedDrop();

      const targetUuid =
        adjacentDrop !== null && parentUUID && components[parentUUID]
          ? components[parentUUID].uuid
          : uuid;
      const targetType =
        adjacentDrop !== null && parentUUID && components[parentUUID]
          ? components[parentUUID].type
          : type;
      dispatch(setDraggedComponent(null));
      setIsBeingHoveredWhenDragged && setIsBeingHoveredWhenDragged(false);

      if (ev.dataTransfer == null) return;
      /* Check target component is valid (current component being dragged
      can be dropped into target component) */
      const moveInfo = ev.dataTransfer.getData('exocode/move-source');
      const addInfo = ev.dataTransfer.getData('exocode/component-type');
      let sourceComponentType = null;
      if (moveInfo) {
        const moveSource: MoveSource = JSON.parse(moveInfo);
        sourceComponentType = moveSource.type;
        if (moveSource.uuid === uuid) return;
      } else if (addInfo) {
        sourceComponentType = addInfo;
      }
      const hasSections = COMPONENTS_MANIFEST[targetType].hasSections;
      if (hasSections && !section) {
        return;
      }

      if (!checkComponentIsValidParent(sourceComponentType, targetType)) {
        return;
      }

      /* Don't proceed if:
        - Source component has the same id as the target (it's just going to disappear).
        - Parent component of source is equal to target component (this check is duplicated
          for other views too (page_view)) */
      if (moveInfo) {
        const moveAction: MoveSource = JSON.parse(moveInfo);
        if (moveAction && moveAction.uuid === targetUuid) {
          return;
        }

        if (
          !adjacentDrop &&
          moveAction.parentUUID === targetUuid &&
          moveAction.section === section
        ) {
          if (!handleGetAnchorZone || views[viewUUID]?.editorMode !== EditorModeOptions.NORMAL)
            return;
          handleChangeParentAnchor();
          return;
        }
      }

      // If target component is a custom component do not allow drop nor select.
      if (custom_uuid && editor !== Editor.CUSTOM_COMPONENT) return;

      //-----//
      /* Target component is valid, procede to add a new component/move an
      existing component into another component. */
      //-----//

      const allowDrop = COMPONENTS_MANIFEST[targetType].allowDrop;
      if (allowDrop) {
        if (!canAddMoreChildren(targetUuid)) return;
        const custom = ev.dataTransfer.getData('exocode/custom-component-uuid');
        let customComponent = {} as CustomComponent;
        if (custom) customComponent = await getCustomComponentData(custom);
        if (
          !checkComponentIsValidChild(
            sourceComponentType as string,
            targetType,
            customComponent.isHierarchy
          )
        )
          return;
        // e.g. container, rows, column, etc.
        handleDropOnAllowDropComponents(
          moveInfo,
          addInfo,
          custom,
          adjacentDrop ? adjacentDrop : undefined
        );
      } else {
        // e.g. button, input, etc.
        handleDropOnSingularComponents(moveInfo, addInfo);
      }

      isAdvancedDrop && isAdvancedDrop();
    },
    [
      checkComponentIsValidParent,
      custom_uuid,
      dispatch,
      editor,
      handleDropOnAllowDropComponents,
      handleDropOnSingularComponents,
      section,
      type,
      uuid,
      links,
      components
    ]
  );

  const handleChangeParentAnchor = () => {
    if (!handleGetAnchorZone) return;
    const anchorZone = handleGetAnchorZone();
    if (!anchorZone) return;

    const anchors = anchorZone.split('-');
    const horizontalAlignment = anchors[0];
    const verticalAlignment = anchors[1];
    const changeComponentPropertiesPayload: ChangePropertyPayload[] = [];

    const { horizontal, vertical }: Record<string, string> =
      components[uuid]?.data?.flexDirection === 'column'
        ? { horizontal: horizontalAlignment, vertical: verticalAlignment }
        : { horizontal: verticalAlignment, vertical: horizontalAlignment };

    changeComponentPropertiesPayload.push({
      uuid: uuid,
      key: 'horizontalAlign',
      value: anchorToOrientationMapper[horizontal]
    });
    changeComponentPropertiesPayload.push({
      uuid: uuid,
      key: 'verticalAlign',
      value: anchorToOrientationMapper[vertical]
    });
    dispatch(changeComponentProperties(changeComponentPropertiesPayload));
  };

  useEffect(() => {
    if (!element.current) return;

    const node = element.current;

    node.addEventListener('drop', handleDrop);

    return () => {
      node.removeEventListener('drop', handleDrop);
    };
  }, [element, handleDrop]);
}

export default useDropZone;
