import { useSelector } from 'react-redux';
import { useViewport } from '../studio/components/viewport/ViewportContext';
import { InterfaceStudioState } from '../studio/store';
import { useParams } from 'react-router-dom';

// this hook updates the relative size of page child components
const useCalculateComponentSize = (componentUUID: string, style: any) => {
  const { height: viewportHeight } = useViewport();
  const components = useSelector((state: InterfaceStudioState) => state.components);
  const links = useSelector((state: InterfaceStudioState) => state.links);
  const { uuid: view_uuid } = useParams();

  const calculateFullHeight = (heightString: string) => {
    const percentMatch = heightString?.toString().match(/(\d+)%/);
    const vhMatch = heightString?.toString().match(/(\d+)vh/);

    if (percentMatch) {
      const percentValue = parseFloat(percentMatch[1]);
      const pixelValue = (viewportHeight / 100) * percentValue;

      const updatedCalc = heightString.replace(`${percentMatch[1]}%`, `${pixelValue}px`);
      return updatedCalc;
    }

    if (vhMatch) {
      const percentValue = parseFloat(vhMatch[1]);
      const pixelValue = (viewportHeight / 100) * percentValue;

      const updatedCalc = heightString.replace(`${vhMatch[1]}vh`, `${pixelValue}px`);

      return updatedCalc;
    }

    return heightString;
  };

  if (!componentUUID || !components[componentUUID]) return;

  if (!view_uuid || !links[view_uuid].includes(componentUUID)) return;
  let correctViewportHeight = {
    height: components[componentUUID].data.height + '' + components[componentUUID].data.heightUnit
  };

  if (['%', 'vh'].includes(components[componentUUID].data.heightUnit)) {
    correctViewportHeight = {
      height: (viewportHeight / 100) * components[componentUUID].data.height + 'px'
    };
  }

  if (components[componentUUID].data.optionSizeHeight === 'full') {
    correctViewportHeight = {
      height: calculateFullHeight(components[componentUUID].data.height)
    };
  }

  if (correctViewportHeight) {
    style = {
      ...style,
      height: correctViewportHeight.height
    };
  }

  return style;
};

export default useCalculateComponentSize;
