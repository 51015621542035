import React, { useContext } from 'react';
import LogicBuilderContext from 'modules/logic_builder/store';
import styles from './styles.module.css';
import TagItemRadio from './tag_item_radio';
import { EndpointExtended, Tag } from 'modules/logic_builder/types';
import { EndpointsService } from 'modules/logic_builder/services';

type TagEditorProps = {
  search: string;
  endpointExtended: EndpointExtended;
  setEndpointExtended: (ee: EndpointExtended) => void;
};

function TagSelector(props: TagEditorProps) {
  const { tags } = useContext(LogicBuilderContext);

  const selectedOneTag = async (tag: Tag) => {
    const copyEndpoint = { ...props.endpointExtended };

    const verifying = copyEndpoint.tags.findIndex((item) => item === tag.id);

    if (verifying !== -1) {
      copyEndpoint.tags.splice(verifying, 1);
    } else {
      if (tag.id) {
        copyEndpoint.tags.push(tag.id);
      }
    }

    // change state and saving
    props.setEndpointExtended(copyEndpoint);

    await EndpointsService.updateEndpoint(props.endpointExtended.uuid, copyEndpoint);
  };

  const sortingItems = (a: Tag, b: Tag) => {
    if (!a.id || !b.id) return 0;
    const A = props.endpointExtended.tags.includes(a.id);
    const B = props.endpointExtended.tags.includes(b.id);

    return A === B ? 0 : A ? -1 : 1;
  };

  return (
    <div className={`card p-2 ${styles.TagsWrapper}`}>
      {Object.values(tags)
        .filter((tag) => tag.name.toLowerCase().includes(props.search.toLowerCase()))
        .sort((a, b) => sortingItems(a, b))
        .map((tag) => {
          return (
            <TagItemRadio
              key={tag.id}
              tag={tag}
              onSelect={selectedOneTag}
              tags={props.endpointExtended.tags}
            />
          );
        })}
    </div>
  );
}

export default TagSelector;
