import { MenuType } from 'web_ui/workboard/sidebar/controls/MenuTypeControl/menu_type_selector';
import { SideMenuData, SideMenuStyle } from '.';
import { LayoutComponent } from '../../../types';
import { IconText } from 'web_ui/workboard/sidebar/controls/IconTextControl';

export const SIDE_MENU_TEMPLATE: LayoutComponent<SideMenuData, SideMenuStyle> = {
  uuid: 'empty',
  type: 'SIDEMENU',
  data: {
    links: {
      items: {},
      children: {}
    },
    linkText: IconText.BOTH,
    width: 'fit-content',
    widthUnit: '',
    maxWidth: '',
    maxWidthUnit: '',
    minWidth: 60,
    minWidthUnit: 'px',
    height: 'fit-content',
    heightUnit: '-',
    maxHeight: 'none',
    maxHeightUnit: '',
    minHeight: 200,
    minHeightUnit: '',
    menuType: MenuType.COLLAPSE,
    buttonOrientation: 'Horizontal',
    optionSizeWidth: 'auto',
    optionSizeHeight: 'auto'
  },
  styles: {
    themeColor: 'dark',
    iconMargin: '8',
    iconColor: 'dark',
    iconSize: '20',
    css: ''
    // marginTop: 0,
    // marginRight: 0,
    // marginBottom: 0,
    // marginLeft: 0,
    // borderTop: 0,
    // borderRight: 0,
    // borderBottom: 0,
    // borderLeft: 0,
    // paddingBottom: 10,
    // paddingRight: 10,
    // paddingTop: 10,
    // paddingLeft: 10
  }
};
