import React, { forwardRef, memo, useCallback, useMemo } from 'react';
import { ComponentUUID, VarComponent } from '../../../types';
import { parseCustomCss, removeDeclarationsWithUndefinedValue } from 'utils/cssUtils';
import useCorrectedUrl from 'modules/designer/hooks/correctUrl';
import { useSelector } from 'react-redux';
// import { InterfaceStudioState } from '../../store';
import { convertingCSS } from 'utils/utils';
import { InterfaceStudioState } from '../../store';
import useCalculateComponentSize from 'modules/designer/hooks/useCalculateComponentSize';

export type ImageData = {
  source?: string;
  tooltip?: string;
  width?: number | string;
  widthUnit?: number | string;
  maxWidth?: number | string;
  maxWidthUnit?: string;
  minWidth?: number | string;
  minWidthUnit?: string;
  widthCalc?: number;
  height?: number | string;
  heightUnit?: number | string;
  maxHeight?: number | string;
  maxHeightUnit?: string;
  minHeight?: number | string;
  minHeightUnit?: string;
  variable?: VarComponent;
  optionSizeWidth?: string;
  optionSizeHeight?: string;
};

export type ImageStyles = {
  paddingTop?: number;
  paddingLeft?: number;
  paddingRight?: number;
  paddingBottom?: number;
  borderTop?: number;
  borderLeft?: number;
  borderRight?: number;
  borderBottom?: number;
  borderRadius?: number;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  css?: string;
  borderColor?: string;
  boxshadow?: string;
  classes?: string[];
};

export type ImageComponentProps = {
  uuid: ComponentUUID;
  data: ImageData;
  styles: ImageStyles;
};

function ImageComponent(props: ImageComponentProps, ref: React.Ref<any>) {
  const correctUrl = useCorrectedUrl(props.data.source);
  const theme = useSelector((state: InterfaceStudioState) => state.theme);

  let style = {
    ...(props.data.width &&
      props.data.widthUnit && {
        width: props.data.width + '' + props.data.widthUnit
      }),
    ...(props.data.height &&
      props.data.heightUnit && {
        height: props.data.height + '' + props.data.heightUnit
      }),
    ...(props.data.width &&
      !props.data.widthUnit && {
        width: props.data.width
      }),
    ...(props.data.height &&
      !props.data.heightUnit && {
        height: props.data.height
      }),
    ...(props.data.maxHeight &&
      props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight + '' + props.data.maxHeightUnit
      }),
    ...(props.data.maxHeight &&
      !props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight
      }),
    ...(props.data.minHeight &&
      props.data.minHeightUnit && {
        minHeight: props.data.minHeight + '' + props.data.minHeightUnit
      }),
    ...(props.data.minHeight &&
      !props.data.minHeightUnit && {
        minHeight: props.data.minHeight
      }),
    ...(props.data.maxWidth &&
      props.data.maxWidthUnit && {
        maxWidth: props.data.maxWidth + '' + props.data.maxWidthUnit
      }),
    ...(props.data.maxWidth &&
      !props.data.maxWidthUnit && {
        maxWidth: props.data.maxWidth
      }),
    ...(props.data.minWidth &&
      props.data.minWidthUnit && {
        minWidth: props.data.minWidth + '' + props.data.minWidthUnit
      }),
    ...(!props.data.minWidthUnit && {
      minWidth: props.data.minWidth
    }),
    paddingTop: `${props.styles.paddingTop}px`,
    paddingRight: `${props.styles.paddingRight}px`,
    paddingBottom: `${props.styles.paddingBottom}px`,
    paddingLeft: `${props.styles.paddingLeft}px`,
    borderTop: `${props.styles.borderTop}px solid #ffffff33`,
    borderRight: `${props.styles.borderRight}px solid #ffffff33`,
    borderBottom: `${props.styles.borderBottom}px solid #ffffff33`,
    borderLeft: `${props.styles.borderLeft}px solid #ffffff33`,
    borderRadius: `${props.styles.borderRadius}px`,
    marginTop: `${props.styles.marginTop}px`,
    marginRight: `${props.styles.marginRight}px`,
    marginBottom: `${props.styles.marginBottom}px`,
    marginLeft: `${props.styles.marginLeft}px`,
    borderColor:
      props.styles.borderColor &&
      convertingCSS(props.styles.borderColor, theme.colors[props.styles.borderColor]),
    boxShadow: props.styles.boxshadow,
    overflow: props.styles.boxshadow ? 'visible' : undefined
  };

  if (props.data.widthCalc && props.data.widthUnit !== 'px') {
    style.width = `calc(${style.width} - ${props.data.widthCalc}px)`;
  }

  removeDeclarationsWithUndefinedValue(style);
  style = useCalculateComponentSize(props.uuid, style) ?? style;

  const classes = props.styles.classes?.join(' ') || '';

  return (
    <>
      {!props.data.source ? (
        <img
          ref={ref}
          src={'/assets/default.png'}
          className={`figure-img ${classes}`}
          alt={props.data.tooltip}
          title={props.data.tooltip}
          style={{ ...style, ...parseCustomCss(props.styles.css) }}
        />
      ) : (
        <img
          ref={ref}
          src={correctUrl}
          className="figure-img"
          alt={props.data.tooltip}
          title={props.data.tooltip}
          style={{ ...style, ...parseCustomCss(props.styles.css) }}
        />
      )}
    </>
  );
}

export * from './manifest';
export * from './template';
export default memo(forwardRef(ImageComponent));
