import React, {
  CSSProperties,
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { FolderService, PageService, TemplatesService } from '../../../services';
import { FolderType, ILayoutDefault, Layout, Template } from 'modules/designer/types';
import { useTranslation } from 'react-i18next';
import { TemplatesList } from './templates_list';
import { VIEWS_TYPES } from '../../frames';
import { PopupAlert, PopupAlertVariant } from 'web_ui/popup_alert';
import data from './reserved_words.json';
import { FoldersContext } from '../../FolderContext';

type CreatePageDialogProps = {
  dialogOnClose: () => void;
  showDialog: boolean;
  onCreate: (uuid: string) => void;
  layoutsList: Layout[];
  parentUuid: string;
  layoutDefault: ILayoutDefault;
  isFirstPage?: boolean;
};

const styleNotValidated: CSSProperties = {
  borderColor: 'red',
  backgroundImage: 'none',
  boxShadow: 'none'
};

function CreatePageDialog(props: CreatePageDialogProps) {
  const { module_id, app_id } = useParams();
  const [pageName, setPageName] = useState('');
  const [pageRoute, setPageRoute] = useState('/');
  const [shouldClonePageNameInput, setShouldClonePageNameInput] = useState(true);
  const [selectedLayout, setSelectedLayout] = useState(
    props.layoutDefault ? props.layoutDefault.defaultLayout : ''
  );
  const [validated, setValidated] = useState<boolean>(false);
  const [validRoute, setValidRoute] = useState<boolean>(true);
  const [templates, setTemplates] = useState<Template[]>();
  const [alertMessage, setAlertMessage] = useState('');
  const [alertVariant, setAlertVariant] = useState<PopupAlertVariant>('success');
  const { folders } = useContext(FoldersContext)!;
  const { t } = useTranslation();

  const [selectedTemplate, setSelectedTemplate] = useState('');

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget;

    // validation the name of the page
    if (data.reservedWords.includes(pageName.toLowerCase())) {
      event.stopPropagation();
      event.preventDefault();
      showErrorPopup(t('designer.page.errorReservedWord'));
      return;
    }

    if (form.checkValidity() === false) {
      event.stopPropagation();
      event.preventDefault();
    } else {
      event.preventDefault();

      if (!module_id) return;

      const pagesFolderId = props.isFirstPage ? await getPagesFolderId() : null;

      try {
        await PageService.createPage(
          module_id,
          pageName,
          pageRoute,
          '', // page description
          selectedLayout === 'default' ? '' : selectedLayout,
          selectedTemplate,
          !props.parentUuid && pagesFolderId ? pagesFolderId : props.parentUuid
          // isMainPage
        ).then((page) => {
          onClose();
          props.onCreate(page.uuid);
        });
      } catch (error) {
        if (error instanceof Error) {
          showErrorPopup(error.message);
        }
      }
    }

    setValidated(true);
  };

  const showErrorPopup = (message: string) => {
    setAlertVariant('danger');
    setAlertMessage(message);
  };

  const fetchTemplates = useCallback(async (app_id) => {
    await TemplatesService.getTemplates('createPage', VIEWS_TYPES.PAGE, app_id).then(
      (fetchedTemplates) => {
        setTemplates(fetchedTemplates);
        if (fetchedTemplates.length) {
          setSelectedTemplate(fetchedTemplates[0].uuid);
        }
      }
    );
  }, []);

  useEffect(() => {
    if (!app_id || !props.showDialog) return;
    fetchTemplates(app_id);
  }, [app_id, fetchTemplates, props.showDialog]);

  const handleLayoutSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedLayout(e.target.value);
  };

  const handlePageNameOnChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // Clone pageName into pageRoute input.
      if (shouldClonePageNameInput) {
        const formattedPageRoute = event.target.value.replace(/\s/g, '_').toLowerCase();
        setPageRoute(`/${formattedPageRoute}`);
      }
      setPageName(event.target.value);
    },
    [shouldClonePageNameInput]
  );

  const handlePageRouteOnChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // If page route input has changed, then stop cloning pageName into pageRoute input.
      const strPageNameAux: string = event.target.value;

      if (strPageNameAux[0] !== '/') {
        setValidRoute(false);
      } else {
        setValidRoute(true);
      }
      setShouldClonePageNameInput(false);
      setPageRoute(event.target.value);
    },
    []
  );

  const t_LAYOUT_SELECT = t('designer.page.LayoutSelect');

  const onClose = () => {
    props.dialogOnClose();
    setPageName('');
    setPageRoute('/');
    setSelectedLayout(props.layoutDefault ? props.layoutDefault.defaultLayout : '');
  };

  const hideAlertPopup = () => {
    setAlertMessage('');
  };

  const getPagesFolderId = async () => {
    if (folders && folders.length > 0) {
      const pageFolderIndex = folders.findIndex((folder) => folder.name === 'Pages');

      if (pageFolderIndex !== -1 && folders[pageFolderIndex]) {
        return folders[pageFolderIndex].uuid;
      }
    }

    if (module_id) {
      const createdPagesFolder = await FolderService.createFolder(
        module_id,
        'Pages',
        '',
        FolderType.UI
      );
      return createdPagesFolder.uuid;
    }

    return '';
  };

  return (
    <>
      {alertMessage && (
        <Modal
          show={alertMessage ? true : false}
          style={{ background: 'transparent', width: 'auto' }}
          centered={false}
        >
          <PopupAlert i18nKey={alertMessage} onClose={hideAlertPopup} variant={alertVariant} />
        </Modal>
      )}
      <Modal centered show={props.showDialog} onHide={onClose}>
        <Form noValidate={true} onSubmit={onSubmit} validated={validated}>
          <Modal.Header closeButton>
            <Modal.Title>{t('designer.page.CreatePage')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Form.Group className="mb-3">
                <Form.Label>{t('Name')}</Form.Label>
                <Form.Control
                  type="text"
                  id="formName"
                  onChange={handlePageNameOnChange}
                  value={pageName}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>{t('designer.page.Route')}</Form.Label>
                <Form.Control
                  type="text"
                  id="formRout"
                  onChange={handlePageRouteOnChange}
                  value={pageRoute}
                  style={validRoute === false ? styleNotValidated : {}}
                  required
                />
                <Form.Control.Feedback
                  type="invalid"
                  style={validRoute === false ? { display: 'flex' } : {}}
                >
                  {t('designer.page.validationURL')}
                </Form.Control.Feedback>
              </Form.Group>

              {/* Select Layouts */}
              {props.layoutsList != null && (
                <Form.Group className="mb-3">
                  <Form.Label>{t('designer.page.Layout')}</Form.Label>
                  <Form.Select
                    aria-label={t_LAYOUT_SELECT}
                    onChange={handleLayoutSelect}
                    value={selectedLayout}
                  >
                    <option value="default">{t('designer.page.LayoutSelect')}</option>
                    {props.layoutsList.map((layout) => {
                      return (
                        <option key={layout.uuid} value={layout.uuid}>
                          {layout.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              )}
            </div>
            <Form.Label>{t('designer.page.ChooseTemplate')}</Form.Label>
            <TemplatesList
              templates={templates ?? []}
              selectedTemplate={selectedTemplate}
              setSelectedTemplate={(template: string) => setSelectedTemplate(template)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button id="saveButton" type="submit">
              {t('designer.page.CreatePage')}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default CreatePageDialog;
