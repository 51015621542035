import { UpdateUserApp } from 'modules/auth/types/auth_types';
import { ProjectsRepo } from '../repos';
import { AppFullInfo, AppInfo, AppVersionInfo, JobInfo, JobOptions } from '../types';
import { ModuleInfo, ModuleQuery } from 'modules/dashboard/types';

export default class Projects {
  async createApp(data: AppFullInfo & { selectedLanguage?: string }) {
    return ProjectsRepo.createApp(data);
  }

  async createFavIcon(data: FormData, appId: string) {
    return ProjectsRepo.createFavIconApp(data, appId);
  }

  async deleteFavIcon(appId: string) {
    return ProjectsRepo.deleteFavIconApp(appId);
  }

  async createAppVersion(appId: string, appVersion: AppVersionInfo) {
    return ProjectsRepo.createAppVersion(appId, appVersion);
  }

  async getProjectById(appId: string) {
    return ProjectsRepo.getProjectById(appId);
  }

  async getProjects() {
    return ProjectsRepo.getProjects();
  }

  async updateProjectById(appId: string, data: AppFullInfo | AppInfo) {
    return ProjectsRepo.updateProjectById(appId, data);
  }

  async deleteProjectById(appId: string) {
    return ProjectsRepo.deleteProject(appId);
  }

  async getModuleById(moduleId: string) {
    return ProjectsRepo.getModuleById(moduleId);
  }

  async createModule(moduleQuery: ModuleQuery) {
    return ProjectsRepo.createModule(moduleQuery);
  }

  async updateModuleById(moduleId: string, moduleQuery: ModuleQuery): Promise<ModuleInfo> {
    return ProjectsRepo.updateModuleById(moduleId, moduleQuery);
  }

  async deleteModuleById(moduleId: string) {
    return ProjectsRepo.deleteModuleById(moduleId);
  }

  async runJob(appId: string, jobOptions: JobOptions) {
    return await ProjectsRepo.runJob(appId, jobOptions);
  }

  async getJobStatus(appId: string, jobId: string) {
    return await ProjectsRepo.getJobStatus(appId, jobId);
  }

  async getJobResult(appId: string, jobId: string) {
    return await ProjectsRepo.getJobResult(appId, jobId);
  }

  async deleteJob(appId: string, jobId: string) {
    return await ProjectsRepo.deleteJob(appId, jobId);
  }

  async getActiveJobsByApp(appId: string) {
    const jobs = await ProjectsRepo.getJobList(appId);
    const jobsMap: Record<string, JobInfo> = {};
    jobs.length > 0 && jobs.forEach((job) => (jobsMap[job.id] = job));
    return jobsMap;
  }

  async getMembersOfApp(appId: string) {
    return ProjectsRepo.getMembersOfApp(appId);
  }

  async updateMembersApp(appId: string, members: UpdateUserApp) {
    return ProjectsRepo.updateMembersApp(appId, members);
  }
}
