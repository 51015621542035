import produce from 'immer';
import { CssClass } from 'web_ui/workboard/sidebar/controls/CssClassesControl/types';
import {
  ADD_CSS_CLASS,
  AddCssClassAction,
  DELETE_CSS_CLASS,
  DeleteCssClassAction,
  SET_CSS_CLASSES,
  SetCssClassesAction,
  UPDATE_CSS_CLASS,
  UPDATE_CSS_CLASSES_ORDER,
  UpdateCssClassAction,
  UpdateCssClassesOrderAction
} from '../actions/cssClasses';

type CssClassActions =
  | SetCssClassesAction
  | AddCssClassAction
  | UpdateCssClassAction
  | DeleteCssClassAction
  | UpdateCssClassesOrderAction;

export const cssClassesReducer = (state: CssClass[] = [], action: CssClassActions): CssClass[] => {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_CSS_CLASSES:
        return doSetCssClasses(draft, action);
      case ADD_CSS_CLASS:
        return doAddCssClass(draft, action);
      case UPDATE_CSS_CLASS:
        return doUpdateCssClass(draft, action);
      case DELETE_CSS_CLASS:
        return doDeleteCssClass(draft, action);
      case UPDATE_CSS_CLASSES_ORDER:
        return doUpdateCssClassesOrder(draft, action);
      default:
        return draft;
    }
  });
};

function doSetCssClasses(_: CssClass[], action: SetCssClassesAction): CssClass[] {
  return action.payload.cssClasses;
}

function doAddCssClass(state: CssClass[], action: AddCssClassAction): CssClass[] {
  state.push(action.payload.cssClass);
  return state;
}

function doUpdateCssClass(state: CssClass[], action: UpdateCssClassAction): CssClass[] {
  const index = state.findIndex((cssClass) => cssClass.id === action.payload.cssClass.id);
  state[index] = action.payload.cssClass;
  return state;
}

function doDeleteCssClass(state: CssClass[], action: DeleteCssClassAction): CssClass[] {
  const index = state.findIndex((cssClass) => cssClass.id === action.payload.id);
  state.splice(index, 1);
  return state;
}

function doUpdateCssClassesOrder(
  state: CssClass[],
  action: UpdateCssClassesOrderAction
): CssClass[] {
  const updatedItems = [...state];
  const [movedItem] = updatedItems.splice(action.payload.oldIndex, 1);
  updatedItems.splice(action.payload.newIndex, 0, movedItem);
  return updatedItems;
}
