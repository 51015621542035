import React, { useEffect, useRef } from 'react';

import styles from './styles.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedComponent, setSelectedView, setViewportMode } from '../../store/actions/studio';
import { ComponentUUID, ViewUUID } from '../../../types';

import Viewport from '../../components/viewport';
import { InterfaceStudioState } from '../../store';

export type ViewData = {
  title: string;
  description: string;
  route: string;
};

export type ViewStyles = {
  backgroundColor: string;
  globalFont: string;
  globalFontSize: number;
  globalFontColor: string;
};

export type LayoutViewProps = {
  uuid: ViewUUID;
  data: ViewData;
  name: string;
  layout: ComponentUUID[];
  styles?: ViewStyles;
};

function LayoutView(props: LayoutViewProps) {
  const dispatch = useDispatch();
  const elementRef = useRef<HTMLDivElement>(null);
  const selectedViewport = useSelector((state: InterfaceStudioState) => state.studio.viewportMode);

  const style = {
    backgroundColor: props.styles?.backgroundColor,
    fontFamily: `${props.styles?.globalFont}`,
    fontSize: `${props.styles?.globalFontSize}px`,
    color: props.styles?.globalFontColor
  };

  function handleBrowserClick() {
    dispatch(setSelectedComponent(null));
  }

  function handleSelectView() {
    dispatch(setSelectedView(props.uuid));
    dispatch(setSelectedComponent(null));
  }

  return (
    <>
      <section
        className={`${styles[selectedViewport]} shadow`}
        ref={elementRef}
        onClick={handleSelectView}
      >
        <Viewport uuid={props.uuid} styles={style} />
      </section>
    </>
  );
}

export * from './manifest';
export default LayoutView;
