import React, { useEffect, useState } from 'react';
import styles from '../styles.module.css';
import { ControlProps } from '../index';
import { DefaultInput, DefaultLabel } from '../styles';
import HelpPopover from '../components/Popover';
import HelpIcon from '../components/HelpIcon';
import { useTranslation } from 'react-i18next';

function TextControl({
  value,
  onChange,
  id,
  label,
  maxLength,
  validation,
  tooltip,
  errorMessages
}: ControlProps) {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string>(value ?? '');
  const [isValid, setIsValid] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (value !== inputValue) {
      setInputValue(value ?? '');
    }
  }, [value]);

  const handleChange = (newValue: string) => {
    setInputValue(newValue);
    if (validation) {
      const { valid, code } = validation(newValue);
      setIsValid(valid);
      setErrorMessage(errorMessages?.[code ?? ''] ?? '');
    }
    if (onChange) {
      onChange(newValue, id);
    }
  };

  return (
    <div
      className="mb-3"
      style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}
    >
      <div style={{ marginBottom: '.5rem' }}>
        <DefaultLabel
          style={{ display: 'flex', justifyContent: 'flex-start', whiteSpace: 'nowrap' }}
        >
          {label}
          {tooltip && (
            <HelpPopover
              placement="top"
              helpBoxProps={{
                description: t(`${tooltip}`) ?? ''
              }}
            >
              <HelpIcon />
            </HelpPopover>
          )}
        </DefaultLabel>
      </div>
      <DefaultInput
        id={`form${label}`}
        type="text"
        className={`${styles.defaultInput} form-control form-control-sm ${
          isValid === false && styles.invalidTextInput
        }`}
        value={inputValue}
        onChange={(e) => handleChange(e.target.value)}
        maxLength={maxLength}
      />
      {errorMessage && <span className={styles.errorMsg}>{t(errorMessage)}</span>}
    </div>
  );
}

export default TextControl;
