import React, {
  ComponentType,
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { XY } from './MoveElement';
import { WorkboardContext } from '..';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeWidget,
  deleteWidget,
  updateWidgetPosition
} from 'modules/designer/studio/store/actions/widgets';
import { InterfaceStudioState } from 'modules/designer/studio/store';
import { DatabaseStudioState } from 'modules/modeler/studio/store';
import { WidgetType, WidgetUUID } from './types';
import ContextMenu from '../ContextMenu';
import ContextMenuItem from '../ContextMenuItem';
import Confirmation from 'web_ui/confirmation';
import { useTranslation } from 'react-i18next';

export type WidgetWrapperProps = {
  uuid: WidgetUUID;
  type: keyof typeof WidgetType;
  point: XY;
  data: any;
};

export function WidgetWrapper(Element: ComponentType<any>) {
  const Widget = (props: WidgetWrapperProps) => {
    const workboardContext = useContext(WorkboardContext);
    const widgetWrapperRef = useRef<HTMLDivElement>();
    const [shouldActivateWidget, setShouldActivateWidget] = useState(false);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const widget = useSelector(
      (state: InterfaceStudioState | DatabaseStudioState) => state.widgets[props.uuid]
    );
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const documentMouseMove = React.useCallback(
      (event: MouseEvent) => {
        if (!widgetWrapperRef.current) return;

        if (!props.point.allowedToMove) return;

        const currentCursorX = props.point.x - event.clientX;
        const currentCursorY = props.point.y - event.clientY;

        props.point.x = event.clientX;
        props.point.y = event.clientY;

        widgetWrapperRef.current.style.top =
          widgetWrapperRef.current.offsetTop - currentCursorY / workboardContext.scale + 'px';
        widgetWrapperRef.current.style.left =
          widgetWrapperRef.current.offsetLeft - currentCursorX / workboardContext.scale + 'px';
      },
      [props.point, widgetWrapperRef, workboardContext.scale]
    );

    // Use this to update widget under the widget component.
    const sendChangeAction = useCallback(
      (widgetData: any) => {
        dispatch(changeWidget({ ...widget, data: widgetData }));
      },
      [dispatch, widget]
    );

    const documentMouseUp = React.useCallback(
      (event: MouseEvent) => {
        if (!widgetWrapperRef.current) return;

        const newHeight = widgetWrapperRef.current.clientHeight;
        const newWidth = widgetWrapperRef.current.clientWidth;

        // If mouse did not move and widget type allows activation then set shouldActivateWidget
        // to true. If this event was fired with mouse right click (event.button === 2), then do
        // not activate widget.
        // TODO: Actually, we should also have a property on type Widget, saying whether or not widget
        //   is selectable. Because if it is not selectable, when you click on it without moving it,
        //   allowedToMove and shouldActivateWidget should not change and widget still should be able
        //   move around. Something like this: props.widgetCanBeActivated.
        if (
          event.clientX === props.point.initialX &&
          event.clientY === props.point.initialY &&
          // props.widgetCanBeActivated &&
          props.type === WidgetType.STICKY_NOTE &&
          event.button !== 2
        ) {
          // TODO: move control over shouldActivateWidget and allowedToMove values over to the widget component itself.
          setShouldActivateWidget(true);
          props.point.allowedToMove = false;
        }

        if (
          props.point.allowedToMove &&
          (props.point.initialX !== event.clientX || props.point.initialY !== event.clientY) &&
          // allowedToMove indicates whether or not we are allowed to update the widget's position.
          // allowedToMove is basically the opposite of shouldActivateWidget (not allowed to update
          // when widget is active: StickyNote editing modal is open for example).
          props.point.allowedToMove
        ) {
          const currentCursorX = props.point.x - event.clientX;
          const currentCursorY = props.point.y - event.clientY;
          const newPosX =
            widgetWrapperRef.current.offsetLeft - currentCursorY / workboardContext.scale;
          const newPosY =
            widgetWrapperRef.current.offsetTop - currentCursorX / workboardContext.scale;
          dispatch(updateWidgetPosition(props.uuid, newPosX, newPosY));
        }

        if (
          !props.point.allowedToMove &&
          (props.point.initialDivX !== newWidth || props.point.initialDivY !== newHeight)
        ) {
          const widgetData = { ...props.data, height: newHeight, width: newWidth };
          sendChangeAction(widgetData);
        }

        document.removeEventListener('mousemove', documentMouseMove);
        document.removeEventListener('mouseup', documentMouseUp);
      },
      [
        dispatch,
        documentMouseMove,
        props.data,
        props.point,
        props.type,
        props.uuid,
        sendChangeAction,
        widgetWrapperRef,
        workboardContext.scale
      ]
    );

    const handleMouseDown = React.useCallback(
      (event: MouseEvent) => {
        if (props.point.allowedToMove) {
          event.preventDefault();
        }

        if (!widgetWrapperRef.current) return;

        // Do not move widget if event was fired with mouse right click. Just open the widget
        // context menu instead.
        if (event.button === 2) return;

        props.point.initiate(event.clientX, event.clientY);

        props.point.initialDivX = widgetWrapperRef.current.clientWidth;
        props.point.initialDivY = widgetWrapperRef.current.clientHeight;

        document.addEventListener('mousemove', documentMouseMove);
        document.addEventListener('mouseup', documentMouseUp);
      },
      [documentMouseMove, documentMouseUp, props.point, widgetWrapperRef]
    );

    const handleDeleteWidget = React.useCallback(() => {
      dispatch(deleteWidget(props.uuid));
    }, [dispatch, props.uuid]);

    useEffect(() => {
      if (!widgetWrapperRef.current) return;

      // ! This is necessary because the class props.point resets (widgetsRenderer rerenders)
      if (shouldActivateWidget) {
        props.point.allowedToMove = false;
      }

      const node = widgetWrapperRef.current;

      node.addEventListener('mousedown', handleMouseDown);

      return () => {
        node.removeEventListener('mousedown', handleMouseDown);
        document.removeEventListener('mouseup', documentMouseUp);
        document.removeEventListener('mousemove', documentMouseMove);
      };
    }, [
      documentMouseMove,
      documentMouseUp,
      handleMouseDown,
      props.point,
      shouldActivateWidget,
      widgetWrapperRef
    ]);

    useEffect(() => {
      if (!widgetWrapperRef.current) return;

      if (shouldActivateWidget) {
        widgetWrapperRef.current.style.zIndex = '999';
      } else {
        widgetWrapperRef.current.style.zIndex = '1';
      }
    }, [shouldActivateWidget, widgetWrapperRef]);

    return (
      <>
        {/* TODO: Maybe change zIndex inside widget component? Or create a class that controls every component in the workboard. */}
        <Element
          ref={widgetWrapperRef}
          // Not necessary, but can be used to indicate widget selection. For example,
          // StickyNotes expands for editing when widget is active.
          shouldActivateWidget={shouldActivateWidget}
          setShouldActivateWidget={(value: boolean) => setShouldActivateWidget(value)}
          // For now allowedToMove is the opposite of shouldActivateWidget.
          // If true then do not update xy coordinates.
          allowedToMove={(value: boolean) => {
            props.point.allowedToMove = value;
          }}
          // Update widget (update redux action).
          sendChangeAction={sendChangeAction}
          uuid={props.uuid}
          type={props.type}
        />
        {!shouldActivateWidget && (
          <ContextMenu elementRef={widgetWrapperRef} offsetX={96} offsetY={79}>
            <ContextMenuItem
              icon="trash"
              label={t('modeler.DeleteWidget')}
              onClick={() => setShowConfirmationDialog(true)}
            />
          </ContextMenu>
        )}
        <Confirmation
          show={showConfirmationDialog}
          message={t('modeler.Delete Note')}
          onConfirmation={() => handleDeleteWidget()}
          onCancel={() => setShowConfirmationDialog(false)}
          onClose={() => {
            setShowConfirmationDialog(false);
          }}
          confirmationLabel={t('Confirm') as string}
          cancelLabel={t('Cancel') as string}
        />
      </>
    );
  };
  return memo(Widget);
}
